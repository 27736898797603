import { FC, useState } from 'react'
import classes from './ShippmentPackages.module.scss'
import { IPackages, IPackagesProps, IShipToBox } from 'src/api/postApproval/boxing/model'
import PackagesItems from './PackagesItems/PackagesItems'
const ShippmentPackages: FC<IPackagesProps> = ({ data, handleChangeData,setSendDataForBack, createPrintShippmentLabel, errorRequest }): JSX.Element => {
  const [packagesData, setPackagesData] = useState<IPackages>(data)
  
  const addItem = (): void => {
    setPackagesData(prev => {
      const lastBox: IShipToBox = prev.boxes.length ? prev.boxes[prev.boxes.length - 1] : {} as IShipToBox 
      return {
        ...prev,
        boxes: [...prev.boxes, lastBox]        
      }
    })
    setSendDataForBack(prev => {
      const updatedPrev = { ...prev }
      Object.keys(updatedPrev).forEach(key => {
        const value = updatedPrev[key]
        if (Array.isArray(value)) {
          updatedPrev[key] = [...value, value[value.length - 1]]
        }
      })
  
      return updatedPrev
    })
  }
  
  const removePackages = (index: number): void => {
    setPackagesData({ boxes:[ ...packagesData.boxes.filter((_, i) => i !== index )] })
    setSendDataForBack(prev => {
      const updatedPrev = { ...prev }
      Object.keys(updatedPrev).forEach(key => {
        const value = updatedPrev[key]
        if (Array.isArray(value)) {
          updatedPrev[key] = updatedPrev[key].filter((_,i) => i !== index)
        }
      })
  
      return updatedPrev
    })
  }

  return (
    <div className={classes.shippmentPackages}>
      {
        packagesData.boxes.length ? packagesData.boxes.map((e, index) => (
          <PackagesItems 
            key={index}
            item={e}
            isRemove={data.boxes.length - 1 !== index}
            index={index}
            removePackages={removePackages}
            handleChangeData={handleChangeData}
            errorRequest={errorRequest}
          />
        )) : null
      }
      <button onClick={addItem}>Add packages</button>
      <button onClick={createPrintShippmentLabel}>Request Shippment</button>
    </div>
  )
}
export default ShippmentPackages