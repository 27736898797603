import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, Suspense, lazy, useState } from 'react'
import { toast } from 'react-toastify'
import { deleteGang, editGang, getImportGangCSV, postSaveGang, postStartStripping } from 'src/api/postApproval/stripper/stripper'
import { IStripperOrderHeader } from 'src/Components/PostApproval/model'
import useModal from 'src/ui/modal/toggleModal'
import { dateConvertToStringToDate } from 'src/utils/dateConvertToString'
import { downloadCSV } from 'src/utils/downloadCSV'
import { IResponseError } from 'src/utils/errorMessage/model'
import classes from '../../Stripper.module.scss'
const GangsSettings = lazy(() => import('src/Components/PostApproval/Gangs/GangsSettings/GangsSettings'))
const StripperOrderHeader: FC<IStripperOrderHeader> = ({ gangId, gangJobs, headerData, closeSingleGang }): JSX.Element => {

  const [startStrippingSuccessMessage, setStartStrippingSuccessMessage] = useState<string>('')
  const [savedGangId, setSavedGangId] = useState<number | null>(null)
  const { isShowing, toggle } = useModal()

  const removeGang = async (): Promise<void> => {
    try {
      if (gangId) {
        await deleteGang(gangId)
        toast.success('Gang deleted successfully')
        closeSingleGang?.()
      }
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }


  const saveStripping = async (): Promise<void> => {
    try {
      const res = await postSaveGang({ gangJobs })
      if (res.gang.length) {
        setSavedGangId(res.gang[0].gang_id)
        setStartStrippingSuccessMessage(await postStartStripping(res.gang[0].gang_id))
      }
      toast.success('Stripping saved successfully')
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }

  const importCSV = async (): Promise<void> => {
    try {
      let saveResponseGangId
      let invalidJob
      if (!headerData?.gangId) {
        const response = await postSaveGang({ gangJobs })
        saveResponseGangId = response?.gang[0]?.gang_id
      } else {
        try {
          const res = await editGang({ gangId: headerData.gangId, gangJobs })
          saveResponseGangId = res?.gang[0]?.gang_id
          invalidJob = res.invalidJobs.length
        } catch (error) {
          const errorMessage = error as IResponseError
          toast.error(Object.values(errorMessage.response.data)[0])
        }
      }

      if (saveResponseGangId || !invalidJob.length) {
        const res = await getImportGangCSV(saveResponseGangId)
        downloadCSV<{'': string}[]>([{
          '': res
        }], 'gang_data')
      } else {
        toast.error('Invalid job: ' + invalidJob)
      }
    } catch (error) {
      const errorMessage = error as IResponseError
      if (errorMessage?.response?.data && 'detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }
  const closePopup = (isCancel?: boolean): void => {
    isCancel && setSavedGangId(null)
    toggle()
    closeSingleGang?.()
  }
  const StrippingButtonRender = (): JSX.Element => {
    return <>
      <button onClick={importCSV}>Imp</button>
      <button>Metrix</button>
      <button
        onClick={startStrippingSuccessMessage.length ? toggle : saveStripping}>
        {startStrippingSuccessMessage.length ? 'Finish Stripping' : 'Start Stripping'}
      </button>
      <button onClick={() => gangId ? removeGang() : closeSingleGang?.()}>
        <FontAwesomeIcon icon={faTrash} /> Delete
      </button>
      <button onClick={() => closeSingleGang?.()}>Exit</button>
    </>
  }

  return (
    <div className={classes.stripper_container_tableBlock_header}>
      {
        Object.keys(headerData).length ? Object.entries(headerData).map(([key, value]) => (
          <span key={key}>{ key === 'timestamp' ? dateConvertToStringToDate(String(value)) : value}</span>
        )) : null
      }
      <StrippingButtonRender/>
      {
        savedGangId && isShowing &&
        <Suspense fallback={null}>
          <GangsSettings
            pageName='stripper'
            gangId={savedGangId}
            closedBlock={closePopup}/>
        </Suspense>
      }
    </div>
  )
}
export default StripperOrderHeader