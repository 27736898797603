import { faBan, faEye, faFloppyDisk, faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, Suspense, lazy, useEffect, useState } from 'react'
import classes from './Timming.module.scss'
import { IGetOneWorkflowSettingById, IPostTimming } from 'src/api/settings/workflow-settings/model'
import { deleteTimming, getTimming, patchTimming, postTimming } from 'src/api/settings/workflow-settings/workflow-settings'
import { IConditionalRender, ITimmingProps } from '../../model'
import useModal from 'src/ui/modal/toggleModal'
import { IErrorMessage, IResponseError } from 'src/utils/errorMessage/model'
import { toast } from 'react-toastify'
import { toastErrorOrFieldError } from 'src/utils/errorMessage/errorMessage'
const TimmingAction = lazy(() => import('./TimmingAction/TimmingAction')) 
const CreateWorkflowSetting = lazy(() => import('./CreateTimming/CreateTimming')) 
const DeletePopup = lazy(() => import('src/Components/DeletePopup/DeletePopup'))

const Timming: FC<ITimmingProps> = ({ toggleItem, id }): JSX.Element => {
  const { isShowing, toggle } = useModal()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [errorRequest, setErrorRequest] = useState<IErrorMessage>({})
  const [timmingInfo, setTimmingInfo] = useState<IGetOneWorkflowSettingById[]>([])
  const [conditionRender, setConditionRender] = useState<IConditionalRender>({ pageName: '' })
  const [editTimmingData, setEditTimmingData] = useState<IPostTimming>({ } as IPostTimming )

  const oneWorkflowSettingById = async (id: number): Promise<void> => {
    try {
      setTimmingInfo( await getTimming(id))
    } catch (error) {
      console.log(error)      
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (toggleItem === id ) {
      oneWorkflowSettingById(id)
    }
  },[id, toggleItem])

  const removeWorkflowSetting = async (): Promise<void> => {
    try {
      if (conditionRender.id) {
        await deleteTimming(conditionRender.id)
        setTimmingInfo(prev => {
          return prev.filter(e => e.id !== conditionRender.id)
        })
        toast.success('Workflow Setting deleted successfully')
        toggle()
      }
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }

  const handleChange = (field: string, value: string | number): void => {
    if (!Object.values(errorRequest).every(error => error === '')) {
      setErrorRequest({ ...errorRequest, [field]: '' })
    }
    setEditTimmingData(prev => {
      if (field !== 'name') {
        return {
          ...prev,
          [field]: value
        }
      } else {
        return {
          ...prev,
          lable: {
            name: String(value)
          }
        }
      }
    })
  }

  const createTimming = async (): Promise<void> => {
    try {
      const res = await postTimming(editTimmingData)
      setTimmingInfo([...timmingInfo, {
        id: res.timingId,
        hours: res.hours,
        parent_label_id: res.parentLabelId,
        label_id: res.labelId,
        name: editTimmingData.lable.name,
        color: res.color
      }])
      toast.success('Timming created successfully')
      setConditionRender({ pageName:'' })
    } catch (error) {
      const errorMessage = error as IResponseError
      toastErrorOrFieldError(errorMessage?.response?.data, setErrorRequest)
              
    }
  }

  const editTimming = async (id: number): Promise<void> => {
    try {
      
      const res = await patchTimming(id, editTimmingData)
      setTimmingInfo(prev => {
        const updatedArray = prev.map(e => {
          if (e.id === res.timingId) { 
            e = {
              id: res.timingId,
              hours: res.hours,
              parent_label_id: res.parentLabelId,
              label_id: res.labelId,
              name: editTimmingData.lable.name,
              color: res.color
            }
          }
          return e
        })
        return updatedArray
      })
      
      toast.success('Timming updated successfully')
      setConditionRender({ pageName:'' })
    } catch (error) {
      const errorMessage = error as IResponseError
      toastErrorOrFieldError(errorMessage?.response?.data, setErrorRequest)
      
    }
  }

  const handleSave = (): void => {
    if (conditionRender.id) {
      editTimming(conditionRender.id)
    } else {
      createTimming()
    }
  }

  const handleOpenEdit = (field: string,editData?: IGetOneWorkflowSettingById): void => {
    if (field === 'edit' && editData) {

      setConditionRender({ pageName:field, id: editData.id })
      
      setEditTimmingData({
        hours: editData.hours,
        parentLabelId: editData.parent_label_id,
        color: editData.color,
        labelId: editData.label_id,
        lable: {
          name: editData.name
        }
      })
    } else {
      
      setConditionRender({ pageName:field, })
      
      setEditTimmingData({ parentLabelId:id } as IPostTimming)
    }
  }
  

  return (
    <div className={`${classes.timming} ${toggleItem === id ? classes.timming_open : ''}`}>
      <div className={classes.timming_container}>
        <div className={classes.timming_container_title}>
          <span>Timing Conditions</span>
        </div>
        <div className={classes.timming_container_btnBlock}>
          <span>Timing Conditions -</span>
          { conditionRender.pageName !== 'create' && conditionRender.pageName !== 'edit' ?
            <>
              <button onClick={() => handleOpenEdit('create')}><FontAwesomeIcon icon={faPlus}/>Add</button>
            </> :
            <>
              <button onClick={handleSave}> <FontAwesomeIcon icon={faFloppyDisk} />save</button>
              <button onClick={() =>setConditionRender({ pageName:'' })}> <FontAwesomeIcon icon={faBan} />cancel</button>
            </>}
        </div>
        {
          (conditionRender.pageName === 'create' || conditionRender.pageName === 'edit') &&
            <Suspense fallback={null}>
              <CreateWorkflowSetting handleChange={handleChange} editTimmingData={editTimmingData} errorRequest={errorRequest}/>
            </Suspense>
        }
        <div className={classes.timming_container_body}>
          {
            timmingInfo.length ?
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Hours</th>
                    <th>Color</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    timmingInfo.map(workflow => (
                      <tr key={workflow.parent_label_id}>
                        <td>{workflow.name}</td>
                        <td>{workflow.hours}</td>
                        <td>{workflow.color}</td>
                        <td> 
                          <FontAwesomeIcon onClick={() => handleOpenEdit('edit',workflow)} icon={faPen} color='#2e6e9e'/>
                          <FontAwesomeIcon onClick={() => setConditionRender({ pageName:'view', id: workflow.id })} icon={faEye} color='#2e6e9e' />
                          <FontAwesomeIcon onClick={() =>{
                            setConditionRender({ pageName:'delete', id:workflow.id })
                            toggle()
                          }} icon={faTrash} color='#2e6e9e'/>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table> : null
          }
          {
            isLoading && <p>Loading...</p> 
          }
          {
            !isLoading && !timmingInfo.length && <p>Data not found</p>
          }
          {
            conditionRender.pageName === 'view' &&
            <Suspense fallback={null}>
              <TimmingAction setConditionRender={setConditionRender} id={conditionRender.id}/>
            </Suspense>
          }
        </div>
      </div>
      {
        isShowing && <Suspense fallback={null}>
          <DeletePopup toggle={toggle} remove={removeWorkflowSetting}/>
        </Suspense>
      }
    </div>
  )
}

export default Timming
