import { Fragment, useEffect, useState } from 'react'
import classes from './PrintShippment.module.scss'
import { IShipping } from 'src/api/postApproval/shipping/model'
import { useLocation } from 'react-router-dom'
import { getPrintShipping, postPrintMultipleShippments } from 'src/api/postApproval/shipping/shipping'
import { floatNumberCut } from 'src/utils/FloatNumberCut'
import { shippmentIdListStore } from 'src/stores/shippmentIdListStore'
import { observer } from 'mobx-react-lite'
const PrintShippment = (): JSX.Element => {
  const [printShippmentInfo, setPrintShippmentInfo] = useState<IShipping[]>([])
  const location = useLocation().pathname.split('/').pop()
  const shipmentIds = shippmentIdListStore.shippmentId
  
  const printShippmentData = async (shippmentId: string): Promise<void> => {
    try {
      setPrintShippmentInfo( await getPrintShipping(shippmentId))
    } catch (error) {
      console.log(error)
        
    }
  }
  
  const printShippmentArr = async (): Promise<void> => {
    try {
      if (shipmentIds?.length) {
        setPrintShippmentInfo( await postPrintMultipleShippments(shipmentIds))
      }
    } catch (error) {
      console.log(error)
        
    }
  }

  useEffect(() => {
    if (location && location.toUpperCase() === location) {
      printShippmentData(location)
    } else {
      printShippmentArr()
    }
  },[location])

  return (
    <div className={classes.printShippment}>
      <div className={classes.printShippment_container}>
        <table>
          <tbody>
            {
              printShippmentInfo.length ? printShippmentInfo.map((e, index) => (
                <Fragment key={index}>
                  <tr>
                    <td>Order {e?.order_id}</td>
                    <td>Shippment {e?.number_of} Of {e?.of} </td>
                    <td>{e?.service_name}</td>
                  </tr>
                  <tr>
                    <div>
                      <span>{e?.company_name}</span>
                      <span>{e?.recipient_first_name}   {e?.recipient_last_name}</span>
                      <span>{e?.address1} {e?.address2}</span>
                      <span>{e?.city} {e?.state} {e?.zip_code}</span>
                    </div>
                  </tr>
                  <tr>
                    <td>Item</td>
                    <td>Product</td>
                    <td>Notes</td>
                    <td>Weight</td>
                    <td>Qty</td>
                    <td>{e.boxes.length} Box(es)</td>
                  </tr>
                  {
                    e.boxes.length ? e.boxes.map(box => {
                      return box.items.map((item, i) => (
                        <tr key={i}>
                          <td>{item.job_id}</td>
                          <td>{floatNumberCut(item.width, 1)} X {floatNumberCut(item.height, 1)} {item.product_group_name}</td>
                          <td>
                            {
                              item.notes.length ? item.notes.map((note, noteIndex) => (
                                <span key={noteIndex}>{note.note}</span>
                              )) : null
                            }
                          </td>
                          <td>{box.box_weight_in_pounds}lbs</td>
                          <td>{item.quantity}</td>
                        </tr>
                      )) 
                    }) : null
                 
                  }

                </Fragment>
              ) ) : null
            }
          </tbody>
        </table>
      </div>

    </div>
  )
}
export default observer(PrintShippment)