import { FC } from 'react'
import CKEditor from 'react-ckeditor-component'

const toolbarConfig = {
  height: '180px',
  toolbar: 'Full',
  allowedContent: true,
  startupFocus: false,
  // toolbarGroups: [
  //   { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
  //   { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
  //   { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
  //   { name: 'forms', groups: [ 'forms' ] },
  //   { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
  //   { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
  //   { name: 'links', groups: [ 'links' ] },
  //   { name: 'insert', groups: [ 'insert' ] },
  //   { name: 'styles', groups: [ 'styles' ] },
  //   { name: 'colors', groups: [ 'colors' ] },
  //   { name: 'tools', groups: [ 'tools' ] },
  //   { name: 'others', groups: [ 'others' ] },
  //   { name: 'about', groups: [ 'about' ] }
  // ],
  // removeButtons: 'PasteFromWord,Image,Source,Save,NewPage,Preview,Templates,Cut,Copy,Paste,PasteText,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,Blockquote,CreateDiv,BidiLtr,BidiRtl,Link,Unlink,Anchor,Flash,Smiley,SpecialChar,PageBreak,Iframe,Styles,Maximize,ShowBlocks,About,Language',
  // removePlugins: 'elementspath',
}

const CkEditor: FC<{
  targetName: string
  data?: string
  changeData?: (filed: string, value: string | number) => void
}> = ({ targetName, data, changeData }): JSX.Element => {
  const onChange = (e): void => {
    const newContent = e.editor.getData()
    changeData?.(targetName, newContent)
  }
  return (
    <div>
      <CKEditor
        activeClass='p10' 
        config={toolbarConfig}
        content={data}
        events={{
          'change': (e) => onChange(e)
        }}
      />
    </div>
  )
}

export default CkEditor