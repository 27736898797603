import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { MetaData } from 'src/ui/MetaData'
import classes from './Coating.module.scss'
import { Suspense, useEffect, useState } from 'react'
import { IGetCoating, ISendCoatingActionData, TCoatingFrontOrBack } from 'src/api/postApproval/coating/model'
import { getCoating, patchCoatingFrontOrBack, postCoatingAction } from 'src/api/postApproval/coating/coating'
import { openLinkNewTab } from 'src/utils/open-link-new-tab'
import Loading from 'src/Components/Loading/Loading'
import { toast } from 'react-toastify'
import { IResponseError } from 'src/utils/errorMessage/model'
import EditCoatingDueDate from './EditCoatingDueDate/EditCoatingDueDate'
import { useNavigate } from 'react-router-dom'
const Coating = (): JSX.Element => {
  const navigate = useNavigate()
  const [coatingInfo, setCoatingInfo] = useState<IGetCoating[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [itemGangId, setItemGangId] = useState<number>(0)
  const coatingData = async (): Promise<void> => {
    try {
      setCoatingInfo(await getCoating())
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  
  useEffect(() => {
    coatingData()
  },[])

  const frontOrBack = async (field: 'coatedFront' | 'coatedBack', value: boolean, gangId: number): Promise<void> => {
    try {
      await patchCoatingFrontOrBack('coating', gangId, { [field]: value } as TCoatingFrontOrBack)
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }



  const tableCoatingBlock = (side: number, step: string, front: number, back: number, gangId: number): JSX.Element => {
    if (side === 2) {
      return (
        <>
          <span>{step}</span>
          <label>
              F
            <input 
              defaultChecked={Boolean(front)}
              type='checkbox'
              name='coatedFront'
              onChange={e => frontOrBack('coatedFront', e.target.checked, gangId)}
              onClick={e => e.stopPropagation()}
            />
          </label>
          <label>
              B
            <input 
              defaultChecked={Boolean(back)} 
              type='checkbox'
              name='coatedBack'
              onChange={e => frontOrBack('coatedBack', e.target.checked, gangId)}
              onClick={e => e.stopPropagation()}
            />
          </label>
        </>
      ) 
    } else if (side === 1) {
      return (
        <>
          <span>{step}</span>
          <label>
              F
            <input 
              defaultChecked={Boolean(front)}
              type='checkbox'
              name='coatedFront'
              onChange={e => frontOrBack('coatedFront', e.target.checked, gangId)}
              onClick={e => e.stopPropagation()}
            />
          </label>
        </>
      )
    }
    return <></>
  }


  const postAction = async (data: ISendCoatingActionData): Promise<void> => {
    try {
      const res = await postCoatingAction('coating', data)
      if (res.message === 'success') {
        setCoatingInfo(prev => {
          const updatedCoating = prev.map(e => {
            if (e.gang_id === data.gangId ) {
              if (data.status === 'Coating') {
                
                e.status = 'Coating'
              } else {
                e.status = 'Ready For Coating'
              }
            }
            return e
          })
          return updatedCoating
        })
      }

    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }

  const coatingAction = (gangId: number, status: string): JSX.Element => {
    if (status === 'Ready For Coating') {
      return <button onClick={(e) => {
        e.stopPropagation()
        postAction({ gangId, status: 'Coating' })
      }}>Start</button> 
    } else if (status === 'Coating') {
      return <button 
        className={classes.coating_container_tableBlock_finish}
        onClick={(e) => {
          e.stopPropagation()
          postAction({ gangId, status:'Ready To Cut' })
        }}
      >Finish</button> 
    }
    return <></>
  }

  const closeDueEditBlock = (): void => {
    setItemGangId(0)
  } 

  return (
    <div className={classes.coating}>
      <MetaData title='Coating'/>
      {
        isLoading ? <Loading/> :
          <div className={classes.coating_container}>
            <PageHeader pageName='Coating' pageWidth='100'/>
            <div className={classes.coating_container_tableBlock}>
              <table>
                <thead>
                  <tr>
                    <th>Gang</th>
                    <th>Run</th>
                    <th>Colors</th>
                    <th>Stock</th>
                    <th>Code</th>
                    <th>Notes</th>
                    <th>Press</th>
                    <th>Stripped By</th>
                    <th>Color Proof</th>
                    <th>Due</th>
                    <th>TUR</th>
                    <th>MES</th>
                    <th>FSC</th>
                    <th>Tiket</th>
                    <th>Coating</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    coatingInfo.length ? coatingInfo.map((e, index) => (
                      <tr onClick={()=> navigate(`/coating/${e.gang_id}`)} key={index}>
                        <td>{e.gang_id}</td>
                        <td>{e.quantity}</td>
                        <td>{e.colors}</td>
                        <td>{e.internal_name}</td>
                        <td>{e.code}</td>
                        <td>
                          <span className={classes.coating_container_tableBlock_note}>{e.note}</span>
                        </td>
                        <td>{e.press}</td>
                        <td>{e.username}</td>
                        <td>{e.proof}</td>
                        <td>
                          {itemGangId === e.gang_id ?
                            <Suspense fallback={null}>
                              <EditCoatingDueDate
                                value={e.due_on} 
                                setCoatingInfo={setCoatingInfo} 
                                gangId={e.gang_id}
                                closeDueEditBlock={closeDueEditBlock}
                              />
                            </Suspense> : 
                            <span onClick={() => setItemGangId(e.gang_id)}>{e.due_on}</span>
                          }
                        </td>
                        <td>{e.turnaround}</td>
                        <td>
                          <span className={classes.coating_container_tableBlock_mess}>
                            {e.messengered ? 'YES' : ''}
                          </span>
                        </td>
                        <td>{e.fsc}</td>
                        <td>
                          <span 
                            onClick={() => openLinkNewTab(`coating/ticket/${e.gang_id}`)}
                            className={classes.coating_container_tableBlock_tiket}
                          >Tiket</span>
                        </td>
                        <td>
                          {tableCoatingBlock(e.sides, e.next_step, e.coated_front, e.coated_back, e.gang_id)}
                        </td>
                        <td>
                          {
                            coatingAction(e.gang_id, e.status)
                          }
                          
                        </td>
                      </tr>
                    )) : null
                  }
                </tbody>
              </table>
            </div>
          </div>
      }
    </div>
  )
}
export default Coating