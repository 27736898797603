import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { saveIPCConfig } from 'src/api/ipc/ipc'
import { IProjectFile } from 'src/Components/Preflight/OrderList/CreateOrder/ProjectInfoBlock/model'
import { ipcStore } from 'src/stores/IPC/ipcStore'
import { IIPCUploadProps } from '../IPC.model'
import classes from './Upload.module.scss'


const IPCUpload: FC<IIPCUploadProps> =
	({
	  orderId,
	  isShowedWarningPopup,
	  jobId,
	  inCreateOrder = false,
	  close,
	  setCustomOrder,
	  IPCRes,
	  updateOrder
	}): JSX.Element => {
	  const { loadingStatus: isLoading } = ipcStore
	  const handleUploadFile = async (): Promise<void> => {
	    if (!inCreateOrder) {
	      ipcStore.loadingStatus = true
	      try {
	        await saveIPCConfig({
	          jobId, orderId, data: {
	            product: {
	              product_group_id: IPCRes.groupData.product_group_id,
	              product_group_name: IPCRes.groupData.product_group_label,
	              hasStandardSizes: IPCRes.productData.size?.custom ? 0 : 1,
	              print_product_id: IPCRes.productData.size?.print_product_id,
	              print_product_name: IPCRes.productData.size?.product_label,
	              height: IPCRes.productData.size?.height,
	              width: IPCRes.productData.size?.width,
	              quantity: IPCRes.payloadUpdates.quantity,
	              attributes: IPCRes.productPrice.attributesWF,
	              turnaround: {
	                info: IPCRes.productPrice.turnaroundInfo.info
	              },
	              direct_mail: IPCRes.groupData.direct_mail,
	              base_price: IPCRes.productPrice.basePrice,
	              turnaround_days: IPCRes.productPrice.turnaroundDays,
	              subtotal: IPCRes.productPrice.stablePrice,
	              total: IPCRes.productPrice.finalPrice,
	              discount: null
	            },
	            asap: 0,
	            comment: '',
	            dev_customer_session: 'chucknorris',
	            sessionid: 'chucknorris'
	          }
	        })
	        await updateOrder?.()
	        ipcStore.loadingStatus = false
	        close?.()
	      } catch (e) {
	        ipcStore.loadingStatus = false
	        console.log('error', e)
	      }
	    } else {
	      setCustomOrder?.((prev) => {
	        return { ...prev, job: [...prev.job, {
					  product: {
						 product_group_id: IPCRes.groupData.product_group_id,
						 product_group_name: IPCRes.groupData.product_group_label,
						 hasStandardSizes: Boolean(IPCRes.productData.size?.custom),
						 print_product_id: Number(IPCRes.productData.size?.print_product_id),
						 selected:  String(IPCRes.productData.size?.print_product_id),
						 print_product_name: String(IPCRes.productData.size?.product_label),
						 height: Number(IPCRes.productData.size?.height),
						 width: Number(IPCRes.productData.size?.width),
						 quantity: IPCRes.payloadUpdates.quantity,
						 attributes: IPCRes.productPrice.attributesWF,
						 turnaround: {
	              checked: IPCRes.productPrice.turnaroundInfo.checked,
	                info: IPCRes.productPrice.turnaroundInfo.info
						 },
						 direct_mail: Boolean(IPCRes.groupData.direct_mail),
						 base_price: Number(IPCRes.productPrice.basePrice),
						 turnaround_days: IPCRes.productPrice.turnaroundDays,
						 subtotal: Number(IPCRes.productPrice.stablePrice),
						 total: Number(IPCRes.productPrice.finalPrice),
						 discount: null
					  },
					  name: '',
					  notes: '',
					  seconday_email: '',
					  files: [] as IProjectFile[]
				 }] }
	      })
		 }
	  }

	  return (
	    <>
	      <>
	        <div className={classes.IPCUploadBlock}>
	         <>
	            <button
	              disabled={isLoading}
	              style={{
	                color: '#fff',
	                background: isLoading ? '#666571' : '',
	                borderColor: isLoading ? '#6E6D7A' : '',
	              }}
	              onClick={() => !isLoading && handleUploadFile()}
	              className={`${classes.IPCUpload} ${isShowedWarningPopup ? classes.IPCUpload__disabled : ''
	              }`}
	            >

	              {isLoading ? (
	                <span>Loading...</span>
	              ) : (
	                <span>
	                  {inCreateOrder ? 'Add to Cart' : 'Save Changes'}
	                </span>
	              )}
	            </button>
	          </>
	        </div>
	      </>
	    </>
	  )
	}
export default observer(IPCUpload)
