import classes from './Stripper.module.scss'
import { MetaData } from 'src/ui/MetaData'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { useEffect, useState } from 'react'
import { IGetStripper, IStriperTableData, IStripperJob } from 'src/api/postApproval/stripper/model'
import { getStripper } from 'src/api/postApproval/stripper/stripper'
import StripperOrder from './StripperOrder/StripperOrder'
import GangOrder from './GangOrder/GangOrder'
import Loading from 'src/Components/Loading/Loading'
import Filter from './Filter/Filter'
import { IStripperFilterationType } from '../model'
const Stripper = (): JSX.Element => {
  const [stripperInfo, setStripperInfo] = useState<IGetStripper>({} as IGetStripper)
  const [stripperSingleJobInfo, setStripperSingleJobInfo] = useState< IStripperJob[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [filterationData, setFilterationData] = useState<IStriperTableData>({} as IStriperTableData)
  const [gangId, setGangId] = useState<number| null>(null)

  const [filterationType, setFilterationType] = useState<IStripperFilterationType>({
    filteredDataName: 'stripperJob'
  } as IStripperFilterationType)
  
  const stripperData = async (): Promise<void> => {
    try {
      setStripperInfo(await getStripper())
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }   
  }

  useEffect(() => {
    stripperData()
  },[])

  const filterData = (): void => {
    const field = filterationType.filteredDataName
    if (filterationType?.filterItem) {
      setFilterationData(prevData => {
        const filteredStrippers = stripperInfo[field]?.filter(stripper => {
          return Object.entries(filterationType?.filterItem).every(([key, filterValue]) => {
            const stripperValue = String(stripper[key] || '')
            return filterValue === '' || stripperValue === filterValue
          })
        })
        return { ...prevData, [field]: filteredStrippers }
      })
    }
  } 

  useEffect(() => {
    filterData()
  }, [filterationType])

  useEffect(() => {
    setFilterationData({
      stripperJob:stripperInfo.stripperJob,
      gangs: stripperInfo.gangs
    })
  }, [stripperInfo.gangs, stripperInfo.stripperJob])

  const addFilterationType = (field: string, value: string, key: string): void => {
    setFilterationType((prev) => {
      const newFilterItem = { [field]: value }

      if (prev.filteredDataName !== key) {
        return {
          ...prev,
          filteredDataName: key,
          filterItem: newFilterItem,
        }
      } else {
        return {
          ...prev,
          filteredDataName: key,
          filterItem: { ...prev.filterItem, ...newFilterItem },
        }
      }
    })
  }

  const closeSingleGang = (): void => {
    setGangId(null)
    setStripperSingleJobInfo([])
  }

  const addSingleStriperJob = (jobId: number, paperType: string): void => {
    if (stripperSingleJobInfo?.length && stripperSingleJobInfo.every(e => e.paper_type !== paperType)) {
      window.alert('Wrong Paper Type')
      return
    } 
    setStripperSingleJobInfo([...stripperSingleJobInfo,...stripperInfo.stripperJob.filter(e => e.Job === jobId)])
    setFilterationData({
      ...filterationData,
      stripperJob: filterationData.stripperJob.filter(e => e.Job !== jobId)
    })
    setGangId(null)
  }

  const removeSingleStriperJob = (jobId: number, newData: IStripperJob[]): void => {
    setFilterationData({
      ...filterationData,
      stripperJob: [...newData]
    })
    setStripperSingleJobInfo([...stripperSingleJobInfo.filter(e => e.Job !== jobId)])
  }
  
  return (
    <>
      {
        isLoading ? <Loading/> :
          <div className={classes.stripper}>
            <MetaData title='Stripper'/>
            <div className={classes.stripper_container}>
              <PageHeader pageName='Stripper' pageWidth='100'/>
              <Filter
                title='ORDERS'
                filterDataKey='stripperJob'
                labelName={['Paper Type:', 'Quantity:', 'Due Date:']}
                selectData={{
                  'paper_type': stripperInfo.paperTypesArr,
                  'quantity': stripperInfo.quantityOfArr,
                  'due_date': stripperInfo.dueDateArr,
                }}
                filterData={ addFilterationType}
                isRefrersh
              />
              <StripperOrder jobs={filterationData.stripperJob} addSingleStriperJob={addSingleStriperJob}/>
              <Filter
                title='Gangs'
                filterDataKey='gangs'
                labelName={['Paper Type:', 'Created By:']}
                selectData={{
                  'internal_name': stripperInfo.paperTypeGangArr,
                  'username': stripperInfo.usersArr,
                }}
                filterData={ addFilterationType}
              />
              {
                stripperSingleJobInfo.length || gangId ?
                  <StripperOrder jobs={stripperSingleJobInfo}
                    addSingleStriperJob={addSingleStriperJob}
                    removeSingleStriperJob={removeSingleStriperJob}
                    singleItem
                    gangId={gangId}
                    closeSingleGang={closeSingleGang}
                  />
                  :
                  <GangOrder
                    gangs={filterationData.gangs}
                    setGangId={setGangId}
                    setStripperInfo={setStripperInfo}
                  />
              }
            </div>
          </div>
      }
    </>
  )
}
export default Stripper