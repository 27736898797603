import { FC, useState } from 'react'
import { convertDateTime, dateConvertToStringToDate } from 'src/utils/dateConvertToString'
import EditGangNot from '../../Gangs/EditGangNot/EditGangNot'
import { IGangItemProps } from '../../model'
import classes from '../Stripper.module.scss'
import { getDateColor } from 'src/utils/getDateColor'
const GangOrder: FC<IGangItemProps> = ({ gangs, setGangId, setStripperInfo }): JSX.Element => {
  const [edit, setEdit] = useState<number>(-1)


  const toggleEdit = ( e?: React.MouseEvent<HTMLButtonElement, MouseEvent>, index?: number): void => {
    e?.stopPropagation()
    if (index !== undefined && index >= 0) {
      setEdit(index)
    } else {
      setEdit(-1)
    }
  }
  const addNote = (gangId: number, note: string): void => {
    setStripperInfo(prev => {
      const gangData = prev.gangs || []
      gangData.map(e => {
        if (e.gang_id === gangId) {
          e.note = note
        }
        return e
      })
      return {
        ...prev,
        gangData
      }
    })

  }
    
  return (
    <div className={classes.stripper_container_tableBlock}>
      <table>
        <thead>
          <tr>
            <th>Gang</th>
            <th># Jobs</th>
            <th>Due</th>
            <th>Paper</th>
            <th>Created</th>
            <th>Status</th>
            <th>Date</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          {
            gangs?.length ? gangs.map((gang, index) => {
              const { color, text } = getDateColor(convertDateTime(gang.due_on))
              return (
                <tr onClick={() => setGangId(gang.gang_id)} key={index}>
                  <td>{gang.gang_id}</td>
                  <td>{gang.job_count}</td>
                  <td style={{ background:color }}>{text || convertDateTime(gang.due_on)}</td>
                  <td>{gang.internal_name}</td>
                  <td>{gang.username}</td>
                  <td>{gang.status}</td>
                  <td>{dateConvertToStringToDate(gang.timestamp)}</td>
                  <td>
                    {
                      edit === index ?
                        <EditGangNot note={gang.note} gangId={gang.gang_id} closedBlock={toggleEdit} addNote={addNote}/> :
                        <>
                          {gang?.note}
                          <button onClick={e => toggleEdit(e, index)}>edit</button>
                        </>
                    }
                  </td>
                </tr>
              
              )
            }) : null
          }
        </tbody>
      </table>
    </div>
  )
}

export default GangOrder