import { api } from 'src/api/api'
import { IAsignUser, IHotJobReps, IPrintableSummary, IProjectNameData, IPutHotJob, IUpdateMultipleFields } from './model'

export const updateMultipleField = (jobId: string, data: IUpdateMultipleFields): Promise<void> =>
  api.patch(`/dashboard/jobs/${jobId}`, data).then(res => res.data)

export const patchProjectName = (jobId: number, data: IProjectNameData): Promise<void> =>
  api.patch(`/dashboard/jobs/${jobId}/saveProjectName`, data).then(res => res.data)

export const patchJobRepp = (jobId: number, data: {userId: number} ): Promise<IAsignUser> =>
  api.patch(`/dashboard/jobs/${jobId}/repp`, data).then(res => res.data)

export const patchOrder = (jobId: number, data: {[key: string]: boolean}): Promise<void> =>
  api.patch(`/dashboard/orders/${jobId}`, data).then(res => res.data)

export const putHotJob = (data: IPutHotJob): Promise<void> =>
  api.put('/dashboard/jobs/updateHotJob', data).then(res => res.data)

export const getHotJobById = (jobId: number): Promise<IHotJobReps> =>
  api.get(`/dashboard/jobs/hotJob/${jobId}`).then(res => res.data)

export const setHotJobTracker = (data): Promise<void> =>
  api.post('/dashboard/jobs/setHjTrackers', data).then(res => res.data)

export const postExportQrException = (orderId: number): Promise<void> =>
  api.post('/dashboard/orders/exportQrException', { orderId }).then(res => res.data)

export const putChangeJobState = (jobId: number,stateId: number, parentId: number): Promise<void> =>
  api.put(`/dashboard/jobs/${jobId}/changeJobState`, { stateId, parentId }).then(res => res.data)

export const getPrintableSummary = (orderId: string): Promise<IPrintableSummary> =>
  api.get(`/dashboard/orders/orderPrintableSummary/${orderId}`).then(res => res.data)