import { ITabsData } from './model'

export const tabData: ITabsData = {
  orderByIdTab:[ 
    { id: 1,
      title: 'Summary',
      path: '/orders/:id/summary'
    },
    { id: 2,
      title: 'Shipping',
      path: '/orders/:id/shipping'
    },
    { id: 3,
      title: '',
      path: '/orders/:id/billing'
    },
    {   
      id: 4,
      title: 'Files',
      path: '/orders/:id/files'
    },
    {   
      id: 4,
      title: 'Cart',
      path: '/orders/:id/cart'
    }],
  customerTabs: [
    {
      id: 1,
      title: 'Notes'
    },
    {
      id: 2,
      title: 'Address Book'
    },
    {
      id: 3,
      title: 'History'
    },
    {
      id: 4,
      title: 'Contacts'
    },
    {
      id: 5,
      title: 'Designs'
    },
    {
      id: 6,
      title: 'Coupons'
    },
    {
      id: 7,
      title: 'Actions'
    }
  ]
}