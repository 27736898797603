import { FC, useEffect, useState } from 'react'
import { IStripperSingleGang } from 'src/api/postApproval/gangs/model'
import { IStriperGangJob, IStripperJob } from 'src/api/postApproval/stripper/model'
import { getStriperGangJob, getStripperGang } from 'src/api/postApproval/stripper/stripper'
import { IStripperOrderProps } from '../../model'
import classes from '../Stripper.module.scss'
import StripperOrderHeader from './StripperOrderHeader/StripperOrderHeader'
import StripperOrderItem from './StripperOrderItem/StripperOrderItem'

const StripperOrder: FC<IStripperOrderProps> = ({ jobs, addSingleStriperJob, removeSingleStriperJob, singleItem, gangId, closeSingleGang }): JSX.Element => {
  const [stripperGangInfo, setStripperGangInfo] = useState<IStripperSingleGang>({} as IStripperSingleGang)
  const [stripperGangJobsDetailInfo, setStripperGangJobsDetailInfo] = useState<IStriperGangJob[]>([])
  const stripperGang = async (gangId: number): Promise<void> => {
    try {
      setStripperGangInfo(await getStripperGang(gangId))
    } catch (error) {
      console.log(error)
    }
  }

  const gangJobsDetail = async (gangId: number): Promise<void> => {
    try {
      setStripperGangJobsDetailInfo(await getStriperGangJob(gangId))
    } catch (error) {
      console.log(error)

    }
  }

  useEffect(() => {
    if (gangId) {
      jobs = []
      stripperGang(gangId)
      gangJobsDetail(gangId)
    }
  },[gangId])



  const removeGangJob = (jobId: number, newData: IStripperJob[]): void => {
    setStripperGangJobsDetailInfo([...stripperGangJobsDetailInfo.filter(e => e.Job !== jobId)])
    removeSingleStriperJob?.(jobId, newData)
  }



  return (
    <div className={classes.stripper_container_tableBlock}>
      <div>
        <div className={classes.stripper_container_tableBlock_gangDetail}>
          <span>Gang Detail</span>
        </div>
        {
          gangId ?
            <StripperOrderHeader
              gangId={gangId}
              gangJobs={(jobs.length ? jobs : stripperGangJobsDetailInfo).map(e => e.Job)}
              headerData={{
                gangId: stripperGangInfo?.gang_id,
                count: 'Gang Jobs Count:' + stripperGangInfo?.job_count,
                userName: stripperGangInfo?.username,
                timestamp: stripperGangInfo?.timestamp
              }}
              closeSingleGang={closeSingleGang}
            /> : null
        }
      </div>

      {
        jobs?.length && singleItem ?
          <StripperOrderHeader
            gangJobs={(jobs.length ? jobs : stripperGangJobsDetailInfo).map(e => e.Job)}
            headerData={{
              count:'Gang Jobs Count:' + String(jobs.length),
              paperType: jobs[0]?.paper_type
            }}
            closeSingleGang={closeSingleGang}
          />
          : null
      }
      <table>
        <thead>
          <tr>
            <th>Job</th>
            <th>Name</th>
            <th>Paper</th>
            <th>Due</th>
            <th>Size</th>
            <th>Desc</th>
            <th>Ink</th>
            <th>Quantity</th>
            <th>Code</th>
            <th>Repp</th>
            <th>State</th>
            <th>Bindery</th>
            <th>Lots</th>
            <th>Booklet Cover</th>
            <th>Proof</th>
            <th>Finish</th>
            <th>FSC</th>
            <th>Notes</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            jobs?.length ? jobs.map((job, index) => (
              <StripperOrderItem key={index}
                job={job}
                addSingleStriperJob={addSingleStriperJob}
                removeJobItem={removeGangJob}
                singleItem={singleItem}
              />
            )) : null
          }
          {
            stripperGangJobsDetailInfo?.length ? stripperGangJobsDetailInfo.map((job, index) => (
              <StripperOrderItem key={index}
                job={job}
                addSingleStriperJob={addSingleStriperJob}
                removeJobItem={removeGangJob}
                singleItem={singleItem}
              />
            )) : null
          }
        </tbody>
      </table>
    </div>
  )
}
export default StripperOrder