import axios from 'axios'
import { BASE_URL } from '../utils/constant'
import { deleteCookies, getCookies } from '../utils/storageFunction'
const accessToken = getCookies('access-token')
export const apiWithoutToken = axios.create({
  baseURL: BASE_URL
})

export const api = axios.create({
  baseURL: BASE_URL,
  headers:{
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + accessToken
  }
})
api.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response.status === 401) {
    deleteCookies('access-token')
    window.location.assign('/signIn')
  }
  if (error.response.status === 404) {
    window.location.assign('/404')
  }
  return Promise.reject(error)
})

export const formDataApi = axios.create({
  baseURL: BASE_URL,
  headers:{
    Authorization: 'Bearer ' + accessToken
  }
})