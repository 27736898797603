import { api } from 'src/api/api'
import { IGetOneWorkflowSettingById, IGetTimmingAction, IGetWorkflowSettings, IPostTimming, IPostTimmingAction, IPostTimmingResponse } from './model'
import { ICannedMessageByCategory } from 'src/api/Preflight/orderList/OrderListNote/model'

export const getAllWorkflowSettings = (): Promise<IGetWorkflowSettings[]> => 
  api.get('/workflow/settings').then(res => res.data)

export const getTimming = (id: number): Promise<IGetOneWorkflowSettingById[]> =>
  api.get(`/workflow/timing/${id}`).then(res => res.data)

export const postTimming = (data: IPostTimming): Promise<IPostTimmingResponse> => 
  api.post('/workflow/timing', data).then(res => res.data.result)

export const patchTimming = (id: number, data: IPostTimming): Promise<IPostTimmingResponse> => 
  api.patch(`/workflow/timing/${id}`, data).then(res => res.data.result)

export const deleteTimming = (id: number): Promise<void> =>
  api.delete(`/workflow/timing/${id}`).then(res => res.data)

export const getTimmingActionById = (id: number): Promise<IGetTimmingAction[]> =>
  api.get(`/workflow/timing/action/${id}`).then(res => res.data)

export const postTimmingAction = (data: IPostTimmingAction): Promise<IGetTimmingAction> => 
  api.post('/workflow/timing/action', data).then(res => res.data.timingAction)

export const patchTimmingAction = (id: number, data: IPostTimmingAction): Promise<IGetTimmingAction> => 
  api.patch(`/workflow/timing/action/${id}`, data).then(res => res.data.timingAction)

export const deleteTimmingActionById = (id: number): Promise<void> =>
  api.delete(`/workflow/timing/action/${id}`).then(res => res.data)

export const getCannedMessagesTimingAction = (): Promise<ICannedMessageByCategory[]> =>
  api.get('/canned_messages/timing/action').then(res => res.data)