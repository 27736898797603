import { api } from 'src/api/api'
import { ICreateCannedMessageData, IGetCannedMessage } from './model'
import { ICategory } from 'src/api/Preflight/orderList/OrderListNote/model'

export const getAllCannedMessage = (): Promise<IGetCannedMessage[]> =>
  api.get('canned_messages').then(res => res.data)

export const getCannedMessageById = (id: string): Promise<IGetCannedMessage> =>
  api.get(`/canned_messages/${id}`).then(res => res.data)

export const getCannedMessageAllCategory = (): Promise<ICategory[]> => 
  api.get('/canned_messages/category').then(res => res.data)
  
export const postCannedMessage = (data: ICreateCannedMessageData): Promise<IGetCannedMessage> =>
  api.post('/canned_messages', data).then(res => res.data.data)

export const patchCannedMessage = (id: number,data: ICreateCannedMessageData): Promise<IGetCannedMessage> =>
  api.patch(`/canned_messages/${id}`, data).then(res => res.data.data)

export const deleteCannedMessage = (id: number): Promise<void> => 
  api.delete(`canned_messages/${id}`).then(res => res.data)