import { FC, useRef, useState } from 'react'
import classes from './EditGangNot.module.scss'
import { IEditGangNoteProps } from '../../model'
import { putGangNote } from 'src/api/postApproval/gangs/gangs'
import { toast } from 'react-toastify'
import { IErrorMessage, IResponseError } from 'src/utils/errorMessage/model'
import { errorMessage, toastErrorOrFieldError } from 'src/utils/errorMessage/errorMessage'
const EditGangNot: FC<IEditGangNoteProps> = ({ note = '', gangId, closedBlock, addNote }): JSX.Element => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [errorRequest, setErrorRequest] = useState<IErrorMessage>({})
  const edit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    e.stopPropagation()
    const value = inputRef.current?.value
    try {
      await putGangNote(gangId, value || '')
      addNote(gangId, value || '')
      toast.success('Note updated successfully')
      closedBlock()
    } catch (error) {
      const errorMessage = error as IResponseError
      toastErrorOrFieldError(errorMessage?.response?.data, setErrorRequest)       
    }
  }
  return (
    <div className={classes.editGangNot}>
      <div className={classes.editGangNot_inputBlock}>
        <input
          ref={inputRef}
          defaultValue={note}
          onClick={e => {
            e.stopPropagation()
            setErrorRequest({})
          }}
        />
        {errorMessage('note', errorRequest)}
      </div>
      <div className={classes.editGangNot_btnBlock}>
        <button onClick={e => {
          e.stopPropagation()
          closedBlock()
        }} className={classes.editGangNot_btnBlock_cancel}>Cancel</button>
        <button onClick={(e) => edit(e)} className={classes.editGangNot_btnBlock_save}>Save</button>
      </div>
    </div>
  )
}
export default EditGangNot