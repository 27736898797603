export const filterByObjectKey = <T,>(data: T[], field: string, objKey?: string): {[key: string]: T[]} => {
  const obj: {[key: string]: T[]} = {}
  if (data) {      
    data?.forEach(item => {
      if (!obj[item[field]]) {
        obj[item[field]] = []
      }
      if (objKey && Array.isArray([objKey]) ) {
        obj[item[field]] = item[objKey]
      } else {
        obj[item[field]].push(item as T)

      }
    })
  }
  return obj
}