import { FC } from 'react'
import MetaTags from 'react-meta-tags'

export const MetaData: FC<{ title: string }> = ({ title }): JSX.Element => {
  return (
    <MetaTags>
      <title>{title}</title>
    </MetaTags>
  )
}
