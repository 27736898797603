import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import Loading from 'src/Components/Loading/Loading'
import { IGetWorkflowSettings } from 'src/api/settings/workflow-settings/model'
import { getAllWorkflowSettings } from 'src/api/settings/workflow-settings/workflow-settings'
import { MetaData } from '../../../ui/MetaData'
import PageHeader from '../../Layout/PageHeader/PageHeader'
import Timming from './Timming/Timming'
import classes from './WorkflowSettings.module.scss'

const WorkflowSettings = (): JSX.Element => {
  const [toggleItem, setToggleItem] = useState<number | null>(null)
  const [workflowSettingsInfo, setWorkflowSettingsInfo] = useState<IGetWorkflowSettings[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const allWorkflowSettings = async (): Promise<void> => {
    try {
      setWorkflowSettingsInfo(await getAllWorkflowSettings())
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!workflowSettingsInfo.length) {
      allWorkflowSettings()
    }
  },[workflowSettingsInfo])

  const handleToggle = (id: number): void => {
    if (id === toggleItem) {
      setToggleItem(null)
    } else {
      setToggleItem(id)
    }
  }
  return (
    <>
      <MetaData title='Workflow Settings'/>
      { isLoading ? <Loading/> :
        <div className={classes.workflowSettings}>
          <PageHeader pageName='Workflow Settings' />
          <div className={classes.workflowSettings_body}>
            {
              workflowSettingsInfo.length ? workflowSettingsInfo.map(workflow => (
                <>
                  <div key={workflow.id} onClick={() => handleToggle(workflow.id)} className={classes.workflowSettings_body_item}>
                    <p className={classes.workflowSettings_body_item_title}>
                      <span className={`${classes.workflowSettings_body_item_title_icon} ${toggleItem === workflow.id ? classes.workflowSettings_body_item_title_icon_open : ''}`}>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </span>
                      {workflow.name}</p>
                  </div>
                  <Timming toggleItem={toggleItem} id={workflow.id}/>
                </>
              )) : null
            }
          </div>
        </div>
      }
    </>
  )
}

export default WorkflowSettings