import { FC } from 'react'
import classes from '../PrintShippmentLabel.module.scss'
import { IShipToProps } from 'src/Components/PostApproval/model'
import { errorMessage } from 'src/utils/errorMessage/errorMessage'
const ShipTo: FC<IShipToProps> = ({ data, sendDataForBack,handleChangeData, errorRequest }): JSX.Element => {
  
  return (
    <div className={classes.printShippmentLabel_container_mainBlock} >
      <div className={classes.printShippmentLabel_container_mainBlock_blockHeader}>
        <span>Ship To</span>
      </div>
      <table className={classes.printShippmentLabel_container_mainBlock_body}>
        <tbody>
          <tr>
            <td>
              <label htmlFor='toCompany'>
              Company
              </label>
            </td>
            <td>
              <input
                id='toCompany'
                name='ShipTo_CompanyName'
                type='text'
                value={sendDataForBack.ShipTo_CompanyName}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='toName'>
                Name
              </label>
            </td>
            <td>
              <input
                id='toName'
                name='ShipTo_AttentionName'
                type='text'
                value={sendDataForBack.ShipTo_AttentionName}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='toPhone'>
                Phone #
              </label>
            </td>
            <td>
              <input
                id='toPhone'
                name='ShipTo_PhoneNumber'
                type='text'
                value={sendDataForBack.ShipTo_PhoneNumber}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='toAddress1'>
              Address
              </label>
            </td>
            <td>
              <input
                id='toAddress1'
                name='ShipTo_AddressLine1'
                type='text'
                value={sendDataForBack.ShipTo_AddressLine1}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='toAddress2'>
              Address 2
              </label>
            </td>
            <td>
              <input
                id='toAddress2'
                name='ShipTo_AddressLine2'
                type='text'
                value={sendDataForBack.ShipTo_AddressLine2}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              />
              {errorMessage('ShipTo_AddressLine2', errorRequest)}
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='toCity'>
                City
              </label>
            </td>
            <td>
              <input
                id='toCity'
                name='ShipTo_City'
                type='text'
                value={sendDataForBack.ShipTo_City}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>State</td>
            <td>
              <select
                value={sendDataForBack.ShipTo_StateProvinceCode}
                name='ShipTo_StateProvinceCode'
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              >
                {
                  data?.shipperStates?.length && ( data.shipperStates.map((state, index) => (
                    <optgroup key={index} label={state.label}>
                      {state.label}
                      {
                        state.options?.length && state.options.map((e, optionIndex) => (
                          <option key={optionIndex} value={e.value}>{e.label}</option>
                        ))
                      }
                    </optgroup>
                  ))
                  )
                }
              </select>
            </td>
          </tr>
          <tr>
            <td>Country</td>
            <td>
              <select
                name='ShipTo_CountryCode'
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              >
                {
                  data?.Countries?.length && (data.Countries.map((country, index) => (
                    <option key={index} value={country.value}>{country.label}</option>
                  )))
                } 
              </select>
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='toZIP'>
                ZIP
              </label>
            </td>
            <td>
              <input
                id='toZIP'
                name='ShipTo_PostalCode'
                type='text'
                value={sendDataForBack.ShipTo_PostalCode}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
            </td>
            <td>
              <label >
                <input
                  name='salesCustomerscustomerCategory'
                  type='checkbox'
                  value={data?.ShipToResidentialAddress}
                />
                Residential Address
              </label>
              <div>
                <label htmlFor='Notification' >
                  <input
                    id='Notification'
                    name='Notification'
                    type='checkbox'
                    value={sendDataForBack.Notification}
                    onChange={e => handleChangeData(e.target.name, Number(e.target.checked))}
                  />
                Notification
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='toEmail'>
               Email
              </label>
            </td>
            <td>
              <input
                id='toEmail'
                name='Notification_EMailAddress'
                type='text'
                value={sendDataForBack.Notification_EMailAddress}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              />
            </td>
          </tr>
        </tbody>
      </table>  
    </div>
  )
}

export default ShipTo