import { FC } from 'react'
import classes from './Modal.module.css'
import { TModalHeaderProps } from './model'

export const ModalHeader: FC<TModalHeaderProps> = ({ children }): JSX.Element => {
  return (
    <div className={classes.modalHeader}>
      {children}
    </div>
  )
}
