import Cookies from 'js-cookie'
export const setCookies = (key: string,value: string): void => {
  Cookies.set(key, value)
}

export const getCookies = (key: string): string => {
  return Cookies.get(key)
}

export const deleteCookies = (key: string): void => Cookies.remove(key)

export const setLocalStorage = (key: string, value: string): void => {
  localStorage.setItem(key, value)
}

export const getLocalStorage = (key: string): string => {
  const value = localStorage.getItem(key )
  if (value) {
    
    return value
  }
  return ''
   
}

export const deleteLocalStorage = (key: string): void => {
  localStorage.removeItem(key)
}