import { MetaData } from 'src/ui/MetaData'
import classes from './ApprovedOrder.module.scss'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { useState } from 'react'
import { IErrorMessage, IResponseError } from 'src/utils/errorMessage/model'
import { errorMessage, toastErrorOrFieldError } from 'src/utils/errorMessage/errorMessage'
import { getAllCheckoutReports } from 'src/api/report/report'
import { IGetApprovedOrder } from 'src/api/report/model'
import LoadingButton from 'src/ui/loadingButton/LoadingButton'
import { IParams } from 'src/api/Preflight/orderList/OrderListNote/model'
const ApprovedOrder = (): JSX.Element => {
  const [params, setParams] = useState<IParams>({} as IParams)
  const [errorRequest, setErrorRequest] = useState<IErrorMessage>({})
  const [approvedOrderInfo, setApprovedOrderInfo] = useState<IGetApprovedOrder[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const approvedOrder = async (): Promise<void> => {
    try {
      setIsLoading(true)
      setApprovedOrderInfo(await getAllCheckoutReports<IGetApprovedOrder>('/approved',params))
    } catch (error) {
      const errorMessage = error as IResponseError
      toastErrorOrFieldError(errorMessage?.response?.data, setErrorRequest)
    } finally {
      setIsLoading(false)
    }
  }
  const handleChange = (field: string, value: string): void => {
    if (!Object.values(errorRequest).every(error => error === '')) {
      setErrorRequest({ ...errorRequest, [field]: '' })
    }
    setParams({ ...params, [field]: value })
  }

  return (
    <div className={classes.approvedOrder}>
      <MetaData title='Approved Order' />
      <PageHeader pageName='Approved Order' />
      <div className={classes.approvedOrder_container}>
        <div>
          <label>Start Date</label>
          <input
            name='start'
            type='date'
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          <div>
            {errorMessage('start', errorRequest)}
          </div>
        </div>
        <div>
          <label>End Date</label>
          <input
            name='end'
            type='date'
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          <div>
            {errorMessage('end', errorRequest)}
          </div>
        </div>
      
        <div>
          <LoadingButton isLoading={isLoading} buttonName='Get Orders' changeButton={approvedOrder}/>
        </div>
        {
          approvedOrderInfo?.length ?
            <table>
              <thead>
                <tr>
                  <th>Customer Name</th>
                  <th>Email</th>
                  <th>Job Id</th>
                  <th>Thumbnail</th>
                </tr>
              </thead>
              <tbody>
                {
                  approvedOrderInfo.map((e, index ) => (
                    <tr key={index}>
                      <td>{e.full_name}</td>
                      <td>{e.email}</td>
                      <td>{e.job_id}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            : null
        }
        {
          Array.isArray(approvedOrderInfo) && !approvedOrderInfo.length && <p>Data not found</p>
        }
      </div>
    </div>
    
  )
}
export default ApprovedOrder