import { FC, useEffect, useState } from 'react'
import { calculateCustomOrderShipping, getPaymentMethodsByCustomer } from 'src/api/addOrder/addOrder'
import { getCustomerAddresses, getPaymentMethods, getShipmentMethods } from 'src/api/createCustomOrder/createCustomOrder'
import { ICustomerAddressesData, IPaymentMethods, IShipmentMethodsData } from 'src/api/createCustomOrder/model'
import { ICreditCards } from 'src/api/Preflight/orderList/orderListBilling/model'
import { IShippingMethod } from 'src/api/Preflight/orderList/orderListShipping/model'
import { filterByObjectKey } from 'src/utils/filteredObject'
import classes from './CreateOrderInfoBlock.module.scss'
import { ICreateOrderInfoBlockProps } from './model'
import { errorMessage } from 'src/utils/errorMessage/errorMessage'

const CreateOrderInfoBlock: FC<ICreateOrderInfoBlockProps> = ({ customOrder, customerId, setCustomOrder, errorRequest, setErrorRequest }): JSX.Element => {
  const [shipmentMethods, setShipmentMethods] = useState<IShipmentMethodsData[]>()
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethods[]>()
  const [customerAddressInfo, setCustomerAddressInfo] = useState<ICustomerAddressesData[]>([])
  const [filteredShippingMethod, setFilteredShippingMethod] = useState<Record<string, IShippingMethod[]>>({})
  const [customerPaymentMethods, setCustomerPaymentMethods] = useState<ICreditCards[]>([])

  const getShipment = async (): Promise<void> => {
    try {
      const res = await getShipmentMethods()
      setShipmentMethods(res)
    } catch (error) {
      console.log('error :>> 16', error)
    }
  }
  const getPayment = async (): Promise<void> => {
    try {
      const res = await getPaymentMethods()
      setPaymentMethods(res)
      setCustomOrder((prev) => ({ ...prev, billing_method: res.find((el) => el.paymentMethodId === 7)?.paymentMethodId || res[0].paymentMethodId }))
    } catch (error) {
      console.log('error :>> 16', error)
    }
  }
  const getAddress = async (): Promise<void> => {
    try {
      const res = await getCustomerAddresses(customerId)
      setCustomerAddressInfo(res)
      setCustomOrder((prev) => ({ ...prev, billing_address: res[0].customerAddressId, shipping_address: res[0].customerAddressId }))
    } catch (error) {
      console.log('error :>> 16', error)
    }
  }

  const getCustomerPaymentMethods = async (): Promise<void> => {
    try {
      const res = await getPaymentMethodsByCustomer(customerId)
      setCustomerPaymentMethods(res)
    } catch (error) {
      console.log('error :>> 16', error)
    }
  }
  useEffect(() => {
    getCustomerPaymentMethods()
  }, [customerId])

  useEffect(() => {
    if (!shipmentMethods?.length) {
      getShipment()
    }
    if (!customerAddressInfo?.length) {
      getAddress()
    }
    if (!paymentMethods?.length) {
      getPayment()
    }
  }, [])
  const handleChange = (name: string, value: string): void => {
    setCustomOrder((prev) => ({ ...prev, [name]: value }))
  }
  const calculateMultiShipping = async (): Promise<void> => {
    try {
      const res = await calculateCustomOrderShipping(customOrder)
      setCustomOrder((prev) => ({ ...prev, shipping_cost: res.price, taxes_cost: res.tax, total_cost: (res.price || 0) + (res.tax || 0) + prev.printing_cost }))
    } catch (error) {
      console.log('error :>> 78', error)
    }
  }
  useEffect(() => {
    if (customOrder.shipping_method) {
      calculateMultiShipping()
    }
  }, [customOrder.shipping_method])
  useEffect(() => {
    setFilteredShippingMethod(filterByObjectKey<IShippingMethod>(shipmentMethods as unknown as IShippingMethod[], 'groupLabel'))

  }, [shipmentMethods])
  const printingCost = customOrder.job?.map((job) => job.product.total).reduce((a, b) => a + b, 0)
  useEffect(() => {
    setCustomOrder((prev) => ({ ...prev, printing_cost: printingCost, total_cost: printingCost + prev.shipping_cost + prev.taxes_cost }))
  }, [])
  return (
    <div className={classes.customerInfo}>
      <div className={classes.customerInfo_body}>
        <label>
			Billing Address
          <select
            value={customOrder.billing_address}
            onChange={(e) => handleChange( 'billing_address', e.target.value)}
          >
            <option disabled selected>Select...</option>
            {
              customerAddressInfo.length && (
                customerAddressInfo.map(e => (
                  <option key={e.customerAddressId} value={e.customerAddressId}>{e.companyName} {e?.address1} {e?.address2} {e?.city} {e?.addressState?.stateAbbreviation} {e?.zipCode}</option>
                ))
              )
            }
          </select>
        </label>
		  <label>Billing Method
          <select value={customOrder.billing_method} onChange={e => handleChange('billing_method', e.target.value)}>
            <option selected disabled>Select...</option>
            {
              paymentMethods?.length ? paymentMethods.map((e, index )=> (
                <option key={index} value={e.paymentMethodId}>{e.paymentMethodDescription}</option>
              )) : null
            }
          </select>
		  </label>
        <label>Customer Payment Method
          <select>
            {
              customerPaymentMethods.length && (
                customerPaymentMethods.map(e => (
                  <option key={e.id} value={e.id}>{e.cardNumber} {e.expirationMonth}/{e.expirationYear} </option>
                ))
              )
            }
          </select>
		  </label>
		  <label>
		  Shipping Address
		  <select
            value={ customOrder.shipping_address}
            onChange={(e) => handleChange( 'shipping_address', e.target.value)}
          >
            <option disabled selected>Select...</option>
            {
              customerAddressInfo.length && (
                customerAddressInfo.map(e => (
                  <option key={e.customerAddressId} value={e.customerAddressId}>{e.companyName} {e?.address1} {e?.address2} {e?.city} {e?.addressState?.stateAbbreviation} {e?.zipCode}</option>
                ))
              )
            }
          </select>
		  </label>
		  <label>Shipping Method
          <select defaultValue='' value={customOrder.shipping_method} onChange={e => {
            handleChange('shipping_method', e.target.value)
          }}
          onClick={() => setErrorRequest({ ...errorRequest, shipping_method:'' })}
          >
            <option disabled value=''>---- Select a Shipping Method ----</option>
            {
              Object.keys(filteredShippingMethod).length ? Object.keys(filteredShippingMethod).map((key, j) => (
                <optgroup key={j} label={key}>
                  {key}
                  {
                    filteredShippingMethod[key].map((element, idx) => (
                      <option key={idx} value={element.serviceCode}>{element.serviceName}</option>
                    ))
                  }
                </optgroup>
              )) : null
            }
          </select>
          {errorMessage('shipping_method', errorRequest)}
		  </label>
		  <label>Shipping Cost:
          <input
            type='text'
            disabled
            value={customOrder.shipping_cost}
          />
		  </label>
		  <label>Printing Cost:
          <input
            type='text'
            disabled
            value={printingCost}
          />
		  </label>
		  <label>Tax:
          <input
            type='text'
            disabled
            value={customOrder.taxes_cost}
          />
		  </label>
		  <label>Total:
          <input
            type='text'
            disabled
            value={customOrder.total_cost}
          />
		  </label>
      </div>
    </div>
  )
}

export default CreateOrderInfoBlock