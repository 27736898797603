import { FC } from 'react'
import classes from '../Shipping.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { IShippingAction, IShippingActionType } from '../../model'
import { postFinishDirectMail, postFinishShippment } from 'src/api/postApproval/shipping/shipping'
import { useNavigate } from 'react-router-dom'
const ShippingBtnBlock: FC<{
    directMail: number, 
    status: string, 
    shippingType: string
    shippmentId: number
}> = ({ directMail,status,shippingType, shippmentId }): JSX.Element => {
  const navigate = useNavigate()
  const shippingAction = (shippingType: string): IShippingAction => {
    const shippingActionTypes: IShippingActionType = {
      MESSENGER: { desc: 'Messengered', color: 'blue' },
      UPS: { desc: 'Shipped', color: 'red' },
      PICKUP: { desc: 'Picked Up', color: 'green' },
      FEDEX: { desc: 'Shipped', color: 'red' },
      USPS: { desc: 'Shipped', color: 'red' },
      DISCARD: { desc: 'Discarded', color: 'Yellow' },
      boxed: { desc: 'USPS Mailed', color: 'blue' }
    }
      
    return shippingActionTypes[shippingType] || { desc: '', color: '' }
  }

  const finishShippment = async (shippmentId: number): Promise<void> => {
    console.log(directMail, 'directMail')
    
    try {
      await postFinishShippment(shippmentId)
    } catch (error) {
      console.log(error)
        
    }
  }
  const finishDirectMail = async (shippmentId: number): Promise<void> => {
    
    try {
      await postFinishDirectMail(shippmentId)
    } catch (error) {
      console.log(error)
        
    }
  }
  const handleClickBtn = (desc: string): void => {
    if (desc === 'USPS Mailed') {
      finishDirectMail(shippmentId)
    } else {
      finishShippment(shippmentId)
    }
  }

  return (
    <>
      {
        directMail ?
          <button 
            onClick={() => handleClickBtn(shippingAction(status).desc)}
            className={classes.shipping_container_action}
            style={{ background:shippingAction(status).color }}>{
              shippingAction(status).desc}
          </button> : ''
      }
      <button onClick={() => handleClickBtn(shippingAction(shippingType).desc)}
        className={classes.shipping_container_action} style={{ background:shippingAction(shippingType).color }}>{shippingAction(shippingType).desc}</button>
      <button onClick={() => navigate(`/workflow/postaproval/shipping/printShippment/${shippmentId}`)}
        className={classes.shipping_container_print}><FontAwesomeIcon icon={faPrint}/> Print </button>
    </>
  )
}
export default ShippingBtnBlock