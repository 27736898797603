import { IParams } from '../Preflight/orderList/OrderListNote/model'
import { api } from '../api'
import { IEditJobeNote, INote, IOutsourcedData, IPostCharging } from './model'

export const outsorsedOrLfpOrOkiData = (type: string, params: IParams): Promise<IOutsourcedData> => 
  api.get(`/postapproval/outsourced/${type}`,{ params }).then(res => res.data)

export const putAcknowledgeJob = (jobId: number): Promise<void> =>
  api.put(`/postapproval/outsourced/acknowledgeJob/${jobId}`).then(res => res.data)

export const postEditJobNote = (data: IEditJobeNote): Promise<INote> => 
  api.post('/postapproval/outsourced/editJobNote', data).then(res => res.data)

// Charging 

export const postCharging = (data: IPostCharging): Promise<IPostCharging> =>
  api.post('/postapproval/charging', data).then(res => res.data[0])

export const getCharging = (): Promise<IPostCharging> =>
  api.get('/postapproval/charging').then(res => res.data[0])