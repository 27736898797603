import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { MetaData } from 'src/ui/MetaData'
import classes from './CreateTemplate.module.scss'
import UploadTemplateFiles from './UploadTemplateFiles/UploadTemplateFiles'
const CreateTemplate = (): JSX.Element => {
  return (
    <div className={classes.createTemplate}>
      <MetaData title='Add new Template'/>
      <div className={classes.createTemplate_container}>
        <PageHeader pageName='Add new Template' pageWidth='90'/>
        <div className={classes.createTemplate_container_firstBlock}>
          <table>
            <tbody>
              <tr>
                <td>Product Name</td>
                <td><select></select></td>
              </tr>
              <tr>
                <td>Size</td>
                <td><select></select></td>
              </tr>
              <tr>
                <td>Category</td>
                <td><select></select></td>
              </tr>
              <tr>
                <td>Template Name</td>
                <td><input type='text'/></td>
              </tr>
              <tr>
                <td>Keywords</td>
                <td><input type='text'/></td>
              </tr>
              <tr>
                <td>	Resolution (DPI)</td>
                <td><select></select></td>
              </tr> 
            </tbody>
          </table>
          <div>
            <label>Disabled
              <input type='checkbox'/>
            </label> 
            <label>Advanced
              <input type='checkbox'/>
            </label>
            <label>QR Item
              <input type='checkbox'/>
            </label>
            <label>Disabled Back Side
              <input type='checkbox'/>
            </label>
          </div>
        </div>
        <UploadTemplateFiles/>
      </div>
    </div>
  )
}

export default CreateTemplate