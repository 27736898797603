import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { MetaData } from 'src/ui/MetaData'
import classes from './Shipping.module.scss'
import { useEffect, useState } from 'react'
import { getShipping } from 'src/api/postApproval/shipping/shipping'
import { IFilteredAndAllData, IShipping } from 'src/api/postApproval/shipping/model'
import { floatNumberCut } from 'src/utils/FloatNumberCut'
import { IItemAndBox, IShippingCheckedItems, } from '../model'
import ShippingHeader from './ShippingHeader/ShippingHeader'
import ShippingBtnBlock from './ShippingBtnBlock/ShippingBtnBlock'
const Shipping = (): JSX.Element => {
  const [checkedItems, setCheckedItems] = useState<IShippingCheckedItems[]>([])
  const [shippingInfo, setShippingInfo] = useState<IFilteredAndAllData>({
    allShipping: [],
    filteredShipping:[]
  })
  
  const shippingData = async (): Promise<void> => {
    try {
      const res = await getShipping()
      setShippingInfo({ allShipping: res, filteredShipping: res })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    shippingData()
  },[])

  const shippingBoxes = (shipping: IShipping): JSX.Element[] => {
    const boxesItems: JSX.Element[] = []
    const allItems: IItemAndBox [] = []

    shipping.boxes.length && shipping.boxes.forEach(box => {
      box.items.length && box.items.forEach((item) => {
        allItems.push({ ...item, box_weight_in_pounds: box.box_weight_in_pounds })
      })
    })
    let filtered: IItemAndBox[] = []
    allItems.forEach((item, itemIndex) => {
      if (filtered.find(elem => elem.job_id === item.job_id)) return
      filtered = allItems.filter(elem => elem.job_id === item.job_id)
      if (filtered.length > 1) {
        boxesItems.push (
          <tr key={filtered[0].job_id}>
            <td></td>
            <td>{filtered[0].job_id}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              {
                floatNumberCut(filtered[0].width, 0) +
                'x' +  
                floatNumberCut(filtered[0].height, 0)} 
              {' '} 
              {filtered[0].product_group_name
              }
            </td>
            <td>
              {
                filtered[0].notes.length ? filtered[0].notes.map((note, noteIndex) => (
                  <p className={classes.shipping_container_greenItem} key={noteIndex}>{note.note}</p>
                )) : null
              }
            </td>
            <td>{filtered[0].due_date}</td>
            <td>
              {
                filtered.map((filteredItem, i) => (
                  <tr key={i} className={classes.shipping_container_childTr}>{filteredItem.box_weight_in_pounds}lbs</tr>
                  
                ))
              } 
            </td>
            <td>
              {
                filtered.map((filteredItem, i) => (
                  <tr className={classes.shipping_container_childTr} key={i}>{floatNumberCut(filteredItem.quantity, 0)}
                  </tr>
                ))
              } 
            </td>
            <td></td>
            <td></td>
          </tr>
        )
      } else {
        boxesItems.push (
          <tr key={itemIndex}>
            <td></td>
            <td>{item.job_id}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              {
                floatNumberCut(item.width, 0) +
                              'x' +  
                              floatNumberCut(item.height, 0)} 
              {' '} 
              {item.product_group_name
              }
            </td>
            <td>
              {
                item.notes.length ? item.notes.map((note, noteIndex) => (
                  <p className={classes.shipping_container_greenItem} key={noteIndex}>{note.note}</p>
                )) : null
              }
            </td>
            <td>{item.due_date}</td>
            <td>{item.box_weight_in_pounds}</td>
            <td>{floatNumberCut(item.quantity, 0)}</td> 
            <td></td>
            <td></td>
          </tr>
        ) 
      }
    })

    return boxesItems
  }

  const filteration = (filterValue: string): void => {
    setShippingInfo(prev => {
      let filterData: IShipping[] = []
      if (filterValue === 'All') {
        filterData = prev.allShipping
      } else {
        filterData = prev.allShipping.filter(e => e.shippingType === filterValue)
      }
      return {
        ...prev,
        filteredShipping: filterData
      }
    })
  }

  const handleChangeShippmentIdArr = (isChecked: boolean, item: IShippingCheckedItems): void => {
    if (isChecked) {
      setCheckedItems([...checkedItems, item])
    } else {
      setCheckedItems([...checkedItems.filter(e => e.shippmentId !== item.shippmentId)])
    }
  }
  
  const getShipmentInput = (shippingType: string, shipmentId: number): JSX.Element => {
    const permissionShippingType = ['MESSENGER', 'UPS', 'PICKUP', 'USPS']
    
    if (!permissionShippingType.includes(shippingType)) {
      return <></> 
    }
  
    let checkboxName = ''
    const checkboxValue = shipmentId
  
    switch (shippingType) {
      case 'MESSENGER':
        checkboxName = 'driver'
        break
      case 'UPS':
        checkboxName = 'multiple'
        break
    }
  
    return (
      <input
        type='checkbox'
        name={`${checkboxName}${shipmentId}`}
        value={checkboxValue}
        onChange={(e) =>
          handleChangeShippmentIdArr(e.target.checked, {
            shippmentId: shipmentId,
            shippingType,
          })
        }
      />
    )
  }
  

  

  return (
    <div className={classes.shipping}>
      <MetaData title='Shipping'/>
      <div className={classes.shipping_container}>
        <PageHeader pageName='Shipping' pageWidth='100'/>
        <ShippingHeader filteration={filteration} checkedItems={checkedItems}/>
        <table>
          <thead>
            <tr>
              <th> </th>
              <th>Item</th>
              <th>Name</th>
              <th>Shipment</th>
              <th>Boxes</th>
              <th>Blind</th>
              <th>Method</th>
              <th>Product</th>
              <th>Notes</th>
              <th>Due Date</th>
              <th>Weight</th>
              <th>Qty</th>
              {shippingInfo.filteredShipping.every(e => e.shippingType === 'USPS') && <td>Address</td>}
              <th>Thumbnails</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              shippingInfo.filteredShipping.length ? shippingInfo.filteredShipping.map((shipping, index) => (
                <>
                  <tr style={{ background: 'rgb(255, 255, 217)' }} key={index}>
                    <td>
                      {getShipmentInput(shipping.shippingType, shipping.shippment_id)}
                    </td>
                    <td>{shipping.order_id}</td>
                    <td>{shipping.full_name}</td>
                    <td>{shipping.number_of + ' of ' + shipping.of}</td>
                    <td>{shipping.boxes.length}</td>
                    <td>{shipping.blind_shipping === '1' ? 'Blind Shipping' : ''}</td>
                    <td>{shipping.internal_label}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    { shippingInfo.filteredShipping.every(e => e.shippingType === 'USPS') &&
                     <td>
                       <div>
                         <div>{shipping.company_name}</div>
                         <div>
                           {shipping.recipient_first_name} {shipping.recipient_last_name}
                         </div>
                         <div>{shipping.address1}</div>
                         {shipping.address2 && (
                           <>
                             <div>{shipping.address2}</div>
                             <br />
                           </>
                         )}
                         <div>
                           {shipping.city}, {shipping.state} {shipping.zip_code}
                         </div>
                       </div>
                     </td>
                    }
                    <td></td>
                    <td>
                      <ShippingBtnBlock 
                        directMail={shipping.direct_mail}
                        status={shipping.status}
                        shippingType={shipping.shippingType}
                        shippmentId={shipping.shippment_id}
                      />
                    </td>
                  </tr>
                  {
                    shippingBoxes(shipping)
                  }
                </>
              )) : null
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default Shipping