
import { Outlet } from 'react-router-dom'
// import SideBar from 'src/Components/Layout/SideBar/SideBar'
import Header from '../Components/Layout/Header/Header'

const Wrapper = (): JSX.Element => {
  return (
    <div className='horizontalMenucontainer'>
      <Header/>
      <div className='page'>
        {/* <SideBar /> */}
        <div className='main-content app-content'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Wrapper
