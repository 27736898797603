import { MetaData } from 'src/ui/MetaData'
import classes from './Outsourced.module.scss'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { FC, useEffect, useState } from 'react'
import { IOutsourcedData } from 'src/api/postApproval/model'
import { outsorsedOrLfpOrOkiData, putAcknowledgeJob } from 'src/api/postApproval/postApproval'
import { putChangeJobState } from 'src/api/Preflight/orderList/OrderListSummary/OrderListSummary'
import { IResponseError } from 'src/utils/errorMessage/model'
import { toast } from 'react-toastify'
import Loading from 'src/Components/Loading/Loading'
import OutsourcedNoteEdit from './OutsourcedNoteEdit/OutsourcedNoteEdit'
import { IParams } from 'src/api/Preflight/orderList/OrderListNote/model'
import OutsourcedFilter from './OutsourcedFilter/OutsourcedFilter'
import { IOutsourcedProps } from '../model'
const Outsourced: FC<IOutsourcedProps> = ({ pathName,type }): JSX.Element => {
  const [toggleNoteEdit, setToggleNoteEdit] = useState<number | null>(null)
  const [outsourcedInfo, setOutsourcedInfo] = useState<IOutsourcedData>({} as IOutsourcedData)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pageName, setPageName] = useState<string>(pathName)
  const [params, setParams] = useState<IParams>({} as IParams)
  
  useEffect(() => {
    setPageName(pathName)
  },[pathName])

  const outsourcedData = async (type: string, params?: IParams): Promise<void> => {
    try {
      setIsLoading(true)
      const res = await outsorsedOrLfpOrOkiData(type, params || {})
      setOutsourcedInfo(res)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (type) {
      outsourcedData(type)
    }
  },[type])

  const changeJobState = async (jobId: number,stateId: number, parentId: number): Promise<void> => {
    try {
      await putChangeJobState(jobId, stateId, parentId)
      toast.success('Updated successfully')
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
      
    }
  }

  const acknowledgeJob = async (jobId: number): Promise<void> => {
    try {
      const isConfirm = window.confirm('Are you sure this job is done?')
      if (isConfirm) {
        await putAcknowledgeJob(jobId)
        toast.success('Done successfully')
      }
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
      
    }
  }

  const closeEditNote = (): void => {
    setToggleNoteEdit(null)
  }

  useEffect(() => {
    outsourcedData(type, params)
  },[params])

  
  return (
    <> 
      {
        isLoading ? <Loading/> :
          <div className={classes.outsourced}>
            <MetaData title={pageName}/>
            <div className={classes.outsourced_container}>
              <PageHeader pageName={pageName} pageWidth='100'/>
              <div className={classes.outsourced_container_filterBlock}>
                <OutsourcedFilter
                  stages={outsourcedInfo.stages}
                  states={outsourcedInfo.states}
                  params={params}
                  setParams={setParams}
                />
              </div>
              <div className={classes.outsourced_container_tableBlock}>
                <table>
                  <thead>
                    <tr>
                      <th>Job</th>
                      <th>Name</th>
                      <th>Paper</th>
                      <th>Due</th>
                      <th>Size</th>
                      <th>Desc</th>
                      <th>Ink</th>
                      <th>Quantity</th>
                      <th>Repp</th>
                      <th>State</th>
                      <th>Status</th>
                      <th> <span>Notes</span></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      outsourcedInfo.jobs?.length ? outsourcedInfo.jobs.map((job, index) => (
                        <tr key={index}>
                          <td>{job.Job}</td>
                          <td>{job.Name}</td>
                          <td>{job.paper_type}</td>
                          <td className={classes[`outsourced_container_tableBlock_${job.dueClass}`]}>{(job.due_date)}</td>
                          <td>{job.size}</td>
                          <td>{job.is_any_size + job.is_custom_size > 0 ? 'Any Size' : job.product_label}</td>
                          <td>{job.ink}</td>
                          <td>{job.quantity}</td>
                          <td>{job.username}</td>
                          <td>{job.shipping_state}</td>
                          <td>
                            <select
                              defaultValue={ outsourcedInfo.stateList?.length ?
                                outsourcedInfo?.stateList.find(e => Number(e.label_id) === job.id_label_state)?.label_id : ''}
                              onChange={e => changeJobState(job.Job,Number(e.target.value), Number(e.target.options[e.target.selectedIndex].id))} 
                            >
                              {
                                outsourcedInfo.stateList.length ? outsourcedInfo.stateList.map((state, index) => (
                                  <option key={index} id={state.parent_id} value={state.label_id}>{state.parent_name + ' - ' + state.name}</option>
                                )) : null
                              }
                            </select>
                          </td>
                          <td>
                            {
                              job.notes.length ? job.notes.map((note, index) => (
                                <div key={index}>
                                  {
                                    toggleNoteEdit !== note.job_note_id ?
                                      <>
                                        <span>{note.note}</span>
                                        <button 
                                          className={classes.outsourced_container_tableBlock_edit}
                                          onClick={() => setToggleNoteEdit(note.job_note_id)}
                                        >Edit</button> 
                                      </> :
                                      <OutsourcedNoteEdit
                                        jobId={note.job_id} 
                                        closeEditNote={closeEditNote}
                                        setOutsourcedInfo={setOutsourcedInfo}
                                        jobNoteId={note.job_note_id} 
                                        value={note.note}/>
                                  }
                                </div>
                              )) :
                                <>
                                  {
                                    toggleNoteEdit !== index ? 
                                      <button
                                        className={classes.outsourced_container_tableBlock_edit}
                                        onClick={() => setToggleNoteEdit(index)} 
                                      >
                                        Edit
                                      </button> :
                                      <OutsourcedNoteEdit 
                                        jobId={job.Job}
                                        closeEditNote={closeEditNote}
                                        setOutsourcedInfo={setOutsourcedInfo}
                                      />
                                  }
                                </>
                            }
                          </td>
                          <td><button onClick={() => acknowledgeJob(job.Job)}>Done</button></td>
                        </tr>
                      )) : null
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      }
    </>
  )
}
export default Outsourced