import { FC, useRef } from 'react'
import classes from './OutsourcedNoteEdit.module.scss'
import { postEditJobNote } from 'src/api/postApproval/postApproval'
import { IOutsourcedData } from 'src/api/postApproval/model'
const OutsourcedNoteEdit: FC<{
  jobId: number, 
  closeEditNote: () => void,
  setOutsourcedInfo: React.Dispatch<React.SetStateAction<IOutsourcedData>>
  jobNoteId?: number
  value?: string
}> = ({ jobId, closeEditNote, setOutsourcedInfo, jobNoteId, value }): JSX.Element => {
  const inputRef = useRef<HTMLInputElement | null > (null)
  
  const editJobNote = async (): Promise<void> => {
    try {
      const res = await postEditJobNote({
        jobId,
        message: String(inputRef.current?.value),
        jobNoteId
      })
      setOutsourcedInfo(prev => {
        const updatedData = [...prev.jobs]
        updatedData.forEach(job => {
          if (job.Job === jobId) {
            const notes = job.notes || []
            const index = notes.findIndex(note => note.job_note_id === res.job_note_id)
            if (index !== -1) {
              notes[index] = res
            } else {
              notes.push(res)
            }
            job.notes = notes 
          }
        })
        return {
          ...prev,
          jobs: updatedData
        }
      })
      
      closeEditNote()
    } catch (error) {
      console.log(error)
      
    }
  }
  return (
    <div className={classes.outsourcedNoteEdit}>
      <input ref={inputRef} defaultValue={value}/>
      <button onClick={closeEditNote} className={classes.outsourcedNoteEdit_cancel}>Cancel</button>
      <button onClick={editJobNote} className={classes.outsourcedNoteEdit_save}>Save</button>
    </div>
  )
}
export default OutsourcedNoteEdit