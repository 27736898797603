import { useParams } from 'react-router-dom'
import classes from '../PrintShippmentLabel.module.scss'
import { openLinkNewTab } from 'src/utils/open-link-new-tab'
import { getCookies } from 'src/utils/storageFunction'
import { deleteVoidShippmentLabels, getCreatePdfLabels } from 'src/api/postApproval/boxing/boxing'
import { FC, useEffect } from 'react'
import { BASE_URL } from 'src/utils/constant'
import { IResponseError } from 'src/utils/errorMessage/model'
import { toast } from 'react-toastify'
const Manage: FC<{shippingType: string, setIsManage: React.Dispatch<React.SetStateAction<boolean>>}> = ({ shippingType, setIsManage }): JSX.Element => {
  const accessToken = getCookies('access-token')
  const { id } = useParams()
  const pdfLabel = async (id: string, token: string): Promise<void> => {
    try {
      await getCreatePdfLabels(id, { token })
    } catch (error) {
      console.log(error)
        
    }
  }

  useEffect(() => {
    if (id && accessToken) {
      pdfLabel(id, accessToken)
    }
  },[id, accessToken])

  const voidShippmentLabels = async (shippmentId: string | undefined,type: string): Promise<void> => {
    try {
      if (shippmentId) { 
        const res = await deleteVoidShippmentLabels({ shippmentId:Number(shippmentId),type })
        console.log(res)
        toast.success(res.message)
        setIsManage(false)
      }
    } catch (error) {
      const errorMessage = error as IResponseError
      console.log(errorMessage?.response?.data?.detail)
      toast.error(errorMessage?.response?.data?.detail)
      
    
    }
  }

  return (
    <div className={classes.printShippmentLabel_container_manage}>
      <h2>MANAGE {shippingType} LABELS</h2>
      <h2>SHIPPMENT #{id}</h2>
      <button onClick={() => openLinkNewTab(`${BASE_URL}/publicApi/createPdfLabels/${id}?token=${accessToken}`)}>PRINT LABELS</button>
      <button onClick={() => voidShippmentLabels(id, shippingType)}>VOID SHIPPMENT</button>
    </div>
  )
}
export default Manage