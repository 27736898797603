import { api } from 'src/api/api'
import { ICutterActionSendData, IFinishCuttingSendData, IGetCutterData, IStartCuttingSendData } from './model'
import { ICoatingTiket } from '../coating/model'
import { IBinderyData } from '../gangs/model'

export const getAllCutterByType = (type: number): Promise<IGetCutterData[]> =>
  api.get(`/postapproval/cutter/index/${type}`).then(res => res.data)

export const postStartCuttingGang = (gangId: number): Promise<string> =>
  api.post('/postapproval/cutter/startCuttingGang', { gangId }).then(res => res.data.message)

export const postValidateGangJobStatus = (gangId: number): Promise<string> => 
  api.post('/postapproval/cutter/validateGangJobStatus',{ gangId }).then(res => res.data.message)

export const postFinishCuttingGang = (gangId: number): Promise<string> => 
  api.post('/postapproval/cutter/finishCuttingGang',{ gangId }).then(res => res.data.message)

export const getCutterGang = (gangId: string): Promise<ICoatingTiket> =>
  api.get(`/postapproval/cutter/gang/${gangId}`).then(res => res.data[0])

export const getCutterJobs = (gangId: string): Promise<IBinderyData[]> => 
  api.get(`/postapproval/cutter/gangJobs/${gangId}`).then(res => res.data)

export const postStartCutting = (data: IStartCuttingSendData): Promise<void> => 
  api.post('/postapproval/cutter/startCutting', data).then(res => res.data)

export const postFinishCutting = (data: IFinishCuttingSendData): Promise<void> => 
  api.post('/postapproval/cutter/finishCutting', data).then(res => res.data)

export const postCutterAction = (data: ICutterActionSendData): Promise<void> => 
  api.post('/postapproval/cutter/action', data).then(res => res.data)