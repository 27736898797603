import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { MetaData } from 'src/ui/MetaData'
import classes from './GangsSingle.module.scss'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getGangById, getGangJobsDetail } from 'src/api/postApproval/gangs/gangs'
import { IGangJobsDetail } from 'src/api/postApproval/gangs/model'
import Loading from 'src/Components/Loading/Loading'
import { IGangById } from '../../model'
import { dateConvertToStringToDate } from 'src/utils/dateConvertToString'
import Gangs from '../Gangs'
import GangsSingleItem from './GangsSingleItem/GangsSingleItem'
import AddJobToGang from './AddJobToGang/AddJobToGang'
const GangsSingle = (): JSX.Element => {
  const { id: gangId } = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [gangByIdInfo, setGangByIdInfo] = useState<IGangById>({
    gangById:[],
    gangJobsDetail:[]
  })
  
  const byIdGang = async (gangId: string): Promise<void> => {
    try {
      const res = await getGangById(gangId)
      setGangByIdInfo(prev => {
        return { ...prev, gangById: res }
      })
    } catch (error) {
      console.log(error)
        
    }
  }
  const byIdGangJobsDetail = async (gangId: string): Promise<void> => {
    try {
      const res = await getGangJobsDetail<IGangJobsDetail[]>('gangs', gangId)  
      setGangByIdInfo(prev => {
        return { ...prev, gangJobsDetail: res }
      })
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const getData = async (): Promise<void> => {
    if (gangId) {
      await byIdGang(gangId)
      await byIdGangJobsDetail(gangId)
    }
  }
  useEffect(() => {
    getData()
  },[gangId])
  

  const deleteJobFromGang = (jobId: number): void => {
    setGangByIdInfo(prev => {
      return {
        ...prev,
        gangJobsDetail: [...prev.gangJobsDetail.filter(e => e.Job !== jobId )]
      }
    })
  }
  const addJobToGang = (newGangJobsDetail: IGangJobsDetail[]): void => {
    setGangByIdInfo({ ...gangByIdInfo, gangJobsDetail:newGangJobsDetail })
  }
  return (
    <>
      {
        isLoading ? <Loading/> :
          <div className={classes.gangsSingle}>
            <MetaData title='Gang'/>
            <div className={classes.gangsSingle_container}>
              <PageHeader pageName='Gang' pageWidth='100'/>
              <AddJobToGang gangId={gangByIdInfo.gangById[0]?.gang_id} addJobToGang={addJobToGang}/>
              <div className={classes.gangsSingle_container_tableBlock}>
                <div className={classes.gangsSingle_container_tableBlock_header}>
                  <span>{gangByIdInfo.gangById[0]?.gang_id}</span>
                  <span>{gangByIdInfo.gangById[0]?.internal_name}</span>
                  <span>{gangByIdInfo.gangById[0]?.username}</span>
                  <span>{dateConvertToStringToDate(gangByIdInfo.gangById[0]?.timestamp)}</span>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>Job</th>
                      <th>Name</th>
                      <th>Paper</th>
                      <th>Due</th>
                      <th>Size</th>
                      <th>Desc</th>
                      <th>Ink</th>
                      <th>Quantity</th>
                      <th>Spots</th>
                      <th>Repp</th>
                      <th>Bindery</th>
                      <th>Lots</th>
                      <th>Booklet Cover</th>
                      <th>Proof</th>
                      <th>Finish</th>
                      <th>FSC</th>
                      <th>Notes</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      gangByIdInfo.gangJobsDetail.length ? gangByIdInfo.gangJobsDetail.map((e, index) => (
                        <GangsSingleItem key={index} gang={e} removeItem={deleteJobFromGang}/>
                      )) : null
                    }
                  </tbody>
                </table>
              </div>
              <p>Closed Gangs</p>
            </div>  
            <Gangs singleGang/> 
          </div>
      }
    </>
  )
}
export default GangsSingle