import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from '../Stripper.module.scss'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FC } from 'react'
import { IStripperFilterProps } from '../../model'
const Filter: FC<IStripperFilterProps> = ({ title, filterDataKey, labelName, selectData, filterData, isRefrersh = false }): JSX.Element => {
    
  return (
    <div className={classes.stripper_container_filterBlock}>
      <div className={classes.stripper_container_filterBlock_leftBlock}>
        <span>{title}</span>
        {
          Object.keys(selectData).length ? Object.keys(selectData).map((key, index) => (
            <div key={key}>
              <label> {labelName[index]}</label>
              <select 
                name={key}
                onChange={e => filterData(e.target.name, e.target.value, filterDataKey)}
              >
                <option value=''>All</option>
                {
                  selectData[key].length && selectData[key].map(element => (
                    <option key={element} value={element}>{element}</option>
                  ))     
                }
              </select>
            </div>
          )) : null
        }
      </div>
      {
        isRefrersh &&
        <div className={classes.stripper_container_filterBlock_rightBlock}>
          <button onClick={() => window.location.reload()}><FontAwesomeIcon icon={faPlus}/> Refresh</button>
        </div>
      }
    </div>
  )
}
export default Filter