import { FC } from 'react'
import classes from '../PrintShippmentLabel.module.scss'
import { IOptionProps } from 'src/Components/PostApproval/model'
const Options: FC<IOptionProps> = ({ data, sendDataForBack, handleChangeData }): JSX.Element => {
  
  return (
    <div className={classes.printShippmentLabel_container_mainBlock} >
      <div className={classes.printShippmentLabel_container_mainBlock_blockHeader}>
        <span>Options</span>
      </div>
      <table className={classes.printShippmentLabel_container_mainBlock_body}>
        <tbody>
          <tr>
            <td>
              <label htmlFor='opService'>
              Service
              </label>
            </td>
            <td>
              <select
                id='opService'
                name='Service'
                value={sendDataForBack.Service}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              >
                {
                  data?.serviceList?.length && (data.serviceList.map((service, index) => (
                    <option key={index} value={service.value}>{service.label}</option>
                  )))
                }
              </select>
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='opPayment'>
              Payment
              </label>
            </td>
            <td>
              <select
                id='opPayment'
                name='PaymentInformation'
                value={sendDataForBack.PaymentInformation}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              >
                {
                  data?.paymentOptions?.length && (data.paymentOptions.map((payment, index) => (
                    <option key={index} value={payment.value}>{payment.label}</option>
                  )))
                }
              </select>
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='Reference'>
              Reference
              </label>
            </td>
            <td>
              <input
                id='Reference'
                name='ReferenceNumberValue'
                type='text'
                value={sendDataForBack.ReferenceNumberValue}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <h3>
              Other Information
              </h3>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='invoiceTotal'>
              Invoice Total
              </label>
            </td>
            <td>
              <input
                id='invoiceTotal'
                name='InvoiceTotal'
                type='text'
                value={sendDataForBack.InvoiceTotal}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='packageDescription'>
              Package Description	
              </label>
            </td>
            <td>
              <input
                id='packageDescription'
                name='PackageDescription'
                type='text'
                value={sendDataForBack.PackageDescription}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <img src={data?.proofs[0]?.path} alt=''/>
            </td>
          </tr>
        </tbody>
      </table> 
    </div>
  )
}
export default Options