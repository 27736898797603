import { FC } from 'react'
import classes from '../Cutter.module.scss'
import { postFinishCuttingGang, postStartCuttingGang, postValidateGangJobStatus } from 'src/api/postApproval/cutter/cutter'
import { toast } from 'react-toastify'
import { IResponseError } from 'src/utils/errorMessage/model'
import { ICutterActionProps } from '../../model'
const CutterAction: FC<ICutterActionProps> = ({ status, gangId, updateCurrentItemStatus }): JSX.Element => {

  const startCuttingGang = async (gangId: number): Promise<void> => {
    try {
      await postStartCuttingGang(gangId)
      updateCurrentItemStatus(gangId)
      toast.success('Start Cutting Gang successfully')
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      } 
    }
  }

  const validateGangJobStatus = async (gangId: number): Promise<void> => {
    try {
      const res = await postValidateGangJobStatus(gangId)
      if (res === 'success') {
        await postFinishCuttingGang(gangId)
        toast.success('Finish Cutting Gang successfully')
      }
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }

  const startOrFinishButton = (status: string): JSX.Element => {
    switch (status) {
      case 'Ready To Cut':
        return (
          <button onClick={e => {
            e.stopPropagation()
            startCuttingGang(gangId)
          }}>Start</button>
        )
      case 'Cutting':
        return (
          <button onClick={e => {
            e.stopPropagation()
            validateGangJobStatus(gangId)
          }} className={ classes.cutter_container_tableBlock_finish}>Finish</button>
        )
      default:
        return <></>
    }
  }
    
  return (
    <>
      {startOrFinishButton(status)}
    </>
  )
}
export default CutterAction