import { getRepprint } from 'src/api/createCustomOrder/createCustomOrder'
import classes from '../LeftBlock.module.scss'
import { FC, useRef, useState } from 'react'
import { IParams } from 'src/api/Preflight/orderList/OrderListNote/model'
import { IRepprint } from '../../../model'
import { IErrorMessage, IResponseError } from 'src/utils/errorMessage/model'
import { errorMessage, toastErrorOrFieldError } from 'src/utils/errorMessage/errorMessage'
const Repprint: FC<IRepprint> = ({
  setAddToCardData,
  setJobAtributes,
  handleChangeBackData,
  setProductAttributesInfo,
  setCustomerInfo,
  handleChangeCustomer,
  setJob
}): JSX.Element => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [errorRequest, setErrorRequest] = useState<IErrorMessage>({})

  const repprintReq = async (jobId: number): Promise<void> => {
    try {
      const res = await getRepprint(jobId)
      setCustomerInfo([res.details.customer])
      handleChangeCustomer(String(res.details.customerId),res.details.customer.user.fullName)
      setJob(prev => {
        return {
          ...prev,
          height:res.details.height,
          width:res.details.width,
          quantity: res.details.quantity,
          turnaround: res.details.turnaround,
          subtotal: res.details.subtotal,
          total: res.details.total,
          productGroupId:res.details.productGroupId
        }
      })
      if (res.attributes?.length) {
        const newAtributeArray: IParams = {} as IParams
        const newSendBackendData: IParams = {} as IParams
        setProductAttributesInfo([])
        setJobAtributes(prev => {
          return [
            ...prev,
            ...res.attributes
          ]
          
        })
        setAddToCardData(prev => {
          return {
            ...prev,
            Height:res.details.height,
            Width:res.details.width,
            Quantity: String(res.details.quantity),
            Turnaround: String(res.details.turnaround),
            SubTotal: res.details.subtotal,
            Total: res.details.total
          }
        })
        
        res.attributes.forEach(element => {
          if (element?.attAttributeOptions && element.attAttributeOptions.length > 0) {
            const option = element.attAttributeOptions[0].internalLabel
            newAtributeArray[element.publicLabel] = option
            newSendBackendData[element.attributeId] = String(element.attAttributeOptions[0].attributeOptionId)
          }
        })
        for (const key in newAtributeArray) {
          if (Object.prototype.hasOwnProperty.call(newAtributeArray, key)) {
            handleChangeBackData('attributes', newSendBackendData)
          }
        }
        for (const key in newAtributeArray) {
          if (Object.prototype.hasOwnProperty.call(newAtributeArray, key)) {
            setAddToCardData(prev => {
              return {        
                ...prev,
                ...newAtributeArray 
              }
            })
          }
        }
      }
      
    } catch (error) {
      const errorMessage = error as IResponseError
      toastErrorOrFieldError(errorMessage?.response?.data, setErrorRequest)
    }
    if (inputRef.current?.value) {    
      inputRef.current.value = ''
    }
  }

  const clear = (): void => {
    setAddToCardData({})
    setJobAtributes([])
    setCustomerInfo([])
    if (inputRef.current?.value) {
      inputRef.current.value = ''
    }
  }

  return (
    <div className={classes.leftBlock_selectProduct}>
      <input ref={inputRef} onClick={() => setErrorRequest({ id:'' })}/>
      <div>
        {errorMessage('id', errorRequest)}
      </div>
      <button onClick={() => repprintReq(Number(inputRef.current?.value))}>Load</button>
      <button onClick={clear}>Clear</button>
    </div>
  )
}
export default Repprint