import { FC } from 'react'
import classes from './ClientReportsTable.module.scss'
import { IGetClientData } from 'src/api/report/model'
const ClientReportsTable: FC<{clientReportInfo: IGetClientData[]}> = ({ clientReportInfo }): JSX.Element => {
  return (
    <div className={classes.clientReportsTable}>
      <table className={classes.clientReportsTable_table}>
        <thead>
          <tr>
            <th>Email</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone</th>
            <th>Date Signed</th>
            <th>Recipient Name</th>
            <th>Address 1</th>
            <th>Address 2</th>
            <th>State</th>
            <th>City</th>
            <th>Zip</th>
            <th>Total 1</th>
            <th>Total approved 1</th>
            <th>Total 2</th>
            <th>Total approved 2</th>
            <th>Orders Placed</th>
            <th>Last Order</th>
            <th>Category</th>
          </tr>
        </thead>
        <tbody>   
          {
            clientReportInfo?.length ? clientReportInfo.map((client, index) => (
              <tr key={index} className={index % 2 === 0 ? classes.clientReportsTable_table_couple : classes.clientReportsTable_table_odd }>
                <td>{client.username}</td>
                <td>{client.first_name}</td>
                <td>{client.last_name}</td>
                <td>{client.phone_number}</td>
                <td>{client.signed}</td>
                <td>{client.recipient_first_name + ' ' + client.recipient_last_name}</td>
                <td>{client.address1}</td>
                <td>{client.address2}</td>
                <td>{client.state_abbreviation}</td>
                <td>{client.city}</td>
                <td>{client.zip_code}</td>
                <td>{client.total11}</td>
                <td>{client.total21}</td>
                <td>{client.total12}</td>
                <td>{client.total22}</td>
                <td>{client.number_orders}</td>
                <td>{client.date_last_order}</td>
                <td>{client.customer_category}</td>
              </tr>
            )) : null
          }  
             
        </tbody>
      </table>
    </div>
  )
}
export default ClientReportsTable