import { FC, memo } from 'react'
import classes from '../Outsourced.module.scss'
import { IOutsourcedFilterProps } from '../../model'
const OutsourcedFilter: FC<IOutsourcedFilterProps> = ({ stages, states, params, setParams }): JSX.Element => {
    
  const keyUpHandler = (event: React.KeyboardEvent<HTMLFormElement>): void => {
    event.preventDefault()
    const target = event.target as HTMLInputElement | HTMLTextAreaElement
    if (event.key === 'Enter' && target) {
      if (!target.value.length) {
        setParams({}) 
      } else {
        setParams({ searchFor:target.value })
      }
    }
  }
  const changeParams = (field: string, value: string): void => {
    if (!value?.length) {
      setParams({})
    } else {
      setParams({ [field]: value })
    }
  }
  return (
    <div className={classes.outsourced_container_filterBlock_leftBlock}>
      <form onKeyUp={(e) => keyUpHandler(e)} onSubmit={e => e.preventDefault()}>
        <label>
      Search For Job
        </label>
        <input type='text' defaultValue={params.searchFor}/>
      </form>
      <div>
        <label>
     Stage
        </label>
        <select
          value={params.stage}
          onChange={(e) => changeParams('stage',e.target.value)}
        >
          <option value='' selected>All</option>
          {
            stages?.length && stages.map((e, index) => (
              <option key={index} value={e.label_id}>{e.name}</option>
            ))
          }
        </select>
      </div>
      <div>
        <label>
      Status 
        </label>
        <select
          value={params.state}
          onChange={(e) => changeParams('state',e.target.value )}
        >
          <option value='' selected>All</option>
          {
            states?.length && states.map((e, index) => (
              <option key={index} value={e.label_id}>{e.name}</option>
            ))
          }
        </select>
      </div>
    </div>
  )
}

export default memo(OutsourcedFilter)