
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useRef } from 'react'
import { ipcStore } from 'src/stores/IPC/ipcStore'
import { IIPCSelectSizeProps } from '../../IPC.model'
import classes from '../IPCPriceCalc.module.scss'

const IPCSelectSize: FC<IIPCSelectSizeProps> =
  ({ IPCRes, changeIPCPayloadUpdate, payloadUpdates }): JSX.Element => {
    const { currentProductSizes, setCurrentProductSizes, isClickedOnCustomSize, isShowSizesPopupWarning, loadingStatus: isLoading } = ipcStore

	  const sizeRef = useRef<HTMLSelectElement>(null)
	  const minWidth = parseFloat(IPCRes?.productData?.minWidth)
	  const minHeight = parseFloat(IPCRes?.productData?.minHeight)
	  const maxWidth = parseFloat(IPCRes?.productData?.maxWidth)
	  const maxHeight = parseFloat(IPCRes?.productData?.maxHeight)
	  const widthHeightData = IPCRes?.widthHeightData
	  const isCustomSize = IPCRes?.productData?.customSize

	  // eslint-disable-next-line @typescript-eslint/no-explicit-any
	  const widthRef = useRef<HTMLInputElement>(null)
	  const heightRef = useRef<HTMLInputElement>(null)

	  const optionWidthRef = useRef<HTMLSelectElement>(null)
	  const optionHeightRef = useRef<HTMLSelectElement>(null)

	  useEffect(()=>{
		 if (optionWidthRef.current && optionHeightRef.current) {
        const width = optionWidthRef.current?.options[optionWidthRef.current.options.selectedIndex]?.value
        const height = optionHeightRef.current.options[optionHeightRef.current.options.selectedIndex]?.value
        if (width && height) {
          setCurrentProductSizes({ width, height })
        }
		 }
	  }, [optionWidthRef.current, optionHeightRef.current])

	  const handleSizeChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
      const currentProduct = IPCRes.productData.products.find(
        ({ print_product_id: printProductId }) =>
          e.target.options[e.target.options.selectedIndex].id === `${printProductId}`,
      )

      // !! dispatch(
      //   changeTrimOrientationAvailabilityStatus({
      //     width: Number(currentProduct.width),
      //     height: Number(currentProduct.height),
      //   }),
      // )
      currentProduct
        ?
        setCurrentProductSizes({
          height: currentProduct.height,
          width: currentProduct.width,
        })
        :
        setCurrentProductSizes({
          height: currentProductSizes?.height
            ? `${currentProductSizes.height}`
            : IPCRes.productData.products[0].height,
          width: currentProductSizes?.width
            ? `${currentProductSizes.width}`
            : IPCRes.productData.products[0].width,
        })
      e.target.options[e.target.options.selectedIndex].getAttribute('data-customsize') ===
      'customSize'
        ? ipcStore.isClickedCustomSize = true
        : ipcStore.isClickedCustomSize = false

      changeIPCPayloadUpdate(
        'printProductId',
        IPCRes?.productData?.products[e.target?.options?.selectedIndex]?.print_product_id,
      )
    }

	  const handleChangeProductCurrentSize = ( width: string, height: string): void => {
		 const GLOBAL_MIN_WIDTH = 1.5
		 const GLOBAL_MAX_WIDTH = 22
		 const GLOBAL_MIN_HEIGHT = 1.5
		 const GLOBAL_MAX_HEIGHT = 28

		 if (widthHeightData.defaultWidth.length > 1 && widthHeightData.defaultWidth.length > 1) {
        setCurrentProductSizes({ width, height })
        return
		 }

		 if (IPCRes?.groupData?.is_large_format_print === 0) {

        let isOutOfBoundries = false
        let newHeight = height
        let newWidth = width

        if (parseInt(width) > parseInt(height)) {
			  newHeight = width
			  newWidth = height
        }

        if (parseFloat(newWidth) < GLOBAL_MIN_WIDTH) {
			  isOutOfBoundries = true
        }
        if (parseFloat(newWidth) > GLOBAL_MAX_WIDTH) {
			  isOutOfBoundries = true
        }
        if (parseFloat(newWidth) > maxWidth) {
			  isOutOfBoundries = true
        }
        if (parseFloat(newHeight) < GLOBAL_MIN_HEIGHT) {
			  isOutOfBoundries = true
        }
        if (parseFloat(newHeight) > GLOBAL_MAX_HEIGHT) {
			  isOutOfBoundries = true
        }
        if (parseFloat(newHeight) > maxHeight) {
			  isOutOfBoundries = true
        }
        if (isOutOfBoundries) {
        	  ipcStore.isShowSizesPopupWarning = true
        }
		 }
		  setCurrentProductSizes({ width, height })
	  }

	  useEffect(() => {
		 const customHeight = document.getElementById('customHeight')
		 customHeight?.addEventListener('change', (event) => {
        const { target } = event
        handleChangeProductCurrentSize(`${widthRef.current?.value}`, (target as HTMLInputElement).value)
		 })
		 const customWidth = document.getElementById('customWidth')
		 customWidth?.addEventListener('change', (event) => {
        const { target } = event
        handleChangeProductCurrentSize( (target as HTMLInputElement).value, `${heightRef.current?.value}`)
		 })
	  }, [isClickedOnCustomSize])


	  return (
		 <div>
        {IPCRes?.productData?.products[0]?.product_label !== 'Any Size' && (
			  <label htmlFor='size' id={classes.sizeLabel}>
				 <div>
              {IPCRes?.groupData?.attribute_label}
				  <select
                name='size'
                id={classes.size}
                onChange={handleSizeChange}
                ref={sizeRef}
                disabled={isLoading}
                value={payloadUpdates.printProductId + (isClickedOnCustomSize ? '-customsize' : '-size')}
              >
                {IPCRes?.productData?.products?.map((el) =>
                  el.product_label !== 'Any Size' && (
                    <option
                      key={el.print_product_id}
                      id={`${el.print_product_id}`}
                      value={el.print_product_id + '-size'}
                    >
                      {el.product_label}
                    </option>
                  ),
                )}
                {isCustomSize && (
                  <option
                    // id='0'
                    id={classes.customSizeId}
                    data-customsize='customSize'
                    value={payloadUpdates.printProductId + '-customsize'}
                  >
                    custom size
                  </option>
                )}
              </select>
				 </div>
				 {IPCRes?.groupData?.product_group_id === 45 && <p>Scroll Down for Circles & Ovals</p>}
			  </label>
        )}

        {(IPCRes?.productData?.products[0]?.product_label === 'Any Size' ||
			  isClickedOnCustomSize) && (
			  <>
				 {widthHeightData?.defaultHeight && <label htmlFor='height'>
					Height
              {widthHeightData?.defaultHeight.length !== 1 ? (
					  <select
						 name='height'
						 id='height'
						 ref={optionHeightRef}
						 onChange={(e) => {
                    handleChangeProductCurrentSize(
							  `${currentProductSizes?.width}`,
							  e.target.options[e.target.options.selectedIndex].value,
                    )
                  }
						 }
						 disabled={isLoading}
						 defaultValue={currentProductSizes?.height}
						 value={currentProductSizes?.height}
					  >
						 {widthHeightData?.defaultHeight?.map((item, i) => (
                    <option selected={currentProductSizes?.height === Number(item)} key={i}
							  value={item}>{`${item}"`}</option>
						 ))}
					  </select>
              ) : (
					  <input
						 ref={heightRef}
						 type='number'
						 step={0.1}
						 name='height'
						 id='customHeight'
						 placeholder='height'
						 min={minHeight}
						 max={maxHeight}
						 disabled={isLoading}
						 onChange={(e) => {
						   handleChangeProductCurrentSize(`${widthRef?.current?.value}`, (e.target as HTMLInputElement).value)
						 }}
						 defaultValue={currentProductSizes?.height}
						 value={isShowSizesPopupWarning ? currentProductSizes?.height : undefined}
					  />
              )}
				 </label>}
				 {widthHeightData?.defaultWidth && <label htmlFor='width'>
				 Width
              {widthHeightData?.defaultWidth.length !== 1 ? (
					  <select
						 name='width'
						 id='width'
						 ref={optionWidthRef}
						 onChange={(e) => {
                    handleChangeProductCurrentSize(
							  e.target.options[e.target.options.selectedIndex].value,
							  `${currentProductSizes?.height}`,
                    )
                  }
						 }
						 defaultValue={currentProductSizes?.width}
						 value={currentProductSizes?.width}
						 disabled={isLoading}
					  >
						 {widthHeightData?.defaultWidth?.map((item) => (
                    <option selected={currentProductSizes?.width === Number(item)} key={item}
							  value={item}>{`${item}"`}</option>
						 ))}
					  </select>
              ) :
					  <input
						 ref={widthRef}
						 type='number'
						 step={0.1}
						 name='width'
						 id='customWidth'
						 placeholder='width'
						 min={minWidth}
						 max={maxWidth}
						 disabled={isLoading}
						 onChange={(e) => {
						   handleChangeProductCurrentSize( (e.target as HTMLInputElement).value, `${heightRef?.current?.value}`)
						 }}
						 defaultValue={currentProductSizes?.width}
						 value={isShowSizesPopupWarning ? currentProductSizes?.width : undefined}
					  />
              }
				 </label>}
			  </>
        )}
		 </div>
	  )
  }
export default observer(IPCSelectSize)

