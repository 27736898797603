import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { IGetQbBackup } from 'src/api/settings/qbBackup/model'
import { QbBackupStartOrStop, getQbBackup } from 'src/api/settings/qbBackup/qbBackup'
import { MetaData } from 'src/ui/MetaData'
import { dateConvertToStringToDate } from 'src/utils/dateConvertToString'
import { IConditionalFieldQBBackup } from '../model'
import classes from './QbBackup.module.scss'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
const QbBackup = (): JSX.Element => {
  const [qbBackupInfo, setQbBackupInfo] = useState<IGetQbBackup>({} as IGetQbBackup)
  const qbBackupData = async (): Promise<void> => {
    try {
      setQbBackupInfo( await getQbBackup())
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!Object.keys(qbBackupInfo).length) {
      qbBackupData()
    }
  },[qbBackupInfo])

  const startOrStop = async (): Promise<void> => {
    try {
      await QbBackupStartOrStop(!qbBackupInfo.running ? 'start' : 'stop')
    } catch (error) {
      console.log('error', error)
    }
  }
  const handleClick = async (): Promise<void> => {
    await startOrStop()
    await qbBackupData() 
  }
  const conditionalField = (): IConditionalFieldQBBackup => {
    if (!qbBackupInfo.running) {
      return {
        icon:  faPlay,
        buttonName: 'Start Backup',
        changeField: 'Ready'
      }
    }
    return {
      icon:  faStop,
      buttonName: 'Stop BackUp',
      changeField: 'Running'
    }
  }
  return (
    <>
      <MetaData title='QB Backup'/>
      <div className={classes.qbBackup}>
        <PageHeader pageName='QB Backup'/>
        <div className={classes.qbBackup_container}>
          <div>
            <div>
              <p>Status </p>
              <p>Last Backup </p>
              <button onClick={handleClick}><FontAwesomeIcon icon={conditionalField().icon} /> {conditionalField().buttonName}</button>
            </div>
            <div>
              <p>{conditionalField().changeField}</p>
              <p>	{dateConvertToStringToDate(qbBackupInfo.lastBackup)}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QbBackup