import { Link } from 'react-router-dom'
import './PageErrors.css'
const PageErrors = (): JSX.Element => {

  return (
    <section className='page_404'>
      <div className='four_zero_four_bg'>
		    <Link to='/' className='link_404'>Go to Home</Link>
		    <h1 className='text-center '>404</h1>
      </div>
    </section>
  )
}

export default PageErrors