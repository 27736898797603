import { MetaData } from 'src/ui/MetaData'
import classes from './Ticket.module.scss'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { useEffect, useState } from 'react'
import { ICoatingTiket } from 'src/api/postApproval/coating/model'
import { getCoatingTicket } from 'src/api/postApproval/coating/coating'
import { useParams } from 'react-router-dom'
const Ticket = (): JSX.Element => {
  const { id:gangId } = useParams()
  const [ticketInfo, setTicketInfo] = useState<ICoatingTiket[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const ticketData = async (gangId: string): Promise<void> => {
    try {
      setTicketInfo(await getCoatingTicket(gangId))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (gangId) {
      ticketData(gangId)
    }
  },[gangId])

  return (
    <div className={classes.ticket}>
      <MetaData title='Ticket'/>
      <div className={classes.ticket_container}>
        <PageHeader pageName='4OVER4 JOB TICKET' pageWidth='100'/>
        {
          isLoading ? <h2>Loading...</h2> :
            <div className={classes.ticket_container_body}>
              {
                ticketInfo.length ? ticketInfo.map((ticket, index) => (
                  <div key={index} className={classes.ticket_container_body_itemBlock}>
                    <div className={classes.ticket_container_body_itemBlock_item}>
                      <span>GANG ID:</span>
                      <span>{ticket.gang_id}</span>
                    </div>
                    <div className={classes.ticket_container_body_itemBlock_item}>
                      <span>PRESS:</span>
                      <span>{ticket.press}</span>
                    </div>
                    <div className={classes.ticket_container_body_itemBlock_item}>
                      <span>QUANTITY:</span>
                      <span>{ticket.quantity}</span>
                    </div>
                    <div className={classes.ticket_container_body_itemBlock_item}>
                      <span>STOCK:</span>
                      <span>{ticket.internal_name}</span>
                    </div>
                    <div className={classes.ticket_container_body_itemBlock_item}>
                      <span>COLORS:</span>
                      <span>{ticket.colors}</span>
                    </div>
                    <div className={classes.ticket_container_body_itemBlock_item}>
                      <span>COLORS PROOF:</span>
                      <span>{ticket.proof}</span>
                    </div>
                    <div className={classes.ticket_container_body_itemBlock_item}>
                      <span>NOTES:</span>
                      <span>{ticket.note}</span>
                    </div>
                    <div className={classes.ticket_container_body_itemBlock_item}>
                      <span>STRIPPED BY:</span>
                      <span>{ticket.username}</span>
                    </div>
                  </div>
                )) : null
              }

            </div>
        }
      </div>
    </div>
  )
}
export default Ticket