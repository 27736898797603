import { FC } from 'react'
import { ModalHeader } from 'src/ui/modal/ModalHeader'
import { IDeletePopup } from 'src/ui/modal/model'
import Modal from '../../ui/modal/Modal'
import classes from './DeletePopup.module.scss'
const DeletePopup: FC<IDeletePopup> = ({ toggle, remove }): JSX.Element => {

  return (
    <Modal isShowing>
      <>
        <ModalHeader>
          <div className={classes.deletePopup}>
            <h2 className={classes.deletePopup_title}>Do you really want to delete? </h2>
          </div>

        </ModalHeader>
        <div className={classes.deletePopup_body}>
          <button onClick={toggle} className={classes.deletePopup_body_no}>
            No
          </button>
          <button className={classes.deletePopup_body_yes} onClick={()=> remove()}>
            Yes
          </button>
        </div>
      </>
    </Modal>
  )
}

export default DeletePopup