import { api } from 'src/api/api'
import { IAddJobToGang, IBinderyData, IBinderyDetails, IClosedGangs, ICurentGangs, IGangJobsDetail, IGangPressmanSingle, IGangSingleData, IGetGangsSimple, ILaminationData, IPressmanGangJobsDetail, ISendGangData } from './model'
import { IParams } from 'src/api/Preflight/orderList/OrderListNote/model'
import { ICoatingActionSuccess, ICoatingTiket, ISendCoatingActionData } from '../coating/model'

export const getCurentGangs = (params?: IParams, query?: string): Promise<ICurentGangs> => {
  if (params && query) {
    return api.get('/postapproval/gangs/' + query, { params }).then(res => res.data)
  } else {
    return api.get('/postapproval/gangs').then(res => res.data)
  }
}

export const getClosedGangs = (params: IParams): Promise<IClosedGangs> =>
  api.get('/postapproval/gangs/closed',{ params }).then(res => res.data)

export const putCloseGangStatus = (gangId: number): Promise<void> => 
  api.put(`/postapproval/gangs/${gangId}/closeGangStatus`)

export const putGangNote = (gangId: number, note: string): Promise<void> =>
  api.put(`/postapproval/gangs/${gangId}/note`, { note }).then(res => res.data)

export const getGangsSimple = (gangId: number): Promise<IGetGangsSimple> =>
  api.get(`/postapproval/gangs/simple/${gangId}`).then(res => res.data)

export const putGangData = (gangId: number, data: ISendGangData): Promise<void> => 
  api.put(`/postapproval/gangs/${gangId}/updateGangData`, data).then(res => res.data)

export const getLamination = (query: string): Promise<ILaminationData[]> =>
  api.get(`/postapproval/${query}/index`).then(res => res.data)

export const getBindery = (): Promise<IBinderyData[]> =>
  api.get('/postapproval/bindery/index').then(res => res.data)

export const getBinderyDetails = (jobId: string): Promise<IBinderyDetails[]> => 
  api.get(`/postapproval/bindery/jobs/${jobId}`).then(res => res.data)

export const postBinderyAction = (jobId: number): Promise<string> => 
  api.post('/postapproval/bindery/action', { jobId }).then(res => res.data.message)

export const getPressmanById = ( gangId: string): Promise<IGangPressmanSingle[]> => 
  api.get(`/postapproval/pressman/gang/${gangId}`).then(res => res.data)

export const getGangJobsDetail = <T>(field: string, gangId: string): Promise<T> =>
  api.get(`/postapproval/${field}/gangJobsDetail/${gangId}`).then(res => res.data)

export const getGangById = (gangId: string): Promise<IGangSingleData[]> => 
  api.get(`/postapproval/gangs/id/${gangId}`).then(res => res.data)

export const postAddJobToGang = (data: IAddJobToGang): Promise<IGangJobsDetail[]> => 
  api.post('/postapproval/gangs/addJobToGang', data).then(res => res.data)

export const removeJobFromGang = (jobId: number): Promise<void> =>
  api.delete(`/postapproval/gangs/removeJobFromGang/${jobId}`).then(res => res.data)

export const getLaminationGang = (gangId: string): Promise<ICoatingTiket> => 
  api.get(`/postapproval/sleeking/gang/${gangId}`).then(res => res.data[0])

export const getLaminationJobs = (gangId: string): Promise<IPressmanGangJobsDetail[]> => 
  api.get(`/postapproval/sleeking/gangJobs/${gangId}`).then(res => res.data)

export const postLaminationAction = (field: string, data: ISendCoatingActionData): Promise<ICoatingActionSuccess> =>
  api.put('/postapproval/sleeking/action', data).then(res => res.data)