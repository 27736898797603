import { useParams } from 'react-router-dom'
import classes from './PrintableSummary.module.scss'
import { useEffect, useState } from 'react'
import { IPrintableSummary } from 'src/api/Preflight/orderList/OrderListSummary/model'
import { getPrintableSummary } from 'src/api/Preflight/orderList/OrderListSummary/OrderListSummary'
import { MetaData } from 'src/ui/MetaData'
import { floatNumberCut } from 'src/utils/FloatNumberCut'
const PrintableSummary = (): JSX.Element => {
  const { id:orderId } = useParams()
  const [printableSummary, setPrintableSummary] = useState<IPrintableSummary>({} as IPrintableSummary)
 
  const printableSummaryRequest = async (orderId: string): Promise<void> => {
    try {
      setPrintableSummary(await getPrintableSummary(orderId))   
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (orderId) {
      printableSummaryRequest(orderId)
    } 
  },[orderId])

  const jobCoupon = (jobId: number): {code: string,discount_applied: string } => {
    const coupon = printableSummary.coupons.find(coupon => coupon.job_id === jobId )
    return {
      code:coupon?.code || '',
      discount_applied:coupon?.discount_applied || ''
    }
  }

  const jobAmount = (jobId: number,type: string): string => {
    const extrasAmount = type === 'extras'
      ? printableSummary.extras.find(e => e.job_id === jobId)?.amount
      : printableSummary.discounts.find(e => e.job_id === jobId)?.amount
    return extrasAmount || ''
  }

  return (
    <div className={classes.printableSummary}>
      <MetaData title='Printable Summary'/>
      <div className={classes.printableSummary_container}>
        <div className={classes.printableSummary_container_order}>
          <span>Order#</span>
          <span>{orderId}</span>
          <span>{printableSummary.details?.fullName}</span>
          <span>{printableSummary.details?.userId}</span>
          <span>{printableSummary.details?.email}</span>
          <span>{printableSummary.details?.salesCustomers?.phoneNumber}</span>
        </div>
        <div className={classes.printableSummary_container_body}>
          <div className={classes.printableSummary_container_body_leftBlock}>
            {
              printableSummary.shippments?.length ?
                <div className={classes.printableSummary_container_body_leftBlock_shippment}>
                  <h3>Shippment {printableSummary.shippments?.length}</h3>
                  <div className={classes.printableSummary_container_body_leftBlock_shippment_body}>
                    {
                      printableSummary.shippments.map((e,index) => (
                        <div key={index} className={classes.printableSummary_container_body_leftBlock_shippment_body_item}>
                          <span>{e.shipping_method}</span>
                          <span>{e.company_name}</span>
                          <span>{e.recipient_first_name + ' ' + e.recipient_last_name}</span>
                          <span>{e.address1}</span>
                          <span>{e.city + ' ' + e.state + ' ' + e.zip_code}</span>
                          <span>{e.phone_number}</span>
                        </div>
                      )) 
                    }
                  </div>
                </div> : null
            }
            {
              printableSummary.billing?.length ?
                <div className={classes.printableSummary_container_body_leftBlock_shippment}>
                  <h3>Billing {printableSummary.shippments?.length}</h3>
                  <div className={classes.printableSummary_container_body_leftBlock_shippment_body}>
                    {
                      printableSummary.billing.map((e,index) => (
                        <div key={index} className={classes.printableSummary_container_body_leftBlock_shippment_body_item}>
                          <span>{e.payment_method}</span>
                          <span>{e.company_name}</span>
                          <span>{e.recipient_first_name + ' ' + e.recipient_last_name}</span>
                          <span>{e.address1}</span>
                          <span>{e.city + ' ' + e.state + ' ' + e.zip_code}</span>
                          <span>{e.phone_number}</span>
                        </div>
                      )) 
                    }
                  </div>
                </div> : null
            }
          </div>
          
          <div className={classes.printableSummary_container_body_jobs}>
            <h3>Item {printableSummary?.order?.jobs?.length}</h3>
            {
              printableSummary.order?.jobs?.length ? printableSummary.order.jobs.map((e, index) => (
                <div key={index} className={classes.printableSummary_container_body_jobs_items}>
                  <div>
                    <span>Product</span>
                    <span>{e.printProductName + ' ' + e.productGroupName}</span>
                  </div>
                  <div>
                    <span>Quantity</span>
                    <span>{e.quantity}</span>
                    <span>${e.basePrice}</span>
                  </div>
                  <div>
                    <span>Turnaround</span>
                    <span>{e.turnaround + ' ' + 'Days'}</span>
                    <span>${floatNumberCut(e.turnaroundPrice, 2)}</span>
                  </div>
                  {
                    e.attributes?.length ? e.attributes.map((atribute, aIndex) =>(
                      <div key={aIndex}>
                        <span>{atribute?.attributeName}</span>
                        <span>{atribute.attributeOptionName}</span>
                        <span>${floatNumberCut(atribute.price, 2)}</span>
                      </div>
                    )) : null
                  }
                  {
                    printableSummary.coupons?.length ? 
                      <div>
                        <span>Coupon Applied</span>
                        <span>{jobCoupon(e.jobId).code}</span>
                        <span>${floatNumberCut(jobCoupon(e.jobId).discount_applied,2)}</span>
                      </div> : null
                  }
                  {
                    printableSummary.extras?.length ? 
                      <div>
                        <span>Extras</span>
                        <span>${floatNumberCut(jobAmount(e.jobId, 'extras'),2)}</span>
                      </div> : null
                  }
                  {
                    printableSummary.discounts?.length ? 
                      <div>
                        <span>Discount</span>
                        <span>${floatNumberCut(jobAmount(e.jobId, 'discount'),2)}</span>
                      </div> : null
                  }
                  <div>
                    <span><b>Product Total</b></span>
                    <span>${floatNumberCut(e.productTotal, 2)}</span>
                  </div>
                </div>
              )) : null
            }
            <div className={classes.printableSummary_container_body_jobs_items}>
              <div>
                <span><b>Tax</b></span>
                <span>${floatNumberCut(String(printableSummary.taxTotal), 2)}</span>
              </div>
              <div>
                <span><b>Shipping</b></span>
                <span>${floatNumberCut(String(printableSummary.shippingTotal), 2)}</span>
              </div>
              <div>
                <span><b>Total</b></span>
                <span>${floatNumberCut(String(printableSummary.Total), 2)}</span>
              </div>
            </div>
          </div>
          <div className={classes.printableSummary_container_body_proofs}>
            {
              printableSummary.order?.jobs?.length ? printableSummary.order.jobs?.map(e => {
                return e.proofs?.length ? e.proofs.map((e,index )=> (
                  <img key={index} src={e.url} alt=''/>
                )) : null
              }) : null 
            }
          </div>
        </div>
      </div>
            
    </div>
  )
}
export default PrintableSummary