import { useState } from 'react'

const useActiveTab = (defoultTabName: string): { tabName: string; changeTab: (tab: string) => void } => {
  const [tabName, setTabName] = useState<string>(defoultTabName)


  const changeTab = (tab: string): void => {
    if (tab !== tabName) {
      setTabName(tab)
    } 
  }
  return {
    tabName,
    changeTab
  }
}

export default useActiveTab
