import { MetaData } from 'src/ui/MetaData'
import classes from './Boxing.module.scss'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { FC, useEffect, useState } from 'react'
import { IGetBoxing } from 'src/api/postApproval/boxing/model'
import { getBoxing } from 'src/api/postApproval/boxing/boxing'
import BoxHeader from './BoxHeader/BoxHeader'
import BodyBlock from './BodyBlock/BodyBlock'
import Loading from 'src/Components/Loading/Loading'
const Boxing: FC<{type: number}> = ({ type }): JSX.Element => {
  const [boxingInfo, setBoxingInfo] = useState<IGetBoxing>({} as IGetBoxing)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  
  const boxingData = async (type: number): Promise<void> => {
    try {
      setIsLoading(true)
      setBoxingInfo(await getBoxing(type))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (type) {
      boxingData(type)
    }
  },[type])

  const pageName = (type: number): string => {
    if (type === 1) {
      return 'Boxing'
    }
    return 'Boxing FBC'
  }


  const boxingItem = (): JSX.Element[] => {
    const mainBlock: JSX.Element[] = []
    
    Object.values(boxingInfo).forEach((shipment, index) => {
      const tbodyData: JSX.Element[] = []
      let previousJobId: number
      
      shipment.forEach(({ boxes }) => {
        boxes.forEach(e => {
          e.items.forEach(item => {
            if (previousJobId !== item.job_id) {
              tbodyData.push(
                <BodyBlock key={item.job_id} item={item} shippmentId={e.shippment_id} setBoxingInfo={setBoxingInfo}/>
              )
            }
            previousJobId = item.job_id
          })
        })
      })
  
      mainBlock.push(
        <div className={classes.boxing_container_tableBlock} key={index}>
          <BoxHeader
            orderId={Number(shipment[0].order_id)}
            shippmentId={Number(shipment[0].shippment_id)}
            boxesLength={shipment[0].boxes?.length}
            blindShiping={shipment[0].blind_shipping}
            itemNumber={shipment[0].total}
            publicLabel={shipment[0].public_label}
            serviceType={shipment[0].service_type}
            numberOf={shipment[0].number_of}
            of={shipment[0].of}
            skuLable ={shipment[0].sku_label}
            firstOrder={shipment[0].first_order}
            missingTotal={shipment[0].missingTotal}
          />
          <table>
            <thead>
              <tr>
                <th>Item</th>
                <th>Product</th>
                <th>Quantity</th>
                <th>Notes</th>
                <th>Due Date</th>
                <th>Action</th>
                <th>Thumbnails</th>
              </tr>
            </thead>
            <tbody>
              {tbodyData}
            </tbody>
          </table>
        </div>
      )
    })
  
    return mainBlock
  }
  
  return (
    <div className={classes.boxing}>
      <MetaData title={pageName(type)} />
      {
        isLoading ? <Loading/> :
          <div className={classes.boxing_container}>
            <PageHeader pageName={pageName(type)} pageWidth='100' />
            {boxingItem()}
          </div>
      }
    </div>
  )
}

export default Boxing