import { IResponseError } from 'src/utils/errorMessage/model'
import classes from '../GangsSingle.module.scss'
import { toast } from 'react-toastify'
import { postAddJobToGang } from 'src/api/postApproval/gangs/gangs'
import { FC, useRef } from 'react'
import { IAddJobToGangProps } from 'src/Components/PostApproval/model'
const AddJobToGang: FC<IAddJobToGangProps> = ({ gangId, addJobToGang }): JSX.Element => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const addJob = async (): Promise<void> => {
    try {
      const res = await postAddJobToGang({
        gangId,
        jobId: Number(inputRef.current?.value)
      })   
      if (res.length) {
        addJobToGang(res)
      }
      toast.success('Job added to gang successfully')
      console.log(res)
      if (inputRef?.current && inputRef.current.value.length) {
        inputRef.current.value = ''
      }
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }
  return (
    <div className={classes.gangsSingle_container_addBlock}>
      <input ref={inputRef}/>
      <button onClick={addJob}>Add</button>
    </div>
  )
}
export default AddJobToGang