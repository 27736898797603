export const getDateColor = (dueDateStr: string): { color: string; text: string } => {
  let color = ''
  const month = parseInt(dueDateStr.substr(0, 2), 10) - 1
  const day = parseInt(dueDateStr.substr(3, 2), 10)
  const year = parseInt(dueDateStr.substr(6, 2), 10)
  const dueDate = new Date(year, month, day)
  dueDate.setHours(0, 0, 0, 0)

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(today.getDate() + 1)
  const afterTomorrow = new Date(tomorrow)
  afterTomorrow.setDate(tomorrow.getDate() + 1)

  if (today.getDay() === 0) {
    today.setDate(today.getDate() + 1)
  } else if (today.getDay() === 6) {
    today.setDate(today.getDate() + 2)
  }

  if (tomorrow.getDay() === 0) {
    tomorrow.setDate(tomorrow.getDate() + 1)
  } else if (tomorrow.getDay() === 6) {
    tomorrow.setDate(tomorrow.getDate() + 2)
  }

  if (afterTomorrow.getDay() === 0) {
    afterTomorrow.setDate(afterTomorrow.getDate() + 1)
  } else if (afterTomorrow.getDay() === 6) {
    afterTomorrow.setDate(afterTomorrow.getDate() + 2)
  }

  if (dueDate < today) {
    color = '#6F0000'
  } else if (dueDate.getDate() === today.getDate() &&
               dueDate.getMonth() === today.getMonth() &&
               dueDate.getFullYear() === today.getFullYear()) {
    return { color:'#EE0000', text: 'TODAY' }
  } else if (dueDate.getDate() === tomorrow.getDate() &&
               dueDate.getMonth() === tomorrow.getMonth() &&
               dueDate.getFullYear() === tomorrow.getFullYear()) {
    return { color:'#E17009', text: 'TOMORROW' }
  } else if (dueDate.getDate() === afterTomorrow.getDate() &&
               dueDate.getMonth() === afterTomorrow.getMonth() &&
               dueDate.getFullYear() === afterTomorrow.getFullYear()) {
    color = '#F8C534'
  }

  return { color, text: '' }
}