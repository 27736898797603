import { FC, useEffect, useState } from 'react'
import classes from '../CreateCustomer.module.scss'
import { IGetInvoiceMethods, ISalesCustomer } from 'src/api/customers/model'
import { getInvoiceMethods } from 'src/api/customers/customers'
const EmailOptions: FC<{
    salesCustomers: ISalesCustomer
    handleChange: (field: string, value: string | number) => void
}> = ({ salesCustomers, handleChange }): JSX.Element => {
  const [invoiceMethodsInfo, setInvoiceMethods] = useState<IGetInvoiceMethods>({} as IGetInvoiceMethods)

  const invoiceMethods = async (): Promise<void> => {
    try {
      setInvoiceMethods( await getInvoiceMethods())
    } catch (error) {
      console.log(error)
      
    }
  }

  useEffect(() => {
    invoiceMethods()
  },[])

  return (
    <>
      <div className={classes.createCustomer_blockHeader}>
        <span>Email Options</span>
      </div>
      <table className={classes.createCustomer_body}>
        <tbody>
          <tr>
            <td>Receive Status Emails</td>
            <td>
              <div>
                <label>
                  <input
                    type='radio'
                    name='salesCustomersreceiveStatusEmail'
                    checked={salesCustomers?.receiveStatusEmail === 1}
                    onChange={(e) => handleChange(e.target.name, 1)}
                  />
                  YES
                </label>
                <label>
                  <input
                    type='radio'
                    name='salesCustomersreceiveStatusEmail'
                    checked={salesCustomers?.receiveStatusEmail === 0}
                    onChange={(e) => handleChange(e.target.name, 0)}
                  />
                NO
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td>Receive Promotional Emails</td>
            <td>
              <div>
                <label>
                  <input 
                    type='radio' 
                    name='salesCustomersreceivePromoEmail' 
                    checked={salesCustomers?.receivePromoEmail === 1}
                    onChange={(e) => handleChange(e.target.name, 1)}
                  />
                  YES
                </label>
                <label>
                  <input 
                    type='radio' 
                    name='salesCustomersreceivePromoEmail' 
                    checked={salesCustomers?.receivePromoEmail === 0}
                    onChange={(e) => handleChange(e.target.name, 0)}
                  />
                NO
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td>Customer Shipping</td>
            <td>
              <div>
                <label>
                  <input 
                    type='radio' 
                    name='salesCustomersshippingPaymentMethod' 
                    checked={salesCustomers?.shippingPaymentMethod === 'FreightCollect'}
                    onChange={(e) => handleChange(e.target.name, 'FreightCollect')}
                  />
                  YES
                </label>
                <label>
                  <input 
                    type='radio' 
                    name='salesCustomersshippingPaymentMethod' 
                    checked={salesCustomers?.shippingPaymentMethod !== 'FreightCollect'}
                    onChange={(e) => handleChange(e.target.name, '')}
                  />
                NO
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <div>
                <input 
                  type='text'
                  placeholder='Account #'
                  name='salesCustomersfreightAccountNumber'
                  value={salesCustomers?.freightAccountNumber || ''}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
                <input 
                  type='text' 
                  placeholder='Zip Code'
                  name='salesCustomersfreightAccountZipCode' 
                  value={salesCustomers?.freightAccountZipCode || ''}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Invoice Delivery Method	</td>
            <td>
              <select
                value={salesCustomers?.invoiceDeliveryMethod || invoiceMethodsInfo[0]}
                name='salesCustomersinvoiceDeliveryMethod'
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              >
                {
                  Object.keys(invoiceMethodsInfo).length && (
                    Object.keys(invoiceMethodsInfo).map((e, index) => (
                      <option key={index} value={e}>{invoiceMethodsInfo[e]}</option>
                    ))
                  )
                }
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
export default EmailOptions