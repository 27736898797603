import { FC, Fragment, useState } from 'react'
import classes from '../LeftBlock.module.scss'
import { IAttributesOptionData, ICustomAttributesProps, ISelectedAttribute } from '../../../model'
import { getAttributeOptions } from 'src/api/createCustomOrder/createCustomOrder'
const CustomAttributes: FC<ICustomAttributesProps> = ({ customAttributes, productAttributesInfo, jobAtributes, handleChangeAddToCard, handleChangeBackData }): JSX.Element => {
  const [selectedAttribute, setSelectedAttribute] = useState<ISelectedAttribute>({
    id:'67',
    name:'Custom - Booklet Cover'
  })
  const [attributeOptionsInfo, setAttributeOptionsInfo] = useState<IAttributesOptionData>({} as IAttributesOptionData)

  const attributeOptions = async (): Promise<void> => {
    try {
      const res = await getAttributeOptions(selectedAttribute.id)
      setAttributeOptionsInfo({ ...attributeOptionsInfo, [selectedAttribute.name] :res })
      handleChangeAddToCard(selectedAttribute.name, res[0].internalLabel )
      handleChangeBackData('attributes,' + res[0].attributeId, String(res[0].attributeOptionId))
    } catch (error) {
      console.log(error)
    }
  }

    
  return (
    <>
      <div className={classes.leftBlock_items_customer}>
        <label>Customer:</label>
        <div>
          <select
            onChange={e => setSelectedAttribute({
              id: e.target.value,
              name: e.target.selectedOptions[0].id
            })
            }
          >
            {
              customAttributes.length ? customAttributes.map(e => (
                <option id={e.internalLabel} key={e.attributeId} value={e.attributeId}>{e.internalLabel}</option>
              )) : null
            }
          </select>
          <button onClick={attributeOptions}>Add Attribute</button>
        </div>

      </div>
      {
        Object.keys(attributeOptionsInfo).length ? Object.entries(attributeOptionsInfo).map(([key, value]) => (
          <div key={key} className={classes.leftBlock_items_itemCustom}>
            <label>{key}</label>
            <select
              onChange={e => {
                handleChangeAddToCard(key,e.target.value.split(',')[2])
                handleChangeBackData('attributes,' + e.target.value.split(',')[0], e.target.value.split(',')[1])
              }}
            >
              {value.map((e, index) => (
                <option key={index} value={e.attributeId + ',' + e.attributeOptionId + ',' + e.internalLabel}>{e.internalLabel}</option>
              ))}
            </select>
          </div>
        )) : null
      }
      {
        !jobAtributes?.length && productAttributesInfo?.length ? productAttributesInfo.map((e, index) => (
          <div key={index} className={classes.leftBlock_items_itemCustom}>
            <label>{e.attribute.publicLabel}</label>
            <select
              onChange={event =>{
                handleChangeAddToCard(e.attribute.publicLabel, event.target.value.split(',')[2])
                handleChangeBackData('attributes,' + event.target.value.split(',')[0], event.target.value.split(',')[1])
              }}
            >
              {e.attribute?.attAttributeOptions?.length ? e.attribute?.attAttributeOptions.map((e, optionIndex) => (
                <option key={optionIndex} value={e.attributeId + ',' + e.attributeOptionId + ',' + e.internalLabel}>{e.internalLabel}</option>
              )) : null}
            </select>
          </div>
        )) : null
      }
      {
        jobAtributes?.length && !productAttributesInfo?.length ? jobAtributes.map((el,index) => (
          <div key={index} className={classes.leftBlock_items_itemCustom}>
            <label>{el.publicLabel}</label>
            <select
              onChange={e => {
                handleChangeAddToCard(el.publicLabel,e.target.value.split(',')[2])
                handleChangeBackData('attributes,' + e.target.value.split(',')[0], e.target.value.split(',')[1])
              }}
            >
              {el.attAttributeOptions.map((e, index) => (
                <option key={index} value={e.attributeId + ',' + e.attributeOptionId + ',' + e.internalLabel}>{e.internalLabel}</option>
              ))}
            </select>
          </div>
        )) : null
      }

    </>
  )
}

export default CustomAttributes