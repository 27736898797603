import { useEffect, useState } from 'react'
import { signIn } from '../../../api/auth/auth'
import { ISignInData } from '../../../api/auth/model'
import { errorMessage, toastErrorOrFieldError } from '../../../utils/errorMessage/errorMessage'
import { IErrorMessage, IResponseError } from '../../../utils/errorMessage/model'
import { getCookies, setCookies } from '../../../utils/storageFunction'
import classes from './SignIn.module.scss'
import { useNavigate } from 'react-router-dom'
const SignIn = (): JSX.Element => {
  const accessToken = getCookies('access-token')
  const navigate = useNavigate()
  const [userLoginData, setUserLoginData] = useState<ISignInData>({})
  const [errorRequest, setErrorRequest] = useState<IErrorMessage>({})

  const handleChange = (field: string, value: string): void => {
    if (!Object.values(errorRequest).every(error => error === '')) {
      setErrorRequest({ ...errorRequest, [field]: '' })
    }
    setUserLoginData({ ...userLoginData, [field]: value })
  }

  const handleClick = async (data: ISignInData): Promise<void> => {
    try {
      const res = await signIn(data)
      if (res.accessToken) {
        setCookies('access-token', res.accessToken)
        window.location.assign('/')
      }
    } catch (error) {
      const errorMessage = error as IResponseError
      toastErrorOrFieldError(errorMessage?.response?.data, setErrorRequest)
    }
  }

  useEffect(() => {
    if (accessToken) {
      navigate(-1)
    }
  },[accessToken])

  return (
    <div className={classes.signIn}>
      <div className={classes.signIn_container}>
        <header className={classes.signIn_container_header}>
          <h1>Workflow</h1>
        </header>
        <section className={classes.signIn_container_inputBlock}>
          <input
            id='forUserName'
            name='username'
            placeholder='User Name...'
            type='text'
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          {errorMessage('username', errorRequest)}
          <input
            id='forPassword'
            name='password'
            placeholder='Password...'
            type='password'
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          {errorMessage('password', errorRequest)}
          <button onClick={() => handleClick(userLoginData)}>Sign In</button>
        </section>
      </div>
    </div>
  )
}

export default SignIn