import { MetaData } from 'src/ui/MetaData'
import classes from './BinderyDetails.module.scss'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { useEffect, useState } from 'react'
import { IBinderyDetails } from 'src/api/postApproval/gangs/model'
import { getBinderyDetails } from 'src/api/postApproval/gangs/gangs'
import { useParams } from 'react-router-dom'
const BinderyDetails = (): JSX.Element => {
  const { id: jobId } = useParams()
  const [binderyDetailsInfo, setBinderyDetailsInfo] = useState<IBinderyDetails[]>([])

  const binderyDetails = async (jobId: string): Promise<void> => {
    try {
      setBinderyDetailsInfo(await getBinderyDetails(jobId))
    } catch (error) {
      console.log(error)
            
    }
  }

  useEffect(() => {
    if (jobId) {
      binderyDetails(jobId)
    }
  },[jobId])
  
  return (
    <div className={classes.binderyDetails}>
      <MetaData title='Bindery'/>
      <div className={classes.binderyDetails_container}>
        <PageHeader pageName='Bindery' pageWidth='Bindery'/>
        <div className={classes.binderyDetails_container_tableBlock}>
          <table>
            <thead>
              <tr>
                <th>Indicator</th>
                <th>Detail</th>
              </tr>
            </thead>
            <tbody>
              {
                binderyDetailsInfo.length ? binderyDetailsInfo.map((e, index) => (
                  <tr key={index}>
                    <td>{e.attribute_name}</td>
                    <td>{e.attribute_option_name}</td>
                  </tr>
                )) : <p>Not found data</p>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
export default BinderyDetails