import { FC, useState } from 'react'
import { IFinishOneBoxingSendData, IGetBoxing, IItem } from 'src/api/postApproval/boxing/model'
import { floatNumberCut } from 'src/utils/FloatNumberCut'
import classes from '../Boxing.module.scss'
import { IResponseError } from 'src/utils/errorMessage/model'
import { toast } from 'react-toastify'
import { postFinishOneBoxing } from 'src/api/postApproval/boxing/boxing'
const BodyBlock: FC<{item: IItem,
   shippmentId: number
   setBoxingInfo: React.Dispatch<React.SetStateAction<IGetBoxing>>
  }> = ({ item,shippmentId, setBoxingInfo }): JSX.Element => {
    const [itemStatus, setItemStatus] = useState<string>(item.status || '')
    const finishOneBoxing = async (data: IFinishOneBoxingSendData): Promise<void> => {
      try {
        await postFinishOneBoxing(data)
        toast.success('Boxing finished successfully')
        setItemStatus('boxed') 
        setBoxingInfo(prev => {
          const updatedPrev = Object.fromEntries(
            Object.entries(prev).map(([key, value]) => [
              key,
              value.map(element => {
                if (element.shippment_id === shippmentId) {
                  return { ...element, sku_label: 0 }
                }
                return element
              }),
            ])
          )
        
          return updatedPrev
        })
        
      } catch (error) {
        console.log(error)
        const errorMessage = error as IResponseError
        if ('detail' in errorMessage.response.data) {
          toast.error(errorMessage.response.data.detail)
        }      
      }
    }

    return (
      <tr key={item.job_id}>
        <td>{item.job_id}</td>
        <td>{floatNumberCut(item.width, 0) +
     'x' + 
     floatNumberCut(item.height, 0)}
     + {' '} + {item.product_group_name}
        </td>
        <td>{floatNumberCut(item.quantity, 0)}</td>
        <td>
          <div className={classes.boxing_container_tableBlock_notes}>
            {
              item.notes.length ? item.notes.map((note, index) => (
                <span key={index}>{note.note}</span>
              )) : null
            }
          </div>
        </td>
        <td>{item.due_date}</td>
        <td> 
          <button
            disabled={itemStatus === 'boxed'}
            className={itemStatus === 'boxed' ? classes.boxing_container_tableBlock_secondBlock_greyBackground
              : classes.boxing_container_tableBlock_secondBlock_redBackground }
            onClick={() => finishOneBoxing({ jobId:item.job_id, shippmentId })}
          >
        Finished
          </button>
        </td>
        <td>
          <div>
            {
              item.thumbnails.length ? item.thumbnails.map((thumbnail, index) => (
                <img key={index} src={thumbnail.path} alt={thumbnail.file_name}/>
              )) : null
            }
          </div>
        </td>
      </tr>
    )
  }
export default BodyBlock