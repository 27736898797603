import { FC } from 'react'
import classes from '../ShippmentPackages.module.scss'
import { IPackagesItemsProps } from 'src/Components/PostApproval/model'
import { errorMessage } from 'src/utils/errorMessage/errorMessage'
const PackagesItems: FC<IPackagesItemsProps> = ({ item,isRemove, index, removePackages, handleChangeData, errorRequest }): JSX.Element => {
  
  return (
    <div className={classes.shippmentPackages_body}>
      <div className={classes.shippmentPackages_body_items}>
        <div>
          <label>
            Type
          </label>
          <select name='Package_PackagingType_Code'
            onChange={e => handleChangeData(e.target.name, e.target.value, index)}>
            <option value='02'>Package</option>
            <option value='01'>UPS Letter</option>
            <option value='03'>UPS Tube</option>
            <option value='04'>UPS Express Pak</option>
            <option value='21'>UPS Express Box - Small</option>
            <option value='25'>UPS Express Box - Medium</option>
          </select>
        </div>
        <div>
          <label>
          Weight
          </label>
          <input
            name='Package_PackageWeight_Weight'
            defaultValue={item.box_weight_in_pounds}
            type='number'
            onChange={e => handleChangeData(e.target.name, Number(e.target.value), index)}
          />
          {errorRequest?.Package_PackageWeight_Weight?.includes(String(index)) && errorMessage('Package_PackageWeight_Weight', errorRequest)}
        </div>
        <div>
          <label>
          Insurence
          </label>
          <div className={classes.shippmentPackages_body_items_checkbox}>
            <input type='checkbox'/>
          </div>
        </div>
        <div>
          <label>
          Insurence value
          </label>
          <input
            defaultValue='100'
            name='InsuredValue_MonetaryValue'
            type='number'
            onChange={e => handleChangeData(e.target.name, Number(e.target.value), index)}
          />
        </div>
      </div>
      <div className={classes.shippmentPackages_body_items}>
        <div>
          <label>
          Dimensions
          </label>
          <div className={classes.shippmentPackages_body_items_checkbox}>
            <input type='checkbox'/>
          </div>
        </div>
        <div>
          <label>
          Length
          </label>
          <input
            name='Package_Dimensions_Length'
            type='number'
            onChange={e => handleChangeData(e.target.name, Number(e.target.value), index)}
          />
        </div>
        <div>
          <label>
          Width	
          </label>
          <input
            name='Package_Dimensions_Width'
            type='number'
            onChange={e => handleChangeData(e.target.name, Number(e.target.value), index)}
          />
        </div>
        <div>
          <label>
          Height	
          </label>
          <input
            name='Package_Dimensions_Height'
            type='number'
            onChange={e => handleChangeData(e.target.name, Number(e.target.value), index)}
          />
        </div>
      </div>
      <div className={classes.shippmentPackages_body_items}>
        <div>
          <label>
          Large Package
          </label>
          <div className={classes.shippmentPackages_body_items_checkbox}>
            <input type='checkbox'/>
          </div>
        </div>
        <div>
          <label>
          Additional Handling
          </label>
          <div className={classes.shippmentPackages_body_items_checkbox}>
            <input type='checkbox'/>
          </div>
        </div>
        {
          isRemove &&
          (<div>
            <button onClick={() => removePackages(index)}>Remove Package</button>
          </div>)
        }
      </div>
    </div>
  )
}

export default PackagesItems