import { FC } from 'react'
import classes from '../CutterSinglePage.module.scss'
import { dateConvertToStringToDate } from 'src/utils/dateConvertToString'
import { ICutterSingleHeaderProps } from 'src/Components/PostApproval/model'
import { IResponseError } from 'src/utils/errorMessage/model'
import { toast } from 'react-toastify'
import { postCutterAction, postFinishCutting, postStartCutting, postValidateGangJobStatus } from 'src/api/postApproval/cutter/cutter'

const CutterSinglePageHeader: FC<ICutterSingleHeaderProps> = ({ pageName, gangId, internalName, userName, timestamp, jobs,bindery }): JSX.Element => {

  const cutterAction = async (status: string): Promise<void> => {
    try {
      if (pageName === 'Die Cutting') {
        await postCutterAction({ gangId, status })
      }
    } catch (error) {
      console.log(error)
            
    }
  }

  const startCutting = async (): Promise<void> => {
    try {
      await postStartCutting({ gangId, jobs })
      await cutterAction('Cutting Partially')
      toast.success('Start Cutting successfully')
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }

  const validateGangJobStatus = async (): Promise<void> => {
    try {
      const res = await postValidateGangJobStatus(gangId)
      if (res === 'success') {
        await postFinishCutting({ jobs, bindery })
        await cutterAction('Ready For Bindery')
        toast.success('Finish Cutting Gang successfully')
      }
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }


  return (
    <div className={classes.cutterSinglePage_container_tableBlock_header}>
      <div className={classes.cutterSinglePage_container_tableBlock_header_leftBlock}>
        <span>{gangId}</span>
        <span>{internalName}</span>
        <span>{userName}</span>
        <span>{dateConvertToStringToDate(timestamp)}</span>
      </div>
      <div className={classes.cutterSinglePage_container_tableBlock_header_rightBlock}>
        <button onClick={startCutting}>Start Cutting</button>
        <button onClick={validateGangJobStatus}>Finish Cutting</button>
      </div>
    </div>
  )
}

export default CutterSinglePageHeader