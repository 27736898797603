import { FC } from 'react'
import classes from './OrdersReportsTable.module.scss'
import { IGetOrderReports } from 'src/api/report/model'
import { dateConvertToStringToDate } from 'src/utils/dateConvertToString'
import OrderReportsTotal from './OrderReportsTotal/OrderReportsTotal'
const OrdersReportsTable: FC<{ordereReportsInfo: IGetOrderReports}> = ({ ordereReportsInfo }): JSX.Element => {
  return (
    <div className={classes.ordersReportsTable}>
      <OrderReportsTotal totalData={ordereReportsInfo.totals}/>
      <table className={classes.ordersReportsTable_table}>
        <thead>
          <tr>
            <th>Order</th>
            <th>Date</th>
            <th>Customer</th>
            <th>Customer Name</th>
            <th>Customer Id</th>
            <th>Customer Phone</th>
            <th>Job</th>
            <th>Product</th>
            <th>Quantity</th>
            <th>Coupon</th>
            <th>Product Price</th>
            <th>Coupon Discount</th>
            <th>Discount</th>
            <th>Extras</th>
            <th>Job Price</th>
            <th>Shipping</th>
            <th>Tax</th>
            <th>Approved</th>
            <th>Recepient Name</th>
            <th>Address1</th>
            <th>Address2</th>
            <th>State</th>
            <th>City</th>
            <th>Zip</th>
            <th>Phone</th>
            <th>Category</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>   
          {
            ordereReportsInfo.results?.length ? ordereReportsInfo.results.map((report, index) => (
              <tr key={index} className={index % 2 === 0 ? classes.ordersReportsTable_table_couple : classes.ordersReportsTable_table_odd }>
                <td>{report.orderx}</td>
                <td>{dateConvertToStringToDate(report.created)}</td>
                <td>{report.customer}</td>
                <td>{report.customer_first_name + ' ' + report.customer_last_name}</td>
                <td>{report.customer_id}</td>
                <td>{report.customer_phone}</td>
                <td>{report.job}</td>
                <td>{report.product}</td>
                <td>{report.quantity}</td>
                <td>{report.quantity}</td>
                <td>{report.printtotal}</td>
                <td>{report.coupontotal}</td>
                <td>{report.discounttotal}</td>
                <td>{report.extratotal}</td>
                <td>{report.jobtotal}</td>
                <td>{report.shippingtotal}</td>
                <td>{report.taxtotal}</td>
                <td>{report.approved}</td>
                <td>{report.first_name + ' ' + report.last_name}</td>
                <td>{report.address1}</td>
                <td>{report.address1}</td>
                <td>{report.label_state}</td>
                <td>{report.city}</td>
                <td>{report.zip}</td>
                <td>{report.phone}</td>
                <td>{report.customer_category}</td>
                <td>{report.status}</td>
              </tr>
            )) : null
          }  
               
        </tbody>
      </table>
    </div>
  )
}
export default OrdersReportsTable