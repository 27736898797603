import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { IOptionViewTypeProps } from 'src/Components/IPC/IPC.model'
import { ipcStore } from 'src/stores/IPC/ipcStore'
import AttributeGrouping from './AttributeGrouping'


const OptionViewType: FC<IOptionViewTypeProps> = ({
  attributeLabel,
  attributeOption,
  changeIPCPayloadUpdate,
  payloadUpdates,
  isMajesticPostcards,
  attributeId
}): JSX.Element => {
  const { data:  { selectedTrim, selectedTrimSizes }, loadingStatus: isLoading } = ipcStore
  useEffect(() => {
    if (selectedTrim?.attributeOptionId && payloadUpdates?.attributes?.['Diecut Trim']) {
      payloadUpdates.attributes['Diecut Trim'] = selectedTrim.attributeOptionId
    }
  }, [selectedTrim])

  useEffect(() => {
    if (attributeLabel === 'Diecut Trim') {
      ipcStore.setDicuteTrimData(
			    attributeOption.map((el) => ({
          optionLabel: el.option_label.split('-')[0],
          optionOrder: el.option_order,
          attributeOptionId: el.attribute_option_id,
        })
        )
      )
    }
  }, [payloadUpdates, selectedTrimSizes])

  if ( attributeId === 285 && isMajesticPostcards.isMajestic && !isMajesticPostcards.lamination ) {
    return <p>{attributeOption[0].option_label}</p>
  } else if (attributeOption?.length > 1) {
    const handleChangeAttributes = (e: React.ChangeEvent<HTMLSelectElement>): void => {
      const attributeLabel =
        e.target.options[e.target.options.selectedIndex].getAttribute('data-attributelabel')

      const attributeOptionId = Number(
        e.target.options[e.target.options.selectedIndex].getAttribute('data-attributeoptionid'),
      )
      changeIPCPayloadUpdate('attributes', Number(attributeOptionId), String(attributeLabel))
      if (attributeLabel === 'Diecut Trim') {
        const optionOrder = Number(
          e.target.options[e.target.options.selectedIndex].getAttribute('data-optionorder'),
        )
        const optionLabel =
          e.target.options[e.target.options.selectedIndex].getAttribute('data-optionlabel')
			 ipcStore.setSelectedTrim({
          attributeOptionId,
          comingLocation: 'IPC',
          optionLabel: optionLabel?.split('-')[0] || '',
          optionOrder,
			 })
      }
    }

    return (
      <select
        name={attributeLabel}
        id={attributeLabel}
        onChange={handleChangeAttributes}
        disabled={isLoading}
        value={payloadUpdates?.attributes && payloadUpdates.attributes[attributeLabel]}
      >
        <AttributeGrouping
          attributeLabel={attributeLabel}
          attributeOption={attributeOption}
        />
      </select>
    )
  } else if (attributeOption?.length === 1) {
    return <select disabled>
      <option>{attributeOption[0].option_label}</option>
    </select>
  } else {
    return <select disabled>
      <option>N/A</option>
    </select>
  }
}

export default observer(OptionViewType)
