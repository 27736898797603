import { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { IGetCardInfoExpirationDate, ISavedBillingInfo } from 'src/api/Preflight/orderList/orderListBilling/model'
import { getCardInfoExpirationDate, postManagePayment, putBillingSettings } from 'src/api/Preflight/orderList/orderListBilling/orderListBilling'
import { errorMessage, toastErrorOrFieldError } from 'src/utils/errorMessage/errorMessage'
import { IErrorMessage, IResponseError } from 'src/utils/errorMessage/model'
import { floatNumberCut } from 'src/utils/FloatNumberCut'
import { IBillingInformationProps } from '../model'
import classes from '../OrderBilling.module.scss'

const BillingInformation: FC<IBillingInformationProps> = ({
  setBillingInfo,
  billingInfo,
  forAllTabs,
  isSave = true,
  isCharge = false,
  jobId,
  closeModal }): JSX.Element => {
  const [expirationData, setExpirationDate] = useState<IGetCardInfoExpirationDate>({} as IGetCardInfoExpirationDate)
  const [errorRequest, setErrorRequest] = useState<IErrorMessage>({})
  const settingsUpdate = async (data: ISavedBillingInfo): Promise<void> => {
    if (typeof data.reference !== 'string') {
      delete data.reference
    }
    try {
      const res = await putBillingSettings(data)
      setBillingInfo({ ...billingInfo, savedBillingInfo: res })
      toast.success('Settings updated successfully')
      if (jobId) {
        forAllTabs?.(jobId)
      }
      closeModal?.()
    } catch (error) {
      const errorMessage = error as IResponseError
      toastErrorOrFieldError(errorMessage?.response?.data, setErrorRequest)
    }
  }

  const handleChangeSettings = (field: string, value: string | number): void => {
    if (!Object.values(errorRequest).every(error => error === '')) {
      setErrorRequest({ ...errorRequest, [field]: '' })
    }
    setBillingInfo(prev => {
      return {
        ...prev,
        savedBillingInfo: {
          ...prev.savedBillingInfo,
          [field]: value
        }
      }
    })
  }

  const showExpirationDate = (): JSX.Element => {
    if (typeof expirationData !== 'undefined' && expirationData.cardNumber && billingInfo.savedBillingInfo.paymentMethodId === 7) {
      return <p
        className={classes.billing_information_body_item_expirationDate}>
        {'Expiration Date :' + ' ' + expirationData.expirationMonth + '/' + expirationData.expirationYear}
      </p>
    }
    return <></>
  }

  const cardInfoExpirationDate = async (cardId: number, orderId: number): Promise<void> => {
    try {
      setExpirationDate(await getCardInfoExpirationDate(cardId, orderId))
    } catch (error) {
      console.log(error)

    }
  }
  useEffect(() => {
    if (billingInfo.savedBillingInfo?.paymentMethodId === 1 || billingInfo.savedBillingInfo?.paymentMethodId === 7 && billingInfo.savedBillingInfo.creditCardId) {
      cardInfoExpirationDate(billingInfo.savedBillingInfo.creditCardId, billingInfo.savedBillingInfo.orderId)
    }
  },[billingInfo.savedBillingInfo?.paymentMethodId])


  const managePayment = async (orderId: number): Promise<void> => {
    try {
      const res = await postManagePayment(orderId,{
        cs: String(billingInfo.savedBillingInfo.creditCardId),
        paymentMethod: String(billingInfo.savedBillingInfo.paymentMethodId),
        ref: String(billingInfo.savedBillingInfo.reference),
        job: String(jobId),
        billingId:String(billingInfo.savedBillingInfo.billingAddressId),
      })
      toast.success(res.Message)
      closeModal?.()
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className={classes.billing_information}>
      <div className={classes.billing_information_title}>
        <p>Charging Information Order # {billingInfo?.savedBillingInfo?.orderId}</p>
      </div>
      <div className={classes.billing_information_body}>
        <div className={classes.billing_information_body_item}>
          <label>Payment Methods</label>
          <select
            name='paymentMethodId'
            value={billingInfo.savedBillingInfo?.paymentMethodId}
            onChange={e => handleChangeSettings(e.target.name,Number(e.target.value))}
          >
            {
              billingInfo?.paymentMethod?.length ? billingInfo?.paymentMethod.map(payment => (
                <option key={payment.payment_method_id} value={payment.payment_method_id}>{payment.payment_method_description}</option>
              )) : null
            }
          </select>
          {errorMessage('paymentMethodId', errorRequest)}
          {showExpirationDate()}
        </div>
        <div className={classes.billing_information_body_item}>
          <label>Reference</label>
          {
            Number(billingInfo.savedBillingInfo?.paymentMethodId) === 7 ?
              <select
                name='reference'
                value={billingInfo.savedBillingInfo?.creditCardId}
                onChange={e => handleChangeSettings(e.target.name,e.target.value)}
              >
                <option disabled selected value=''>Select...</option>
                {
                  billingInfo.creditCards.length ? billingInfo.creditCards.map(card => (
                    <option key={card.id} value={card.cardNumber}>{card.cardNumber}</option>
                  )) : null
                }
              </select> :
              <input
                type='string'
                name='reference'
                defaultValue={billingInfo.savedBillingInfo?.reference}
                onChange={e => handleChangeSettings(e.target.name,e.target.value)}
              />
          }
          {errorMessage('reference', errorRequest)}
        </div>
        <div className={classes.billing_information_body_item}>
          <label>Billing Address {billingInfo?.savedBillingInfo?.billingAddressId}</label>
          <select
            name='billingAddressId'
            value={billingInfo.savedBillingInfo?.billingAddressId}
            onChange={e => handleChangeSettings(e.target.name,e.target.value)}
          >
            <option disabled selected>Select...</option>
            {
              billingInfo?.addresses?.length ? billingInfo.addresses.map((billing, index) => (
                <option key={index} value={billing.customer_address_id}>{billing.address1}</option>
              )) : null
            }
          </select>
          {errorMessage('billingAddressId', errorRequest)}
        </div>
        <div className={classes.billing_information_body_total}>
          <span>Total Order :</span>
          <span>${floatNumberCut( String(billingInfo.totalCost), 2)}</span>
        </div>
        <div className={classes.billing_information_body_total}>
          {
            isSave ?
              <button onClick={() => settingsUpdate(billingInfo.savedBillingInfo)}>Save Settings</button> : null
          }
          {
            isCharge ?
              <button onClick={() => managePayment(billingInfo.savedBillingInfo.orderId)}>Charge Now</button>
              : null
          }
        </div>
      </div>
    </div>
  )
}
export default BillingInformation