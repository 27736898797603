import { MetaData } from 'src/ui/MetaData'
import classes from './CardBuilder.module.scss'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { ICardBuilderButton } from '../model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CreateCategory from './CreateCategory/CreateCategory'
import useModal from 'src/ui/modal/toggleModal'
import { useNavigate } from 'react-router-dom'
const buttonData: ICardBuilderButton[] = [
  {
    id:1,
    title:'Edit a Category',
    icon: faPen
  },
  {
    id:2,
    title:'Add new Category',
    icon: faPlus
  },
  {
    id:3,
    title:'Delete Category',
    icon: faTrash
  },
  {
    id:4,
    title:'Edit This Template',
    icon: faPen
  },
  {
    id:5,
    title:'Add new Template',
    icon: faPlus
  },
  {
    id:6,
    title:'Delete This Template',
    icon: faTrash
  },
  {
    id:7,
    title:'Add Template Batch',
    icon: faPlus
  },
  {
    id:8,
    title:'Configure Category´s Background Set',
    icon: faPen
  },
]
const CardBuilder = (): JSX.Element => {
  const { isShowing, toggle } = useModal()
  const navigate = useNavigate()
  const hangleClickButton = (name: string): void => {
    console.log(name)
    
    if (name.includes('Category')) {
      toggle()
    }
    if (name.includes('Template') && !name.includes('Delete')) {
      navigate('create-template')
    }
  }
  return (
    <div className={classes.cardBuilder}>
      <MetaData title='Card Builder'/>
      <div className={classes.cardBuilder_container}>
        <PageHeader pageName='Card Builder' pageWidth='90'/>
        <div className={classes.cardBuilder_container_firstBlock}>
          <div className={classes.cardBuilder_container_firstBlock_btnBlock}>
            {
              buttonData.map(button => (
                <button key={button.id} onClick={() => hangleClickButton(button.title)}>
                  <FontAwesomeIcon icon={button.icon} /> 
                  { button.title}
                </button>
              ))
            }
          </div>
          <div className={classes.cardBuilder_container_firstBlock_inputBlock}>
            <table>
              <tbody>
                <tr>
                  <td>Product Name</td>
                  <td><select></select></td>
                </tr>
                <tr>
                  <td>Size</td>
                  <td><select></select></td>
                </tr>
                <tr>
                  <td>Category</td>
                  <td><select></select></td>
                </tr>
                <tr>
                  <td>Template Name</td>
                  <td><select></select></td>
                </tr>
                <tr>
                  <td>Keywords</td>
                  <td><input/></td>
                </tr>
                <tr>
                  <td>Side A Preview</td>
                  <td><img src='' alt=''/></td>
                </tr>
                <tr>
                  <td>Side B Preview</td>
                  <td><img src='' alt=''/></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isShowing && <CreateCategory close={toggle}/>}
    </div>
  )
}
export default CardBuilder