import { FC } from 'react'
import { toast } from 'react-toastify'
import { IStripperJob } from 'src/api/postApproval/stripper/model'
import { deleteJob, postJobAlreadyInGang } from 'src/api/postApproval/stripper/stripper'
import { IStripperOrderItemProps } from 'src/Components/PostApproval/model'
import { convertDateTime } from 'src/utils/dateConvertToString'
import { IResponseError } from 'src/utils/errorMessage/model'
import classes from '../../Stripper.module.scss'
import { decodeHtmlEntities } from 'src/utils/decodeHtmlEntities'
const StripperOrderItem: FC<IStripperOrderItemProps> = ({ job, addSingleStriperJob,removeJobItem, singleItem }): JSX.Element => {

  const removeJob = async (jobId: number): Promise<void> => {
    let res: IStripperJob[] = []
    try {
      const { isJobAlreadyInGang } = await postJobAlreadyInGang(jobId)
      if (isJobAlreadyInGang) {
        res = await deleteJob(jobId)
        toast.success('Job deleted successfully')
      }
      removeJobItem?.(job.Job, res)
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }

  return (
    <tr>
      <td>{job.Job}</td>
      <td>{decodeHtmlEntities(job.Name)}</td>
      <td>{job.paper_type}</td>
      <td>{('due_date' in job) && job?.due_date ? convertDateTime(job.due_date) : ''}</td>
      <td>{job.size}</td>
      <td>{job.product_label}</td>
      <td>{job.ink}</td>
      <td>{job.quantity}</td>
      <td>{('code' in job) ? job?.code : ''}</td>
      <td>{job.username}</td>
      <td>{decodeHtmlEntities(job.shipping_state)}</td>
      <td><span className={classes.stripper_container_redColor}>{job.bindery}</span></td>
      <td>{job.lots}</td>
      <td>{job.bookletCover}</td>
      <td> <span className={classes.stripper_container_redColor}>{job.proof}</span></td>
      <td>{job.finish}</td>
      <td>{job.finish}</td>
      <td>{
        job.notes.length ? job.notes.map((e, i) => (
          <p key={i}>{e.note}</p>
        )) : null
      }</td>
      <td>
        {
          singleItem ?
            <button className={classes.stripper_container_redBackground}
              onClick={() =>removeJob(job.Job)}
            >Remove</button>
            :
            <button onClick={() => addSingleStriperJob(job.Job, job.paper_type)}>Add</button>
        }
      </td>
    </tr>
  )
}

export default StripperOrderItem