import { Dispatch, MutableRefObject, SetStateAction, useState } from 'react'
import { useClickedOnOutside } from './useClickedOnOutside'

type TOut<T> = {
	ref: MutableRefObject<HTMLDivElement | undefined>
	isShow: T
	setIsShow: Dispatch<SetStateAction<T>>
}

export const useOutside = <T >(initialVisible: T): TOut<T | undefined> => {
  const [isShow, setIsShow] = useState<T | undefined>(initialVisible)
  const { ref } = useClickedOnOutside(() => setIsShow(undefined))
  return { ref, isShow, setIsShow }
}
