import { FC, memo, useState } from 'react'

import { IIPCAttributesPopupData, IIPCSelectAttributesProps } from '../../IPC.model'
import IPCAttributesPopup from './AttributesPopup/AttributesPopup'
import IPCMailingOptions from './MailingOptions/MailingOptions'
import OptionViewType from './MailingOptions/OptionViewType'

const IPCSelectAttributes: FC<IIPCSelectAttributesProps> = memo(
  ({ IPCRes, changeIPCPayloadUpdate, payloadUpdates, isMajesticPostcards }): JSX.Element => {
    const [popupData, setPopupData] = useState<IIPCAttributesPopupData>({ title: '' })
    return (
      <>
        {IPCRes?.options?.map(
          ({
            // tooltip,
            attribute_id: attributeId,
            attribute_label: attributeLabel,
            attribute_option: attributeOption,
          }) => (
            <div key={attributeId}>
              {attributeLabel === 'Proof Options' && Boolean(IPCRes.mailingOptions) && (
                <IPCMailingOptions
                  mailingOptions={IPCRes.mailingOptions}
                  changeIPCPayloadUpdate={changeIPCPayloadUpdate}
                  defaultMailingOptions={payloadUpdates?.defaultMailingOptions}
                  payloadUpdatesMailingQuantity={
                    payloadUpdates?.defaultMailingOptions?.mailingQuantity ?? 0
                  }
                  payloadUpdatesQuantity={payloadUpdates?.quantity}
                />
              )}
              <label htmlFor={attributeLabel} key={attributeId}>
                <span>{attributeLabel}</span>
                <OptionViewType
                  attributeOption={attributeOption}
                  attributeLabel={attributeLabel}
                  changeIPCPayloadUpdate={changeIPCPayloadUpdate}
                  payloadUpdates={payloadUpdates}
                  isMajesticPostcards={isMajesticPostcards}
                  attributeId={attributeId}
                />
              </label>
            </div>
          ),
        )}
        {popupData.title && <IPCAttributesPopup popupData={popupData} onClose={setPopupData}/>}
      </>
    )
  },
)

export default IPCSelectAttributes
