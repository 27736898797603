import { api, apiWithoutToken } from 'src/api/api'
import { IFinishOneBoxingSendData, IGetBoxing, IGetPrintShippmentLabelInfo, IMessageSuccess, IPrintShippmentLabelPostData, IVoidShippmentLabels, } from './model'
import { IParams } from 'src/api/Preflight/orderList/OrderListNote/model'

export const getBoxing = (type: number): Promise<IGetBoxing> => 
  api.get(`/postapproval/boxing/index/${type}`).then(res => res.data)

export const postFinishOneBoxing = (data: IFinishOneBoxingSendData): Promise<void> => 
  api.post('/postapproval/boxing/finishOne', data).then(res => res.data)

export const postBoxingSkip = (shippmentId: number): Promise<void> => 
  api.post('/postapproval/boxing/skip', { shippmentId }).then(res => res.data)

export const getPrintShippmentLabel = (shippmentId: string, upsLabel: string): Promise<IGetPrintShippmentLabelInfo> =>
  api.get(`/dashboard/detail/printShippmentLabel/${shippmentId}/${upsLabel}`).then(res => res.data)

export const postPrintShippmentLabel = (shippmentId: number, upsLabel: string, data: IPrintShippmentLabelPostData): Promise<void> =>
  api.post(`/dashboard/detail/printShippmentLabel/${shippmentId}/${upsLabel}`,data).then(res => res.data)

export const getCreatePdfLabels = (shippmentId: string, params: IParams): Promise<void> => 
  apiWithoutToken.get(`/publicApi/createPdfLabels/${shippmentId}`, { params }).then(res => res.data)

export const deleteVoidShippmentLabels = (data: IVoidShippmentLabels): Promise<IMessageSuccess> => 
  api.delete('/dashboard/detail/voidShippmentLabels', { data }).then(res => res.data)