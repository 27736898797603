import { MutableRefObject, useEffect, useRef } from 'react'

export const useClickedOnOutside = (onClickOutside: () => void): {ref: MutableRefObject<HTMLDivElement | undefined>} => {
  const ref = useRef<HTMLDivElement>()
  const handleClickOutside = (evt: MouseEvent): void => {
    if (ref.current && !ref.current.contains(evt.target as Node)) {
      onClickOutside()
    }

  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])
  return { ref }
}