import { IProjectFile } from 'src/Components/Preflight/OrderList/CreateOrder/ProjectInfoBlock/model'
import { ICustomOrder, IShippingInfo } from 'src/Components/Preflight/OrderList/CreateOrder/model'
import { IParams } from '../Preflight/orderList/OrderListNote/model'
import { ICreditCards } from '../Preflight/orderList/orderListBilling/model'
import { api, formDataApi } from '../api'
import { ILikelyProducts } from './model'
import { ICustomer } from '../createCustomOrder/model'

export const getCustomOrders = (params: IParams): Promise<ICustomer[]> =>
  api.get('/dashboard/customOrders/getCustomers', { params }).then(res => res.data)

export const getLikelyProducts = (params: IParams): Promise<ILikelyProducts[]> =>
  api.get('/dashboard/customOrders/getLikelyProducts', { params }).then(res => res.data)

export const uploadProjectFile = (data: FormData): Promise<IProjectFile> =>
  formDataApi.post('/dashboard/customOrders/uploadFile', data).then(res => res.data)

export const getPaymentMethodsByCustomer = (customerId: number): Promise<ICreditCards[]> => api.get(`/dashboard/customOrders/getCustomerPaymentMethods/${customerId}`).then(res => res.data.data)

export const calculateCustomOrderShipping = (data: ICustomOrder): Promise<IShippingInfo> => api.post('/dashboard/customOrders/addCustomOrder/calculateOrderShipping', data).then(res => res.data.respData[0])

export const saveCustomOrder = (data: ICustomOrder): Promise<{orderId: number, jobs: number[]}> =>
  api.post('/dashboard/customOrders/addCustomOrder/saveOrder', data).then(res => res.data)