import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import classes from './PageHeader.module.scss'
const PageHeader: FC<{
  pageName: string
  pageWidth?: string
  buttonName?: string
  handleAddClick?: (field: string) => void
}> = ({ pageName, pageWidth, buttonName, handleAddClick }): JSX.Element => {
  return (
    <div style={{ width: pageWidth || '95%' , margin: pageWidth ? '0' : '10px auto' }} className={classes.pageHeader}>
      <span>{pageName}</span>
      {
        buttonName ?
          <button onClick={() => handleAddClick?.('add')}><FontAwesomeIcon icon={faPlus}/>{buttonName}</button> : null
      }
    </div>
  )
}

export default PageHeader