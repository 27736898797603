import { dateData } from './selectDateData'
import classes from './selectDate.module.scss'
import { FC, useEffect, useState } from 'react'
const SelectDate: FC<{
  fromOrTo?: string,
  field: string,
  handleChange: (field: string, value: string | number) => void
}> = ({ fromOrTo, field, handleChange }): JSX.Element => {
  const [changeableDate, setChangeableDate] = useState<{day: string,month: string, year: string}>({
    day: '01',month: '01', year: '2010'
  })

  const changeData = (field: string,value: string): void => {
   
    setChangeableDate({ ...changeableDate, [field]: value })
  }
  useEffect(() => {
    handleChange(field, `${changeableDate.year}-${changeableDate.month}-${changeableDate.day}`)
  },[changeableDate])
  return (
    <div className={classes.selectDate}>
      {fromOrTo ? <span>{fromOrTo}</span> : ''}
      <select
        onChange={e => changeData('month',e.target.value)}
      >
        {
          dateData.months.map(e => (
            <option key={e} value={e}>{e}</option>
          ))
        }
      </select>
      <span>/</span>
      <select
        onChange={e => changeData('day', e.target.value)}
      >
        {
          dateData.days.map(e => (
            <option key={e} value={e}>{e}</option>
          ))
        }
      </select>
      <span>/</span>
      <select
        onChange={e => changeData('year', e.target.value)}>
        {
          dateData.years.map(e => (
            <option key={e} value={e}>{e}</option>
          ))
        }
      </select>    
    </div>
  )
} 

export default SelectDate 