import Papa from 'papaparse'
export const downloadCSV = <T,>(data: T, downloadName: string): void => {
  const csvData = Papa.unparse(data) 
  const blob = new Blob([csvData], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = downloadName 
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  window.URL.revokeObjectURL(url)
}