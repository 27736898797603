import { IParams } from '../Preflight/orderList/OrderListNote/model'
import { api } from '../api'
import { IMessageSuccess } from '../postApproval/boxing/model'
import { ICustomerAddress, IEditContact, IGetAllCustomerAddressState, IGetAllCustomerHistory, IGetContact, IGetCustomerAddressCountry, IGetCustomerById, IGetCustomerCoupon, IGetCustomerDesign, IGetCustomerNote, IGetCustomers, IGetInvoiceMethods, IOpenCustomerOrder, IPaymentMethods, IPostAddressBook, IPostCustomerNote, IWfHistory } from './model'

export const getAllCustomers = (params: IParams): Promise<IGetCustomers> =>
  api.get('/customer', { params }).then(res => res.data)

export const getOneCustomers = (id: string): Promise<IGetCustomerById> =>
  api.get(`/customer/${id}`).then(res => res.data)

export const patchCustomer = (id: string,data: IGetCustomerById): Promise<IGetCustomerById> => 
  api.patch(`/customer/${id}`, data).then(res => res.data.getOne)

export const postCustomer = (data: IGetCustomerById): Promise<IGetCustomerById> => 
  api.post('/customer', data).then(res => res.data.getOne)

export const getPaymentMethods = (): Promise<IPaymentMethods[]> => 
  api.get('/payment_methods').then(res => res.data)
  
export const getInvoiceMethods = (): Promise<IGetInvoiceMethods> =>
  api.get('/invoice_methods').then(res => res.data)

// Address 

export const getCustomerAddress = (customerId: number): Promise<ICustomerAddress[]> => 
  api.get(`/customer_address/${customerId}`).then(res => res.data)

export const getCustomerAddressById = (id: number): Promise<ICustomerAddress> => 
  api.get(`/customer_address/one/${id}`).then(res => res.data)
  
export const postCustomerAddress = (data: IPostAddressBook): Promise<ICustomerAddress> => 
  api.post('/customer_address', data).then(res => res.data.data)

export const patchCustomerAddress = (id: number, data: IPostAddressBook): Promise<ICustomerAddress> => 
  api.patch(`/customer_address/${id}`, data).then(res => res.data.data)

export const deleteCustomerAddress = (id: number): Promise<void> => 
  api.delete(`/customer_address/${id}`).then(res => res.data)

export const getCustomerAddressCountry = (): Promise<IGetCustomerAddressCountry[]> => 
  api.get('/customer_address/country').then(res => res.data)

export const getCustomerAddressState = (countryId: number): Promise<IGetAllCustomerAddressState[]> => 
  api.get(`/customer_address/state/${countryId}`).then(res => res.data)


// history

export const getCustomerHistory = (customerId: number): Promise<IGetAllCustomerHistory[]> =>
  api.get(`/customer_history/${customerId}`).then(res => res.data)

export const getCustomerHistoryByOrderId = (orderId: number): Promise<IWfHistory[]> => 
  api.get(`/customer/order/${orderId}`).then(res => res.data)

export const putOpenCustomerOrder = (data: IOpenCustomerOrder): Promise<IMessageSuccess> =>
  api.put('/customer/openOrder', data).then(res => res.data)

// coupons

export const getAllCustomerCoupons = (customerId: number): Promise<IGetCustomerCoupon[]> =>
  api.get(`/customer_coupon/${customerId}`).then(res => res.data)

// Contacts

export const getAllContacts = (customerId: number): Promise<IGetContact[]> =>
  api.get(`/customer_contact/${customerId}`).then(res => res.data)

export const postContact = (data: IEditContact): Promise<IGetContact> => 
  api.post('/customer_contact', data).then(res => res.data.data)

export const patchContact = (customerContactId: number, data: IEditContact): Promise<IGetContact> =>
  api.patch(`/customer_contact/${customerContactId}`, data).then(res => res.data.data)

export const deleteContact = (customerContactId: number): Promise<void> =>
  api.delete(`/customer_contact/${customerContactId}`).then(res => res.data)


// design
export const getAllCustomerDesigns = (customerId: number): Promise<IGetCustomerDesign[]> =>
  api.get(`/customer_design/${customerId}`).then(res => res.data)

// notes

export const getAllCustomerNotes = (customerId: string): Promise<IGetCustomerNote[]> => 
  api.get(`/customer_notes/${customerId}`).then(res => res.data)

export const postCustomerNote = (data: IPostCustomerNote): Promise<IGetCustomerNote> => 
  api.post('/customer_notes', data).then(res => res.data.data)

export const patchCustomerNote = (jobNoteId: number, data: IPostCustomerNote): Promise<IGetCustomerNote> =>
  api.patch(`/customer_notes/${jobNoteId}`, data).then(res => res.data.data)

export const removeCustomerNotes = (jobNoteId: number): Promise<void> => 
  api.delete(`/customer_notes/${jobNoteId}`).then(res => res.data)

// Actions

export const putResetCustomer = (customerId: number): Promise<IMessageSuccess> =>
  api.put('/customer/resetCustomer', { customerId }).then(res => res.data)

export const putVerifyCustomer = (customerId: number): Promise<IMessageSuccess> =>
  api.put('/customer/verifyCustomer', { customerId }).then(res => res.data)
