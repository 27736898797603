import { FC, useState } from 'react'
import classes from './EditCoatingDueDate.module.scss'
import { IGetCoating } from 'src/api/postApproval/coating/model'
import { putCoatingDueDate } from 'src/api/postApproval/coating/coating'
import { IErrorMessage, IResponseError } from 'src/utils/errorMessage/model'
import { errorMessage, toastErrorOrFieldError } from 'src/utils/errorMessage/errorMessage'
import { toast } from 'react-toastify'
const EditCoatingDueDate: FC<{
    value: string, 
    setCoatingInfo: React.Dispatch<React.SetStateAction<IGetCoating[]>>
    gangId: number
    closeDueEditBlock: () => void
}> = ({ value, setCoatingInfo, gangId, closeDueEditBlock }): JSX.Element => {
  const [errorRequest, setErrorRequest] = useState<IErrorMessage>({})
  const handleChangeDueDate = (newValue: string): void => {
    setCoatingInfo(prev => {
      const updatedDueDate = prev.map(e => {
        if (e.gang_id === gangId) {
          e.due_on = newValue
        }
        return e
      })
      return updatedDueDate
    })
  }


  const editDueDate = async (newDate: string): Promise<void> => {
    try {
      await putCoatingDueDate({ gangId, newDate })
      toast.success('Due date updated successfully')
      closeDueEditBlock()
    } catch (error) {
      const errorMessage = error as IResponseError
      toastErrorOrFieldError(errorMessage?.response?.data, setErrorRequest)
        
    }
  }

  return (
    <div className={classes.editCoatingDueDate}>
      <input 
        value={value} 
        onChange={(e) => handleChangeDueDate(e.target.value)}
        onClick={(e) => {
          e.stopPropagation()
          setErrorRequest({})
        }}
      />
      {errorMessage('newDate', errorRequest)}
      <div>
        <button onClick={e =>{
          e.stopPropagation()
          closeDueEditBlock()
        }} 
        className={classes.editCoatingDueDate_cancel}>Cancel</button>
        <button onClick={(e) => {
          e.stopPropagation()
          editDueDate(value)
        }}
        className={classes.editCoatingDueDate_save}>Save</button>
      </div>
    </div>
  )
}
export default EditCoatingDueDate