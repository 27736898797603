import Cookies from 'js-cookie'
import { FC } from 'react'

const ProtectedRoute: FC<{children: JSX.Element}> = ({ children }) => {
  const accessToken = Cookies.get('access-token')
  if (accessToken) {
    return children
  }
  window.location.assign('/signIn')
  return <></>
  
}

export default ProtectedRoute
