import { FC, Suspense } from 'react'
import { IGangJobsDetail } from 'src/api/postApproval/gangs/model'
import classes from '../GangsSingle.module.scss'
import { IResponseError } from 'src/utils/errorMessage/model'
import { toast } from 'react-toastify'
import { removeJobFromGang } from 'src/api/postApproval/gangs/gangs'
import DeletePopup from 'src/Components/DeletePopup/DeletePopup'
import useModal from 'src/ui/modal/toggleModal'
const GangsSingleItem: FC<{
    gang: IGangJobsDetail
    removeItem: (jobId: number) => void
}> = ({ gang, removeItem }): JSX.Element => {
  const { isShowing, toggle } = useModal()
  const remove = async (): Promise<void> => {
    try {
      await removeJobFromGang(gang.Job)
      removeItem(gang.Job)
      toast.success('Job deleted successfully')
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }
  return (
    <>
      <tr>
        <td>{gang.Job}</td>
        <td>{gang.Name}</td>
        <td>{gang.paper_type}</td>
        <td>{gang.due_on}</td>
        <td>{gang.size}</td>
        <td>{gang.product_label}</td>
        <td>{gang.ink}</td>
        <td>{gang.quantity}</td>
        <td>{gang.gang_spots}</td>
        <td>{gang.username}</td>
        <td>{gang.bindery}</td>
        <td>{gang.lots}</td>
        <td>{gang.bookletCover}</td>
        <td>{gang.proof}</td>
        <td><span className={classes.gangsSingle_container_tableBlock_redColor}>{gang.finish}</span></td>
        <td>{gang.is_fsc_certified}</td>
        <td>
          {
            gang.notes.length ? gang.notes.map((note, noteIndex) => (
              <p className={classes.gangsSingle_container_tableBlock_greenColor} key={noteIndex}>{note.note}</p>
            )) : null
          }
        </td>
        <td>
          <button onClick={toggle}>Remove</button>
        </td>
      </tr>
      {
        isShowing && <Suspense fallback={null}>
          <DeletePopup toggle={toggle} remove={remove}/>
        </Suspense>
      }   
    </>
  )
}

export default GangsSingleItem