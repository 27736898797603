import { FC, LegacyRef } from 'react'
import ReactDOM from 'react-dom'
import classes from './Modal.module.css'
import { TModalProps } from './model'

const Modal: FC<TModalProps> = ({ children, isShowing, modalRef }): JSX.Element => {
  return ReactDOM.createPortal(
    <div className={classes.darkBG} style={{ display: isShowing ? 'block' : 'none' }}>
      <div>
        <div ref={modalRef as unknown as LegacyRef<HTMLDivElement> | undefined} className={classes.centered}>
          <div className={classes.modal}>{children}</div>
        </div>
      </div>
    </div>,
    document.body
  )
}

export default Modal
