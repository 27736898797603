import { debounce } from 'lodash'
import { FC, useEffect, useRef, useState } from 'react'
import { getCustomOrders, getLikelyProducts } from 'src/api/addOrder/addOrder'
import { ILikelyProducts } from 'src/api/addOrder/model'
import { IParams } from 'src/api/Preflight/orderList/OrderListNote/model'
import classes from '../CreateOrder.module.scss'
import { ICustomer } from 'src/api/createCustomOrder/model'

const CustomerBlock: FC<{setOrder: React.Dispatch<React.SetStateAction<ILikelyProducts | undefined>>, setSelectedCustomer: (arg: number) => void}> = ({ setOrder, setSelectedCustomer }): JSX.Element => {
  const [customerInfo, setCustomerInfo] = useState<ICustomer[]>([])
  const [likelyProductsInfo,setLikelyProductsInfo] = useState<ILikelyProducts[]>([])
  const [selectedProduct, setSelectedProduct] = useState<ILikelyProducts>()
  const ref = useRef<HTMLSelectElement>(null)

  useEffect(() => {
    if (ref.current) {
      ref.current?.dispatchEvent(new MouseEvent('click', { bubbles: true }))
    }
  }, [likelyProductsInfo])

  useEffect(() => {
    if (selectedProduct) {
      setOrder({ ...selectedProduct, productGroupName: selectedProduct.name })
    }
  }, [selectedProduct])
  const customerOrder = async (value: string): Promise<void> => {
    const parameter: IParams = { match:value }
    try {
      if (value.length) {
        setCustomerInfo(await getCustomOrders(parameter))
      }
    } catch (error) {
      console.log(error)

    }
  }
  const handleChangeProduct = (index: number): void => {
    setSelectedProduct(likelyProductsInfo?.[index])
  }
  const likelyProducts = async (value: string): Promise<void> => {
    const parameter: IParams = { match: value }
    try {
      if (value.length) {
        const res = await getLikelyProducts(parameter)
        setLikelyProductsInfo(res)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const debouncedCustomer = debounce(customerOrder, 350)
  const debouncedProduct = debounce(likelyProducts, 350)

  return (
    <div className={classes.createOrder_container_leftBlock_customer}>
      <div className={classes.createOrder_container_leftBlock_inputBlock}>
        <label>Customer</label>
        <input type='text' placeholder='Start typing...'
          onChange={async (e) => await debouncedCustomer(e.target.value)}
        />
        <select onChange={e =>
          setSelectedCustomer(Number(e.target.value))
        }
        defaultValue=''
        >
          <option value='' disabled>Select a customer</option>
          {
            customerInfo.length ? customerInfo.map((e, index) => (
              <option key={index} value={e.customerId}>{e.user.fullName + ' <' + e.user.email + '>'}</option>
            )) : null
          }
        </select>
      </div>
      <div className={classes.createOrder_container_leftBlock_inputBlock}>
        <label>Product</label>
        <input type='text' placeholder='Start typing...'
          onChange={async (e) => await debouncedProduct(e.target.value)}
        />
        <select ref={ref} onChange={(e) => handleChangeProduct(e.target.selectedIndex - 1)} defaultValue='' value={selectedProduct?.name || ''}>
          <option value='' disabled>
          Select a product
          </option>
          {
            likelyProductsInfo.length ? likelyProductsInfo.map((e, index) => (
              <option key={index} value={e.name}>{e.name}</option>
            )) : null
          }
        </select>
      </div>
    </div>
  )
}

export default CustomerBlock