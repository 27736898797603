import { FC, useEffect, useState } from 'react'
import classes from '../CreateCustomer.module.scss'
import { IAccountInformation } from 'src/Components/Customers/model'
import { ICustomerAddress } from 'src/api/customers/model'
import { getCustomerAddress } from 'src/api/customers/customers'
const AccountInformation: FC<IAccountInformation> = ({
  firstName,
  lastName,
  userName,
  email,
  phoneNumber,
  category,
  defaultBillingAddres,
  defaultShippingAddress,
  customerId,
  handleChange
}): JSX.Element => {
  const [customerAddressInfo, setCustomerAddressInfo] = useState<ICustomerAddress[]>([])
  
  const customerAddress = async (customerId: number): Promise<void> => {
    try {
      setCustomerAddressInfo( await getCustomerAddress(customerId))
    } catch (error) {
      console.log(error)
      
    }
  }

  useEffect(() => {
    if (customerId) {
      customerAddress(customerId)
    }
  },[customerId])
  
  return (
    <>
      <div className={classes.createCustomer_blockHeader}>
        <span>Account Information</span>
      </div>
      <table className={classes.createCustomer_body}>
        <tbody>
          <tr>
            <td>
              <label htmlFor='firstName'>
                First Name
              </label>
            </td>
            <td>
              <input
                id='firstName'
                name='firstName'
                type='text'
                value={firstName}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='lastName'>
                Last Name
              </label>
            </td>
            <td>
              <input
                id='lastName'
                name='lastName'
                type='text'
                value={lastName}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='userName'>
                Username
              </label>
            </td>
            <td>
              <input
                id='userName'
                name='username'
                type='username'
                value={userName}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='email'>
                Email
              </label>
            </td>
            <td>
              <input
                id='email'
                name='email'
                type='text'
                value={email}
                onChange={(e) => handleChange(e.target.name, e.target.value)} />
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <span className={classes.createCustomer_body_red}>
                Please note that if you update the customer email, you will also need to update it on authorize.net and QB
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='phoneNumber'>
                Phone Number
              </label>
            </td>
            <td>
              <input
                id='phoneNumber'
                name='salesCustomersphoneNumber'
                type='text'
                value={phoneNumber}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='category'>
                Category
              </label>
            </td>
            <td>
              <input
                id='category'
                name='salesCustomerscustomerCategory'
                type='text'
                value={category}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Default Billing Address	</td>
            <td>
              <select
                name='salesCustomersdefaultBillingAddress'
                value={defaultBillingAddres}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              >
                <option disabled selected>Select...</option>
                {
                  customerAddressInfo.length && (
                    customerAddressInfo.map(e => (
                      <option key={e.customerAddressId} value={e.customerAddressId}>{
                        e.address1 + ', ' + e.recipientFirstName + ' ' + e.recipientLastName +
                        ', ' + e.city + ', ' + e.addressState.stateAbbreviation + ' ' + e.zipCode
                      }
                      </option>
                    ))
                  )
                }
              </select>
            </td>
          </tr>
          <tr>
            <td>	Default Shipping Address</td>
            <td>
              <select
                name='salesCustomersdefaultShippingAddress'
                value={defaultShippingAddress}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              >
                <option disabled selected>Select...</option>
                {
                  customerAddressInfo.length && (
                    customerAddressInfo.map(e => (
                      <option key={e.customerAddressId} value={e.customerAddressId}>{
                        e.address1 + ', ' + e.recipientFirstName + ' ' + e.recipientLastName +
                        ', ' + e.city + ', ' + e.addressState.stateAbbreviation + ' ' + e.zipCode
                      }
                      </option>
                    ))
                  )
                }
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default AccountInformation