
import { Suspense, lazy, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import './App.css'
import SignIn from './Components/Auth/SignIn/SignIn'
import Coupons from './Components/Coupons/Coupons'
import CustomerTabs from './Components/Customers/CustomerTabs/CustomerTabs'
import Customers from './Components/Customers/Customers'
import Bindery from './Components/PostApproval/Bindery/Bindery'
import BinderyDetails from './Components/PostApproval/Bindery/BinderyDetails/BinderyDetails'
import Boxing from './Components/PostApproval/Boxing/Boxing'
import PrintShippmentLabel from './Components/PostApproval/Boxing/PrintShippmentLabel/PrintShippmentLabel'
import Charging from './Components/PostApproval/Charging/Charging'
import Coating from './Components/PostApproval/Coating/Coating'
import CoatingSinglePage from './Components/PostApproval/Coating/CoatingSinglePage/CoatingSinglePage'
import Ticket from './Components/PostApproval/Coating/Ticket/Ticket'
import Cutter from './Components/PostApproval/Cutter/Cutter'
import CutterSinglePage from './Components/PostApproval/Cutter/CutterSinglePage/CutterSinglePage'
import Gangs from './Components/PostApproval/Gangs/Gangs'
import GangsSingle from './Components/PostApproval/Gangs/GangsSingle/GangsSingle'
import Lamination from './Components/PostApproval/Lamination/Lamination'
import PressmanSingle from './Components/PostApproval/Lamination/PressmanSingle/PressmanSingle'
import Outsourced from './Components/PostApproval/Outsourced/Outsourced'
import PrintDriversRunSheet from './Components/PostApproval/Shipping/PrintDriversRunSheet/PrintDriversRunSheet'
import PrintShippment from './Components/PostApproval/Shipping/PrintShippment/PrintShippment'
import Shipping from './Components/PostApproval/Shipping/Shipping'
import Stripper from './Components/PostApproval/Stripper/Stripper'
import CustomOrders from './Components/Preflight/CustomOrders/CustomOrders'
import Exceptions from './Components/Preflight/Exceptions/Exceptions'
import CreateCustomOrder from './Components/Preflight/OrderList/CreateCustomOrder/CreateCustomOrder'
import CreateOrder from './Components/Preflight/OrderList/CreateOrder/CreateOrder'
import OrderList from './Components/Preflight/OrderList/OrderList'
import ApprovedOrder from './Components/Reports/ApprovedOrder/ApprovedOrder'
import CheckoutReport from './Components/Reports/CheckoutReport/CheckoutReport'
import ClientsReports from './Components/Reports/ClientsReports/ClientsReports'
import OrdersReports from './Components/Reports/OrdersReports/OrdersReports'
import CannedMessages from './Components/Settings/CannedMessages/CannedMessages'
import CreateCannedMessage from './Components/Settings/CannedMessages/CreateCannedMessage/CreateCannedMessage'
import EmailBanners from './Components/Settings/EmailBanners/EmailBanners'
import EditEstateEmail from './Components/Settings/EstateEmail/EditEstateEmail/EditEstateEmail'
import EstateEmail from './Components/Settings/EstateEmail/EstateEmail'
import QbBackup from './Components/Settings/QbBackup/QbBackup'
import WorkflowSettings from './Components/Settings/WorkflowSettings/WorkflowSettings'
import CardBuilder from './Components/TemplateManager/CardBuilder/CardBuilder'
import CreateTemplate from './Components/TemplateManager/CardBuilder/CreateTemplate/CreateTemplate'
import DesignTemplate from './Components/TemplateManager/DesignTemplate/DesignTemplate'
import ProtectedRoute from './HOC/ProtectedRoute'
import PageErrors from './PageWrap/PageErrors/PageErrors'
import Wrapper from './PageWrap/Wrapper'
import { userStore } from './stores/userStore'
import './styles/global.scss'
import { getCookies } from './utils/storageFunction'
import PrintSKULabel from './Components/PrintSKULabel/PrintSKULabel'
import PrintableSummary from './Components/PrintableSummary/PrintableSummary'
const OrderTabs = lazy(() => import('./Components/Preflight/OrderList/OrderTabs/OrderTabs'))
function App (): JSX.Element {
  const accessToken = getCookies('access-token')
  const location = useLocation()
  useEffect(() => {
    if (accessToken && location.pathname !== '/signIn') {
      userStore.setUser()
    }
  }, [accessToken])
  const orderTabsRender = (): JSX.Element => {
    return <>
      <Route path='orders/:id/summary' element={<Suspense fallback={null}><OrderTabs/></Suspense>}/>
      <Route path='orders/:id/shipping' element={<Suspense fallback={null}><OrderTabs/></Suspense>}/>
      <Route path='orders/:id/files' element={<Suspense fallback={null}><OrderTabs/></Suspense>}/>
      <Route path='orders/:id/billing' element={<Suspense fallback={null}><OrderTabs/></Suspense>}/>
      <Route path='orders/:id/setup' element={<Suspense fallback={null}><OrderTabs/></Suspense>}/>
      <Route path='orders/:id/cart' element={<Suspense fallback={null}><OrderTabs/></Suspense>}/>
    </>
  }
  return (
    <Routes>
      <Route path='/' element={
        <ProtectedRoute>
          <Wrapper/>
        </ProtectedRoute>
      }>
        <Route path=''>
          <Route index element={<OrderList requestPath='my_orders'/>}/>
          {orderTabsRender()}
        </Route>
        <Route path='order_create' element={<CreateOrder/>}/>
        <Route path='custom_order_create' element={<CreateCustomOrder/>}/>
        <Route path='dashboard'>
          <Route index element={<OrderList requestPath='new_dashboard'/>}/>
          {orderTabsRender()}
        </Route>
        <Route path='cancelled_online'>
          <Route index element={<OrderList requestPath='cancelled_online'/>}/>
          {orderTabsRender()}
        </Route>
        <Route path='custom-orders' element={<CustomOrders/>}/>
        <Route path='hot-jobs' element={<OrderList requestPath='hot_jobs'/>}/>
        <Route path='exceptions' element={<Exceptions/>}/>
        <Route path='stripper' element={<Stripper/>}/>
        <Route path='outsourced' element={<Outsourced pathName='Outsourced' type='1'/>}/>
        <Route path='lfp' element={<Outsourced pathName='LFP' type='2'/>}/>
        <Route path='oki' element={<Outsourced pathName='OKI' type='3'/>}/>
        <Route path='gangs'>
          <Route index element={<Gangs/>}/>
          <Route path=':id' element={<GangsSingle/>}/>
        </Route>
        <Route path='pressman'>
          <Route index element={<Lamination requestQuery='pressman'/>}/>
          <Route path=':id' element={<PressmanSingle/>}/>
        </Route>
        <Route path='coating'>
          <Route index element={<Coating/>}/>
          <Route path=':id' element={<CoatingSinglePage pageName='Coating'/>}/>
          <Route path='ticket/:id' element={<Ticket/>}/>
        </Route>
        <Route path='cutter'>
          <Route index element={<Cutter requestType={0}/>}/>
          <Route path=':id' element={<CutterSinglePage pageName='Cutter'/>}/>
        </Route>
        <Route path='lamination'>
          <Route index element={<Lamination requestQuery='sleeking'/>}/>
          <Route path=':id' element={<CoatingSinglePage pageName='Lamination'/>}/>
        </Route>
        <Route path='die-cutting'>
          <Route index element={<Cutter requestType={1}/>}/>
          <Route path=':id' element={<CutterSinglePage pageName='Die Cutting'/>}/>
        </Route>
        <Route path='bindery'>
          <Route index element={<Bindery/>}/>
          <Route path=':id' element={<BinderyDetails/>}/>
        </Route>
        <Route path='boxing' element={<Boxing type={1}/>}/>
        <Route path='fbc' element={<Boxing type={2}/>}/>
        <Route path='shipping' element={<Shipping/>}/>
        <Route path='/workflow/postaproval/shipping/printShippment/:id' element={<PrintShippment/>}/>
        <Route path='/workflow/postaproval/shipping/printMultipleShippments' element={<PrintShippment/>}/>
        <Route path='/workflow/postaproval/shipping/printDriversRunSheet' element={<PrintDriversRunSheet/>}/>
        <Route path='charging' element={<Charging/>}/>
        <Route path='workflowSettings' element={<WorkflowSettings/>}/>
        <Route path='cannedMessages'>
          <Route index element={<CannedMessages/>}/>
          <Route path=':id/edit' element={<CreateCannedMessage/>}/>
          <Route path='create' element={<CreateCannedMessage/>}/>
        </Route>
        <Route path='estateEmail'>
          <Route index element={<EstateEmail/>}/>
          <Route path=':id/edit' element={<EditEstateEmail/>}/>
        </Route>
        <Route path='qbBackup' element={<QbBackup/>}/>
        <Route path='emailBanners' element={<EmailBanners/>}/>
        <Route path='customers'>
          <Route index element={<Customers/>}/>
          <Route path=':id/edit' element={<CustomerTabs/>}/>
          <Route path='create' element={<CustomerTabs/>}/>
        </Route>
        <Route path='coupons' element={<Coupons/>}/>
        <Route path='checkout' element={<CheckoutReport queryName='/checkout_report' pageName='Checkout Report' downloadName='checkout-report.csv'/>}/>
        <Route path='approved' element={<CheckoutReport queryName='/not_approved' pageName='Not Approved Orders Report' downloadName='notApprovedOrders-report.csv'/>}/>
        <Route path='ready-approved' element={<CheckoutReport queryName='/ready_to_approve' pageName='Orders Ready to approve' downloadName='ordersReadyToApprove-report.csv'/>}/>
        <Route path='approved-order' element={<ApprovedOrder/>}/>
        <Route path='orders-reports' element={<OrdersReports downloadName='orders-report.csv'/>}/>
        <Route path='clients-reports' element={<ClientsReports downloadName='clients-report.csv'/>}/>
        <Route path='orders-summary' element={<OrdersReports downloadName='orders-summary.csv'/>}/>
        <Route path='card-builder'>
          <Route index element={<CardBuilder/>}/>
          <Route path='create-template' element={<CreateTemplate/>}/>
        </Route>
        <Route path='design-template' element={<DesignTemplate/>}/>
      </Route>
      <Route path='signIn' element={<SignIn/>}/>
      <Route path='/workflow/preflight/dashboard_detail'>
        <Route path='printSkuLabel/:id' element={<PrintSKULabel/>}/>
        <Route path='printShippmentLabel/:id' element={<PrintShippmentLabel/>}/>
        <Route path='getOrderPrintableSummary/:id' element={<PrintableSummary/>}/>
      </Route>
      <Route path='*' element={<PageErrors/>}/>
    </Routes>
  )
}

export default App
