import { useEffect, useState } from 'react'
import classes from './Bindery.module.scss'
import { IBinderyData } from 'src/api/postApproval/gangs/model'
import { getBindery, postBinderyAction } from 'src/api/postApproval/gangs/gangs'
import { MetaData } from 'src/ui/MetaData'
import Loading from 'src/Components/Loading/Loading'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { useNavigate } from 'react-router-dom'
import { IResponseError } from 'src/utils/errorMessage/model'
import { toast } from 'react-toastify'
const Bindery = (): JSX.Element =>{
  const navigate = useNavigate()
  const [binderyInfo, setBinderyInfo] = useState<IBinderyData[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const binderyData = async (): Promise<void> => {
    try {
      setBinderyInfo(await getBindery())
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  
  useEffect(() => {
    binderyData()
  },[])

  const binderyNotes = (index: number): JSX.Element => {
    return (
      <div>
        {
          binderyInfo[index].notes.length ? binderyInfo[index].notes.map((note, index) => (
            <span className={classes.bindery_container_tableBlock_note} key={index}>{note.note}</span>
          )) : null
        }
      </div>
    )
  } 

  const binderyThumbnails = (index: number): JSX.Element => {
    return (
      <div className={classes.bindery_container_tableBlock_images}>
        {
          binderyInfo[index].thumbnails.length ? binderyInfo[index].thumbnails.map((image, index) => (
            <img key={index} src={image.url} alt='' />
          )) : null
        }
      </div>
    )
  } 

  const binderyAction = async (jobId: number, status: string, successField: string): Promise<void> => {
    try {
      const message = await postBinderyAction(jobId)
      if (message === 'success') {
        setBinderyInfo(prev => {
          return prev.map(e => {
            if (e.Job === jobId && status !== 'finish') {
              return { ...e, label_state: status }
            } else if (e.Job === jobId && status === 'finish') {
              return null
            }
            return e
          }).filter(e => e !== null) as IBinderyData[]
        })
        toast.success('Bindery' + ' ' + successField + ' ' + 'successfully')
      }
      
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      } 
    }
  }

  const startOrFinish = (jobId, state: string): JSX.Element => {
    if (state === 'Bindery') {
      return (
        <button onClick={e => {
          e.stopPropagation()
          binderyAction(jobId, 'Cutting Finished', 'Start' )
        }} className={classes.bindery_container_tableBlock_start}>Start</button>
      )
    }
    return (
      <button onClick={e => {
        e.stopPropagation()
        binderyAction(jobId, 'finish', 'Finish' )
      }} className={classes.bindery_container_tableBlock_finish}>Finish</button>
    )
  }
    
  return (
    <div className={classes.bindery}>      
      <MetaData title='Bindery'/>
      {
        isLoading ? <Loading/> :
          <div className={classes.bindery_container}>
            <PageHeader pageName='Bindery' pageWidth='100'/>
            <div className={classes.bindery_container_tableBlock}>
              <table>
                <thead>
                  <tr>
                    <th>Job</th>
                    <th>Name</th>
                    <th>Paper</th>
                    <th>Due</th>
                    <th>Size</th>
                    <th>Quantity</th>
                    <th>Repp</th>
                    <th>Bindery</th>
                    <th>Pages</th>
                    <th>Booklet Cover</th>
                    <th>FSC</th>
                    <th>First</th>
                    <th>Notes</th>
                    <th>Split Shipping</th>
                    <th>Turnaround</th>
                    <th>Thumbnails</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    binderyInfo.length ? binderyInfo.map((e, index) => (
                      <tr key={index} onClick={() => navigate(`/bindery/${e.Job}`)}>
                        <td>{e.Job}</td>
                        <td>{e.Name}</td>
                        <td>{e.paper_type}</td>
                        <td>{e.due_date}</td>
                        <td>{e.size}</td>
                        <td>{e.quantity}</td>
                        <td>{e.username}</td>
                        <td>{e.bindery}</td>
                        <td>{e.pages}</td>
                        <td>{e.bookletCover}</td>
                        <td>{e.is_fsc_certified}</td>
                        <td>{e.first_order}</td>
                        <td>{binderyNotes(index)}</td>
                        <td>{e.split_shipping}</td>
                        <td>{e.turnaround}</td>
                        <td>
                          {binderyThumbnails(index)}
                        </td>
                        <td>
                          {startOrFinish(e.Job,e.label_state)}
                        </td>
                      </tr>
                    )) : null
                  }
                </tbody>
              </table>
            </div>
          </div>
      }
    </div>
  )
}
export default Bindery