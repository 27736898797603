import { api } from 'src/api/api'
import { ICoupons, IDiscount, IExtras } from '../OrderListSummary/model'
import { IPostDiscount, IPostExtras } from '../model'
import { IGetCardInfoExpirationDate, IGetManagePaymentData, IOrderListByIdBilling, ISavedBillingInfo, ISendManagePaymentData } from './model'

export const getBillingData = (jobId: string): Promise<IOrderListByIdBilling> => 
  api.get(`/dashboard/orders/${jobId}/billing`).then(res => res.data)

export const postSetExtras = (jobId: string,data: IPostExtras): Promise<IExtras> =>
  api.post(`/dashboard/jobs/${jobId}/extras`, data).then(res => res.data)

export const postSetDiscount = (jobId: number,data: IPostDiscount): Promise<IDiscount> =>
  api.post(`/dashboard/jobs/${jobId}/discounts`, data).then(res => res.data)

export const postSetCoupon = (jobId: string,data: {code: string}): Promise<ICoupons> => 
  api.post(`/dashboard/jobs/${jobId}/coupons`, data).then(res => res.data)

export const deleteCoupon = (jobId: string, data: { couponId: string }): Promise<void> =>
  api.delete(`/dashboard/jobs/${jobId}/coupons`, { data }).then(res => res.data)

export const putBillingSettings = (data: ISavedBillingInfo): Promise<ISavedBillingInfo> =>
  api.put('/dashboard/orders/updateBillingInfo', data).then(res => res.data)

export const getCardInfoExpirationDate = (cardId: number, orderId: number ): Promise<IGetCardInfoExpirationDate> => 
  api.get(`/dashboard/cardInfo/${cardId}/${orderId}`).then(res => res.data.data)

export const postManagePayment = (orderId: number, data: ISendManagePaymentData): Promise<IGetManagePaymentData> => 
  api.post(`/dashboard/orders/managePayment/${orderId}`,data).then(res => res.data)