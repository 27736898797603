import { IFilterOrderReportData } from 'src/Components/Reports/model'
import { api } from '../api'
import { IClietsReportData, IGetAllCustomerCategory, IGetAllReportsCategory, IGetAllReportsGroup, IGetAllReportsProduct, IGetAllReportsState, IGetClientData, IGetOrderReports, ISummaryReports } from './model'
import { IParams } from '../Preflight/orderList/OrderListNote/model'

export const getAllCheckoutReports = <T,>(query: string,params: IParams): Promise<T[]> =>
  api.get(query,{ params }).then(res => res.data)

export const postAllReportsCoupons = (data: IFilterOrderReportData): Promise<IGetOrderReports> =>
  api.post('/report/coupons', data).then(res => res.data)

export const postAllReportsSummary = (data: IFilterOrderReportData): Promise<ISummaryReports> => 
  api.post('/report/order_summary', data).then(res => res.data)

export const getAllCategoryReport = (): Promise<IGetAllReportsCategory[]> => 
  api.get('/report/categories').then(res => res.data)

export const getReportsGroupByCategoryId = (reportCategoryId: string): Promise<IGetAllReportsGroup[]> =>
  api.get(`/report/groups/${reportCategoryId}`).then(res => res.data)

export const getReportsProductByGroupId = (reportGroupId: string): Promise<IGetAllReportsProduct[]> =>
  api.get(`/report/products/${reportGroupId}`).then(res => res.data)

export const getAllReportsState = (): Promise<IGetAllReportsState[]> => 
  api.get('/report/states').then(res => res.data)

export const getAllCustomerCategory = (): Promise<IGetAllCustomerCategory[]> => 
  api.get('/report/customer_category').then(res => res.data)

export const postAllReportClient = (data: IClietsReportData): Promise<IGetClientData[]> =>
  api.post('/report/clients', data).then(res => res.data)