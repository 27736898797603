import { useEffect, useState } from 'react'
import { getAllCoupons } from 'src/api/coupons/coupons'
import { IAllCoupon, ICouponData } from 'src/api/coupons/model'
import Filter from 'src/ui/Filter/Filter'
import { MetaData } from 'src/ui/MetaData'
import Loading from '../Loading/Loading'
import classes from './Coupons.module.scss'
import { IParams } from 'src/api/Preflight/orderList/OrderListNote/model'
import useNextStepScroll from 'src/Hook/useNextStepScrol'
import { floatNumberCut } from 'src/utils/FloatNumberCut'
const Coupons = (): JSX.Element => {
  const { isScroll, turnOfScroll } = useNextStepScroll()
  const [couponInfo, setCouponInfo] = useState<IAllCoupon>({} as IAllCoupon)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [params, setParams] = useState<IParams>({
    page: '1',
    limit: '50'
  })

  const allCouponData = async (): Promise<void> => {
    return getAllCoupons(params)
      .then(res => {
        if (res.pagination.page !== 1) {
          setCouponInfo(prev => {
            return {
              data: [...prev.data, ...res.data],
              pagination: res.pagination
            }
          })
        } else {
          setCouponInfo(res)
        }
        turnOfScroll()
      })
      .catch((error) => {
        console.log('error', error)
      })
      .finally(() => {
        setIsLoading(false)
      })

  }

  useEffect(() => {
    allCouponData()
  }, [params])
  
  useEffect(() => {
    if (isScroll && Number(params.page) < couponInfo?.pagination?.totalPages) {
      setParams({
        ...params,
        page: String(Number(params.page) + 1)
      })
    }
  }, [isScroll])
  const couponFilter = (data: IParams): void => {
    const newData: IParams = {}
    Object.keys(data).forEach(key => {
      if (data[key].length > 0) {
        newData[key] = data[key]
      }
    })
    newData.page = '1'
    setParams(newData)

  }
  const resetFilter = (): void => {
    setParams({
      page: '1',
      limit: '50'
    })
    setIsLoading(true)
  }

  const displayValidity = ( coupon: ICouponData): string => {
    return new Date().getTime() > new Date(`${coupon.validFromDate} ${coupon.validFromHour}:${coupon.validFromMinute}`).getTime()
     && new Date().getTime() < new Date(`${coupon.validToDate} ${coupon.validToHour}:${coupon.validToMinute}`).getTime() 
      ? 'Still Valid' : 
      'Expired or Not Yet Valid'
  }

  return (
    <>
      <MetaData title='Coupons' />
      {isLoading ? <Loading /> :
        <div className={classes.coupons}>
          <div className={classes.coupons_container}>    
            <div className={classes.coupons_container_header}>
              <Filter
                params={params}
                pageName='coupons'
                handleFilter={couponFilter}
                resetFilter={resetFilter}

              />
            </div>
            <table className={classes.coupons_container_table}>
              <thead>
                <tr>
                  <th>Coupon Id</th>
                  <th>Coupon Code</th>
                  <th>Title</th>
                  <th>Discount</th>
                  <th>Single Use</th>
                  <th>First Order</th>
                  <th>Free Shipping</th>
                  <th>Order Minimum</th>
                  <th>Cap</th>
                  <th>Enabled</th>
                  <th>Validity</th>
                  <th>All Products</th>
                </tr>
              </thead>
              <tbody>
                {
                  couponInfo?.data?.length ? couponInfo.data.map((coupon: ICouponData, index: number): JSX.Element => (
                    <tr key={JSON.stringify(coupon)} className={index % 2 === 0 ? classes.coupons_container_couple : classes.coupons_container_odd}>
                      <td>{coupon.couponId}</td>
                      <td>{coupon.code}</td>
                      <td>{coupon.title}</td>
                      <td>{floatNumberCut(coupon.discount, 2)} %</td>
                      <td>{coupon.singleUse}</td>
                      <td>{coupon.firstOrder}</td>
                      <td>{coupon.freeShipping}</td>
                      <td>{coupon.minOrderTotal}</td>
                      <td>{coupon.cap}</td>
                      <td>{coupon.isEnabled}</td>
                      <td>{displayValidity(coupon)}</td>
                      <td>{coupon.allProducts ? 'All Products' : 'Not All Products'}</td>
                    </tr>
                  )) : null
                }
              </tbody>
            </table>
          </div>
        </div>}
    </>
  )
}

export default Coupons