import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getCannedMessageAllCategory, getCannedMessageById, patchCannedMessage, postCannedMessage } from 'src/api/settings/cannedMessage/cannedMessage'
import { ICreateCannedMessageData } from 'src/api/settings/cannedMessage/model'
import { MetaData } from 'src/ui/MetaData'
import { errorMessage, toastErrorOrFieldError } from 'src/utils/errorMessage/errorMessage'
import { IErrorMessage, IResponseError } from 'src/utils/errorMessage/model'
import classes from './CreateCannedMessage.module.scss'
import { ICategory } from 'src/api/Preflight/orderList/OrderListNote/model'
const CreateCannedMessage = (): JSX.Element => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [errorRequest, setErrorRequest] = useState<IErrorMessage>({})
  const [categoriesInfo, setCategoriesInfo] = useState<ICategory[]>()
  const [cannedMessageData, setCannedMessageData] = useState<ICreateCannedMessageData>({})

  const cannedMessageById = async (id: string): Promise<void> => {
    try {
      const res = await getCannedMessageById(id)
      setCannedMessageData({
        title: res?.title,
        message: res?.message,
        subject: res?.subject,
        categoryId: Number(res?.categoryId),
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (id) {
      cannedMessageById(id)
    }
  }, [id])
  const allCategories = async (): Promise<void> => {
    try {
      setCategoriesInfo(await getCannedMessageAllCategory())
    } catch (err) {
      console.log(err)
    }
  }
  
  useEffect(() => {
    if (!categoriesInfo?.length) {
      allCategories()
    }
  },[categoriesInfo])

  const createCannedMessage = async (): Promise<void> => {
    try {
      await postCannedMessage(cannedMessageData)
      toast.success('Canned message created successfully')
      navigate(-1)
    } catch (error) {
      const errorMessage = error as IResponseError
      toastErrorOrFieldError(errorMessage?.response?.data, setErrorRequest)
    }
  }
  const editCannedMessage = async (id: number): Promise<void> => {
    try {
      await patchCannedMessage(id,cannedMessageData)
      toast.success('Canned message updated successfully')
      navigate(-1)
    } catch (error) {
      const errorMessage = error as IResponseError
      toastErrorOrFieldError(errorMessage?.response?.data, setErrorRequest)
    }
  }
  const handleSave = (): void => {
    if (!id) {
      createCannedMessage()
    } else {
      editCannedMessage(Number(id))
    }
  }

  const handleChange = (field: string, value: string | number): void => {
    if (!Object.values(errorRequest).every(error => error === '')) {
      setErrorRequest({ ...errorRequest, [field]: '' })
    }
    setCannedMessageData({ ...cannedMessageData, [field]: value })
  }
  const pageName = (): string => {
    return id ? 'Edit Canned Messages' : 'Add Canned Messages'
  }
  return (
    <div className={classes.createCannedMessage}>
      <MetaData title={pageName()}/>
      <div className={classes.createCannedMessage_container}>
        <div className={classes.createCannedMessage_container_title}>
          <p>{pageName()}</p>
        </div>
        <div className={classes.createCannedMessage_container_body}>
          <div className={classes.createCannedMessage_container_body_inputBlock}>
            <div className={classes.createCannedMessage_container_body_inputBlock_item}>
              <label htmlFor='category'>Category</label>
              <select 
                id='category'
                name='categoryId'
                value={cannedMessageData?.categoryId || ''}
                onChange={e => handleChange(e.target.name, e.target.value)}
              >
                <option value='' disabled selected>Select...</option>
                {
                  categoriesInfo?.length ? categoriesInfo.map(category => (
                    <option key={category.cannedMessageCategoryId} value={category.cannedMessageCategoryId}>
                      {category.categoryName}
                    </option>
                  )) : null
                }
              </select>
              {errorMessage('categoryId', errorRequest)}
            </div>
            <div className={classes.createCannedMessage_container_body_inputBlock_item}>
              <label htmlFor='title'>Title</label>
              <input type='text'
                id='title' 
                name='title'
                defaultValue={cannedMessageData?.title}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
              {errorMessage('title', errorRequest)}
            </div>
            <div className={classes.createCannedMessage_container_body_inputBlock_item}>
              <label htmlFor='subject'>Subject</label>
              <input id='subject' name='subject' type='text' 
                defaultValue={cannedMessageData?.subject}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
              {errorMessage('subject', errorRequest)}
            </div>
            <div className={classes.createCannedMessage_container_body_inputBlock_item}>
              <label htmlFor='message'>Message</label>
              <textarea id='message' name='message'
                defaultValue={cannedMessageData?.message}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
              {errorMessage('message', errorRequest)}
            </div>
            <div className={classes.createCannedMessage_container_body_btnBlock}>
              <div className={classes.createCannedMessage_container_body_btnBlock_btn}>
                <button onClick={() => navigate(-1)} className={classes.createCannedMessage_container_body_btnBlock_cancel}>
                Cancel
                </button>
                <button onClick={handleSave} className={classes.createCannedMessage_container_body_btnBlock_save}>
                Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateCannedMessage