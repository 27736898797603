import { MetaData } from 'src/ui/MetaData'
import classes from './DesignTemplate.module.scss'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import useModal from 'src/ui/modal/toggleModal'
import { Suspense, lazy } from 'react'
const CreateDesignTemplate = lazy(() => import('./CreateDesignTemplate/CreateDesignTemplate'))
const DesignTemplate = (): JSX.Element => {
  const { isShowing, toggle } = useModal()
  const createPage = (field: string,): void => {
    if (field === 'add') {
      toggle()
    }
  }
  return (
    <div className={classes.designTemplate}>
      <MetaData title='Design Template'/>
      <div className={classes.designTemplate_container}>
        <PageHeader pageName='Design Template' pageWidth='100' buttonName='Add' handleAddClick={createPage}/>
        <table className={classes.designTemplate_container_table}>
          <thead>
            <tr>
              <td>Product</td>
              <td>Size</td>
              <td>Type</td>
              <td>File</td>
              <td>File Size</td>
              <td>Label</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      {
        isShowing &&
        <Suspense fallback={null}>
          <CreateDesignTemplate close={toggle}/>
        </Suspense>
      }
    </div>
  )
}
export default DesignTemplate