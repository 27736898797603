import { useState, useEffect } from 'react'

const useNextStepScroll = (): { isScroll: boolean, turnOfScroll: () => void,} => {
  const [isScroll, setIsScroll] = useState(false)

  const turnOfScroll = (): void => {
    setIsScroll(false)
  }

  const nextStepScroll = (e: Event): void => {
    const target = e.target as HTMLElement    
    if ((target.scrollHeight - target.scrollTop) === target.clientHeight ) {
      setIsScroll(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', nextStepScroll, true)

    return () => {
      window.removeEventListener('scroll', nextStepScroll,true)
    }
  }, [])

  return { isScroll, turnOfScroll }
}

export default useNextStepScroll
