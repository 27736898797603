import { MetaData } from 'src/ui/MetaData'
import classes from './CoatingSinglePage.module.scss'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { useParams } from 'react-router-dom'
import { getCoatingGang, getCoatingJobsByGangId } from 'src/api/postApproval/coating/coating'
import { FC, useEffect, useState } from 'react'
import { ICoatingTiket } from 'src/api/postApproval/coating/model'
import { dateConvertToStringToDate } from 'src/utils/dateConvertToString'
import { IPressmanGangJobsDetail } from 'src/api/postApproval/gangs/model'
import Loading from 'src/Components/Loading/Loading'
import { getLaminationGang, getLaminationJobs } from 'src/api/postApproval/gangs/gangs'
const CoatingSinglePage: FC<{pageName: string}> = ({ pageName }): JSX.Element => {
  const { id: gangId } = useParams()
  const [coatingGangInfo, setCoatingGangInfo] = useState<ICoatingTiket>({} as ICoatingTiket)
  const [coatingJobsInfo, setCoatingJobsInfo] = useState<IPressmanGangJobsDetail[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const coatingGang = async (gangId: string): Promise<void> => {
    try {
      if (pageName === 'Coating') {
        setCoatingGangInfo( await getCoatingGang(gangId))
      } else {
        setCoatingGangInfo(await getLaminationGang(gangId))
      }
    } catch (error) {
      console.log(error)
        
    }
  }
  const coatingJobs = async (gangId: string): Promise<void> => {
    try {
      if (pageName === 'Coating') {
        
        setCoatingJobsInfo( await getCoatingJobsByGangId(gangId))
      } else {
        setCoatingJobsInfo(await getLaminationJobs(gangId))
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (gangId) {
      coatingGang(gangId)
      coatingJobs(gangId)
    }
  },[gangId, pageName])

  return (
    <>
      {
        isLoading ? <Loading/> :
          <div className={classes.coatingSinglePage}>
            <MetaData title={pageName}/>
            <div className={classes.coatingSinglePage_container}>
              <PageHeader pageName={pageName} pageWidth='100'/>
              <div className={classes.coatingSinglePage_container_tableBlock}>
                <div className={classes.coatingSinglePage_container_tableBlock_header}>
                  <span>{coatingGangInfo.gang_id}</span>
                  <span>{coatingGangInfo.internal_name}</span>
                  <span>{coatingGangInfo.username}</span>
                  <span>{dateConvertToStringToDate(coatingGangInfo.timestamp)}</span>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>Job</th>
                      <th>Size</th>
                      <th>Desc</th>
                      <th>Due Date</th>
                      <th>Quantity</th>
                      <th>Proof</th>
                      <th>Shipping</th>
                      <th>Finish</th>
                      <th>FSC</th>
                      <th>Tumbnail</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      coatingJobsInfo.length ? coatingJobsInfo.map((e, index) => (
                        <tr key={index}>
                          <td>{e.Job}</td>
                          <td>{e.size}</td>
                          <td>{e.product_label}</td>
                          <td>{e.due_on}</td>
                          <td>{e.quantity}</td>
                          <td>{e.proof}</td>
                          <td>{e.shippingType}</td>
                          <td>{e.finish}</td>
                          <td>{e.is_fsc_certified}</td>
                          <td>
                            <div>
                              {
                                e.thumbnails.length ? e.thumbnails.map((e, index) => (
                                  <img key={index} src={e.url} alt={e.fof_uploaded_files_file_name}/>
                                )) : null
                              }
                            </div>
                          </td>
                        </tr>
                      )) : null
                    }
                  </tbody>
                </table>
              </div>
            </div>  
          </div>
      }
    </>
  )
}
export default CoatingSinglePage