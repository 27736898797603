import { useEffect, useState } from 'react'
import classes from './Exceptions.module.scss'
import { IGetQrException } from 'src/api/Preflight/exceptions/model'
import { getQrException, putAcknowledge } from 'src/api/Preflight/exceptions/exceptions'
import { dateConvertToStringToDate } from 'src/utils/dateConvertToString'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { MetaData } from 'src/ui/MetaData'
const Exceptions = (): JSX.Element => {
  const [exceptionsInfo, setExceptionsInfo] = useState<IGetQrException[]>([])

  const getQrExceptionData = async (): Promise<void> => {
    try {
      setExceptionsInfo( await getQrException())
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getQrExceptionData()
  },[])

  const acknowledgeReq = async (orderExceptionId: number): Promise<void> => {
    try {
      const isPermission = window.confirm('Order has been Acknowledged successfully you can proceed to the next step !')
      if (isPermission) {
        await putAcknowledge(orderExceptionId)
        window.location.reload()
      }
    } catch (error) {
      console.log(error)
      
    }
  }

  return (
    <div className={classes.exceptions}>
      <MetaData title='QR Exceptions'/>
      <div className={classes.exceptions_container}>
        <PageHeader pageName='QR Exceptions' pageWidth='100'/>
        <table>
          <thead>
            <tr>
              <td>Order</td>
              <td>Name</td>
              <td>Message</td>
              <td>Time</td>
              <td>Status</td>
              <td>Acknowledge</td>
            </tr>
          </thead>
          <tbody>
            {
              exceptionsInfo.length ? exceptionsInfo.map((exception, index) => (
                <tr key={exception.orderExceptionId} className={index % 2 === 0 ? classes.exceptions_container_couple : classes.exceptions_container_odd }>
                  <td>{exception.orderId}</td>
                  <td>{exception.name}</td>
                  <td>{exception.message}</td>
                  <td>{dateConvertToStringToDate(exception.updateTime)}</td>
                  <td>{exception.status}</td>
                  <td>
                    <button onClick={() => acknowledgeReq(exception.orderExceptionId) }>
                    Acknowledge
                    </button>
                  </td>
                </tr>
              )) : null
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default Exceptions