import { FC, useMemo, useRef, useState } from 'react'
import { uploadProjectFile } from 'src/api/addOrder/addOrder'
import classes from './ProjectInfoBlock.module.scss'
import { IProjectFile, IProjectInfo, IProjectInfoBlockProps } from './model'
import { errorMessage } from 'src/utils/errorMessage/errorMessage'
import { IErrorMessage } from 'src/utils/errorMessage/model'
// import { errorMessage } from 'src/utils/errorMessage/errorMessage'

const ProjectInfoBlock: FC<IProjectInfoBlockProps> = ({ jobIndex, changeJob, files, errorRequest,setErrorRequest }): JSX.Element => {
  const [projectInfo, setProjectInfo] = useState<IProjectInfo>({} as IProjectInfo)
  const inputRef = useRef<HTMLInputElement>(null)
  const uploadFile = async (file: File | null): Promise<void> => {
    if (file) {
      const formData = new FormData
      formData.append('file', file)
      const res = await uploadProjectFile(formData)
      changeJob<IProjectFile[]>(jobIndex, 'files', [...files, res])
    }
  }
  const changeField = (name: string, value: string): void => {
    setProjectInfo({ ...projectInfo, [name]: value })
    changeJob<string>(jobIndex, name, value)
  }
  const error = useMemo(() => {
    return errorRequest.job?.includes(`job[${jobIndex}].name`) 
      ? errorRequest 
      : {} as IErrorMessage
  }, [errorRequest])

  const deleteNameError = (): void => {
    setErrorRequest({ ...errorRequest, job:'' })
  }
  return (
	 <div className={classes.projectInfoBlock}>
      <div className={classes.projectInfoBlock_uploadFile} onClick={() => inputRef.current?.click()}>Click here to upload files Maximum File Upload Size: 150MB</div>
      <input style={{ display: 'none' }} ref={inputRef} type='file' onChange={(e) => uploadFile(e.target.files?.[0] || null)}/>
      <label>
        Project Name
        <input type='text' defaultValue={projectInfo.name} onClick={deleteNameError} onBlur={(e) => changeField('name', e.target.value)} />
      </label>
      {errorMessage('job', error)}
      <label>
        Comments/Notes
        <input type='text' defaultValue={projectInfo.notes} onBlur={(e) => changeField('notes', e.target.value)} />
      </label>
      <label>
        Secondary Email Address
        <input type='text' defaultValue={projectInfo.seconday_email} onBlur={(e) => changeField('seconday_email', e.target.value)} />
      </label>
    </div>
  )
}

export default ProjectInfoBlock