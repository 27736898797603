import { FC, useState } from 'react'
import classes from './Filter.module.scss'
import { filterData } from './filterData'
import { IFilterProps } from './model'
import { IParams } from 'src/api/Preflight/orderList/OrderListNote/model'
const Filter: FC<IFilterProps> = ({ params, pageName, selectData, selectName, handleFilter, resetFilter }): JSX.Element => {
  const [changeFilterData, setChangeFilterData] = useState<IParams>(params)
  console.log(changeFilterData, 'changeFilterData')
  
  const handleChange = (field: string, value: string): void => {
    if (value === 'all') {
      delete changeFilterData[field]
      setChangeFilterData({ ...changeFilterData })
    } else {
      setChangeFilterData({ ...changeFilterData, [field]: String(value) })
    }
  }

  const keyUpHandler = (event: React.KeyboardEvent<HTMLFormElement>): void => {
    event.preventDefault()
    if ((event.key) === 'Enter') {
      handleFilter(changeFilterData)
    }
  }
  const filterInputs = (): JSX.Element[] => {
    const jsxElements: JSX.Element[] = []
    filterData[pageName].length > 0 && filterData[pageName].forEach((item, index) => {
      if (item.type !== 'select') {
        jsxElements.push(<div key={item.id} className={classes.filter_item}>
          <label>{item.label}</label>
          <input id={item.label} name={item.name + '/'} type={item.type} placeholder={item.label + '...'}
            onChange={(e) => handleChange(item.name, e.target.value)}
            value={changeFilterData[item.name] ?? ''}
          />
        </div>
        )  
      } else {
        jsxElements.push( <div key={item.id} className={classes.filter_item}>
          <label>{item.label}</label>
          <select
            name={item.name}
            value={changeFilterData[item.name]}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            onKeyDown={(e) => e.preventDefault()}
          >
            <option disabled selected value=''> Select... </option>
            <option value='all'>All</option>
            {
              selectName && selectData && selectData[selectName[index]]?.length > 0 ? selectData[selectName[index]].map((item, i) => (
                <option key={i} value={item.value}>{item.label}</option>
              )
              ) : null
            }
          </select>
        </div>
        )
      }
    })
    return jsxElements
  }
  return (
    <form onSubmit={(e) => {
      e.preventDefault()
      handleFilter(changeFilterData)
    }}
    onKeyUp={(e) => keyUpHandler(e)}
    className={classes.filter}>
      {filterInputs()}
      <div className={classes.filter_btnBlock}>
        <button id='filter' type='submit' className={classes.filter_btnBlock_find}>Filter</button>
        <button type='button' onClick={() =>{
          resetFilter()
          setChangeFilterData({})
        }} className={classes.filter_btnBlock_reset}>Reset</button>
      </div>
    </form>
  )
}

export default Filter