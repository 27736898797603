import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { IUserData } from '../../../api/auth/model'
import { userStore } from '../../../stores/userStore'
import { deleteCookies } from '../../../utils/storageFunction'
import classes from './Header.module.scss'
import { sideMenu } from '../SideBar/sideMenu'
const Header = (): JSX.Element => {
  const navigate = useNavigate()
  const user: IUserData | undefined = userStore.user

  const logout = (): void => {
    deleteCookies('access-token')
    window.location.assign('/signIn')
  }

  return (
    <div className='header'>
      <div className={classes.main}>
        <div className={classes.main_title} onClick={() => navigate('/')}>
          <img src='https://dev.4over4.com/workflow/assets/img/4over4-logo.jpg' alt='logo' height={45} />
        </div>
        <div className={classes.main_leftBlock}>
          <ul className={classes.sideBar_parentMenu}>
            {
              sideMenu.items.map((item, index) => (
                <li key={index} onClick={() => item.path?.length && navigate(item.path)}>
                  {item.name}
                  {item?.children && (
                    <ul>
                      {item?.children?.map((childrenItem, childrenIndex) => (
                        <li key={childrenIndex} onClick={() => navigate(childrenItem.path)}>{childrenItem.name}</li>
                      ))}
                    </ul>
                  )}
                </li>
              ))
            }
          </ul>
          <div className={classes.main_rightBlock}>
            <div className={classes.main_rightBlock_title}>
              <p>{user?.fullName}</p>
              <p onClick = {logout}>Logout</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default observer(Header)