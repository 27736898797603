import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import Loading from 'src/Components/Loading/Loading'
import { getEstateEmailById, patchEstateEmail, postUploadBannerImage } from 'src/api/settings/estateEmail/estateEmail'
import { IEstateEmailById, IUploadBannerImage } from 'src/api/settings/estateEmail/model'
import { MetaData } from 'src/ui/MetaData'
import CkEditor from 'src/ui/ckEditor/CKEditor'
import { errorMessage, toastErrorOrFieldError } from 'src/utils/errorMessage/errorMessage'
import { IErrorMessage, IResponseError } from 'src/utils/errorMessage/model'
import { formDataGeneration } from 'src/utils/formDataGeneration/formDataGeneration'
import classes from './EditEstateEmail.module.scss'
const EditEstateEmail = (): JSX.Element => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [renderedContent, setRenderedContent] = useState<JSX.Element>(<></>)
  const [errorRequest, setErrorRequest] = useState<IErrorMessage>({})
  const [radioInputChecked, setRadioInputChecked] = useState<string>('custom')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [estateEmailByIdInfo, setEstateEmailByIdInfo] = useState<IEstateEmailById>({} as IEstateEmailById)
  const [ckEditorData, setCkEditorData] = useState<{ contentA: string, contentB: string }>({
    contentA: '',
    contentB: ''
  })

  const estateEmailById = async (id: string): Promise<void> => {
    try {
      const res = await getEstateEmailById(id)
      setEstateEmailByIdInfo(res)
      setCkEditorData({ contentA: res.emailA.content, contentB: res.emailB.content })
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = (field: string, value: string | number): void => {

    if (!Object.values(errorRequest).every(error => error === '')) {
      setErrorRequest({ ...errorRequest, [field]: '' })
    }

    if (!field.startsWith('email') && !field.startsWith('customLink')) {
      estateEmailByIdInfo.estateEmail[field] = value
      setEstateEmailByIdInfo({ ...estateEmailByIdInfo })
    }

    if (field.startsWith('bannerLink')) {
      estateEmailByIdInfo[field] = value
      setEstateEmailByIdInfo({ ...estateEmailByIdInfo })
    }

    if (field.startsWith('email') && estateEmailByIdInfo[field]) {
      estateEmailByIdInfo[field].content = value
      setEstateEmailByIdInfo({ ...estateEmailByIdInfo })
    }

    if (field.startsWith('WFSelectedBanner')) {
      estateEmailByIdInfo[field] = estateEmailByIdInfo.WFbanners.find(e => e.bannerId === Number(value))
      setEstateEmailByIdInfo({ ...estateEmailByIdInfo })
    }

  }

  useEffect(() => {
    if (id && !Object.keys(estateEmailByIdInfo).length) {
      estateEmailById(id)
    }
  }, [estateEmailByIdInfo, id])

  const updateEstateEmail = async (data: IEstateEmailById): Promise<void> => {
    try {
      await patchEstateEmail({
        stateId: data.estateEmail.stateId,
        internalLabel: data.estateEmail.internalLabel,
        customerLabel: data.estateEmail.customerLabel,
        isMailable: data.estateEmail.isMailable,
        messageTypeIdA: data.estateEmail.messageTypeIdA,
        messageTypeIdB: data.estateEmail.messageTypeIdB,
        emailA: data.emailA,
        emailB: data.emailB,
        productLink: radioInputChecked === 'custom' ? data.bannerLink : data.productLink,
        WFSelectedBanner: data.WFSelectedBanner
      })
      toast.success('Estate Email was updated')
      navigate('/estateEmail')
    } catch (error) {
      const errorMessage = error as IResponseError
      toastErrorOrFieldError(errorMessage?.response?.data, setErrorRequest)
    }
  }

  const handleRadioChange = (value: string): void => {
    setRadioInputChecked(value)
  }

  const ckEditorRender = (): void => {

    if (ckEditorData) {
      setRenderedContent(
        <div className={classes.editEstateEmail_container_secondBlock} >
          <div>
            <div className={classes.editEstateEmail_container_secondBlock_title}>
              <span>Email to Customer</span>
            </div>
            <CkEditor
              targetName='emailA'
              data={ckEditorData.contentA}
              changeData={handleChange}
            />
          </div>
          <div>
            <div className={classes.editEstateEmail_container_secondBlock_title}>
              <span>Email to Associated Customer</span>
            </div>
            <CkEditor
              targetName='emailB'
              data={ckEditorData.contentB}
              changeData={handleChange}
            />
          </div>
        </div>
      )

    }
  }
  useEffect(() => {
    ckEditorRender()
  }, [ckEditorData])

  const uploadBannerImage = async (data: IUploadBannerImage): Promise<void> => {
    try {
      const res = await postUploadBannerImage(formDataGeneration<IUploadBannerImage>(data))
      setEstateEmailByIdInfo(prev => {
        return {
          ...prev,
          WFbanners:[...prev.WFbanners, ...res]
        }
      })
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }

  const selectImages = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const images = Array.from(e?.target?.files ?? [])
    const imageUrls = images.map(img => img)
    uploadBannerImage({ image: imageUrls })
  }

  return (
    <>
      <MetaData title='Edit Estate Email'/>
      {
        isLoading ? <Loading /> :
          <div className={classes.editEstateEmail}>
            <div className={classes.editEstateEmail_btnContainer}>
              <PageHeader pageName='Edit Estate Email' pageWidth='20%' />
              <div>
                <button onClick={() => updateEstateEmail(estateEmailByIdInfo)}>Save</button>
                <button onClick={() => updateEstateEmail(estateEmailByIdInfo)}>Save and Continue Edit</button>
                <button onClick={() => navigate(-1)}>Cancel</button>
              </div>
            </div>
            <div className={classes.editEstateEmail_container}>
              <div className={classes.editEstateEmail_container_firstBlock}>
                <table className={classes.editEstateEmail_container_firstBlock_leftBlock}>
                  <tr>
                    <td>
                      <label htmlFor='internalLabel'>Internal Label</label>
                    </td>
                    <td>
                      <input
                        type='text'
                        id='internalLabel'
                        name='internalLabel'
                        value={estateEmailByIdInfo?.estateEmail?.internalLabel}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                      />
                      {errorMessage('internalLabel', errorRequest)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='customerLabel'>Customer Label</label>
                    </td>
                    <td>
                      <input
                        type='text'
                        id='customerLabel'
                        name='customerLabel'
                        value={estateEmailByIdInfo?.estateEmail?.customerLabel}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                      />
                      {errorMessage('customerLabel', errorRequest)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='isMailable'>Is Mailable</label>
                    </td>
                    <td>
                      <input
                        id='isMailable'
                        name='isMailable'
                        type='checkbox'
                        checked={Boolean(estateEmailByIdInfo?.estateEmail?.isMailable)}
                        onChange={(e) => handleChange(e.target.name, e.target.checked ? 1 : 0)}
                      />
                      {errorMessage('isMailable', errorRequest)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='banner'>Banner</label>
                    </td>
                    <td>
                      <select
                        id='banner'
                        name='WFSelectedBanner'
                        value={estateEmailByIdInfo.WFSelectedBanner.bannerId}
                        onChange={(e) => 
                          handleChange(e.target.name, Number(e.target.value))
                        }
                      >
                        {
                          estateEmailByIdInfo?.WFbanners?.length ? estateEmailByIdInfo?.WFbanners.map(banner => (
                            <option key={banner.bannerId} value={banner.bannerId}>
                              {banner.fileName}
                            </option>
                          )) : null

                        }
                      </select>
                      {errorMessage('WFSelectedBanner', errorRequest)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='uploadBanner'>Upload banner</label>
                    </td>
                    <td>
                      <input id='uploadBanner' type='file' multiple onChange={e => selectImages(e)} />
                      {errorMessage('image', errorRequest)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input type='radio' id='customLink' name='drone' value='custom' checked={radioInputChecked === 'custom'}
                        onChange={(e) => handleRadioChange(e.target.value)} />
                      <label className={classes.editEstateEmail_container_firstBlock_leftBlock_radioLabel} htmlFor='customLink'>Custom Link</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='https://www.4over4.com/'>https://www.4over4.com/</label>
                    </td>
                    <td>
                      <input
                        type='text'
                        name='bannerLink'
                        id='https://www.4over4.com/'
                        value={radioInputChecked === 'custom' ? estateEmailByIdInfo.bannerLink : ''}
                        onChange={(e) => {
                          handleRadioChange('custom')
                          handleChange(e.target.name, e.target.value)
                        }}
                      />
                      {errorMessage('productLink', errorRequest)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input type='radio' id='productLink' name='drone' value='product' checked={radioInputChecked === 'product'}
                        onChange={(e) => handleRadioChange(e.target.value)} />
                      <label className={classes.editEstateEmail_container_firstBlock_leftBlock_radioLabel} htmlFor='Product Link'>Product Link</label>
                    </td>
                    <td>
                      <select
                        name='productLink'
                        onChange={(e) => {
                          handleRadioChange('product')
                          handleChange(e.target.name, e.target.value)
                        }}
                        value={radioInputChecked === 'product' ? estateEmailByIdInfo.productLink : ''}
                      >
                        <option disabled selected value=''> Select...</option>
                        {
                          estateEmailByIdInfo?.products?.length ? estateEmailByIdInfo?.products.map((product, index) => (
                            <option key={index} value={product.url}>{product.name}</option>
                          )) : null
                        }

                      </select>
                      {errorMessage('productLink', errorRequest)}
                    </td>
                  </tr>
                </table>
                <div className={classes.editEstateEmail_container_firstBlock_rightBlock}>
                  {/* <img src={estateEmailByIdInfo?.WFbanners?.find(banner => banner.bannerId === bannerImgIndex)?.link} alt='' /> */}
                  <img src={estateEmailByIdInfo.WFSelectedBanner.link} alt='' />
                </div>
              </div>
              {renderedContent}
            </div>
          </div>
      }
    </>
  )
}

export default EditEstateEmail

