import { FC } from 'react'
import BtnLoader from '../ckEditor/btnLoader'
import classes from './LoadingButton.module.scss'
const LoadingButton: FC<{
    isLoading: boolean,
    buttonName: string,
    changeButton: (name?: string) => void
    disabled?: boolean
}> = ({ isLoading, buttonName, changeButton, disabled }): JSX.Element => {
  
  return (
    <button className={disabled || isLoading ? classes.loadingButton_btnDisabled : ''}
      name={buttonName}
      disabled={disabled || isLoading}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => changeButton(e.currentTarget.name)}
    >
      { isLoading ? <BtnLoader/> : buttonName}
    </button>
  )
} 
export default LoadingButton