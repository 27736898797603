import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { patchCoatingFrontOrBack } from 'src/api/postApproval/coating/coating'
import { ISendCoatingActionData, TCoatingFrontOrBack } from 'src/api/postApproval/coating/model'
import { getLamination, postLaminationAction } from 'src/api/postApproval/gangs/gangs'
import { ILaminationData } from 'src/api/postApproval/gangs/model'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import Loading from 'src/Components/Loading/Loading'
import { MetaData } from 'src/ui/MetaData'
import { IResponseError } from 'src/utils/errorMessage/model'
import { openLinkNewTab } from 'src/utils/open-link-new-tab'
import Action from './Action/Action'
import classes from './Lamination.module.scss'
const Lamination: FC<{requestQuery: string}> = ({ requestQuery }): JSX.Element => {
  const [laminationInfo, setLaminationInfo] = useState<ILaminationData[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const laminationData = async (requestQuery: string): Promise<void> => {
    try {
      setIsLoading(true)
      setLaminationInfo(await getLamination(requestQuery))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (requestQuery) {

      laminationData(requestQuery)
    }
  },[requestQuery ])


  const handleChangeLaminationStatus = (data: ISendCoatingActionData): void => {
    setLaminationInfo(prev => {
      return prev.map(e => {
        if (data.status === 'Ready To Cut' && e.gang_id === data.gangId) {
          return null
        } else if (e.gang_id === data.gangId) {
          return { ...e, status: data.status }
        }
        return e
      }).filter(e => e !== null) as ILaminationData[]
    })

  }

  const laminationAction = async (data: ISendCoatingActionData): Promise<void> => {
    try {
      const res = await postLaminationAction('sleeking', {
        status: data.status,
        gangId: data.gangId,
      })
      if (res.message === 'success') {
        handleChangeLaminationStatus(data)
      }
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }

  const startOrFinish = (gangId: number, state: string): JSX.Element => {
    switch (state) {
      case 'Ready For Lamination':
        return (
          <button onClick={e => {
            e.stopPropagation()
            laminationAction({ gangId, status: 'Lamination' })
          }} className={classes.lamination_container_tableBlock_start}>Start</button>
        )
      case 'Lamination':
        return (
          <button onClick={e => {
            e.stopPropagation()
            laminationAction({ gangId, status: 'Ready To Cut' })
          }} className={classes.lamination_container_tableBlock_finish}>Finish</button>
        )
      default:
        return <></>
    }
  }



  const frontOrBack = async (field: 'coatedFront' | 'coatedBack', value: boolean, gangId: number): Promise<void> => {
    try {
      await patchCoatingFrontOrBack('pressman', gangId, { [field]: value } as TCoatingFrontOrBack)
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }

  const generatePressmanOptions = (side: number, step: string, front: number, back: number, gangId: number, type: string): JSX.Element => {
    if (type === 'Coating') {
      if (side === 2) {
        return (
          <>
            <span>{step}</span>
            <label>
                F
              <input
                defaultChecked={Boolean(front)}
                type='checkbox'
                name='coatedFront'
                onChange={e => frontOrBack('coatedFront', e.target.checked, gangId)}
                onClick={e => e.stopPropagation()}
              />
            </label>
            <label>
                B
              <input
                defaultChecked={Boolean(back)}
                type='checkbox'
                name='coatedBack'
                onChange={e => frontOrBack('coatedBack', e.target.checked, gangId)}
                onClick={e => e.stopPropagation()}
              />
            </label>
          </>
        )
      } else if (side === 1) {
        return (
          <>
            <span>{step}</span>
            <label>
                F
              <input
                defaultChecked={Boolean(front)}
                type='checkbox'
                name='coatedFront'
                onChange={e => frontOrBack('coatedFront', e.target.checked, gangId)}
                onClick={e => e.stopPropagation()}
              />
            </label>
          </>
        )
      }
    }
    return <></>
  }


  const pageTitle = (requestQuery: string): string => {
    if (requestQuery === 'pressman') {
      return 'Pressman'
    }
    return 'Lamination'
  }

  const navigateSinglePage = (gangId: number): void => {
    requestQuery === 'pressman' ?
      navigate(`/pressman/${gangId}`) :
      navigate(`/lamination/${gangId}`)
  }

  return (
    <>
      {
        isLoading ? <Loading/> :
          <div className={classes.lamination}>
            <MetaData title={pageTitle(requestQuery)}/>
            <div className={classes.lamination_container}>
              <PageHeader pageName={pageTitle(requestQuery)} pageWidth='100'/>
              <div className={classes.lamination_container_tableBlock}>
                <table>
                  <thead>
                    <tr>
                      <th>Gang</th>
                      <th>Run</th>
                      <th>Colors</th>
                      <th>Stock</th>
                      <th>Code</th>
                      <th>Notes</th>
                      <th>Press</th>
                      <th>Stripped By</th>
                      <th>Color Proof</th>
                      <th>Due</th>
                      <th>TUR</th>
                      <th>MES</th>
                      <th>FSC</th>
                      <th>Tiket</th>
                      <th>Coating</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      laminationInfo.length ? laminationInfo.map((e, index) => (
                        <tr key={index} onClick={() => navigateSinglePage(e.gang_id)}>
                          <td>{e.gang_id}</td>
                          <td>{e.quantity}</td>
                          <td>{e.colors}</td>
                          <td>{e.internal_name}</td>
                          <td>{e.code}</td>
                          <td>
                            <span className={classes.lamination_container_tableBlock_note}>{e.note}</span>
                          </td>
                          <td>{e.press}</td>
                          <td>{e.username}</td>
                          <td>{e.proof}</td>
                          <td>{e.due_on}</td>
                          <td>{e.turnaround}</td>
                          <td>{e.messengered ? 'YES' : ''}</td>
                          <td>{e.fsc}</td>
                          <td>
                            <span
                              onClick={() => openLinkNewTab(`coating/ticket/${e.gang_id}`)}
                              className={classes.lamination_container_tableBlock_tiket}>Tiket</span>
                          </td>
                          <td>
                            {
                              requestQuery === 'sleeking' ? e.next_step :
                                generatePressmanOptions(e.sides, e.next_step, e.coated_front, e.coated_back, e.gang_id, e.coating_type_group)
                            }
                          </td>
                          <td>
                            {requestQuery === 'sleeking' ? startOrFinish(e.gang_id, e.status) :
                              <Action
                                status={e.status}
                                gangId={e.gang_id}
                                nextV={e.next_step}
                                coatedFront={e.coated_front}
                                coatedBack={e.coated_back}
                                handleChangeLaminationStatus={handleChangeLaminationStatus}
                              />
                            }
                          </td>
                        </tr>
                      )) : null
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default Lamination