import { MetaData } from 'src/ui/MetaData'
import classes from './ClientsReports.module.scss'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { FC, useEffect, useState } from 'react'
import CustomerCategory from '../CustomerCategory/CustomerCategory'
import { getAllReportsState, postAllReportClient } from 'src/api/report/report'
import { IClietsReportData, IGetAllReportsState, IGetClientData } from 'src/api/report/model'
import { downloadCSV } from 'src/utils/downloadCSV'
import { IErrorMessage, IResponseError } from 'src/utils/errorMessage/model'
import { errorMessage, toastErrorOrFieldError } from 'src/utils/errorMessage/errorMessage'
import SelectDate from 'src/ui/selectDate/selectDate'
import ClientReportsTable from './ClientReportsTable/ClientReportsTable'
import LoadingButton from 'src/ui/loadingButton/LoadingButton'
import { IParams } from 'src/api/Preflight/orderList/OrderListNote/model'
const ClientsReports: FC<{downloadName: string}> = ({ downloadName }): JSX.Element => {
  const [clientReportInfo, setOrderReportInfo] = useState<IGetClientData[]>([])
  const [errorRequest, setErrorRequest] = useState<IErrorMessage>({})
  const [loading, setLoading] = useState<IParams>({})
  const [csvFileName, setCsvFileName] = useState<string>('')
  const [filteredClientData, setFilteredClientData] = useState<IClietsReportData>({
    range1_active: 0,
    range2_active: 0,
    signed_active: 0,
    state:-1,
    range1_start: '2010-01-01',
    range1_end: '2010-01-01',
    range2_start: '2010-01-01',
    range2_end: '2010-01-01'
  })
  const [reportsStateInfo, setReportsStateInfo] = useState<IGetAllReportsState[]>([])

  useEffect(() => {
    setCsvFileName(downloadName)
  },[downloadName])

  const allState = async (): Promise<void> => {
    try {
      setReportsStateInfo( await getAllReportsState())
    } catch (error) {
      console.log(error)
      
    }
  }

  const allClientReports = async (data: IClietsReportData, field?: string): Promise<void> => {
    try {
      const res = await postAllReportClient(data)
      setOrderReportInfo(res)
      if (field) {
        downloadCSV<IGetClientData[]>(res, csvFileName)
      }
    } catch (error) {
      const errorMessage = error as IResponseError
      toastErrorOrFieldError(errorMessage?.response?.data, setErrorRequest)
    } finally {
      setLoading({})
    }
  }

  useEffect(() => {
    allState()
  },[])
  const changeCustomerCategory = (include: string[], exclude: string[]): void => {
    setFilteredClientData({
      ...filteredClientData,
      customer_category_include: include,
      customer_category_exclude: exclude
    })
  }

  const handleChange = (field: string, value: string | number): void => {
    if (!Object.values(errorRequest).every(error => error === '')) {
      setErrorRequest({ ...errorRequest, [field]: '' })
    }
    setFilteredClientData({ ...filteredClientData, [field]: value })
  }

  const handleGenerate = (name?: string): void => {    
    if (name?.includes('Export')) {
      allClientReports(filteredClientData, 'export')
      setLoading({ ...loading, export: 'export' })
    } else {
      allClientReports(filteredClientData,)
      setLoading({ ...loading, generate: 'generate' })
    }
  }

  return (
    <div className={classes.clientsReports}>
      <MetaData title='Clients Reports'/>
      <PageHeader pageName='Clients Reports'/>
      <div className={classes.clientsReports_container}>
        <table>
          <tbody>
            <tr>
              <td>
                <label htmlFor='range1_active'>Range 1</label>
              </td>
              <td>
                <div>
                  <input type='checkbox' id='range1_active' name='range1_active' 
                    onChange={(e) => handleChange(e.target.name, Number(e.target.checked) )}/>
                  {
                    filteredClientData.range1_active ?
                      <>
                        <SelectDate fromOrTo='From' field='range1_start' handleChange={handleChange}/>
                        <SelectDate fromOrTo='To' field='range1_end' handleChange={handleChange}/>
                      , total spent
                        <select 
                          name='range1_limit'
                          onChange={e => handleChange(e.target.name, Number(e.target.value))}
                        >
                          <option value='0'>at least</option>
                          <option value='1'>no more than</option>
                        </select>
                      & <input type='number' name='range1_amount'
                          onChange={e => handleChange(e.target.name, Number(e.target.value))}
                        />
                        
                      </> : null
                  }
                </div> 
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor='range2_active'>Range 2</label>
              </td>
              <td>
                <div>
                  <input type='checkbox' id='range2_active' name='range2_active'
                    onChange={(e) => handleChange(e.target.name, Number(e.target.checked) )}/>
                  {
                    filteredClientData.range2_active ?
                      <>
                        <SelectDate fromOrTo='From' field='range2_start' handleChange={handleChange}/>
                        <SelectDate fromOrTo='To' field='range2_end' handleChange={handleChange}/>
                  , total spent
                        <select 
                          name='range2_limit'
                          onChange={e => handleChange(e.target.name, Number(e.target.value))}
                        >
                          <option value='0'>at least</option>
                          <option value='1'>no more than</option>
                        </select>
                  & <input type='number' name='range2_amount'
                          onChange={e => handleChange(e.target.name, Number(e.target.value))}
                        />
                      </> : null
                  }
                </div> 
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor='withApprovedOrders'>With approved orders</label>
              </td>
              <td><input id='withApprovedOrders' type='checkbox' name='approved'
                onChange={e => handleChange(e.target.name, Number(e.target.checked))}/>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor='includeCoupons'>Include Coupons, Extras, Discounts</label>
              </td>
              <td><input id='includeCoupons' type='checkbox' name='complete'
                onChange={e => handleChange(e.target.name, Number(e.target.checked))}/></td>
            </tr>
            <tr>
              <td>
                <label htmlFor='includeTax'>Include Tax</label>
              </td>
              <td><input id='includeTax' type='checkbox' name='tax'
                onChange={e => handleChange(e.target.name, Number(e.target.checked))}/>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor='sign'>Signed up in</label>
              </td>
              <td>
                <div>
                  <input id='sign' type='checkbox' name='signed_active'
                    onChange={(e) => {

                      handleChange(e.target.name, Number(e.target.checked))
                    }}/>
                  {
                    filteredClientData.signed_active ?
                      <>
                        <SelectDate fromOrTo='From' field='signed_start' handleChange={handleChange}/>
                        <SelectDate fromOrTo='To' field='signed_end' handleChange={handleChange}/>
                      </> : null
                  }
                </div> 
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor='includeNumberOrders'>Include Number of Orders</label>
              </td>
              <td>
                <input id='includeNumberOrders' type='checkbox' name='number_orders_active'
                  onChange={e => handleChange(e.target.name, Number(e.target.checked))}/>
                <p>{errorMessage('number_orders_active', errorRequest)}</p>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor='dateLastOrder'>Date of Last Order</label>
              </td>
              <td>
                <input id='dateLastOrder' type='checkbox' name='date_last_order_active'
                  onChange={e => handleChange(e.target.name, Number(e.target.checked))}/>
                <p>{errorMessage('date_last_order_active', errorRequest)}</p>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor='state'>State</label>
              </td>
              <td>
                <select id='state' name='state'
                  onChange={e => handleChange(e.target.name, Number(e.target.value))}>
                  <option value='-1' selected>N/A</option>
                  {
                    reportsStateInfo?.length && reportsStateInfo.map(state => (
                      <option key={state.addressStateId} value={state.addressStateId}>
                        {
                          state.stateAbbreviation + ' - ' + state.name
                        }
                      </option>
                    ))
                  }
                </select>
                <p>{errorMessage('state', errorRequest)}</p>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor='city'>City</label>
              </td>
              <td>
                <input id='city' type='text' name='city'
                  onChange={e => handleChange(e.target.name, e.target.value)}/>
                <p>{errorMessage('city', errorRequest)}</p>
              </td>
            </tr>
            <tr>
              <td>
                <label>Category</label>
              </td>
              <td>
                <div>
                  <CustomerCategory changeCustomerCategory={changeCustomerCategory}/>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <LoadingButton isLoading={loading.generate === 'generate'} buttonName='Generate Report' changeButton={handleGenerate}/>
                <LoadingButton isLoading={loading.export === 'export'} buttonName='Export Report' changeButton={handleGenerate}/>
              </td>
            </tr>
          </tbody>
        </table>
        <ClientReportsTable clientReportInfo={clientReportInfo}/>
      </div>
    </div>
  )
}

export default ClientsReports