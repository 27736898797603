import { FC } from 'react'
import { createPortal } from 'react-dom'
import { IIPCAttributesPopupProps } from '../../../IPC.model'
import classes from './IPCAttributesPopup.module.scss'

const IPCAttributesPopup: FC<IIPCAttributesPopupProps> = ({ popupData, onClose }): JSX.Element => {
  return createPortal(
    <>
      <div className='overlay' onClick={() => onClose({ title: '' })} />
      <div className={classes.IPCAttributesPopup}>
        <div className={classes.IPCAttributesPopup_wrapper}>
          <div className={classes.IPCAttributesPopup_wrapper_header}>
            <svg onClick={() => onClose({ title: '' })} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M9.28261 8.0001L14.7339 2.54851C15.0887 2.1939 15.0887 1.62056 14.7339 1.26595C14.3793 0.911349 13.806 0.911349 13.4514 1.26595L7.99992 6.71755L2.5486 1.26595C2.19384 0.911349 1.62067 0.911349 1.26607 1.26595C0.911309 1.62056 0.911309 2.1939 1.26607 2.54851L6.71739 8.0001L1.26607 13.4517C0.911309 13.8063 0.911309 14.3797 1.26607 14.7343C1.44279 14.9111 1.67515 15 1.90734 15C2.13953 15 2.37172 14.9111 2.5486 14.7343L7.99992 9.28266L13.4514 14.7343C13.6283 14.9111 13.8605 15 14.0927 15C14.3249 15 14.557 14.9111 14.7339 14.7343C15.0887 14.3797 15.0887 13.8063 14.7339 13.4517L9.28261 8.0001Z' fill='#0B111B'/>
            </svg>
            <h3>{popupData?.title}</h3>
            <div></div>
          </div>
          <div className={classes.IPCAttributesPopup_wrapper_content}>
            {popupData?.image && (
              <img src={popupData.image} alt='' />
            )}
            {popupData?.video && (
              <div className={classes.IPCAttributesPopup_wrapper_video} dangerouslySetInnerHTML={{ __html: popupData.video }}></div>
            )}
            {popupData?.description && (
              <p dangerouslySetInnerHTML={{ __html: popupData.description }} />
            )}
          </div>
        </div>
      </div>
    </>,
    document.body
  )
}

export default IPCAttributesPopup