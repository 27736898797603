import { FC, useEffect, useState } from 'react'
import classes from '../CreateCustomer.module.scss'
import { IPaymentMethods, ISalesCustomer } from 'src/api/customers/model'
import { getPaymentMethods } from 'src/api/customers/customers'
const Defaults: FC<{
    salesCustomers: ISalesCustomer
    handleChange: (field: string, value: string | number) => void
}> = ({ salesCustomers, handleChange }): JSX.Element => {
  const [paymentMethodsInfo, setPaymentMethodsInfo] = useState<IPaymentMethods[]>([])

  const paymentMethods = async (): Promise<void> => {
    try {
      setPaymentMethodsInfo( await getPaymentMethods())
    } catch (error) {
      console.log(error)
    
    } 
  }

  useEffect(() => {
    paymentMethods()
  },[])

  return (
    <>
      <div className={classes.createCustomer_blockHeader}>
        <span>Defaults</span>
      </div>
      <table className={classes.createCustomer_body}>
        <tbody>
          <tr>
            <td>Discount</td>
            <td><input
              id='discount'
              name='salesCustomersdiscount'
              type='number'
              value={salesCustomers?.discount}
              onChange={(e) => handleChange(e.target.name, Number(e.target.value))}
            /></td>
          </tr>
          <tr>
            <td>
              <label htmlFor='methodOfPayment'>
                Method of Payment
              </label>
            </td>
            <td>
              <select
                name='salesCustomersdefaultPaymentMethodId'
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={salesCustomers?.defaultPaymentMethodId}
              >
                <option disabled selected>Select...</option>
                {
                  paymentMethodsInfo.length && (
                    paymentMethodsInfo.map(e => (
                      <option key={e.paymentMethodId} value={e.paymentMethodId}>{e.paymentMethodDescription}</option>
                    ))
                  )
                }
              </select>
            </td>
          </tr>
          <tr>
            <td>
                Tax Exempt
            </td>
            <td>
              <div>
                <label>
                  <input
                    type='radio' 
                    name='salesCustomerstaxExempt' 
                    checked={Boolean(salesCustomers?.taxExempt)} 
                    onChange={(e) => handleChange(e.target.name, 1)}/>
                  YES
                </label>
                <label>
                  <input 
                    type='radio' 
                    name='salesCustomerstaxExempt' 
                    checked={!salesCustomers?.taxExempt} 
                    onChange={(e) => handleChange(e.target.name, 0)}/>
                  NO
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td>Brand Removal</td>
            <td>
              <div>
                <label>
                  <input 
                    type='radio' 
                    name='salesCustomersbrandRemoval' 
                    checked={salesCustomers?.brandRemoval === 1}
                    onChange={(e) => handleChange(e.target.name, 1)}
                  />
                  YES
                </label>
                <label>
                  <input 
                    type='radio'
                    name='salesCustomersbrandRemoval'
                    checked={salesCustomers?.brandRemoval === 0}
                    onChange={(e) => handleChange(e.target.name, 0)}
                  />
                  NO
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td>Brand Removal Exempt</td>
            <td>
              <div>
                <label>
                  <input 
                    type='radio' 
                    name='salesCustomersbrandRemovalFeeExempt' 
                    checked={salesCustomers?.brandRemovalFeeExempt === 1}
                    onChange={(e) => handleChange(e.target.name, 1)}
                  />
                  YES
                </label>
                <label>
                  <input 
                    type='radio' 
                    name='salesCustomersbrandRemovalFeeExempt' 
                    checked={salesCustomers?.brandRemovalFeeExempt === 0}
                    onChange={(e) => handleChange(e.target.name, 0)}
                  />
                  NO
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td>Blind Ship</td>
            <td>
              <div>
                <label>
                  <input
                    type='radio'
                    name='salesCustomersblindShip'
                    checked={salesCustomers?.blindShip === 1}
                    onChange={(e) => handleChange(e.target.name, 1)}
                  />
                  YES
                </label>
                <label>
                  <input 
                    type='radio' 
                    name='salesCustomersblindShip'
                    checked={salesCustomers?.blindShip === 0}
                    onChange={(e) => handleChange(e.target.name, 0)}
                  />
                   NO
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td>SKU Label</td>
            <td>
              <div>
                <label>
                  <input 
                    type='radio'
                    name='salesCustomersskuLabel' 
                    checked={salesCustomers?.skuLabel === 1}
                    onChange={(e) => handleChange(e.target.name, 1)}
                  />
                  YES
                </label>
                <label>
                  <input 
                    type='radio' 
                    name='salesCustomersskuLabel' 
                    checked={salesCustomers?.skuLabel === 0}
                    onChange={(e) => handleChange(e.target.name, 0)}
                  />
                  NO
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td>Fedex</td>
            <td>
              <div>
                <label>
                  <input 
                    type='radio' 
                    name='salesCustomersfedex' 
                    checked={salesCustomers?.fedex === 1}
                    onChange={(e) => handleChange(e.target.name, 1)}
                  />
                  YES
                </label>
                <label>
                  <input 
                    type='radio' 
                    name='salesCustomersfedex' 
                    checked={salesCustomers?.fedex === 0} 
                    onChange={(e) => handleChange(e.target.name, 0)}
                  />
                  NO
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td>Free Ups Ground</td>
            <td>
              <div>
                <label>
                  <input 
                    type='radio'
                    name='salesCustomersfreeUps' 
                    checked={salesCustomers?.freeUps === 1} 
                    onChange={(e) => handleChange(e.target.name, 1)}
                  />
                  YES
                </label>
                <label>
                  <input 
                    type='radio'
                    name='salesCustomersfreeUps'
                    checked={salesCustomers?.freeUps === 0}
                    onChange={(e) => handleChange(e.target.name, 0)}
                  />
                NO
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td>Discounted Messenger</td>
            <td>
              <div>
                <label>
                  <input 
                    type='radio'
                    name='salesCustomersdiscountedMessenger'
                    checked={salesCustomers?.discountedMessenger === 1}
                    onChange={(e) => handleChange(e.target.name, 1)}
                  />
                  YES
                </label>
                <label>
                  <input 
                    type='radio'
                    name='salesCustomersdiscountedMessenger'
                    checked={salesCustomers?.discountedMessenger === 0}
                    onChange={(e) => handleChange(e.target.name, 0)}
                  />
                  NO
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default Defaults