import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getIPCData } from 'src/api/ipc/ipc'
import { IIPCDataResponse, IIPCPayloadUpdates, IIPCPayloadUpdatesAttributes } from 'src/api/ipc/model'
import { ipcStore } from 'src/stores/IPC/ipcStore'
import { ICurrentProductSizes, IIPCProps } from './IPC.model'
import classes from './IPC.module.scss'
import IPCPriceCalc from './IPCPriceCalc/IPCPriceCalc'
import Upload from './IPCUpload/Upload'

const IPC = ({ productGroupName, order, close, attributes, updateOrder, inCreateOrder, setCustomOrder, setPrice }: IIPCProps): JSX.Element => {
  const param = useParams()
  const { currentProductSizes, isClickedOnCustomSize, setSelectedTrim, data: { selectedTrim } } = ipcStore
  const prodSizes: ICurrentProductSizes = {} as ICurrentProductSizes
  prodSizes.height = toJS(currentProductSizes.height)
  prodSizes.width = toJS(currentProductSizes.width)
  const [isMajesticPostcards, setMajesticPostcards] = useState({
    isMajestic: false,
    lamination: true
  })

  const initialState = {
    options: [],
    productData: {
      customSize: false,
      maxHeight: '',
      maxWidth: '',
      minHeight: '',
      minWidth: '',
      products: [],
    },
    productPrice: {
      basePrice: '',
      discount: '',
      finalPrice: '',
      stablePrice: '',
      attributesWF: {
        option: 0,
      },
      discountPercentage: ''
    },
    quantities: [],
    turnaroundTimes: [],
    widthHeightData: {
      defaultHeight: [],
      defaultWidth: [],
    },
    groupData: {
      is_large_format_print: 0,
      attribute_label: 'Size',
      product_group_id: 0,
      product_group_label: '',
    },
    payloadUpdates: {
      quantity: 0,
      printTurnAroundId: 0,
      printProductId: order.printProductId ?? 0,
      attributes: {},
      defaultMailingOptions: null,
    },
    productInfo: {
      optionFoldingInfo: null,
      printTurnaroundInfo: {
        color: '',
        info: '',
      },
    },
    use_coins: {
      value: 0
    },
    hasDesignTemplate: false,
    mailingOptions: null,
    freeProductChecker: null,
  }
  const [IPCRes, setIPCRes] = useState<IIPCDataResponse>(initialState as unknown as IIPCDataResponse)
  const [, setIPCPrice] = useState<string>('')
  const [payloadUpdates, setPayloadUpdates] = useState<IIPCPayloadUpdates>(
    {
      printTurnAroundId: order?.turnaround,
      quantity: order?.quantity,
      printProductId: order?.printProductId,
      attributes,
      defaultMailingOptions: {
        mailingQuantity: null,
        postageId: null,
        remainders: null
      },
    },
  )
  const changeIPCPayloadUpdate = (
    labelName: keyof IIPCPayloadUpdates,
    labelValue: number,
    attributeName?: keyof IIPCPayloadUpdatesAttributes,
  ): void => {
    setPayloadUpdates((prev) => {
      if (
        labelName === 'attributes' ||
        labelName === 'defaultMailingOptions'
      ) {
        return {
          ...prev,
          [labelName]: { ...prev[labelName], [attributeName as string]: labelValue },
        }
      }

      return { ...prev, [labelName]: labelValue }
    })
  }

  let urlIPCString: IIPCPayloadUpdates = {} as IIPCPayloadUpdates

  const getOfferCardData = async (): Promise<void> => {
    ipcStore.loadingStatus = true
    const copedPayloadUpdates = JSON.parse(JSON.stringify(payloadUpdates))
    if (copedPayloadUpdates.attributes) {
      copedPayloadUpdates.attributes.productGroupName = order.productGroupName
    }
    if (!isNaN((prodSizes.height)) && !isNaN(prodSizes.width) && isClickedOnCustomSize) {
      copedPayloadUpdates.customSizeHeight = prodSizes.height
      copedPayloadUpdates.customSizeWidth = prodSizes.width
    }

    if (payloadUpdates.printProductId !== null || urlIPCString.printProductId) {
      urlIPCString = {
        printProductId: payloadUpdates.printProductId ?
          payloadUpdates.printProductId :
          Number(urlIPCString.printProductId),
        quantity: payloadUpdates.quantity ||
          Number(urlIPCString.quantity) || 0,
        attributes: payloadUpdates.attributes &&
          Object.keys(payloadUpdates.attributes).length > 0 ?
          payloadUpdates.attributes : urlIPCString.attributes,
        productGroupName: order.productGroupName,
        jobId: Number(param.id),
        designId: null,
        customSizeHeight: isClickedOnCustomSize ? prodSizes.height : urlIPCString.customSizeHeight,
        customSizeWidth: isClickedOnCustomSize ? prodSizes.width : urlIPCString.customSizeWidth,
        printTurnAroundId: payloadUpdates.printTurnAroundId || Number(urlIPCString.printTurnAroundId) || 0,
        defaultMailingOptions: payloadUpdates?.defaultMailingOptions?.mailingQuantity !== null ?
          payloadUpdates?.defaultMailingOptions :
          urlIPCString?.defaultMailingOptions,
        url: `/printing/${(order.productGroupName).toLowerCase().replace(/ /g, '-')}/${(payloadUpdates.printProductId || urlIPCString.printProductId)}?ipc=${JSON.stringify(copedPayloadUpdates)}`
      }
      const currProd = IPCRes?.productData?.products.find((product) => product.print_product_id === payloadUpdates.printProductId)
      if (currProd?.is_any_size === 1 && isClickedOnCustomSize === null) {
        urlIPCString = {
          ...urlIPCString,
          printProductId: IPCRes.productData.products[0]?.print_product_id
        }
      }
    }
    if (isNaN(Number(urlIPCString.printProductId))) {
      urlIPCString.printProductId = IPCRes.payloadUpdates.printProductId
    }
    if (urlIPCString.printProductId !== null && (typeof urlIPCString.printProductId === 'number' || urlIPCString.printProductId === 0)) {
      try {
        const res = await getIPCData(urlIPCString)
        if (
          JSON.stringify(res.payloadUpdates) !==
          JSON.stringify(payloadUpdates)
        ) {
          setPayloadUpdates(res.payloadUpdates)
        }
        setIPCRes(res)
        setIPCPrice(res.productPrice.basePrice)
        res?.productData?.products[0]?.product_label === 'Any Size' &&
          (ipcStore.isClickedCustomSize = true)
        urlIPCString.customSizeHeight && (ipcStore.isClickedCustomSize = true)
        const dieCutTrim = res.options.find(
          ({ attribute_label: attributeLabel }) =>
            attributeLabel === 'Diecut Trim',
        )
        if (dieCutTrim && !selectedTrim) {
          setSelectedTrim({
            attributeOptionId: Number(
              dieCutTrim.attribute_option[0].attribute_option_id,
            ),
            optionLabel:
              dieCutTrim.attribute_option[0].option_label.split('-')[0],
            optionOrder: Number(dieCutTrim.attribute_option[0].option_order),
            comingLocation: 'IPC',
          })
        }
        ipcStore.loadingStatus = false
      } catch (error) {
        ipcStore.loadingStatus = false
      }
    }
  }
  useEffect(() => {
    const currProd = IPCRes?.productData?.products.find((product) => product.print_product_id === payloadUpdates.printProductId)
    if (currProd?.is_any_size === 1 && isClickedOnCustomSize === null) {
      getOfferCardData()
    }
  }, [isClickedOnCustomSize,])

  useEffect(() => {
    console.log('productGroupName', productGroupName)
    order.productGroupName && getOfferCardData()
  }, [
    prodSizes.width,
    prodSizes.height,
    payloadUpdates,
    productGroupName,
    IPCRes.payloadUpdates.quantity])

  const options = {}
  IPCRes.options.forEach(atts => {
    const filt = atts.attribute_option.filter(at => at.is_default_option)
    if (filt.length) {
      options[filt[0].attribute_label.replaceAll(' ', '_')] = atts.attribute_option.filter(at => at.is_default_option)[0].option_label
    }
  })


  const uploadRequestData = useMemo(
    () => ({
      attributes: IPCRes.payloadUpdates.attributes,
      printProductId: IPCRes.payloadUpdates.printProductId,
      printTurnAroundId: IPCRes.payloadUpdates.printTurnAroundId,
      quantity: IPCRes.payloadUpdates.quantity,
      defaultMailingOptions: IPCRes.payloadUpdates.defaultMailingOptions
    }),
    [payloadUpdates, IPCRes],
  )

  useEffect(() => {
    if (selectedTrim?.comingLocation === 'trim') {
      changeIPCPayloadUpdate(
        'attributes',
        selectedTrim?.attributeOptionId,
        'Diecut Trim',
      )
    }
  }, [selectedTrim])

  useEffect(() => {
    if (IPCRes?.groupData?.product_group_id === 526) {

      if (!isMajesticPostcards.isMajestic) {
        setMajesticPostcards({
          ...isMajesticPostcards,
          isMajestic: true
        })
      }
      if (isMajesticPostcards.isMajestic) {
        IPCRes?.options?.forEach((option) => {
          if (option.attribute_id === 314 && !option.attribute_option.length && isMajesticPostcards.lamination) {
            setMajesticPostcards({
              ...isMajesticPostcards,
              lamination: false,
            })
          } else if (option.attribute_id === 314 && option.attribute_option.length && !isMajesticPostcards.lamination) {
            setMajesticPostcards({
              ...isMajesticPostcards,
              lamination: true
            })
          }
        })
      }
    }
  }, [IPCRes.payloadUpdates.attributes])
  useEffect(() => {
    setPrice?.(Number(IPCRes.productPrice.finalPrice || 0))
  }, [IPCRes.productPrice.finalPrice])
  return (
    <div className={classes.modal_body_content}>
      <IPCPriceCalc
        IPCRes={IPCRes}
        changeIPCPayloadUpdate={changeIPCPayloadUpdate}
        payloadUpdates={payloadUpdates}
        isMajesticPostcards={isMajesticPostcards}
      />
      {inCreateOrder ? <div className={classes.modal_content_price}>
        Total: ${IPCRes?.productPrice?.finalPrice}
      </div> : null}
      <div className={classes.modal_content_footer}>

        <Upload
          setCustomOrder={setCustomOrder}
          updateOrder={updateOrder}
          orderId={order.orderId}
          inCreateOrder={inCreateOrder}
          productGroupId={IPCRes.groupData.product_group_id}
          uploadRequestData={uploadRequestData}
          close={close}
          freeProductChecker={IPCRes?.freeProductChecker}
          finalPrice={IPCRes.productPrice.finalPrice}
          jobId={Number(param.id)}
          IPCRes={IPCRes}
        />
        {close ? <button className={classes.modal_content_closeBtn} onClick={close}>Close</button> : null}
      </div>
    </div>
  )
}

export default observer(IPC)