import Modal from 'src/ui/modal/Modal'
import { ModalHeader } from 'src/ui/modal/ModalHeader'
import classes from './CreateCategory.module.scss'
import { FC } from 'react'
const CreateCategory: FC<{close: () => void}> = ({ close }): JSX.Element => {
  return (
    <Modal isShowing>
      <>
        <ModalHeader>
          <div className={classes.createCategory}>
            <h2 className={classes.createCategory_title}>Create New Category</h2>
          </div>

        </ModalHeader>
        <div className={classes.createCategory_body_inputBlock}>
          <div className={classes.createCategory_body_inputBlock_item}>
            <label htmlFor='parentCategory'>Parent Category</label>
            <select id='parentCategory'></select>
          </div>
          <div className={classes.createCategory_body_inputBlock_item}>
            <label htmlFor='category'>Category Name</label>
            <input 
              id='category'
              type='text' 
            />
          </div>
          <div className={classes.createCategory_body_btnBlock}>
            <button className={classes.createCategory_body_btnBlock_cancel}
              onClick={close}
            >
        Cancel
            </button>
            <button className={classes.createCategory_body_btnBlock_save}>
      Save
            </button>
          </div>
        </div>
      </>
    </Modal>
  )
}
export default CreateCategory