import { FC } from 'react'
import classes from '../PrintShippmentLabel.module.scss'
import { IShipFromProps } from 'src/Components/PostApproval/model'
const ShipFrom: FC<IShipFromProps> = ({ data, sendDataForBack, handleChangeData }): JSX.Element => {
  return (
    <div className={classes.printShippmentLabel_container_mainBlock} >
      <div className={classes.printShippmentLabel_container_mainBlock_blockHeader}>
        <span>Ship From</span>
      </div>
      <table className={classes.printShippmentLabel_container_mainBlock_body}>
        <tbody>
          <tr>
            <td>
              <label htmlFor='ups'>
              UPS Account #
              </label>
            </td>
            <td>
              <input
                id='ups'
                name='Shipper_ShipperNumber'
                type='text'
                value={sendDataForBack.Shipper_ShipperNumber}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='Company'>
              Company
              </label>
            </td>
            <td>
              <input
                id='Company'
                name='Shipper_Name'
                type='text'
                value={sendDataForBack.Shipper_Name}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='Name'>
                Name
              </label>
            </td>
            <td>
              <input
                id='Name'
                name='Shipper_AttentionName'
                type='text'
                value={sendDataForBack.Shipper_AttentionName}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='Phone'>
              Phone #
              </label>
            </td>
            <td>
              <input
                id='Phone'
                name='Shipper_PhoneNumber'
                type='text'
                value={sendDataForBack.Shipper_PhoneNumber}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='Address1'>
              Address
              </label>
            </td>
            <td>
              <input
                id='Address1'
                name='Shipper_AddressLine1'
                type='text'
                value={sendDataForBack?.Shipper_AddressLine1}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='Address2'>
              Address
              </label>
            </td>
            <td>
              <input
                id='Address2'
                name='Shipper_AddressLine2'
                type='text'
                value={sendDataForBack?.Shipper_AddressLine2}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='city'>
                City
              </label>
            </td>
            <td>
              <input
                id='city'
                name='Shipper_City'
                type='text'
                value={sendDataForBack.Shipper_City}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>State</td>
            <td>
              <select
                value={sendDataForBack.Shipper_StateProvinceCode}
                name='Shipper_StateProvinceCode'
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              >
                {
                  data?.shipperStates.length ? data.shipperStates.map((state, index) => (
                    <optgroup key={index} label={state.label}>
                      {state.label}
                      {
                        state.options?.length && state.options.map((e, index) => (
                          <option key={index} value={e.value}>{e.label}</option>
                        ))
                      }
                    </optgroup>
                  )) : null
                }
              </select>
            </td>
          </tr>
          <tr>
            <td>Country</td>
            <td>
              <select
                name='ShipTo_CountryCode'
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              >
                {
                  data?.Countries.length ? data.Countries.map(({ value, label }, index) => (
                    <option key={index} value={value}>{label}</option>
                  )) : null
                }
              </select>
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='zip'>
                ZIP
              </label>
            </td>
            <td>
              <input
                id='zip'
                name='Shipper_PostalCode'
                type='text'
                value={sendDataForBack.Shipper_PostalCode}
                onChange={e => handleChangeData(e.target.name, e.target.value)}
              />
            </td>
          </tr>
        </tbody>
      </table>  
    </div>
  )
}

export default ShipFrom