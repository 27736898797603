import { MetaData } from 'src/ui/MetaData'
import classes from './Charging.module.scss'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { IPostCharging } from 'src/api/postApproval/model'
import { getCharging, postCharging } from 'src/api/postApproval/postApproval'
import { IResponseError } from 'src/utils/errorMessage/model'
import { toast } from 'react-toastify'

const Charging = (): JSX.Element => {
  const [chargingInfo, setChargingInfo] = useState<IPostCharging>({} as IPostCharging)

  const getChargingData = async (): Promise<void> => {
    try {
      const res = await getCharging()
      setChargingInfo(res)
    } catch (error) {
      console.log(error)
    }   
  }

  useEffect(() => {
    getChargingData()
  },[])

  const postChargingData = async (): Promise<void> => {
    try {
      setChargingInfo(await postCharging(chargingInfo))
      toast.success('Charging update successfully')
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }
  const handleChangeData = (field: string, value: number): void => {
    setChargingInfo({ ...chargingInfo, [field]: value })
  }
  
  return (
    <div className={classes.charging}>
      <MetaData title='Charging'/>
      <div className={classes.charging_container}>
        <PageHeader pageName='Charging' pageWidth='100'/>
        <div className={classes.charging_container_inputBlock}>
          <label>
            Use credit card new 
            <input 
              type='checkbox'
              name='methodId'
              checked={Boolean(chargingInfo.methodId)}
              onChange={e => handleChangeData(e.target.name, Number(e.target.checked))}
            />
          </label>
          <label>
            Post Charge Processing On
            <input 
              type='checkbox'
              name='postCharge'
              checked={Boolean(chargingInfo.postCharge)}
              onChange={e => handleChangeData(e.target.name, Number(e.target.checked))}
            />
          </label>
          <button onClick={postChargingData}>
            <FontAwesomeIcon icon={faFloppyDisk} />
             Save
          </button>
        </div>
      </div>
    </div>
  )
}

export default Charging
