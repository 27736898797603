import { IRep } from '../Preflight/orderList/OrderListNote/model'
import { api, apiWithoutToken } from '../api'
import { ILoginResponseData, ISignInData, IUserData } from './model'

export const signIn = (loginData: ISignInData): Promise<ILoginResponseData> =>
  apiWithoutToken
    .post('/login', loginData, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => {
      return res.data
    })

export const getMe = (): Promise<IUserData> => api.get('/users/me').then(res => res.data)

export const getRepps = (): Promise<IRep[]> => api.get('/users/getRepps').then(res => res.data)

export const putLoginAsUser = (username: string): Promise<{accessToken: string}> => 
  api.put('/users/loginAsUser',{ username }).then(res => res.data)