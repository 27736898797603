import { makeAutoObservable, toJS } from 'mobx'

class ShippmentIdListStore {
  shippmentIdList?: number[]

  constructor () {
    makeAutoObservable(this)
  }

  set shippmentId (info: number[] | undefined) {
    this.shippmentIdList = info
  }

  
  get shippmentId (): number[] | undefined {
    return toJS(this.shippmentIdList)
  }

  addShippmentIds (info: number[]): void {
    this.shippmentIdList = info
  }

}

export const shippmentIdListStore = new ShippmentIdListStore()