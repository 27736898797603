import { useEffect, useState } from 'react'
import classes from './PrintDriversRunSheet.module.scss'
import { IPrintDriversRunSheet } from 'src/api/postApproval/shipping/model'
import { postPrintDriversRunSheet } from 'src/api/postApproval/shipping/shipping'
import { observer } from 'mobx-react-lite'
import { shippmentIdListStore } from 'src/stores/shippmentIdListStore'
import { dateConvertUsFormat } from 'src/utils/dateConvertToString'
const PrintDriversRunSheet = (): JSX.Element => {
  const shipmentIds = shippmentIdListStore.shippmentId
  const [printDriversRunSheetInfo, setPrintDriversRunSheetInfo] = useState<IPrintDriversRunSheet[]>([])
 
  const printDriversRunSheetData = async (shipmentIds: number[]): Promise<void> => {
    try {
      setPrintDriversRunSheetInfo( await postPrintDriversRunSheet(shipmentIds))
    } catch (error) {
      console.log(error)
        
    }
  }

  useEffect(() => {
    if (shipmentIds?.length) {
      printDriversRunSheetData(shipmentIds)
    }
  },[shipmentIds])

  return (
    <div className={classes.printDriversRunSheet}>
      <div className={classes.printDriversRunSheet_container}>
        <div>
          <div>
            <h3>{'Driver\'s Run Sheet'}</h3>
            <p>{dateConvertUsFormat(new Date())}</p>
          </div>
          <table>
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Name</th>
                <th>Address</th>
                <th>Tel Number</th>
                <th>Order Number</th>
                <th># of Boxes</th>
                <th>Signature</th>
              </tr>
            </thead>
            <tbody>
              {
                printDriversRunSheetInfo.length ? printDriversRunSheetInfo.map((e, index) => (
                  <tr key={index}>
                    <td>{e.company_name}</td>
                    <td>{e.recipient_first_name} {e.recipient_last_name}</td>
                    <td>{e.address1} {e.city} {e.state}, {e.zip_code}</td>
                    <td>{e.phone_number}</td>
                    <td>{e.order_id}</td>
                    <td>{e.boxes}</td>
                    <td></td>
                  </tr>
                )) : null
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
export default observer(PrintDriversRunSheet)