import { makeAutoObservable, toJS } from 'mobx'
import { getMe } from '../api/auth/auth'
import { IUserData } from '../api/auth/model'
import { setLocalStorage } from '../utils/storageFunction'

class UserStore {
  userData?: IUserData

  constructor () {
    makeAutoObservable(this)
  }

  set user (info: IUserData | undefined) {
    this.userData = info
  }

  get user (): IUserData | undefined {
    return toJS(this.userData)
  }

  async setUser (): Promise<void> {
    try {
      const res = await getMe()
      setLocalStorage('userData', JSON.stringify(res))
      this.userData = res
    } catch (err) {
      console.log(err)
      if (!window.location.href.includes('signIn')) {
        window.location.assign('/signIn')
      }
    }
  }
}

export const userStore = new UserStore()