import { FC, memo, useEffect, useRef, useState } from 'react'
import { IIPCMailingOptionsProps } from 'src/Components/IPC/IPC.model'
import classes from './MailingOptions.module.scss'

const IPCMailingOptions: FC<IIPCMailingOptionsProps> = memo(
  ({
    mailingOptions,
    changeIPCPayloadUpdate,
    payloadUpdatesQuantity,
    payloadUpdatesMailingQuantity,
    defaultMailingOptions
  }): JSX.Element => {
    const [validationMessage, setValidationMessage] = useState('')
    const [remainderValidationMessage, setRemainderValidationMessage] = useState('')
    const selectRemainderRef = useRef<HTMLSelectElement>(null)
    const mailingQuantityRef = useRef<HTMLInputElement>(null)
    const handleChangeMailingQuantity = (value: string): void => {
      const currentValue = Number(value)
      if (currentValue < 500) {
        return setValidationMessage('Direct Mailing is only available for 500+ orders.')
      }
      if (currentValue > payloadUpdatesQuantity) {
        return setValidationMessage('You can\'t mail more items than you\'re printing.')
      }
      setValidationMessage('')
      changeIPCPayloadUpdate('defaultMailingOptions', currentValue, 'mailingQuantity')
    }
	 const handleRemainderChange = (value: number): void => {
      if (payloadUpdatesMailingQuantity === payloadUpdatesQuantity && value === 1) {
        setRemainderValidationMessage(
          'You can\'t have remainders shipped to you if you choose to mail them all',
        )
      } else {
        setRemainderValidationMessage('')
        changeIPCPayloadUpdate('defaultMailingOptions', value, 'remainders')
      }
    }

    //  ??useEffect(() => {
    //    if (validationMessage.length || remainderValidationMessage.length) {
    //      dispatch(setAddToCardButtonStatus(true))
    //    } else {
    //      dispatch(setAddToCardButtonStatus(false))
    //    }
    //  }, [validationMessage, remainderValidationMessage])

    useEffect(() => {
      handleRemainderChange(Number(selectRemainderRef?.current?.value))
      handleChangeMailingQuantity(String(mailingQuantityRef?.current?.value))
    }, [payloadUpdatesMailingQuantity, payloadUpdatesQuantity])

    // !!  const [isOpen, setOpen] = useState(false)
    //  const [isOpenPostage, setOpenPostage] = useState<boolean>(false)

    //  useEffect(() => {
    //    const handleScroll = (): void => {
    //      setOpen(false)
    //      setOpenPostage(false)
    //    }

    //    window.addEventListener('scroll', handleScroll)

    //    return () => {
    //      window.removeEventListener('scroll', handleScroll)
    //    }
    //  }, [])

    return (
      <div className={classes.IPCMailingOptions}>
        <h2>Mailing Options</h2>
        <section className={classes.IPCMailingOptions_details}>
          <div className={classes.IPCMailingOptions_details_mailingQty}>
            <label htmlFor='mailingQty'>
              <span>Mailing Quantity</span>
              <input
                type='number'
                name='mailingQty'
                defaultValue={mailingOptions.mailingQuantity}
                onBlur={(evt) => handleChangeMailingQuantity(evt.target.value)}
                max={payloadUpdatesQuantity}
                min={500}
                step={1}
                ref={mailingQuantityRef}
              />
            </label>
            {validationMessage && <p>{validationMessage}</p>}
          </div>
          <div className={classes.IPCMailingOptions_details_row}>
              Service Fee
            <span>${mailingOptions.serviceFee}</span>
          </div>
          <p className={classes.IPCMailingOptions_details_text}>*The service fee includes maillist processing and items cpreparation as well as wafer sealing if required.</p>
          <label htmlFor='postage' className={classes.IPCMailingOptions_details_postage}>
            <span>Postage</span>
            <select
              name='postage'
              onChange={
                (e) => {
					  changeIPCPayloadUpdate('defaultMailingOptions', Number(e.target[e.target.selectedIndex].id), 'postageId')
                }
				 }
              defaultValue={String(defaultMailingOptions?.postageId)}
            >
              {mailingOptions.postage.map(({ id, customer_label: customerLabel }, i) => (
                <option key={i} value={id} id={`${id}`}>
                  {customerLabel}
                </option>
              ))}
            </select>
          </label>

          <label htmlFor='' className={classes.IPCMailingOptions_details_row}>
            Postage Cost
            <span>${mailingOptions.postageCost}</span>
          </label>
          <div className={classes.IPCMailingOptions_details_reminders}>
            <label htmlFor='remainders'>
              Remainders
              <select
                name='remainders'
                onChange={(e) => handleRemainderChange(Number(e.target.value))}
                ref={selectRemainderRef}
                defaultValue={String(defaultMailingOptions?.remainders)}
              >
                <option value='0'>Discard them</option>
                <option value='1'>Ship them to me</option>
              </select>
            </label>
            {remainderValidationMessage && <p>{remainderValidationMessage}</p>}
          </div>
          <label htmlFor='' className={classes.IPCMailingOptions_details_row}>
            Processing Time
            <span>{mailingOptions.processingTime}</span>
          </label>

          <label htmlFor='' className={classes.IPCMailingOptions_details_row}>
            Mailing Total
            <span>${mailingOptions.mailingTotal}</span>
          </label>
        </section>
      </div>
    )
  },
)

export default IPCMailingOptions
