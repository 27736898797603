import { FC, useEffect, useState } from 'react'
import classes from './LeftBlock.module.scss'
import { IAttribute, ICustomer, IGetCustomOrderCreate, IProductGroupData } from 'src/api/createCustomOrder/model'
import { getCustomOrderCreate, getProductAttributes } from 'src/api/createCustomOrder/createCustomOrder'
import { IParams } from 'src/api/Preflight/orderList/OrderListNote/model'
import { getCustomOrders } from 'src/api/addOrder/addOrder'
import CustomAttributes from './CustomAttributes/CustomAttributes'
import { ILeftBlockProps } from '../../model'
import Repprint from './Repprint/Repprint'
 
const LeftBlock: FC<ILeftBlockProps> = ({
  errorData,
  addToCardData,
  setAddToCardData,
  AddToCard,
  handleValideCase,
  handleChangeBackData,
  customerId,
  setJob }): JSX.Element => {
  const [productAttributesInfo, setProductAttributesInfo] = useState<IProductGroupData[]>([])
  const [jobAtributes, setJobAtributes] = useState<IAttribute[]>([])
  const [customerInfo,setCustomerInfo] = useState<ICustomer[]>([])
  const [customOrderInfo, setCustomOrderInfo] = useState<IGetCustomOrderCreate>({
    attributes: [],
    customPapers: [],
    customProducts: [],
    customAttributes: []
  } as IGetCustomOrderCreate)

  const getCustomData = async (): Promise<void> => {
    try {
      setCustomOrderInfo(await getCustomOrderCreate())   
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getCustomData()
  },[])

  const customerOrder = async (value: string): Promise<void> => {
    if (!value.length) {
      setCustomerInfo([])
      return
    }
  
    try {
      setCustomerInfo(await getCustomOrders({ match: value }))
    } catch (error) {
      console.error('Error fetching customer orders:', error)
    }
  }
  
  const productAttributes = async (productGroupId: string): Promise<void> => {
    try {
      const res = await getProductAttributes(productGroupId)
      setJobAtributes([])
      setProductAttributesInfo(res)
      if (res.length) {
        const newAtributeArray: IParams = {} as IParams
        const newSendBackendData: IParams = {} as IParams
        res.forEach(element => {
          if (element.attribute?.attAttributeOptions && element.attribute.attAttributeOptions.length > 0) {
            const option = element.attribute.attAttributeOptions[0].internalLabel
            newAtributeArray[element.attribute.publicLabel] = option
            newSendBackendData[element.attributeId] = String(element.attribute.attAttributeOptions[0].attributeOptionId)
          }
        })
        for (const key in newAtributeArray) {
          if (Object.prototype.hasOwnProperty.call(newAtributeArray, key)) {
            handleChangeBackData('attributes', newSendBackendData)
          }
        }
        for (const key in newAtributeArray) {
          if (Object.prototype.hasOwnProperty.call(newAtributeArray, key)) {
            setAddToCardData({ ...addToCardData, ...newAtributeArray })
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  

  const handleChangeAddToCard = (field: string, value: string): void => {
    if (field in errorData) {
      handleValideCase(field)
    }
    if (field.includes('Price')) {
      setAddToCardData({ ...addToCardData, Total:value, SubTotal:value })
    } else {
      setAddToCardData({ ...addToCardData, [field]:value })
    }
  } 

  const handleChangeCustomer = (value: string, customerName?: string): void => {
    const fullName = customerName ?? customerInfo.find(e => e.customerId === Number(value))?.user.fullName
    
    if (fullName) {
      handleChangeAddToCard('Customer',fullName)
    }
    handleChangeBackData('customerId',value)
    
  }

  return (
    <div className={classes.leftBlock}>
      <Repprint
        setAddToCardData={setAddToCardData}
        setJobAtributes={setJobAtributes}
        handleChangeBackData={handleChangeBackData}
        setProductAttributesInfo={setProductAttributesInfo}
        setCustomerInfo={setCustomerInfo}
        handleChangeCustomer={handleChangeCustomer}
        setJob={setJob}
      />
      <div className={classes.leftBlock_selectProduct}>
        <select
          onChange={e => {
            productAttributes(e.target.value)
            handleChangeBackData('jobs,productGroupId',e.target.value)
            handleValideCase('productGroupId')
          }}
        >
          <option value=''>Select a Product</option>
          {
            customOrderInfo.customProducts.length ? customOrderInfo.customProducts.map(e => (
              <option key={e.productGroupId} value={e.productGroupId}>{e.name}</option>
            )) : null
          }
        </select>
        {errorData.productGroupId?.length ? <p>{errorData.productGroupId}</p> : null}
      </div>
      <div className={classes.leftBlock_items}>
        <div className={classes.leftBlock_items_customer}>
          <label>Customer:</label>
          <input
            placeholder='start typing...'
            onChange={e => {
              setCustomerInfo([])
              customerOrder(e.target.value)
            }}
          />
          <select
            value={customerId || ''}
            name='Customer'
            onChange={e => 
              handleChangeCustomer(e.target.value)
            }
          >
            <option selected value=''>Select customer</option>
            {
              customerInfo?.length ? customerInfo.map((e, index) => (
                <option key={index} value={e.customerId}>{e.user.fullName + ' <' + e.user.email + '>'}</option>
              )) : null
            }
          </select>
          {errorData.Customer?.length ? <p>{errorData.Customer}</p> : null}
        </div>
        <div className={classes.leftBlock_items_item}>
          <label>Height:</label>
          <input
            type='text'
            name='Height'
            defaultValue={addToCardData.Height}
            id=''
            onChange={e => {
              handleChangeAddToCard(e.target.name,e.target.value)
              handleChangeBackData('jobs,height',e.target.value)
            }}
          />
          {errorData.Height?.length ? <p>{errorData.Height}</p> : null}
        </div>
        <div className={classes.leftBlock_items_item}>
          <label>Width:</label>
          <input
            type='text'
            name='Width'
            defaultValue={addToCardData.Width}
            id='' 
            onChange={e => {
              handleChangeAddToCard(e.target.name,e.target.value)
              handleChangeBackData('jobs,width',e.target.value)
            }}
          />
          {errorData.Width?.length ? <p>{errorData.Width}</p> : null}
        </div>
        <div className={classes.leftBlock_items_item}>
          <label>Shape:</label>
          <input
            type='text'
            name='Shape'
            defaultValue={addToCardData.Shape}
            id=''
            onChange={e => {
              handleChangeAddToCard(e.target.name,e.target.value)
              handleChangeBackData('jobs,shape',e.target.value)
            }}
          />
        </div>
        <div className={classes.leftBlock_items_item}>
          <label>Quantity:</label>
          <input
            type='number'
            name='Quantity'
            defaultValue={addToCardData.Quantity}
            id=''
            onChange={e => {
              handleChangeAddToCard(e.target.name,e.target.value)
              handleChangeBackData('jobs,quantity',e.target.value)
            }}
          />
          {errorData.Quantity?.length ? <p>{errorData.Quantity}</p> : null}
        </div>
        <div className={classes.leftBlock_items_item}>
          <label>Turnaround:</label>
          <select id='job_turnaround' name='Turnaround'
            value={addToCardData.Turnaround}
            onChange={e => {
              handleChangeAddToCard(e.target.name,e.target.value)
              handleChangeBackData('jobs,turnaround',e.target.value)
            }}
          >
            {
              [...new Array(16)].map((_,index) => (
                <option key={index} value={index}>{index} Days</option>
              ))
            }
           
          </select>
        </div>
        <CustomAttributes
          customAttributes={customOrderInfo.customAttributes}
          productAttributesInfo={productAttributesInfo}
          jobAtributes={jobAtributes}
          handleChangeAddToCard={handleChangeAddToCard}
          handleChangeBackData={handleChangeBackData}
        />
      </div>
      <div className={classes.leftBlock_items_item}>
        <label>Price:</label>
        <input
          type='text'
          name='job,Price'
          id=''
          defaultValue={addToCardData.Total}
          placeholder='start typing...'
          onChange={e => {
            handleChangeAddToCard(e.target.name,e.target.value)
            handleChangeBackData(e.target.name,e.target.value)
            handleValideCase('Total')
          }}
        />
        {errorData.Total?.length ? <p>{errorData.Total}</p> : null}

      </div>
      <div className={classes.leftBlock_items_item}>
        <button onClick={() => 
          AddToCard(
            customerInfo.find(e => e.user.fullName === addToCardData?.Customer)?.customerId
          )}>Add To Cart</button>
      </div>
    </div>
  )
}
export default LeftBlock