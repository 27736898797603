export const floatNumberCut = (floatNumber: string, cutNumber: number): string => {
  
  if (floatNumber && !floatNumber?.includes('.')) return `${floatNumber}.00`

  if (typeof floatNumber === 'string' && floatNumber.includes('.')) {
    const [integerPart, decimalPart] = floatNumber.split('.')
      
    if (decimalPart.length > 2 && cutNumber > 0) {
      const truncatedDecimal = decimalPart.slice(0, cutNumber)
      return `${integerPart}.${truncatedDecimal}`
    } else if (decimalPart.length > 2 && cutNumber === 0) {
      return `${integerPart}`
    }
  }
  return floatNumber
}
