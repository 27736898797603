import { api } from 'src/api/api'
import { ICoatingOption, IEditGang, IGetStripper, ISaveGangRes, ISaveGangSendData, ISendStrippingFinishData, IStriperGangJob, IStripperJob } from './model'
import { IStripperSingleGang } from '../gangs/model'

export const getStripper = (): Promise<IGetStripper> => 
  api.get('/postapproval/stripper').then(res => res.data)

export const getStripperGang = (gangId: number): Promise<IStripperSingleGang> =>
  api.get(`/postapproval/stripper/gang/${gangId}`).then(res => res.data[0])

export const getStriperGangJob = (gangId: number): Promise<IStriperGangJob[]> =>
  api.get(`/postapproval/stripper/gangJobDetails/${gangId}`).then(res => res.data)

export const postStartStripping = (gangId: number): Promise<string> => 
  api.post('/postapproval/stripper/startStripping',{ gangId }).then(res => res.data.message)

export const postSaveGang = (data: ISaveGangSendData): Promise<ISaveGangRes> =>
  api.post('/postapproval/stripper/saveGang', data).then(res => res.data)

export const deleteGang = (gangId: number): Promise<void> =>
  api.delete('/postapproval/stripper/deleteGang', { data:{ gangId } }).then(res => res.data)

export const deleteJob = (jobId: number): Promise<IStripperJob[]> =>
  api.delete('/postapproval/stripper/deleteGangJob', { data:{ jobId } }).then(res => res.data)

export const getCoatingData = (): Promise<ICoatingOption[]> =>
  api.get('/postapproval/stripper/coating').then(res => res.data)

export const postFinishStripper = (data: ISendStrippingFinishData): Promise<void> => 
  api.post('/postapproval/stripper/finishStripping', data).then(res => res.data)

export const getImportGangCSV = (gangId: number): Promise<string> => 
  api.get(`/postapproval/stripper/importCsv/${gangId}`).then(res => res.data)

export const editGang = (data: IEditGang): Promise<ISaveGangRes> => 
  api.post('/postapproval/stripper/editGang', data).then(res => res.data)

export const postJobAlreadyInGang = (jobId: number): Promise<{isJobAlreadyInGang: boolean}> => 
  api.post('/postapproval/stripper/isJobAlreadyInGang',{ jobId }).then(res => res.data)