import { IFilterData } from './model'

export const filterData: IFilterData = {
  myOrder: [
    {
      id: 1,
      label: 'Search',
      name: 'searchFor',
      type: 'text',
    },
    {
      id: 2,
      label: 'Stage',
      name: 'stage',
      type: 'select'
    },
    {
      id: 3,
      label: 'Status',
      name: 'state',
      type: 'select'
    },
  ],
  coupons:[
    {
      id: 1,
      label: 'Search',
      name: 'search',
      type: 'text',
    },
    {
      id: 2,
      label: 'Discount',
      name: 'discount',
      type: 'number',
    },
    {
      id: 2,
      label: 'End Date',
      name: 'endDate',
      type: 'date',
    },
  ],
  customer:[
    {
      id: 1,
      label: 'Search',
      name: 'search',
      type: 'text',
    }
  ],
  gangs:[
    {
      id: 1,
      label: 'Search',
      name: 'gangId',
      type: 'text',
    }
  ]
}