import { groupBy, mapValues, omit } from 'lodash'
import { FC } from 'react'
import { IAttributeGroupingProps } from 'src/Components/IPC/IPC.model'
import IPCSelectAttributeItem from './IPCSeectAttributeItem'

const AttributeGrouping: FC<IAttributeGroupingProps> = ({
  attributeLabel,
  attributeOption
}): JSX.Element => {
  const groupedAttributes = mapValues(groupBy(attributeOption, 'subgroup_label'), (groupLabels) =>
    groupLabels.map((groupLabel) => omit(groupLabel, 'groupLabel')),
  )
  const { 'no-group': noGroup, ...rest } = groupedAttributes
  const noGroupContent =
    noGroup?.map((el, index) => {
      return (
        <IPCSelectAttributeItem
          attributeLabel={attributeLabel}
          optionLabel={el.option_label}
          attributeOptionId={el.attribute_option_id}
          key={`${index}${attributeLabel}${el.attribute_option_id}`}
          optionOrder={el.option_order}
        />
      )
    },
    ) ?? []

  const groupedContent =
    Object.entries(rest).map(([key, value], index) => {
      return (
        <optgroup key={index} label={key}>
          {Array.isArray(value) && value?.map((el, i) => {
            return (
              <IPCSelectAttributeItem
                attributeLabel={attributeLabel}
                optionLabel={el.option_label}
                attributeOptionId={el.attribute_option_id}
                key={`${i}${attributeLabel}${el.attribute_option_id}`}
                optionOrder={el.option_order}
              />
            )
          },
          )}
        </optgroup>
      )
    }) ?? []

  return <>{[...noGroupContent, ...groupedContent]}</>
}
export default AttributeGrouping
