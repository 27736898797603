import { faFlag, faGear, faHouse, faSignsPost, faUsers } from '@fortawesome/free-solid-svg-icons'
import { ISideMenu } from '../model'

export const sideMenu: ISideMenu = {
  title: 'Workflow',
  items: [
    {
      name: 'Preflight',
      path: '',
      icon: faHouse, 
      children: [
        {
          name: 'My Orders',
          path: '/',
        },
        {
          name: 'Dashboard',
          path: '/dashboard',
        },
        {
          name: 'Custom Orders',
          path: '/custom-orders',
        },
        {
          name: 'QR Exceptions',
          path: '/exceptions',
        },
        {
          name: 'HOT JOBS',
          path: '/hot-jobs',
        },
        {
          name: 'Can Online',
          path: '/cancelled_online',
        }
      ] 
    },
    {
      name: 'Post Approval',
      path: '',
      icon: faSignsPost, 
      children: [
        {
          name: 'Stripper',
          path: '/stripper',
        },
        {
          name: 'Outsourced',
          path: '/outsourced',
        },
        {
          name: 'LFP',
          path: '/lfp',
        },
        {
          name: 'OKI',
          path: '/oki',
        },
        {
          name: 'Gangs',
          path: '/gangs',
        },
        {
          name: 'Pressman',
          path: '/pressman',
        },
        {
          name: 'Coating',
          path: '/coating',
        },
        {
          name: 'Lamination',
          path: '/lamination',
        },
        {
          name: 'Cutter',
          path: '/cutter',
        },
        {
          name: 'Die Cutting',
          path: '/die-cutting',
        },
        {
          name: 'Bindery',
          path: '/bindery',
        },
        {
          name: 'Boxing',
          path: '/boxing',
        },
        {
          name: 'Boxing FBC',
          path: '/fbc',
        },
        {
          name: 'Shipping',
          path: '/shipping',
        },
        {
          name: 'Charging',
          path: '/charging',
        }
      ] 
    },
    {
      name: 'Settings',
      path: '',
      icon: faGear, 
      children: [
        {
          name: 'Workflow',
          path: '/workflowSettings',
        },
        {
          name: 'Canned Messages',
          path: '/cannedMessages',
        },
        {
          name: 'Estate Email',
          path: '/estateEmail',
        },
        {
          name:'QB Backup',
          path:'/qbBackup',
        },
        {
          name:'Email Banners',
          path:'/emailBanners',
        }
      ] 
    },
    {
      name: 'Customers',
      path: '/customers',
      icon: faUsers   
    },
    {
      name: 'Coupons',
      path: '/coupons',
      icon: faUsers   
    },
    // {
    //   name: 'Template Manager',
    //   path: '/card-builder',
    //   icon: faGopuram, 
    //   children: [
    //     {
    //       name: 'Card Builder',
    //       path: '/card-builder',
    //     },
    //     {
    //       name: 'Design Template',
    //       path: '/design-template',
    //     },
    //   ] 
    // },
    {
      name: 'Reports',
      path: '',
      icon: faFlag, 
      children: [
        {
          name: 'Checkout Report',
          path: '/checkout',
        },
        {
          name: 'Not Approved Orders Report',
          path: '/approved',
        },
        {
          name: 'Orders Reports',
          path: '/orders-reports',
        },
        {
          name: 'Clients Reports',
          path: '/clients-reports',
        },
        {
          name: 'Orders Ready to approve',
          path: '/ready-approved',
        },
        {
          name: 'Orders Summary',
          path: '/orders-summary',
        },
        {
          name: 'Approved Order',
          path: '/approved-order',
        },
      ] 
    },
  ]
}