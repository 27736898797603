import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { MetaData } from 'src/ui/MetaData'
import classes from './PressmanSingle.module.scss'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getGangJobsDetail, getPressmanById } from 'src/api/postApproval/gangs/gangs'
import { IPressmanSingleInfo } from '../../model'
import { dateConvertToStringToDate } from 'src/utils/dateConvertToString'
import Loading from 'src/Components/Loading/Loading'
import { IPressmanGangJobsDetail } from 'src/api/postApproval/gangs/model'
const PressmanSingle = (): JSX.Element => {
  const { id: gangId } = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [pressmanByIdInfo, setPressmanByIdInfo] = useState<IPressmanSingleInfo>({
    pressmanById:[],
    gangJobsDetail:[]
  })

  const byIdPressman = async (gangId: string): Promise<void> => {
    try {
      const res = await getPressmanById( gangId)
      setPressmanByIdInfo(prev => {
        return { ...prev, pressmanById: res }
      })
    } catch (error) {
      console.log(error)
      
    }
  }
  const byIdGangJobsDetail = async (gangId: string): Promise<void> => {
    try {
      const res = await getGangJobsDetail<IPressmanGangJobsDetail[]>('pressman',gangId)
      
      setPressmanByIdInfo(prev =>{ 
        return { ...prev, gangJobsDetail: res } 
      })
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const getData = async (): Promise<void> => {
    if (gangId) {
      await byIdPressman(gangId)
      await byIdGangJobsDetail(gangId)
    }
  }
  useEffect(() => {
    getData()
  },[gangId])

  return (
    <>
      {
        isLoading ? <Loading/> :
          <div className={classes.pressmanSingle}>
            <MetaData title='Pressman'/>
            <div className={classes.pressmanSingle_container}>
              <PageHeader pageName='Pressman' pageWidth='100'/>
              <div className={classes.pressmanSingle_container_tableBlock}>
                <div className={classes.pressmanSingle_container_tableBlock_header}>
                  <span>{pressmanByIdInfo.pressmanById[0]?.gang_id}</span>
                  <span>{pressmanByIdInfo.pressmanById[0]?.internal_name}</span>
                  <span>{pressmanByIdInfo.pressmanById[0]?.username}</span>
                  <span>{dateConvertToStringToDate(pressmanByIdInfo.pressmanById[0]?.timestamp)}</span>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>Job</th>
                      <th>Size</th>
                      <th>Desc</th>
                      <th>Due Date</th>
                      <th>Quantity</th>
                      <th>Proof</th>
                      <th>Shipping</th>
                      <th>Finish</th>
                      <th>FSC</th>
                      <th>Tumbnail</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      pressmanByIdInfo.gangJobsDetail.length ? pressmanByIdInfo.gangJobsDetail.map((job, index) => (
                        <tr key={index}>
                          <td>{job.Job}</td>
                          <td>{job.size}</td>
                          <td>{job.product_label}</td>
                          <td>{job.due_on}</td>
                          <td>{job.quantity}</td>
                          <td>{job.proof}</td>
                          <td>{job.shippingType}</td>
                          <td>{job.finish}</td>
                          <td>{job.is_fsc_certified}</td>
                          <td>
                            <div>
                              {
                                job.thumbnails.length ? job.thumbnails.map((thumbnail, index) => (
                                  <img key={index} src={thumbnail.url} alt={thumbnail.fof_uploaded_files_file_name}/>
                                )) : null
                              }
                            </div>
                          </td>
                        </tr>
                      )) : null
                    }
                  </tbody>
                </table>
              </div>
            </div>   
          </div>
      }
    </>
  )
}
export default PressmanSingle