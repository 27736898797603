import { FC } from 'react'
import classes from './UploadNewBanners.module.scss'
import { toast } from 'react-toastify'
import { IResponseError } from 'src/utils/errorMessage/model'
import { postEmailBanners } from 'src/api/settings/emailBanners/emailBanners'
import { formDataGeneration } from 'src/utils/formDataGeneration/formDataGeneration'
import { IGetEmailBanners } from 'src/api/settings/emailBanners/model'
const UploadNewBanners: FC<{
  setEmailBannersInfo: React.Dispatch<React.SetStateAction<IGetEmailBanners[]>>
}> = ({ setEmailBannersInfo }): JSX.Element => {
  
  const uploadNewBanners = async ( file: (globalThis.Blob | File)[]): Promise<void> => {
    try {
      const res = await postEmailBanners(formDataGeneration<{image: (Blob | File)[]}>({
        image: file ,
      }))
      setEmailBannersInfo(prev => {
        return [
          ...prev,
          ...res 
        ]  
      })
        
      toast.success('Email Banners was created')
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }
  const selectImages = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const images = Array.from(e?.target?.files ?? [])
    const imageUrls = images.map(img =>img)
    uploadNewBanners(imageUrls)
    
  }

  return (
    <div className={classes.uploadNewBanners}>
      <label>Upload new banners</label>
      <input type='file' name='image' id='images' multiple onChange={e => selectImages(e)}/>
    </div>
  )
}

export default UploadNewBanners