import { FC } from 'react'
import classes from '../Shipping.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { IShippingCheckedItems, IShippingHeaderProps, IShippingHeaderStaticData } from '../../model'
import { postExportUSPS, postFinishShippmentArr } from 'src/api/postApproval/shipping/shipping'
import { shippmentIdListStore } from 'src/stores/shippmentIdListStore'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { downloadCSV } from 'src/utils/downloadCSV'

const btnStaticData: IShippingHeaderStaticData[] = [
  {
    id: 1,
    title:'Ship Multiple USPS',
    permissionRequest: 'USPS',
    errorMessage: 'You need to select the USPS orders you want to ship'
  },
  {
    id: 2,
    title:'Pickup Multiple',
    permissionRequest: 'PICKUP',
    errorMessage: 'You need to select the PICKUP orders you want to ship'
  },
  {
    id: 3,
    title:'Ship Multiple',
    permissionRequest: 'UPS',
    errorMessage: 'You need to select the UPS orders you want to ship'
  },
  {
    id: 4,
    title:'Print UPS Sheet',
    permissionRequest: 'UPS',
    errorMessage: 'You need to select the orders you want to include in the UPS Print Sheet',
    navigateUrl: 'printMultipleShippments'
  },
  {
    id:5,
    title:'Print Driver\'s Run Sheet',
    permissionRequest: 'MESSENGER',
    errorMessage: 'You need to select the orders you want to include in the Drivers Run Sheet',
    navigateUrl: 'printDriversRunSheet'
  }
]
const ShippingHeader: FC<IShippingHeaderProps> = ({ filteration,checkedItems }): JSX.Element => {
  const navigate = useNavigate()
  const shipmentIds = shippmentIdListStore.shippmentId
  const finishShippmentArr = async (shippmentIdList: number[]): Promise<void> => {
    try {
      await postFinishShippmentArr(shippmentIdList)
    } catch (error) {
      console.log(error)
      
    }
  }

  const printUPSSheet = (shippmentIdList: number[], errorMessage: string, navigateUrl?: string): void => {
    if (shippmentIdList.length) {
      shippmentIdListStore.addShippmentIds(shippmentIdList)
      shipmentIds?.length && navigate('/workflow/postaproval/shipping/' + navigateUrl)
    } else {
      window.alert(errorMessage)
    }
  }
  
  const validateBtnRequest = (checkedItems: IShippingCheckedItems[], permissionRequest: string, errorMessage: string, id: number, navigateUrl?: string): void => {
    if (checkedItems?.length && checkedItems.every(e => e.shippingType === permissionRequest)) {  
      const shippmentIdList = [...checkedItems.map(e => e.shippmentId)]
      if (id === 4 || id === 5) {
        printUPSSheet(shippmentIdList, errorMessage, navigateUrl)
      } else {
        finishShippmentArr(shippmentIdList)
      }
    } else {
      window.alert(errorMessage)
    }
  }

  const exportUSPS = async (type: string): Promise<void> => {
    try {
      const isPermission = checkedItems?.length && checkedItems.every(e => e.shippingType === type)
      if (isPermission) {
        const res = await postExportUSPS([...checkedItems.map(e => e.shippmentId)],type )
        downloadCSV<{'': string}[]>([{
          '': res
        }], 'gang_data') 
      } else {
        window.alert(`You need to select the ${type} orders you want to export`)
      }
      
    } catch (error) {
      console.log(error)
      
    }
  }

  return (
    <div className={classes.shipping_container_header}>
      <div className={classes.shipping_container_header_leftBlock}>
        <label>Filter</label>
        <select
          name='shipping_type'
          id='shipping_type'
          onChange={e => filteration(e.target.value)}
        >
          <option value='All'>All</option>
          <option value='UPS'>UPS</option>
          <option value='MESSENGER'>MESSENGER</option>
          <option value='PICKUP'>PICKUP</option>
          <option value='FEDEX'>FEDEX</option>
          <option value='USPS'>USPS</option>
          <option value='DISCARD'>DISCARD</option>
        </select>
        <button onClick={() => exportUSPS('USPS')}>
          <FontAwesomeIcon icon={faPrint}/>
          Export USPS
        </button>
        <button onClick={() => exportUSPS('FEDEX')}>
          <FontAwesomeIcon icon={faPrint}/>
          Export for FEDEX
        </button>
      </div>
      <div className={classes.shipping_container_header_rightBlock}>
        {
          btnStaticData.map(e =>(
            <button key={e.id}
              onClick={() => validateBtnRequest(checkedItems, e.permissionRequest, e.errorMessage, e.id, e?.navigateUrl)}><FontAwesomeIcon icon={faPrint}/>{e.title}</button>
          ))
        }
      </div>
    </div>
  )
}

export default observer(ShippingHeader)