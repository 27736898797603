import { MetaData } from 'src/ui/MetaData'
import classes from './CutterSinglePage.module.scss'
import { FC, useEffect, useState } from 'react'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { ICoatingTiket } from 'src/api/postApproval/coating/model'
import { useParams } from 'react-router-dom'
import { getCutterGang, getCutterJobs } from 'src/api/postApproval/cutter/cutter'
import { IBinderyData } from 'src/api/postApproval/gangs/model'
import Loading from 'src/Components/Loading/Loading'
import CutterSinglePageHeader from './CutterSinglePageHeader/CutterSinglePageHeader'
const CutterSinglePage: FC<{pageName: string}> = ({ pageName }): JSX.Element => {
  const { id: gangId } = useParams()
  const [cutterGangInfo, setcutterGangInfo] = useState<ICoatingTiket>({} as ICoatingTiket)
  const [cutterJobsInfo, setCutterJobsInfo] = useState<IBinderyData[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const cutterGang = async (gangId: string): Promise<void> => {
    try {
      setcutterGangInfo( await getCutterGang(gangId))
    } catch (error) {
      console.log(error)
          
    }
  }

  const cutterJobs = async (gangId: string): Promise<void> => {
    try {
      setCutterJobsInfo( await getCutterJobs(gangId))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  
  useEffect(() => {
    if (gangId) {
      cutterGang(gangId)
      cutterJobs(gangId)
    }
  },[gangId, pageName])
  
  return (
    <>
      {
        isLoading ? <Loading/> :
          <div className={classes.cutterSinglePage}>
            <MetaData title={pageName}/>
            <div className={classes.cutterSinglePage_container}>
              <PageHeader pageName={pageName} pageWidth='100'/>
              <div className={classes.cutterSinglePage_container_tableBlock}>
                <CutterSinglePageHeader
                  pageName={pageName}
                  gangId={cutterGangInfo.gang_id}
                  internalName={cutterGangInfo.internal_name}
                  userName={cutterGangInfo.username}
                  timestamp={cutterGangInfo.timestamp}
                  jobs={cutterJobsInfo.map(e => e.Job)}
                  bindery={(cutterJobsInfo.map(e => e.bindery.length && e.bindery) || []).filter(Boolean) as string[]}
                />

                <table>
                  <thead>
                    <tr>
                      <th>Job</th>
                      <th>Quantity</th>
                      <th>Size</th>
                      <th>Due</th>
                      <th>Bindery</th>
                      <th>Lots</th>
                      <th>Shipping</th>
                      <th>Notes</th>
                      <th>Tumbnail</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      cutterJobsInfo.length ? cutterJobsInfo.map((e, index) => (
                        <tr key={index}>
                          <td>{e.Job}</td>
                          <td>{e.quantity}</td>
                          <td>{e.size}</td>
                          <td>{e.due_date}</td>
                          <td>{e.bindery}</td>
                          <td>{e?.lots}</td>
                          <td>{e.shippingType}</td>
                          <td>
                            {
                              e.notes.length ? e.notes.map((e, index) => (
                                <p key={index}>{e.note}</p>
                              )) : null
                            }
                          </td>
                          <td>
                            <div>
                              {
                                e.thumbnails.length ? e.thumbnails.map((e, index) => (
                                  <img key={index} src={e.url} alt={e.fof_uploaded_files_file_name}/>
                                )) : null
                              }
                            </div>
                          </td>
                        </tr>
                      )) : null
                    }
                  </tbody>
                </table>
              </div>
            </div>  
          </div>
      }
    </>
  )
}
export default CutterSinglePage