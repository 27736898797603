import { api } from 'src/api/api'
import { IPrintDriversRunSheet, IShipping } from './model'

export const getShipping = (): Promise<IShipping[]> => 
  api.get('/postapproval/shipping').then(res => res.data)
  
export const postFinishShippment = (shippmentId: number): Promise<void> => 
  api.post(`/postapproval/shipping/finishShippment/${shippmentId}`).then(res => res.data)

export const postFinishDirectMail = (shippmentId: number): Promise<void> =>
  api.post(`/postapproval/shipping/finishDirectMail/${shippmentId}`).then(res => res.data)

export const getPrintShipping = (shippmentId: string): Promise<IShipping[]> => 
  api.get(`/postapproval/shipping/printShippment/${shippmentId}`).then(res => res.data)

export const postFinishShippmentArr = (shippmentIdList: number[]): Promise<void> => 
  api.post('/postapproval/shipping/finishShippmentArr', { shippmentIdList }).then(res => res.data)

export const postPrintMultipleShippments = (shippmentIdList: number[]): Promise<IShipping[]> => 
  api.post('/postapproval/shipping/printMultipleShippments', { shippmentIdList }).then(res => res.data)

export const postPrintDriversRunSheet = (shippmentIdList: number[]): Promise<IPrintDriversRunSheet[]> =>
  api.post('/postapproval/shipping/printDriversRunSheet', { shippmentIdList }).then(res => res.data)

export const postExportUSPS = (shippmentIdList: number[], type: string): Promise<string> => 
  api.post(`/postapproval/shipping/export${type}`,{ shippmentIdList }).then(res => res.data)