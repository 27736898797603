import { MetaData } from 'src/ui/MetaData'
import classes from './CreateCustomOrder.module.scss'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import LeftBlock from './LeftBlock/LeftBlock'
import { Suspense, lazy, useState } from 'react'
import { IParams } from 'src/api/Preflight/orderList/OrderListNote/model'
import { getCustomerAddresses, getPaymentMethods, getShipmentMethods } from 'src/api/createCustomOrder/createCustomOrder'
import { IBillingBlockInfo } from '../model'
import { IJob, ISendAddCustomOrder, IShipmentMethodsData } from 'src/api/createCustomOrder/model'
import { filterByObjectKey } from 'src/utils/filteredObject'
import { IErrorMessage } from 'src/utils/errorMessage/model'
const BillingBlock = lazy(() => import ('./BillingBlock/BillingBlock')) 
const MiddleBlock = lazy(() => import ( './MiddleBlock/MiddleBlock'))

const requiredField: string[] = ['Customer','Height', 'Width', 'Quantity', 'Total']
const taxRate = 0.08875

const CreateCustomOrder = (): JSX.Element => {
  const [errorRequest, setErrorRequest] = useState<IErrorMessage>({})
  const [sendDataBack, setSendDataBack] = useState<ISendAddCustomOrder>({
    printingCost: 0.00,
    shippingCost: '0.00',
    taxesCost: 0.00,
    totalCost: 0.00
  } as ISendAddCustomOrder)
  const [job, setJob] = useState<IJob>({} as IJob)
  const [cardData, setCardData] = useState<IParams[]>([])
  const [addToCardData, setAddToCardData] = useState<IParams>({} as IParams)
  const [errorData, setErrorData] = useState<IParams>({} as IParams)
  const [billingBlockInfo, setBillingBlockInfo] = useState<IBillingBlockInfo>({
    customerAddresses:[],
    shipmentMethods:{},
    paymentMethods:[]
  })

  const validateData = (data: IParams, requiredFields: string[]): boolean => {
    let isValid = true
  
    requiredFields.forEach(field => {
      if (!(field in data) || data[field] === '') {
        setErrorData(prev => {
          return {
            ...prev,
            [field]:field + ' is required'
          }
        })
        isValid = false
      }
    })
  
    return isValid
  }
  
  const AddToCard = async (customerId: number | undefined): Promise<void> => { 
  
    try {

      if (validateData(addToCardData, requiredField) && customerId) {
        const shipmentMethodsData = await getShipmentMethods()
        setBillingBlockInfo({
          customerAddresses: await getCustomerAddresses(customerId),
          paymentMethods: await getPaymentMethods(),
          shipmentMethods: filterByObjectKey<IShipmentMethodsData>(shipmentMethodsData, 'groupLabel'),
        })
        setCardData([ ...cardData,addToCardData ])
        const additionalPrintingCost = Number(addToCardData.Total)
        const additionalTaxes = parseFloat(((additionalPrintingCost * taxRate + Number(sendDataBack.shippingCost) * taxRate)).toFixed(2))

  
        setSendDataBack(prev => ({
          ...prev,
          jobs: prev.jobs?.length ? [...prev.jobs, { ...job }] : [{ ...job }],
          printingCost: prev.printingCost + additionalPrintingCost,
          totalCost: prev.totalCost + additionalPrintingCost + additionalTaxes,
          taxesCost: prev.taxesCost + additionalTaxes
        }))
      }
    } catch (error) {
      console.log(error)
      
    }
  }

  const removeToCard = (removeItemIndex: number): void => {
    setSendDataBack(prev => {
      const updatedPrintingCost = Math.max(prev.printingCost - Number(cardData[removeItemIndex].Total), 0)
      const updatedTaxesCost = Math.round((updatedPrintingCost * taxRate + Number(prev.shippingCost) * taxRate) * 100) / 100
      const updatedTotalCost = Math.round((updatedPrintingCost + Number(prev.shippingCost) + updatedTaxesCost) * 100) / 100
  
      return {
        ...prev,
        jobs: prev.jobs.filter((_, index) => index !== removeItemIndex),
        printingCost: updatedPrintingCost,
        taxesCost: updatedTaxesCost,
        totalCost: updatedTotalCost,
      }
    })
  
    setCardData(cardData.filter((_, index) => index !== removeItemIndex))
  }

  const handleValideCase = (field: string): void => {
    setErrorData({ ...errorData, [field]:'' })
  }

  const handleChangeBackData = (field: string, value: string | number | boolean | IParams, index?: number): void => {
    
    if (field === 'attributes' && typeof value === 'object') {
      setJob(prev => {
        return {
          ...prev,
          attributes:{ ...prev.attributes,...value } 
        }
      })
      return
    }
    if (field.includes('attributes,')) {
      setJob(prev => {
        return {
          ...prev,
          attributes:{ ...prev.attributes,[field.split(',')[1]]: String(value) }
        }
      })
      return
    }
    if (typeof index === 'number') {
      if (field === 'files' && typeof value === 'object') {
        setSendDataBack(prev => {
          const updatedJobs = prev.jobs?.length ? [...prev.jobs ] : []
          updatedJobs.map((e, jobIndex) => {
            if (jobIndex === index) {
              e.files = e.files?.length ? [...e.files, value] : [{ ...value }]
            } 
            return e
          })
          return {
            ...prev,
            jobs:[...updatedJobs]
          }
        })
      } else {
        setSendDataBack(() => {
          const updatedJobs = sendDataBack.jobs 
          updatedJobs.forEach((e, jobIndex) => {
            if (jobIndex === index) {
              e[field] = value
            }
          })
          return {
            ...sendDataBack,
            jobs:[...updatedJobs]
          }
        })
      }
      return
    }
    if (field.includes('job')) {
      if (field.includes('Price')) {
        setJob({ ...job, total: String(value), subtotal: String(value) })
      } else {
        setJob({ ...job, [field.split(',')[1]]: value })
      }
    } else {
      setSendDataBack({ ...sendDataBack, [field]: value })
    }
  }

  const removeRequestError = (field: string): void => {
    setErrorRequest({ ...errorRequest, [field]: '' })
  }

  return (
    <div className={classes.createCustomOrder} >
      <MetaData title='Add Custom Order'/>
      <div className={classes.createCustomOrder_container}>
        <PageHeader pageName='Add Custom Order' pageWidth='100'/>
        <div className={classes.createCustomOrder_container_body}>
          <LeftBlock
            errorData={errorData}
            addToCardData={addToCardData}
            setAddToCardData={setAddToCardData}
            handleValideCase={handleValideCase}
            AddToCard={AddToCard}
            handleChangeBackData={handleChangeBackData}
            customerId={sendDataBack.customerId}
            setJob={setJob}
          />
          {
            cardData.length ?
              <Suspense fallback={null}>
                <MiddleBlock
                  cardData={cardData}
                  removeToCard={removeToCard}
                  handleChangeBackData={handleChangeBackData}
                />
              </Suspense> : null
          }
          {
            cardData.length ?
              <Suspense fallback={null}>
                <BillingBlock
                  billingBlockInfo={billingBlockInfo}
                  sendDataBack={sendDataBack}
                  errorRequest={errorRequest}
                  handleChangeBackData={handleChangeBackData}
                  setErrorRequest={setErrorRequest}
                  removeRequestError={removeRequestError}
                />
              </Suspense> : null
          }
        </div>
      </div>
    </div>
  )
}
export default CreateCustomOrder