import { FC, useEffect, useState } from 'react'
import classes from './CustomerCategory.module.scss'
import { ICustomerCategory } from '../model'
import { getAllCustomerCategory } from 'src/api/report/report'
const CustomerCategory: FC<{changeCustomerCategory: (include: string[], exclude: string[]) => void}> = ({ changeCustomerCategory }): JSX.Element => {
  const [reportsCustomerCategoryInfo, setReportsCustomerCategoryInfo] = useState<ICustomerCategory>({
    include:[],
    exclude:[]
  })
  const [filteredCustomerCategory, setFilteredCustomerCategory] = useState<string[]>([])

  const allCustomerCategory = async (): Promise<void> => {
    try {
      const res = await getAllCustomerCategory()
      res.forEach(e => {
        setReportsCustomerCategoryInfo(prev => {
          return {
            ...prev,
            include: [...prev.include, e.customer_category]
          }
        })
      })
    } catch (error) {
      console.log(error)
      
    }
  }

  useEffect(() => {
    allCustomerCategory()
  },[])

  useEffect(() => {
    changeCustomerCategory(reportsCustomerCategoryInfo.include, reportsCustomerCategoryInfo.exclude)
  },[reportsCustomerCategoryInfo])

  const customerCategoryIncludeOrExclude = ( field: string, value?: string[]): void => {
    setReportsCustomerCategoryInfo(prev => {
      let { include, exclude } = prev
    
      if (field === 'Include' && value) {
        include = [...include, ...value]
        exclude = exclude.filter(e => !value.includes(e))
      } 
      if (field === 'Exclude' && value) {
        exclude = [...exclude, ...value]
        include = include.filter(e => !value.includes(e))
      }
      if (field === 'Include all') {
        if (!include.length && exclude.length) {
          include = exclude
          exclude = []
        } else if (include.length && exclude.length) {
          include = [...include, ...exclude]
          exclude = []
        }
      }
      if (field === 'Exclude all') {
        if (include.length && !exclude.length) {
          exclude = include
          include = []
        } else if (include.length && exclude.length) {
          exclude = [...include, ...exclude]
          include = []
        }
      }
    
      return {
        include,
        exclude
      }
    })
    
    setFilteredCustomerCategory([])
  }
  const isActiveItem = (item: string): void => {
    if (!filteredCustomerCategory.includes(item)) {
      setFilteredCustomerCategory(prev => {
        return [
          ...prev, item
        ]
      })
    } else {
      setFilteredCustomerCategory(filteredCustomerCategory.filter(e => e !== item))
    }
  }
  return (
    <div className={classes.customerCategory}>
      <div className={classes.customerCategory_textBlock}>
        {
          reportsCustomerCategoryInfo.include.length ? reportsCustomerCategoryInfo.include.map((e, index) => (
            <p key={index} className={filteredCustomerCategory.find(category => category === e) && classes.customerCategory_textBlock_activeItem}
              onClick={() => isActiveItem(e)}
            >{e}</p>
          )) : null
        }
      </div>
      <div className={classes.customerCategory_btnBlock}>
        <button onClick={() => customerCategoryIncludeOrExclude('Exclude all')}> 
          {'>>'} Exclude all {'>>'}
        </button>
        <button
          onClick={() => customerCategoryIncludeOrExclude('Exclude', filteredCustomerCategory)}
        > {'>>'} Exclude {'>>'} 
        </button>
        <button
          onClick={() => customerCategoryIncludeOrExclude('Include', filteredCustomerCategory )}
        >
          {'<<'} Include {'<<'}
        </button>
        <button onClick={() => customerCategoryIncludeOrExclude('Include all')}> {'<<'} Include all {'<<'} </button>
      </div>
      <div className={classes.customerCategory_textBlock}>
        {
          reportsCustomerCategoryInfo.exclude.length ? reportsCustomerCategoryInfo.exclude.map((e, index) => (
            <p key={index} className={filteredCustomerCategory.find(category => category === e) && classes.customerCategory_textBlock_activeItem}
              onClick={() => isActiveItem(e)}
            >{e}</p>
          )) : null
        }
      </div>
    </div>
  )
}
export default CustomerCategory