import { MetaData } from 'src/ui/MetaData'
import classes from './CustomOrders.module.scss'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { useEffect, useState } from 'react'
import { ICustomOrdersEmails, ISendInvoice } from 'src/api/Preflight/customOrders/model'
import { getCustomOrders, putUpdateInvoice } from 'src/api/Preflight/customOrders/customOrders'
import { toast } from 'react-toastify'
const CustomOrders = (): JSX.Element => {
  const [peopleIndex, setPeopleIndex] = useState<number>(0)
  const [customOrdersInfo, setCustomOrdersInfo] = useState<ICustomOrdersEmails>({
    peoples:[],
    invoiceList:[],
    stateList:[]
  } as ICustomOrdersEmails)

  const getCustomOrdersData = async (): Promise<void> => {
    try {
      setCustomOrdersInfo( await getCustomOrders() )
    } catch (error) {
      console.log(error)
        
    }
  }
  useEffect(() => {
    getCustomOrdersData()
  },[])
  
  const updateInvoice = async (data: ISendInvoice): Promise<void> => {
    try {
      await putUpdateInvoice(data)
      toast.success('Invoice updated success')
    } catch (error) {
      console.log(error)
      toast.success('Something went wrong')
    }
  }

  return (
    <div className={classes.customOrders}>
      <MetaData title='Custom Orders Emails'/>
      <div className={classes.customOrders_container}>
        <PageHeader pageName='Custom Orders Emails' pageWidth='100'/>
        <div className={classes.customOrders_container_firstBlock}>
          {
            customOrdersInfo.peoples.length ? customOrdersInfo.peoples.map((people, index) => (
              <span onClick={() => setPeopleIndex(index)} key={people.peopleId}>{people.displayName}</span>
            )) : null
          }
        </div>
        <div className={classes.customOrders_container_secondBlock}>
          <p>{customOrdersInfo.peoples[peopleIndex]?.displayName}</p>
          <table className={classes.customOrders_container_table}>
            <thead>
              <tr>
                <td>Order No.</td>
                <td>Job No.</td>
                <td>Date</td>
                <td>Subject Header</td>
                <td>Project Notes</td>
                <td>Confirm</td>
                <td>Discard</td>
                <td>Done?</td>
                <td>Invoice Status</td>
                <td>Production Status</td>
                <td>Production Notes</td>
                <td>Expand	Done?</td>
              </tr>
            </thead>
            <tbody>
              {
                customOrdersInfo.peoples[peopleIndex]?.emails?.length ? customOrdersInfo.peoples[peopleIndex].emails.map((email, index) => (
                  <tr key={index} className={index % 2 === 0 ? classes.customOrders_container_couple : classes.customOrders_container_odd }>
                    <td><input/></td>
                    <td></td>
                    <td></td>
                    <td>{email.subject}</td>
                    <td></td>
                    <td>{email.confirmed}</td>
                    <td>{email.enabled}</td>
                    <td><button>Done</button></td>
                    <td>
                      <select
                        onChange={e => updateInvoice({ emailId:email.emailId, invoiceStatusId: Number(e.target.value) })}
                      >
                        <option value='' disabled selected>select...</option>
                        {
                          customOrdersInfo.invoiceList?.length ? customOrdersInfo.invoiceList.map(e => (
                            <option key={e.invoiceStatusId} value={e.invoiceStatusId}>{e.invoiceStatus}</option>
                          )) : null
                        }
                      </select>
                    </td>
                    <td>
                      <select>
                        <option value='' disabled selected>select...</option>
                        {
                          customOrdersInfo.stateList?.length ? customOrdersInfo.stateList.map(e => (
                            <option key={e.label_id} value={e.label_id}>{e.parent_name} {e.name}</option>
                          )) : null
                        }
                      </select>
                    </td>
                    <td></td>
                    <td><button>Done</button></td>
                  </tr>
                )) : null
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
export default CustomOrders