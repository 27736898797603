import Modal from 'src/ui/modal/Modal'
import classes from './ExportQr.module.scss'
import { FC, useEffect, useState } from 'react'
import { IExportQrData } from 'src/api/Preflight/orderList/model'
import { getExportQr, postBeginTrnQbExport } from 'src/api/Preflight/orderList/orderList'
import { toast } from 'react-toastify'
const ExportQr: FC<{
    jobId: string
    closeModal: () => void
}> = ({ jobId,closeModal }): JSX.Element => {
  const [exportQrInfo, setExportQrInfo] = useState<IExportQrData>({} as IExportQrData)

  const exportData = async (jobId: string): Promise<void> => {
    try {
      setExportQrInfo(await getExportQr(jobId))
      toast.success('success')
    } catch (error) {
      console.log(error)
        
    }
  }

  const beginTrnQbExport = async (): Promise<void> => {
    try {
      await postBeginTrnQbExport(exportQrInfo.orderId)
      closeModal()
    } catch (error) {
      console.log(error)
      
    }
  }

  useEffect(() => {
    if (jobId) {
      exportData(jobId)
    }
  },[jobId])

  return (
    <Modal isShowing>
      <div className={classes.exportQr}>
        <div>
          <span>Transaction ID :</span>
          <span>{exportQrInfo.ref?.split('--')[0]}</span>
        </div>
        <div>
          <span>Authorization Code :</span>
          <span>{exportQrInfo.ref?.split('--')[1]}</span>
        </div>
        <div>
          <span>Amount :</span>
          <span>{exportQrInfo.total}</span>
        </div>
        <div>
          <button onClick={beginTrnQbExport}>Export</button>
          <button onClick={closeModal} className={classes.exportQr_cancel}>Cancel</button>
        </div>
      </div>
      
    </Modal>
  )
}

export default ExportQr