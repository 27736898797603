import { FC } from 'react'
import classes from '../OrdersReportsTable/OrdersReportsTable.module.scss'
import { ISummaryReports } from 'src/api/report/model'
import SummaryReportTotal from './SummaryReportTotal/SummaryReportTotal'
const SummaryReportTable: FC<{summaryReportsInfo: ISummaryReports}> = ({ summaryReportsInfo }): JSX.Element => {
    
  return (
    <div className={classes.ordersReportsTable}>
      <SummaryReportTotal totalData={summaryReportsInfo.totals}/>
      <table className={classes.ordersReportsTable_table}>
        <thead>
          <tr>
            <td>Order</td>
            <td>Items</td>
            <td>Date Created</td>
            <td>Date Approved</td>
            <td>Customer Id</td>
            <td>Customer Name</td>
            <td>Order Price</td>
            <td>ShippingTotal</td>
            <td>Tax Total</td>
            <td>Total</td>
            <td>Approved</td>
            <td>Status</td>
          </tr>
        </thead>
        <tbody>   
          {
            summaryReportsInfo.results?.length ? summaryReportsInfo.results.map((summary, index) => (
              <tr key={index} className={index % 2 === 0 ? classes.ordersReportsTable_table_couple : classes.ordersReportsTable_table_odd }>
                <td>{summary.orderx}</td>
                <td>{summary.totaljobs}</td>
                <td>{summary.created}</td>
                <td>{summary.approveddate}</td>
                <td>{summary.customer_id}</td>
                <td>{summary.customer_first_name + ' ' + summary.customer_last_name}</td>
                <td>{summary.jobtotal}</td>
                <td>{summary.shippingtotal}</td>
                <td>{summary.taxtotal}</td>
                <td>{summary.totaltotal}</td>
                <td>{summary.approved}</td>
                <td>{summary.status}</td>
              </tr>
            )) : null
          }  
                   
        </tbody>
      </table>
    </div>
  )
}
export default SummaryReportTable