import { useState } from 'react'
import classes from './UploadTemplateFiles.module.scss'
import { IStaticObjectData } from 'src/Components/TemplateManager/model'
const checkboxInputData: IStaticObjectData[] = [
  {
    id: 1,
    title: 'Company Name',
  },
  {
    id: 2,
    title: 'Full Name',
  },
  {
    id: 3,
    title: 'Title',
  },
  {
    id: 4,
    title: 'Address Line 1',
  },
  {
    id: 5,
    title: 'Address Line 2',
  },
  {
    id: 6,
    title: 'City, State Zip',
  },
  {
    id: 7,
    title: 'Other 1',
  },
  {
    id: 8,
    title: 'Other 2',
  },
  {
    id: 9,
    title: 'Phone 1',
  },
  {
    id: 10,
    title: 'Phone Description 1',
  },
  {
    id: 11,
    title: 'Phone 2',
  },
  {
    id: 12,
    title: 'Phone Description 2',
  },
  {
    id: 13,
    title: 'Phone 3',
  },
  {
    id: 14,
    title: 'Phone Description 3',
  },
  {
    id: 15,
    title: 'Email',
  },
  {
    id: 16,
    title: 'Website',
  },
]
const UploadTemplateFiles = (): JSX.Element => {
  const [uploadFile, setUploadFile] = useState< {[key: string]: File | null}>()
  
  const handleUploadButtonClick = (field: string): void => {
    const fileInput = document.getElementById(field) as HTMLInputElement | null

    if (fileInput) {
      fileInput.click()
    }
  }
  const handleChange = (field: string, image: FileList | null): void => {
    if (image?.length) {
      setUploadFile({ ...uploadFile, [field]: image[0] })
    }
  }
  return (
    <div className={classes.uploadTemplateFiles}>
      <div className={classes.uploadTemplateFiles_title}>
        <span>Upload Template Files</span>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th>File/Side</th>
              <th>Uploaded File</th>
              <th>Preview</th>
              <th>Select your File</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>SVG Side A :</td>
              <td>{uploadFile?.svgSideA?.name}</td>
              <td></td>
              <td>
                <button name='svgSideA' onClick={(e) => handleUploadButtonClick(e.currentTarget.name)}>
                UPLOAD FILE
                  <input 
                    type='file' 
                    id='svgSideA' 
                    name='svgSideA'
                    onChange={e => handleChange(e.target.name,e.target.files)} />
                </button>
              </td>
            </tr>
            <tr>
              <td>Thumbnail Side A :</td>
              <td>{uploadFile?.thumbnailSideA?.name}</td>
              <td></td>
              <td>
                <button name='thumbnailSideA' onClick={(e) => handleUploadButtonClick(e.currentTarget.name)}>
                UPLOAD FILE
                  <input 
                    type='file' 
                    id='thumbnailSideA'
                    name='thumbnailSideA'
                    onChange={e => handleChange(e.target.name,e.target.files)} />
                </button>
              </td>
            </tr>
            <tr>
              <td>SVG Side B :</td>
              <td>{uploadFile?.svgSideB?.name}</td>
              <td></td>
              <td>
                <button name='svgSideB' onClick={(e) => handleUploadButtonClick(e.currentTarget.name)}>
                UPLOAD FILE
                  <input
                    type='file' 
                    id='svgSideB'
                    name='svgSideB'
                    onChange={e => handleChange(e.target.name,e.target.files)} />
                </button>
              </td>
            </tr>
            <tr>
              <td>Thumbnail Side B :</td>
              <td>{uploadFile?.thumbnailSideB?.name}</td>
              <td></td>
              <td>
                <button name='thumbnailSideB' onClick={(e) => handleUploadButtonClick(e.currentTarget.name)}>
                UPLOAD FILE
                  <input 
                    type='file'
                    id='thumbnailSideB' 
                    name='thumbnailSideB'
                    onChange={e => handleChange(e.target.name,e.target.files)} />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cutout line Image:</td>
              <td>{uploadFile?.cutoutLineImage?.name}</td>
              <td></td>
              <td>
                <button name='cutoutLineImage' onClick={(e) => handleUploadButtonClick(e.currentTarget.name)}>
                UPLOAD FILE
                  <input 
                    type='file'
                    id='cutoutLineImage'
                    name='cutoutLineImage'
                    onChange={e => handleChange(e.target.name,e.target.files)} />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cutout mask Image:</td>
              <td>{uploadFile?.cutoutMaskImage?.name}</td>
              <td></td>
              <td>
                <button name='cutoutMaskImage' onClick={(e) => handleUploadButtonClick(e.currentTarget.name)}>
                UPLOAD FILE
                  <input 
                    type='file'
                    id='cutoutMaskImage'
                    name='cutoutMaskImage'
                    onChange={e => handleChange(e.target.name,e.target.files)} />
                </button>
              </td>
            </tr>
            <tr>
              <td>Upload template`s</td>
              <td>{uploadFile?.uploadTemplates?.name}</td>
              <td></td>
              <td>
                <button name='uploadTemplates' onClick={(e) => handleUploadButtonClick(e.currentTarget.name)}>
                UPLOAD FILE
                  <input 
                    type='file'
                    id='uploadTemplates'
                    name='uploadTemplates'
                    onChange={e => handleChange(e.target.name,e.target.files)} />
                </button>
              </td>
            </tr>
            <tr>
              <td>Backgrounds</td>
              <td>{uploadFile?.backgrounds?.name}</td>
              <td></td>
              <td>
                <button name='backgrounds' onClick={(e) => handleUploadButtonClick(e.currentTarget.name)}>
                UPLOAD FILE
                  <input
                    type='file'
                    id='backgrounds'
                    name='backgrounds'
                    onChange={e => handleChange(e.target.name,e.target.files)} />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <table className={classes.uploadTemplateFiles_checkboxBlock} >
          <thead>
            <tr>
              <th>
              Fields Front
              </th>
              <th>
              Back
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              checkboxInputData.map(e => (
                <tr key={e.id}>
                  <td>
                    <input type='checkbox'/>
                  </td>
                  <td>
                    <input type='checkbox'/>
                  </td>
                  <td>{e.title}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <div className={classes.uploadTemplateFiles_btnBlock}>
          <button className={classes.uploadTemplateFiles_btnBlock_cancel}
          >
        Cancel
          </button>
          <button className={classes.uploadTemplateFiles_btnBlock_save}>
      Save
          </button>
        </div>
      </div>
    </div>
  )
}

export default UploadTemplateFiles