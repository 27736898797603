import { FC, useEffect, useRef, useState } from 'react'
import { ipcStore } from 'src/stores/IPC/ipcStore'
import { IIPCSelectTurnaroundProps } from '../../IPC.model'
import classes from '../IPCPriceCalc.module.scss'


const IPCSelectTurnaround: FC<IIPCSelectTurnaroundProps> =
  ({ IPCRes, changeIPCPayloadUpdate, payloadUpdates }): JSX.Element => {
    const { loadingStatus: isLoading } = ipcStore

    const turnaroundRef = useRef<HTMLSelectElement>(null)

    const [businessDaysToAdd, setBusinessDaysToAdd] = useState(IPCRes?.turnaroundTimes[0]?.turnaround)

    const today = new Date()

    const calculateDeliveryDate = (): void => {
      setBusinessDaysToAdd(Number(turnaroundRef?.current?.options[turnaroundRef?.current?.options?.selectedIndex]?.getAttribute('data-turnaround')))
      if (businessDaysToAdd !== 11) {
        let i = 0
        while (i < businessDaysToAdd) {
          today.setDate(today.getDate() + 1)
          if (today.getDay() !== 0 && today.getDay() !== 6) {
            i++
          }
        }
      } else {
        if (today.getDay() === 0) {
          today.setDate(today.getDate() + 1)
        } else if (today.getDay() === 6) {
          today.setDate(today.getDate() + 2)
        }
      }
      // !! const dateAfterBusinessDays = today.toLocaleString('en-US', {
      //   month: 'long',
      //   day: 'numeric'
      // })
    }


    const handleTurnaroundChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
      changeIPCPayloadUpdate(
        'printTurnAroundId',
        Number(e.target.options[e.target.options.selectedIndex].getAttribute('data-turnaroundid')),
      )
    }

    useEffect(() => {
      calculateDeliveryDate()
    }, [IPCRes, businessDaysToAdd])

    return (
      <span className={classes.IPCPriceCalc_body_selectOptions_printTurnaround}>
        <label htmlFor='printTurnaround'>
          <span>
            Print Turnaround
          </span>
          <select
            name='printTurnaround'
            id='printTurnaround'
            onChange={handleTurnaroundChange}
            disabled={isLoading}
            value={payloadUpdates?.printTurnAroundId}
            ref={turnaroundRef}
          >
            {IPCRes?.turnaroundTimes?.map((el, i) => (
              <option
                key={i}
                value={el.turnaround_id}
                data-turnaroundid={el.turnaround_id}
                data-turnaround={el.turnaround}
              >
                {el.turnaround === 0 ? 'Same Day' : `${el.turnaround} Business Days`}
              </option>
            ))}
          </select>
        </label>
      </span>
    )
  }
export default IPCSelectTurnaround
