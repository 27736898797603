import { api } from 'src/api/api'
import { ICoatingActionSuccess, ICoatingTiket, IGetCoating, ISendCoatingActionData, ISendCoatingDueDate, TCoatingFrontOrBack } from './model'
import { IPressmanGangJobsDetail } from '../gangs/model'

export const getCoating = (): Promise<IGetCoating[]> =>
  api.get('/postapproval/coating/index').then(res => res.data)

export const getCoatingTicket = (gangId: string): Promise<ICoatingTiket[]> =>
  api.get(`/postapproval/coating/ticket/${gangId}`).then(res => res.data)

export const patchCoatingFrontOrBack = (field: string, gangId: number, data: TCoatingFrontOrBack): Promise<void> => 
  api.patch(`/postapproval/${field}/field/${gangId}`, data).then(res => res.data)

export const postCoatingAction = (field: string, data: ISendCoatingActionData): Promise<ICoatingActionSuccess> =>
  api.post(`/postapproval/${field}/action`, data).then(res => res.data)

export const putCoatingDueDate = (data: ISendCoatingDueDate): Promise<void> =>
  api.put('/postapproval/coating/changeDueDate', data).then(res => res.data)

export const getCoatingGang = (gangId: string): Promise<ICoatingTiket> =>
  api.get(`/postapproval/coating/gang/${gangId}`).then(res => res.data[0])

export const getCoatingJobsByGangId = (gangId: string): Promise<IPressmanGangJobsDetail[]> =>
  api.get(`/postapproval/coating/gangJobs/${gangId}`).then(res => res.data)