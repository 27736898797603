import { FC, memo } from 'react'
import { toast } from 'react-toastify'
import { postCoatingAction } from 'src/api/postApproval/coating/coating'
import { ISendCoatingActionData } from 'src/api/postApproval/coating/model'
import { IResponseError } from 'src/utils/errorMessage/model'
import { IPressmanActionProps } from '../../model'

const Action: FC<IPressmanActionProps> = ({ status, gangId, nextV, coatedFront, coatedBack, handleChangeLaminationStatus }) => {

  const postAction = async (data: ISendCoatingActionData): Promise<void> => {
    try {
      const postData = {
        status: data.status,
        gangId: data.gangId,
        ...(data.status === 'Ready To Cut' && { nextV, coatedFront, coatedBack })
      }
      const res = await postCoatingAction('pressman', postData)
      if (res.message === 'success') {
        handleChangeLaminationStatus(postData)
      }
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }

  const renderButton = (text: string, color: string, newStatus: string): JSX.Element => (
    <button
      style={{ background: color }}
      onClick={(e) => {
        e.stopPropagation()
        postAction({ status: newStatus, gangId, nextV, coatedFront, coatedBack })
      }}
    >
      {text}
    </button>
  )
  const renderAction = (status: string): JSX.Element => {
    switch (status) {
      case 'Ready to Print':
        return renderButton('Start', 'green', 'Printing Side 1')
      case 'Printing Side 1':
        return renderButton('Finish Side 1', '#e87878', 'Printed Side 1')
      case 'Printed Side 1':
        return renderButton('Start Side 2', '#acd8ab', 'Printing Side 2')
      case 'Printing Side 2':
        return renderButton('Finish', 'red', 'Ready To Cut')
      default:
        return <></>
    }
  }


  return (
    <>
      {renderAction(status)}
    </>
  )
}

export default memo(Action)
