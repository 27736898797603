import { useEffect, useState } from 'react'
import classes from './PrintSKULabel.module.scss'
import { ISkuLabelInfo } from 'src/api/Preflight/orderList/orderListShipping/model'
import { useParams } from 'react-router-dom'
import { getSkuLabelInfo } from 'src/api/Preflight/orderList/orderListShipping/orderListShipping'
const PrintSKULabel = (): JSX.Element => {
  const { id } = useParams()
  const [skuLabelInfo, setSkuLabelInfo] = useState<ISkuLabelInfo[]>([])
  const [boxesCount, setBoxesCount] = useState<number>(0)
  
  const skuLabelData = async (id: string): Promise<void> => {
    try {
      setSkuLabelInfo(await getSkuLabelInfo(Number(id)))
    } catch (error) {
      console.log(error)
        
    }
  }

  useEffect(() => {
    if (id) {
      skuLabelData(id)
    }
  },[id])

  const addDataInSkuLabelInfo = (): void => {
    if (boxesCount > 0) {
      const newItems: ISkuLabelInfo[] = []
      const firstItem: ISkuLabelInfo = skuLabelInfo[0]
      for (let index = 0; index < boxesCount; index++) {
        newItems.push(firstItem)
      }
      setSkuLabelInfo([...newItems])
    }
  }

  useEffect(() => {
    addDataInSkuLabelInfo()
  },[boxesCount])
  
  return (
    <div className={classes.printSKULabel}>
      <div className={classes.printSKULabel_printBlock}>
        <label>Number of Boxes</label>
        <select
          onChange={e => setBoxesCount(Number(e.target.value))}
        >
          {
            [...new Array(100)].map((_, index) => (
              <option key={index} value={index + 1}>{index + 1}</option>
            ))
          }
        </select>
        <button onClick={() => window.print()}>Print</button>
      </div>
      <div className={classes.printSKULabel_items}>
        {
          setSkuLabelInfo.length ? skuLabelInfo.map((e, index) => (
            <div key={index}>
              <p>{e.orderid}</p>
              <p>SKU: {e.project}</p>
              <p>Purchase Order: {e.purchase_order}</p>
              <p>Quantity: {e.quantity}</p>
              <p>Box: {index + 1 } of {skuLabelInfo.length}</p>
            </div>
          )) : null
        }
      </div>
    </div>
  )
}
export default PrintSKULabel