import { MetaData } from 'src/ui/MetaData'
import classes from './Gangs.module.scss'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { IParams } from 'src/api/Preflight/orderList/OrderListNote/model'
import { FC, Suspense, lazy, useEffect, useState } from 'react'
import Filter from 'src/ui/Filter/Filter'
import { getClosedGangs, getCurentGangs, putCloseGangStatus } from 'src/api/postApproval/gangs/gangs'
import { dateConvertToStringToDate } from 'src/utils/dateConvertToString'
import { IGangsInfo } from '../model'
import { ICurentGangs } from 'src/api/postApproval/gangs/model'
import useNextStepScroll from 'src/Hook/useNextStepScrol'
import { IResponseError } from 'src/utils/errorMessage/model'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { getDateColor } from 'src/utils/getDateColor'
const EditGangNot = lazy(() => import('./EditGangNot/EditGangNot'))
const GangsSettings = lazy(() => import('./GangsSettings/GangsSettings'))
const Gangs: FC<{singleGang?: boolean}> = ({ singleGang }): JSX.Element => {
  const navigate = useNavigate()
  const { isScroll, turnOfScroll } = useNextStepScroll()
  const [gangsInfo, setGangsInfo] = useState<IGangsInfo>({} as IGangsInfo)
  const [isClosedGangs, setIsClosedGangs] = useState<boolean>(singleGang || false)
  const [changingBlock, setChangingBlock] = useState<{
    note?: number | null
    settings?: number | null
  }>()
  const [params, setParams] = useState<IParams>({
    page: '1',
    limit: '50'
  })

  const curentGangs = async (filter?: IParams): Promise<void> => {
    try {
      let res: ICurentGangs = {
        gangs: [],
        coatingOptions:[]
      }
      if (typeof filter !== 'undefined' && filter.gangId) {
        res = await getCurentGangs({ gangId:filter.gangId }, 'search')
      } else {
        res = await getCurentGangs()
      }
      setGangsInfo({
        gangs: res.gangs,
        coatingOptions: res.coatingOptions
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    curentGangs()
  },[])

  const closedGangs = async (params: IParams): Promise<void> => {
    return getClosedGangs(params)
      .then(res => {
        if (res.pagination.page !== 1) {
          setGangsInfo(prev => {
            return {
              gangs: [...prev.gangs, ...res.data],
              pagination: res.pagination
            }
          })
        } else {
          setGangsInfo({
            ...gangsInfo,
            gangs: res.data,
            pagination: res.pagination
          })
        }
        turnOfScroll()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (isClosedGangs) {
      closedGangs(params)
    }
  }, [params, isClosedGangs])

  useEffect(() => {
    if ( gangsInfo?.pagination && isScroll && Number(params.page) < gangsInfo?.pagination?.totalPages) {
      setParams({
        ...params,
        page: String(Number(params.page) + 1)
      })
    }
  }, [isScroll])

  const gangsFilter = (data: IParams): void => {
    setIsClosedGangs(false)
    curentGangs(data)
    
  }

  const resetFilter = (): void => {
    setParams({ })
    curentGangs()
  }

  const closedOrCurrentGangs = (): void => {
    if (isClosedGangs) {
      setIsClosedGangs(false)
      curentGangs()
    } else {
      setParams({ page:'1', limit: '50' })
      setIsClosedGangs(true)
    }
  }

  const closeGangStatus = async (gangId: number): Promise<void> => {
    try {
      await putCloseGangStatus(gangId)
      setGangsInfo({
        ...gangsInfo,
        gangs: gangsInfo.gangs.filter(gang => gang.gang_id !== gangId)
      })
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }

  const closedCurrentStatus = (gangId: number, status: string): JSX.Element => {
    if (status === 'Ready For Bindery') {
      return <></>
    }
    return ( 
      <span 
        className={classes.gangs_container_tableBlock_closedStatus}
        onClick={e => {
          e.stopPropagation()
          closeGangStatus(gangId)
        }}
      >
        Closed
      </span>
    )
  }

  const addNote = (gangId: number, note: string): void => {
    setGangsInfo({
      ...gangsInfo,
      gangs: gangsInfo.gangs.map(gang => {
        if (gang.gang_id === gangId ) {
          gang.note = note
        }
        return gang
      })
    })
  }

  const closedChangingBlock = (): void => {
    setChangingBlock({
      note: null,
      settings: null
    })
  }
  const gangsNoteBlock = (gangId: number, note?: string): JSX.Element => {
    if (gangId === changingBlock?.note) {
      return (
        <Suspense fallback={null}>
          <EditGangNot
            note={note}
            gangId={changingBlock.note}
            closedBlock={closedChangingBlock}
            addNote={addNote}
          />
        </Suspense>
      )
    }
    return (
      <div className={classes.gangs_container_tableBlock_noteBlock}>
        <span>{note}</span>
        <span onClick={(e) => {
          e.stopPropagation()
          setChangingBlock({ note:gangId })
        }}><FontAwesomeIcon icon={faPen} cursor='pointer'/></span>
      </div>
    )
  }


  return (
    <div style={{ marginTop: singleGang ? '0px' : '150px' }} className={classes.gangs}>
      
      {!singleGang && <MetaData title='Gangs'/>}
      <div className={classes.gangs_container}>
        {!singleGang && <PageHeader pageName='Gangs' pageWidth='100'/>}
        {
          !singleGang && 
        <div className={classes.gangs_container_header}>
          <Filter
            params={params}
            pageName='gangs'
            selectName={['']} 
            handleFilter={gangsFilter}
            resetFilter={resetFilter}
            
          />
          <button onClick={closedOrCurrentGangs}>
            {!isClosedGangs ? 'Closed Gangs' : 'Current Gangs'}
          </button>
        </div>
        }
        <div className={classes.gangs_container_tableBlock}>
          <table>
            <thead>
              <tr>
                <th>Gang</th>
                <th>Due</th>
                <th>Paper</th>
                <th>Created</th>
                <th>Status</th>
                <th>Date</th>
                <th>Note</th>
                <th>Settings</th>
              </tr>
            </thead>
            <tbody>
              {
                gangsInfo.gangs?.length ? gangsInfo.gangs.map(gang => { 
                  const { color, text } = getDateColor(gang.due_on)
                  return (
                    <tr key={gang.gang_id} onClick={() => !isClosedGangs && navigate(`/gangs/${gang.gang_id}`) }>
                      <td>{gang.gang_id}</td>
                      <td style={{ background:color }}>{text || gang.due_on}</td>
                      <td>{gang.internal_name}</td>
                      <td>{gang.username}</td>
                      <td>{gang.status} {closedCurrentStatus(gang.gang_id, gang.status)}</td>
                      <td>{dateConvertToStringToDate(gang.timestamp)}</td>
                      <td>
                        {gangsNoteBlock(gang.gang_id, gang.note)}
                      </td>
                      <td>
                        <span 
                          className={classes.gangs_container_tableBlock_settings}
                          onClick={e => {
                            e.stopPropagation()
                            setChangingBlock({ settings: gang.gang_id })
                          }}
                        >
                        settings
                        </span>
                      </td>
                    </tr>
                  )
                }) : null
              }
            </tbody>
          </table>
        </div>
      </div>
      {
        changingBlock?.settings &&
        <Suspense fallback={null}>
          <GangsSettings 
            pageName='gang'
            gangId={changingBlock.settings}
            closedBlock={closedChangingBlock}
            refetchGang={curentGangs}
          />
        </Suspense>
      }
    </div>
  )
}

export default Gangs