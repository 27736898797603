import { api, formDataApi } from 'src/api/api'
import { IGetEmailBanners, IPatchEmailBanners } from './model'

export const getAllEmailBanners = (): Promise<IGetEmailBanners[]> => 
  api.get('/banners').then(res => res.data)

export const postEmailBanners = (data: FormData): Promise<IGetEmailBanners[]> =>
  formDataApi.post('/upload/banner', data).then(res => res.data)

export const patchEmailBanners = (bannerId: number, data: IPatchEmailBanners): Promise<IGetEmailBanners[]> =>
  api.patch(`/update/banner/${bannerId}`, data).then(res => res.data)