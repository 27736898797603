import { FC } from 'react'
import classes from '../Boxing.module.scss'
import { IBoxHeaderProps } from '../../model'
import { IResponseError } from 'src/utils/errorMessage/model'
import { toast } from 'react-toastify'
import { postBoxingSkip } from 'src/api/postApproval/boxing/boxing'
import { openLinkNewTab } from 'src/utils/open-link-new-tab'
const BoxHeader: FC<IBoxHeaderProps> = ({
  orderId, shippmentId, boxesLength,blindShiping, itemNumber, publicLabel, serviceType, numberOf, of,skuLable, firstOrder, missingTotal
}): JSX.Element =>{
  const internalLabelColor = (serviceType: string): string => {
    switch (serviceType) {
      case 'FEDEX':
        return 'green'
      case 'USPS':
        return 'red'
      default:
        return ''
    }
  }
  const shipmentOrSplit = (of: number): JSX.Element => {
    if (of > 1) {
      return (
        <span>Split Ship</span>
      )
    } 
    return (
      <span>Shipment {numberOf} of {of}</span>
    )
  }

  const boxingSkip = async (shippmentId: number): Promise<void> => {
    try {
      await postBoxingSkip(shippmentId)
      toast.success('Boxing skip successfully')
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      } 
    }
  }

  const btnBackgroundColor = (color: string): string => {
    if (Number(missingTotal) > 0 || skuLable === 1) {
      return 'grey'
    }
    return color
  }

  const boxingHeaderBtn = (skuLable: number, serviceType: string): JSX.Element => {
    if (serviceType === 'OTHER' || serviceType === 'USPS') {
      return (
        <>
          <button 
            onClick={() => boxingSkip(shippmentId)} 
            disabled={Boolean(skuLable)} 
            style={{ background:btnBackgroundColor('yellow') }}
            className={classes.boxing_container_tableBlock_secondBlock_btn}>
            Skip
          </button>
          <button 
            onClick={() => openLinkNewTab(`/workflow/preflight/dashboard_detail/printShippmentLabel/${shippmentId}`)}
            disabled={Boolean(skuLable)} 
            style={{ background: btnBackgroundColor('red') }} 
            className={classes.boxing_container_tableBlock_secondBlock_btn}>
            Finished
          </button>
        </>
      )
    } else if (serviceType === 'FEDEX') {
      return (
        <button 
          disabled={Boolean(skuLable)} 
          style={{ background:btnBackgroundColor('yellow') }} 
          className={classes.boxing_container_tableBlock_secondBlock_btn}>
            Skip
        </button>
      )
    }
    return <button 
      onClick={() => boxingSkip(shippmentId)}
      disabled={Boolean(skuLable)} 
      style={{ background: btnBackgroundColor('red') }}
      className={classes.boxing_container_tableBlock_secondBlock_btn}>
        Finished
    </button>
  }

  return (
    <>
      <div className={classes.boxing_container_tableBlock_firstBlock}>
        <span>Order {orderId}</span>
        <span className={classes.boxing_container_tableBlock_secondBlock_red}>{itemNumber} ITEMS</span>
        <span className={classes.boxing_container_tableBlock_secondBlock_red}>{!firstOrder ? 'FIRST ORDER' : '' }</span>
      </div>
      <div className={classes.boxing_container_tableBlock_secondBlock}>
        {shipmentOrSplit(of)}
        <span>Boxes:{boxesLength}</span>
        <span style={{ color:internalLabelColor(serviceType) }}>{publicLabel}</span>
        <span className={classes.boxing_container_tableBlock_secondBlock_red}>{blindShiping === '1' ? 'Blind Shipping' : ''}</span>
        {boxingHeaderBtn(skuLable, serviceType)}
      </div>
    </>
  )
}
export default BoxHeader