import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Suspense, lazy, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loading from 'src/Components/Loading/Loading'
import { deleteCannedMessage, getAllCannedMessage } from 'src/api/settings/cannedMessage/cannedMessage'
import { IGetCannedMessage } from 'src/api/settings/cannedMessage/model'
import { MetaData } from 'src/ui/MetaData'
import useModal from 'src/ui/modal/toggleModal'
import { IResponseError } from 'src/utils/errorMessage/model'
import PageHeader from '../../Layout/PageHeader/PageHeader'
import classes from './CannedMessages.module.scss'
const DeletePopup = lazy(() => import('src/Components/DeletePopup/DeletePopup'))

const CannedMessages = (): JSX.Element => {
  const navigate = useNavigate()
  const [deleteId, setDeleteId] = useState<number>()
  const { isShowing, toggle } = useModal()
  const [cannedMessageInfo, setCannedMessageInfo] = useState<IGetCannedMessage[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const allCannedMessage = async (): Promise<void> => {
    try {
      setCannedMessageInfo(await getAllCannedMessage())
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!cannedMessageInfo.length) {
      allCannedMessage()
    }
  },[cannedMessageInfo])
 
  const removeCannedMessage = async (): Promise<void> => {
    try {
      if (deleteId) {
        await deleteCannedMessage(deleteId)
        setCannedMessageInfo(prev => {
          return prev.filter(message => message.cannedMessageId !== deleteId)
        })
        toast.success('Canned Message deleted successfully')
        toggle()
      }
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }
  const createPage = (field: string, id?: number): void => {
    if (field === 'add') {
      navigate('create')
    } else {
      navigate(`${id}/edit`)
    }
  }
  return (
    <>
      <MetaData title='Canned Messages'/>
      {
        isLoading ? <Loading/> :
          <div className={classes.cannedMessages}>
            <PageHeader pageName='Canned Messages' buttonName='Add' handleAddClick={createPage}/>
            <div className={classes.cannedMessages_container}>
              <table className={classes.cannedMessages_container_table}>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Message</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  { 
                    cannedMessageInfo.length ? cannedMessageInfo.map((message, index) =>(

                      <tr key={index} className={index % 2 === 0 ? classes.cannedMessages_couple : classes.cannedMessages_odd }>
                        <td>{message.categoryName}</td>
                        <td>{message.title}</td>
                        <td>
                          <button onClick={() => createPage('edit', message.cannedMessageId)} ><FontAwesomeIcon icon={faPen} /> </button>
                          <button onClick={() => {
                            setDeleteId(message.cannedMessageId)
                            toggle()
                          }}><FontAwesomeIcon icon={faTrash} color='#4297d7' /></button>
                        </td>
                      </tr>
                    )) : null
                  }
                </tbody>
              </table>
              {
                isShowing && <Suspense fallback={null}>
                  <DeletePopup toggle={toggle} remove={removeCannedMessage}/>
                </Suspense>
              }
            </div>
          </div>
      }
    </>
  )
}

export default CannedMessages