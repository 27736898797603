import { FC } from 'react'
import classes from '../../OrdersReportsTable/OrdersReportsTable.module.scss'
import { ISummaryTotals } from 'src/api/report/model'
import { floatNumberCut } from 'src/utils/FloatNumberCut'
const SummaryReportTotal: FC<{totalData: ISummaryTotals}> = ({ totalData }): JSX.Element => {
  return (
    <>
      {
        totalData && Object?.keys(totalData)?.length ?
          <table className={classes.ordersReportsTable_totalTable}>
            <thead>
              <tr>
                <th>Total Quantity</th>
                {/* <th>{floatNumberCut(String(totalData?.t),2)}</th> */}
              </tr>
              <tr>
                <th>Total Product Price</th>
                <th>{floatNumberCut(String(totalData?.total_product_price), 2)}</th>
              </tr>
              <tr>
                <th>Total Coupon Discount</th>
                <th>{floatNumberCut(String(totalData?.total_coupon_count), 2)}</th>
              </tr>
              <tr>
                <th>Total Discount</th>
                <th>{floatNumberCut(String(totalData?.total_discount),2)}</th>
              </tr>
              <tr>
                <th>Total Extras</th>
                <th>{floatNumberCut(String(totalData?.total_extras),2)}</th>
              </tr>
              <tr>
                <th>Total Order Price</th>
                <th>{floatNumberCut(String(totalData?.total_orders),2)}</th>
              </tr>
              <tr>
                <th>Total Price Approved</th>
                <th>{floatNumberCut(String(totalData?.total_price_approved),2)}</th>
              </tr>
              <tr>
                <th>Total Price Not Approved</th>
                <th>{floatNumberCut(String(totalData?.total_price_not_approved),2)}</th>
              </tr>
              <tr>
                <th>Total Shipping</th>
                <th>{floatNumberCut(String(totalData?.total_shipping),2)}</th>
              </tr>
              <tr>
                <th>Total Tax</th>
                <th>{floatNumberCut(String(totalData?.total_tax),2)}</th>
              </tr>
              <tr>
                <th>Total</th>
                <th>{floatNumberCut(String(totalData?.total_approved_tax_ship),2)}</th>
              </tr>
              <tr>
                <th>Total Orders</th>
                <th>{floatNumberCut(String(totalData?.total_orders),2)}</th>
              </tr>
              <tr>
                <th>Total Approved Orders</th>
                <th>{floatNumberCut(String(totalData?.total_approved_orders),2)}</th>
              </tr>
              <tr>
                <th>Total accounts placed orders</th>
                <th>{floatNumberCut(String(totalData?.total_accounts_count),2)}</th>
              </tr>
            </thead>
          </table> : null
      }
    </>
  )
}

export default SummaryReportTotal