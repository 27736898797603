import { toast } from 'react-toastify'
import { IErrorMessage } from './model'
export const toastErrorOrFieldError = (
  error: IErrorMessage,
  setErrorObject: React.Dispatch<React.SetStateAction<IErrorMessage>>
): void => {
  if (error?.detail) {
    toast.error(error.detail)
  } else {
    setErrorObject(error)
  }
}


export const errorMessage = (field: string, errorObject: IErrorMessage): JSX.Element => {
  const errorValue = errorObject?.[field]
  return errorValue ? <span style={{ color: 'red', fontSize: '13px' }}>{errorValue}</span> : <></>
}