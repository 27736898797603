import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import classes from './EmailBanners.module.scss'
import UploadNewBanners from './UploadNewBanners/UploadNewBanners'
import { useEffect, useState } from 'react'
import { IGetEmailBanners, IPatchEmailBanners } from 'src/api/settings/emailBanners/model'
import { getAllEmailBanners, patchEmailBanners } from 'src/api/settings/emailBanners/emailBanners'
import { toast } from 'react-toastify'
import { IResponseError } from 'src/utils/errorMessage/model'
import Loading from 'src/Components/Loading/Loading'
// import { IResponseError } from 'src/utils/errorMessage/model'
const EmailBanners = (): JSX.Element => {
  const [emailBannersInfo, setEmailBannersInfo] = useState<IGetEmailBanners[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const getEmailBanners = async (): Promise<void> => {
    try {
      setEmailBannersInfo( await getAllEmailBanners())
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getEmailBanners()
  },[])

  const handleEdit = (id: number, link: string): void => {
    setEmailBannersInfo(prev => {
      const updatedEmailBanners = prev.map(email => {
        if (email.bannerId === id) {
          email.link = link 
        }
        return email
      }) 
      return updatedEmailBanners
    } )
  }
  const updateEmailBanners = async (bannerId: number, data: IPatchEmailBanners): Promise<void> => {
    try {
      await patchEmailBanners(bannerId, data)
      toast.success('Email Banners was updated')
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }

  const saveEdit = (id: number): void => {
    const updatedItem = emailBannersInfo.find(e => e.bannerId === id)
    if (updatedItem && updatedItem?.bannerId === id) {
      updateEmailBanners(id, { fileName: updatedItem.fileName, link: updatedItem.link })
    }
  }

  return (
    <>
      {
        isLoading ? <Loading/> :
          <div className={classes.emailBanners}>
            <PageHeader pageName='Email Banners'/>
            <div className={classes.emailBanners_container}>
              <table className={classes.emailBanners_container_table}>
                <thead>
                  <tr>
                    <th>Banner ID</th>
                    <th>File Name</th>
                    <th>Link</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    emailBannersInfo.length ? emailBannersInfo.map(email =>(

                      <tr key={email.bannerId}>
                        <td>{email.bannerId}</td>
                        <td>{email.fileName}</td>
                        <td>
                          <input type='text' value={email.link} onChange={(e) => handleEdit(email.bannerId, e.target.value)}/>
                          <button onClick={() => saveEdit(email.bannerId)}>Save Link</button>
                        </td>
                      </tr>
                    )) : null
                  }
                </tbody>
              </table>
              <UploadNewBanners setEmailBannersInfo={setEmailBannersInfo}/>
            </div>
          </div>
      }
    </>
  )
}

export default EmailBanners