import { api, formDataApi } from 'src/api/api'
import { ICannedMessageByCategory, IDoActionData, IEditJobNote, IGetUseCoupon, INote, IParams, IPatchChangeUser, IProof, IProofNotesJob, ISendChangeOrderStateData } from './model'
import { IOrderByIDSummary } from '../OrderListSummary/model'

export const deleteOrderNote = (id: number): Promise<void> => 
  api.delete(`/dashboard/jobs/notes/${id}`).then(res => res.data)

export const patchOrderNote = (id: number, data: IEditJobNote ): Promise<void> =>
  api.patch(`/dashboard/jobs/notes/${id}`, data).then(res => res.data)

export const deleteOrderNoteProof = (fileId: number): Promise<void> => 
  api.delete(`/dashboard/jobs/${fileId}/proof`).then(res => res.data)

export const uploadProof = (data: FormData): Promise<IProof[]> =>
  formDataApi.post('/dashboard/jobs/uploadProof', data).then(res => res.data)

export const getSearchCoupon = (params: IParams): Promise<IGetUseCoupon[]> =>
  api.get('/dashboard/jobs/usedCoupons',{ params }).then(res => res.data)

export const patchChangeUser = (data: IPatchChangeUser): Promise<void> =>
  api.patch('/dashboard/orders/repp', data).then(res => res.data)

export const getCannedMessagesByCategory = (categoryId: string): Promise<ICannedMessageByCategory[]> => 
  api.get(`/dashboard/jobs/cannedMessages/${categoryId}`).then(res => res.data)

export const postAddJobNote = (jobId: number, data: IProofNotesJob): Promise<INote> => 
  api.post(`/dashboard/jobs/${jobId}/notes`, data).then(res => res.data)

export const postAllNoteRead = (jobId: string): Promise<void> =>
  api.post(`/dashboard/jobs/${jobId}/allNotesRead`).then(res => res.data)

export const patchTakeOrder = (orderList: number[]): Promise<void> =>
  api.patch('/dashboard/orders/take', { orderList }).then(res => res.data)

export const getCannedMessagesByProof = (): Promise<ICannedMessageByCategory[]> =>
  api.get('/dashboard/jobs/proofsCannedMessages').then(res => res.data)

export const postDoAction = (data: IDoActionData): Promise<IOrderByIDSummary> =>
  api.post('/dashboard/orders/doAction', data).then(res => res.data.summaryData)

export const putChangeOrderState = (data: ISendChangeOrderStateData): Promise<void> =>
  api.put('/dashboard/orders/changeOrderState', data).then(res => res.data)