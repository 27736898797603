import { faAngleDown, faAngleUp, faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Loading from 'src/Components/Loading/Loading'
import { getAllEstateEmail } from 'src/api/settings/estateEmail/estateEmail'
import { IEstateEmails } from 'src/api/settings/estateEmail/model'
import { MetaData } from '../../../ui/MetaData'
import PageHeader from '../../Layout/PageHeader/PageHeader'
import classes from './EstateEmail.module.scss'
import { IParams } from 'src/api/Preflight/orderList/OrderListNote/model'

const EstateEmail = (): JSX.Element => {
  const navigate = useNavigate()
  const [estateEmailInfo, setEstateEmailsInfo] = useState<IEstateEmails>({} as IEstateEmails)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isScroll, setIsScroll] = useState<boolean>(false)
  const [params, setParams] = useState<IParams>({
    page: '1',
    limit: '50'
  })
  const estateEmail = async (params: IParams): Promise<void> => {
    try {
      const res = await getAllEstateEmail(params)
      if (res.pagination.page !== 1) {
        setEstateEmailsInfo(prev => {
          return {
            data: [...prev.data, ...res.data],
            pagination: res.pagination
          }
        })
      } else {
        setEstateEmailsInfo(res)
      }
      setIsScroll(false)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    estateEmail(params)
  }, [params])

  useEffect(() => {
    if (isScroll && Number(params.page) < estateEmailInfo?.pagination?.totalPages) {
      setParams({
        ...params,
        page: String(Number(params.page) + 1)
      })
    }
  }, [isScroll])

  const nextStepScroll = (e: Event): void => {
    const target = e.target as Document
    if ((target.documentElement.scrollHeight - target.documentElement.scrollTop - window.innerHeight) < 600) {
      setIsScroll(true)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', nextStepScroll)
    return () => {
      window.removeEventListener('scroll', nextStepScroll)
    }
  }, [])

  const SortEstateEmailInfo = (field: string, value: string): void => {
    setParams({
      ...params,
      page:'1',
      sord: params.sord === 'DESC' ? 'ASC' : 'DESC',
      [field]: value
    })
  }
  const angelUp = (sidX: string, sord: string): JSX.Element => {
    if (sidX === params.sidx && sord === 'ASC') {
      return <FontAwesomeIcon icon={faAngleUp} />
    } else if (sidX === params.sidx && sord === 'DESC') {
      return <FontAwesomeIcon icon={faAngleDown} />
    }
    return <></>
  }
  return (
    <>
      <MetaData title='Estate Email' />
      {isLoading ? <Loading /> :
        <div className={classes.estateEmail}>
          <PageHeader pageName='Estate Email' />
          <div className={classes.estateEmail_container}>
            <table className={classes.estateEmail_container_table}>
              <thead>
                <tr>
                  <th
                    onClick={() =>SortEstateEmailInfo( 'sidx', 'state_id')}>
                    State Id {angelUp('state_id',params.sord)} 
                  </th>
                  <th onClick={() =>SortEstateEmailInfo( 'sidx', 'internal_label')}>Internal Label {angelUp('internal_label',params.sord)}</th>
                  <th onClick={() =>SortEstateEmailInfo( 'sidx', 'customer_label')}>Customer Label {angelUp('customer_label',params.sord)}</th>
                  <th onClick={() =>SortEstateEmailInfo( 'sidx', 'is_mailable')}>Is Mailable {angelUp('is_mailable',params.sord)}</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {estateEmailInfo?.data?.length ? estateEmailInfo.data.map((estate, index) => (

                  <tr key={estate.stateId} className={index % 2 === 0 ? classes.estateEmail_couple : classes.estateEmail_odd}>
                    <td>{estate.stateId}</td>
                    <td>{estate.internalLabel}</td>
                    <td>{estate.customerLabel}</td>
                    <td>{estate.isMailable}</td>
                    <td onClick={() => navigate(`${estate.stateId}/edit`) }>
                      <span><FontAwesomeIcon icon={faPen} color='#4297d7' /> </span>
                    </td>
                  </tr>
                )) : null
                }
              </tbody>
            </table>
          </div>
        </div>}
    </>
  )
}

export default EstateEmail