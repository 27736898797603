import { MetaData } from 'src/ui/MetaData'
import classes from './CheckoutReport.module.scss'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import { FC, useEffect, useState } from 'react'
import { IGetCheckoutReport } from 'src/api/report/model'
import { getAllCheckoutReports } from 'src/api/report/report'
import { IErrorMessage, IResponseError } from 'src/utils/errorMessage/model'
import { errorMessage, toastErrorOrFieldError } from 'src/utils/errorMessage/errorMessage'
import { downloadCSV } from 'src/utils/downloadCSV'
import { IQueryData } from '../model'
import LoadingButton from 'src/ui/loadingButton/LoadingButton'
import { IParams } from 'src/api/Preflight/orderList/OrderListNote/model'

const CheckoutReport: FC<IQueryData> = ({ queryName, pageName, downloadName }): JSX.Element => {
  const [params, setParams] = useState<IParams>({} as IParams)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorRequest, setErrorRequest] = useState<IErrorMessage>({})
  const [query, setQuery] = useState<IQueryData>({
    queryName: '',
    pageName:'',
    downloadName: ''
  })

  useEffect(() => {
    setQuery({
      queryName,
      pageName,
      downloadName
    })
  },[queryName, pageName, downloadName ])

  const checkoutReportData = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const data = await getAllCheckoutReports<IGetCheckoutReport>(query?.queryName,params)
      downloadCSV<IGetCheckoutReport[]>(data, downloadName)
    } catch (error) {
      const errorMessage = error as IResponseError
      toastErrorOrFieldError(errorMessage?.response?.data, setErrorRequest)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = (field: string, value: string): void => {
    if (!Object.values(errorRequest).every(error => error === '')) {
      setErrorRequest({ ...errorRequest, [field]: '' })
    }
    setParams({ ...params, [field]: value })
  }

  return (
    <div className={classes.checkoutReport}>
      <MetaData title={query.pageName} />
      <PageHeader pageName={query.pageName} />
      <div className={classes.checkoutReport_container}>
        <div>
          <label>Start Date</label>
          <input
            name='start'
            type='date'
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          <div>
            {errorMessage('start', errorRequest)}
          </div>
        </div>
        <div>
          <label>End Date</label>
          <input
            name='end'
            type='date'
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          <div>
            {errorMessage('end', errorRequest)}
          </div>
        </div>
        
        <div>
          <LoadingButton isLoading={isLoading} buttonName='Export Report' changeButton={checkoutReportData}/>
        </div>
      </div>
    </div>
  )
}

export default CheckoutReport
