import axios from 'axios'
import { BASE_4OVER4_URL } from 'src/utils/constant'
import { deleteCookies, getCookies } from 'src/utils/storageFunction'
import { api } from './api'

const accessToken = getCookies('access-token')

export const ipcApi = axios.create({
  baseURL: BASE_4OVER4_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + accessToken
  }
})

api.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response.status === 401) {
	  deleteCookies('access-token')
	  window.location.assign('/signIn')
  }
  if (error.response.status === 404) {
	  window.location.assign('/404')
  }
  return Promise.reject(error)
})

export default ipcApi