import { faBan, faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { patchCustomer, postCustomer } from 'src/api/customers/customers'
import { IGetCustomerById } from 'src/api/customers/model'
import { openLinkNewTab } from 'src/utils/open-link-new-tab'
import { ICreateCustomerProps } from '../../model'
import AccountInformation from './AccountInformation/AccountInformation'
import classes from './CreateCustomer.module.scss'
import Defaults from './Defaults/Defaults'
import EmailOptions from './EmailOptions/EmailOptions'

import { MetaData } from 'src/ui/MetaData'
const CreateCustomer: FC<ICreateCustomerProps> = ({ userId, customerInfo, authToken, setCustomerInfo }): JSX.Element => {
  const navigate = useNavigate()
  const handleChange = (field: string, value: string | number): void => {

    const salesCustomersField = field.slice('salesCustomers'.length)

    setCustomerInfo(prev => {
      if (field.startsWith('salesCustomers')) {
        return {
          ...prev,
          salesCustomers: {
            ...prev?.salesCustomers,
            [salesCustomersField]: value
          }
        }
      } else if (!field.startsWith('salesCustomers')) {
        return {
          ...prev,
          [field]: value
        }
      }
      return prev
    })
  }

  const editCustomer = async (data: IGetCustomerById, id?: string ): Promise<void> => {
    delete data.userId
    try {
      const action = id ? patchCustomer(id, data) : postCustomer(data)
      await action
      toast.success(`Customer ${id ? 'updated' : 'created'} successfully`)
    } catch (error) {
      console.log(error)

    }
  }



  return (
    <div className={classes.createCustomer}>
      <MetaData title='Edit Customer'/>
      <div className={classes.createCustomer_title}>
        <span>{customerInfo.firstName}</span>
        <span>{customerInfo.salesCustomers?.customerId}</span>
      </div>
      <AccountInformation
        firstName={customerInfo.firstName}
        lastName={customerInfo.lastName}
        userName={customerInfo.username}
        email={customerInfo.email}
        phoneNumber={customerInfo.salesCustomers?.phoneNumber}
        category={customerInfo.salesCustomers?.customerCategory}
        defaultBillingAddres={customerInfo.salesCustomers?.defaultBillingAddress}
        defaultShippingAddress={customerInfo.salesCustomers?.defaultShippingAddress}
        customerId={customerInfo?.salesCustomers?.customerId}
        handleChange={handleChange}
      />
      <Defaults
        salesCustomers={customerInfo.salesCustomers}
        handleChange={handleChange}
      />
      <EmailOptions
        salesCustomers={customerInfo.salesCustomers}
        handleChange={handleChange}
      />
      <div className={classes.createCustomer_lastBlock}>
        <span onClick={() => openLinkNewTab('https://cs.4over4.com/')}> Login with this user Customer Support
        </span >
        <div className={classes.createCustomer_lastBlock_btnBlock}>
          <p
            onClick={() => openLinkNewTab(`https://v2.4over4.com/login?authToken=${authToken}`)}>
            <span className={classes.createCustomer_lastBlock_new}>
            New
            </span>
          Login with this user to v2
          </p>
          <div>
            <button onClick={() => editCustomer(customerInfo, userId)}><FontAwesomeIcon icon={faFloppyDisk} /> Save</button>
            <button onClick={() => navigate('/customers')}><FontAwesomeIcon icon={faBan} /> Cancel</button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CreateCustomer