import { observer } from 'mobx-react-lite'
import { FC, LegacyRef } from 'react'
import { useOutside } from 'src/Hook/useOutside'
import { IPrintingProductStateOfferCardQuantity } from 'src/api/ipc/model'
import { ipcStore } from 'src/stores/IPC/ipcStore'
import { IIPCSelectQuantityProps } from '../../IPC.model'
import classes from '../IPCPriceCalc.module.scss'

const IPCSelectQuantity: FC<IIPCSelectQuantityProps> =
  ({ IPCRes, changeIPCPayloadUpdate }): JSX.Element => {
	 const { loadingStatus: isLoading } = ipcStore
    const { isShow:isOpenQuantityBlock, setIsShow: setOpenQuantityBlock, ref: modalRef } = useOutside(false)

    const handleQuantityChange = (quantity: IPrintingProductStateOfferCardQuantity): void => {
      changeIPCPayloadUpdate('quantity', quantity?.quantity)
      setOpenQuantityBlock(false)
    }

    // !! useEffect(() => {
    //   const handleScroll = (): void => {
    //     setOpenQuantityBlock(false)
    //   }

    //   window.addEventListener('scroll', handleScroll)

    //   return () => {
    //     window.removeEventListener('scroll', handleScroll)
    //   }
    // }, [])


    // const [isFullHeight, setFullHeight] = useState(false)
    // const iPSPrice = ( basePrice: string ): string => {
    //   if (Number(basePrice)) return '$' + Number(basePrice).toFixed(2)
    //   return '-'
    // }
    return (
      <div>
        <label htmlFor='quantity'>
          Choose your quantity
          <div ref={modalRef as unknown as LegacyRef<HTMLDivElement> | undefined}>
            <input disabled={isLoading} readOnly name='quantity' value={IPCRes?.payloadUpdates.quantity} className={`${classes.IPCPriceCalc_quantitySelect} ${isOpenQuantityBlock ? classes.IPCPriceCalc_quantitySelect_openBlock : ''} ${isLoading ? classes.disabled : ''}`} onClick={() => setOpenQuantityBlock(!isOpenQuantityBlock)}></input>
            {isOpenQuantityBlock && (
              <div className={`${classes.quantityBlock}`}
                style={{ marginBottom: IPCRes?.quantities?.length > 9 ? '0px' : '24px' }}>
                {IPCRes?.quantities?.map((el, index) => (
                  <div key={index} className={`${classes.quantityItem} ${isLoading && classes.disabled}`} onClick={() => handleQuantityChange(el)}>
                    <input type='radio'
                      data-quantity={el.quantity}
                      value={el.quantity}
                      checked={IPCRes?.payloadUpdates.quantity === el.quantity}
                      name='quantity' />
                    <div className={classes.quantityItem_inner}>
                      <span>
                        {el.quantity}
                      </span>
                      <span>
                        {IPCRes?.bestRatedQty === el.quantity && <span className={`${classes.quantityItem_inner_tag} ${classes.quantityItem_inner_tag_black}`}>Recommended</span>}
                        {IPCRes?.popularQty === el.quantity && <span className={`${classes.quantityItem_inner_tag} ${classes.quantityItem_inner_tag_green}`}>Most popular</span>}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </label>
      </div>
    )
  }
export default observer(IPCSelectQuantity)
