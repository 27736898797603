import { MetaData } from 'src/ui/MetaData'
import classes from './OrdersReports.module.scss'
import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import OrdersReportsTable from './OrdersReportsTable/OrdersReportsTable'
import { FC, useEffect, useState } from 'react'
import { getAllCategoryReport, postAllReportsCoupons, getAllReportsState, getReportsGroupByCategoryId, getReportsProductByGroupId, postAllReportsSummary } from 'src/api/report/report'
import { IGetAllReportsCategory, IGetAllReportsGroup, IGetAllReportsProduct, IGetAllReportsState, IGetOrderReports, IOrderReportsData, ISummaryReports, ISummaryResult } from 'src/api/report/model'
import { IFilterOrderReportData } from '../model'
import { IErrorMessage, IResponseError } from 'src/utils/errorMessage/model'
import { errorMessage, toastErrorOrFieldError } from 'src/utils/errorMessage/errorMessage'
import { downloadCSV } from 'src/utils/downloadCSV'
import { useLocation } from 'react-router-dom'
import SummaryReportTable from './SummaryReportTable/SummaryReportTable'
import CustomerCategory from '../CustomerCategory/CustomerCategory'
import SelectDate from 'src/ui/selectDate/selectDate'
import LoadingButton from 'src/ui/loadingButton/LoadingButton'
import { IParams } from 'src/api/Preflight/orderList/OrderListNote/model'
const OrdersReports: FC<{ downloadName: string}> = ({ downloadName }): JSX.Element => {
  const location = useLocation()
  const [csvFileName, setCsvFileName] = useState<string>('')
  const [loading, setLoading] = useState<IParams>({})
  const [filterOrderReportData, setFilterOrderReportData] = useState<IFilterOrderReportData>({
    start: '2010-01-01',
    end: '2010-01-01'
  } as IFilterOrderReportData)
  const [ordereReportsInfo, setOrderReportsInfo] = useState<IGetOrderReports>({} as IGetOrderReports)
  const [summaryReportsInfo, setSummaryReportsInfo] = useState<ISummaryReports>({} as ISummaryReports)
  const [reportsCategoryInfo, setReportsCategoryInfo] = useState<IGetAllReportsCategory[]>([])
  const [reportsGroupInfo, setReportsGroupInfo] = useState<IGetAllReportsGroup[]>([])
  const [reportsProductInfo, setReportsProductInfo] = useState<IGetAllReportsProduct[]>([])
  const [reportsStateInfo, setReportsStateInfo] = useState<IGetAllReportsState[]>([])
  const [errorRequest, setErrorRequest] = useState<IErrorMessage>({})


  useEffect(() => {
    setCsvFileName(downloadName)
  },[downloadName])


  const allCategory = async (): Promise<void> => {
    try {
      setReportsCategoryInfo( await getAllCategoryReport())
    } catch (error) {
      console.log(error)
      
    }
  }

  const allState = async (): Promise<void> => {
    try {
      setReportsStateInfo( await getAllReportsState())
    } catch (error) {
      console.log(error)
      
    }
  }
  useEffect(() => {
    allCategory()
    allState()
  },[])

  const groupByCategoryId = async (categryId: string): Promise<void> => {
    try {
      setReportsGroupInfo( await getReportsGroupByCategoryId(categryId))
    } catch (error) {
      console.log(error)
      
    }
  }

  const productByGroupId = async (groupId: string): Promise<void> => {
    try {
      setReportsProductInfo( await getReportsProductByGroupId(groupId))
    } catch (error) {
      console.log(error)
      
    }
  }

  const allOrderReports = async (data: IFilterOrderReportData, field?: string): Promise<void> => {
    try {
      if (location.pathname.includes('reports')) {
        const res = await postAllReportsCoupons(data)
        setOrderReportsInfo(res)
        if (field) {
          downloadCSV<IOrderReportsData[]>(res.results, csvFileName)
        }
      }
      if (location.pathname.includes('summary')) {
        const res = await postAllReportsSummary(data)
        setSummaryReportsInfo(res)
        if (field) {
          downloadCSV<ISummaryResult[]>(res.results, csvFileName)
        }
      }
    } catch (error) {
      const errorMessage = error as IResponseError
      toastErrorOrFieldError(errorMessage?.response?.data, setErrorRequest)
    } finally {
      setLoading({})
    }
  }


  const handleChange = (field: string, value: string | number): void => {
    if (!Object.values(errorRequest).every(error => error === '')) {
      setErrorRequest({ ...errorRequest, [field]: '' })
    }
    if (field === 'category') {
      groupByCategoryId(String(value))
    }
    if (field === 'group') {
      productByGroupId(String(value))
    }     
    if (field === 'emails') {
      const emailsArray = String(value).split(' ')
      setFilterOrderReportData({ ...filterOrderReportData, [field]: emailsArray })
    } else {      
      setFilterOrderReportData({ ...filterOrderReportData, [field]: value })
    } 
  }

  const changeCustomerCategory = (include: string[], exclude: string[]): void => {
    setFilterOrderReportData({
      ...filterOrderReportData,
      customer_category_include: include,
      customer_category_exclude: exclude
    })
  }
  const pageName = (pathName: string): string => {
    if (pathName.includes('reports')) {
      return 'Orders Reports'
    }
    return 'Orders Summary'
  }

  const handleGenerate = (name?: string): void => {    
    if (name?.includes('Export')) {
      setLoading({ ...loading, export:'export' })
      allOrderReports(filterOrderReportData, 'Export')
    } else {
      setLoading({ ...loading,generate:'generate' })
      allOrderReports(filterOrderReportData)
    }
  }

  return (
    <div className={classes.ordersReports}>
      <MetaData title={pageName(location.pathname)} />
      <PageHeader pageName={pageName(location.pathname)} />
      <div className={classes.ordersReports_container}>
        <table>
          <tbody>
            <tr>
              <td>
                <label htmlFor='startDate'>Start Date</label>
              </td>
              <td>
                <div>
                  <SelectDate field='start' handleChange={handleChange}/>
                  {errorMessage('start', errorRequest)}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor='endDate'>End Date</label>
              </td>
              <td>
                <div>
                  <SelectDate field='end' handleChange={handleChange}/>
                  {errorMessage('end', errorRequest)}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor='onlyOrdersWithCoupon'>Only Orders With Coupon</label>
              </td>
              <td>
                <input
                  id='onlyOrdersWithCoupon'
                  type='checkbox'
                  name='orders_with_coupon'
                  onChange={(e) => handleChange(e.target.name, `${Number(e.target.checked)}`)}
                />
                {errorMessage('orders_with_coupon', errorRequest)}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor='clientsFirstOrder'>Clients First Order</label>
              </td>
              <td>
                <input
                  id='clientsFirstOrder'
                  type='checkbox'
                  name='first_order'
                  onChange={(e) => handleChange(e.target.name, `${Number(e.target.checked)}`)}
                />
                {errorMessage('first_order', errorRequest)}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor='product'>Product</label>
              </td>
              <td>
                <select 
                  className={classes.ordersReports_container_differSelect}
                  id='product'
                  name='category'
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                >
                  <option selected value='-1'>Category</option>
                  {
                    reportsCategoryInfo.length && reportsCategoryInfo.map(category => (
                      <option key={category.productCategoryId} value={category.productCategoryId}>{category.name}</option>
                    ))
                  }
                </select>
                <select 
                  className={classes.ordersReports_container_differSelect}
                  id='product'
                  name='group'
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                >
                  <option selected value='-1'>Group</option>
                  {
                    reportsGroupInfo.length && reportsGroupInfo.map(group => (
                      <option key={group.productGroupId} value={group.productGroupId}>{group.name}</option>
                    ))
                  }
                </select>
                <select 
                  className={classes.ordersReports_container_differSelect} 
                  id='product'
                  name='product'
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                >
                  <option selected value='-1'>Product</option>
                  {
                    reportsProductInfo.length && reportsProductInfo.map(product => (
                      <option key={product.print_product_id} value={product.print_product_id}>{product.public_label}</option>
                    ))
                  }
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor='jobTotalProductPrice'>Job Total Product Price</label>
              </td>
              <td>
                <select className={classes.ordersReports_container_differInput}
                  name='amount_limit'
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                >
                  <option value='-1'>N/A</option>
                  <option value='0'>at least</option>
                  <option value='1'>no more than</option>
                </select>
                {errorMessage('amount_limit', errorRequest)}

                <span>$ </span>
                <input
                  className={classes.ordersReports_container_differInput}
                  id='jobTotalProductPrice'
                  type='text'
                  name='amount'
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
                {errorMessage('amount', errorRequest)}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor='jobQuantity'>Job Quantity</label>
              </td>
              <td>
                <select
                  className={classes.ordersReports_container_differInput}
                  name='quantity_limit'
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                >
                  <option value='-1'>N/A</option>
                  <option value='0'>at least</option>
                  <option value='1'>no more than</option>
                </select>
                {errorMessage('quantity_limit', errorRequest)}
                <input
                  className={classes.ordersReports_container_differInput}
                  id='jobQuantity'
                  type='text'
                  name='quantity'
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
                {errorMessage('quantity', errorRequest)}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor='jobTurnaround'>Job Turnaround</label>
              </td>
              <td>
                <select id='jobTurnaround' name='turnaround'
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                >
                  <option value='-1'>N/A</option>
                  <option value='0'>Same Day</option>
                  <option value='1'>1 Day</option>
                  <option value='2'>2 Day</option>
                  <option value='3'>3 Day</option>
                  <option value='4'>4 Day</option>
                  <option value='5'>5 Day</option>
                  <option value='6'>6 Day</option>
                  <option value='7'>7 Day</option>
                </select>
                {errorMessage('turnaround', errorRequest)}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor='state'>State</label>
              </td>
              <td>
                <select id='state'>
                  <option value='-1' selected>N/A</option>
                  {
                    reportsStateInfo?.length && reportsStateInfo.map(state => (
                      <option key={state.addressStateId} value={state.addressStateId}>
                        {
                          state.stateAbbreviation + ' - ' + state.name
                        }
                      </option>
                    ))
                  }
                </select>
                {errorMessage('state', errorRequest)}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor='city'>City</label>
              </td>
              <td>
                <input id='city' name='city' type='text' 
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
                {errorMessage('city', errorRequest)}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor='days'>Days</label>
              </td>
              <td>
                <select id='days' name='days'>
                  <option value='0'>All(monday-sunday)</option>
                  <option value='1'>Weekdays(monday-friday)</option>
                  <option value='2'>Weekends(saturday-sunday)</option>
                </select>
                {errorMessage('days', errorRequest)}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor='emails'>Emails</label>
              </td>
              <td>
                <textarea id='emails' name='emails'
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
                {errorMessage('emails', errorRequest)}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor='emails'>Customer category</label>
              </td>
              <td>
                <CustomerCategory changeCustomerCategory={changeCustomerCategory}/>
              </td>
            </tr>
            <tr>
              <td>
                <LoadingButton isLoading={loading.generate === 'generate' } buttonName='Generate Report' changeButton={handleGenerate}/>
                <LoadingButton isLoading={loading.export === 'export'} buttonName='Export Report' changeButton={handleGenerate}/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {
        location.pathname.includes('reports') ?
          <OrdersReportsTable ordereReportsInfo={ordereReportsInfo} /> :
          <SummaryReportTable summaryReportsInfo={summaryReportsInfo}/>
      }
    </div>
  )
}

export default OrdersReports