import { api, formDataApi } from 'src/api/api'
import { IEditEstateEmailData, IEstateEmailById, IEstateEmails, IWfbanner } from './model'
import { IParams } from 'src/api/Preflight/orderList/OrderListNote/model'

export const getAllEstateEmail = (params: IParams): Promise<IEstateEmails> => 
  api.get('/state_email', { params }).then(res => res.data)

export const getEstateEmailById = (id: string): Promise<IEstateEmailById> => 
  api.get(`/state_email/${id}`).then(res => res.data.data)

export const patchEstateEmail = (data: IEditEstateEmailData): Promise<void> =>
  api.patch(`/state_email/${data.stateId}`, data).then(res => res.data)

export const postUploadBannerImage = (data: FormData ): Promise<IWfbanner[]> =>
  formDataApi.post('/upload/banner', data).then(res => res.data)