export const formDataGeneration = <T>(data: T): FormData => {
  const formData = new FormData()
  if (data) {
    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key]) && data[key].length > 0) {
        data[key].forEach(item => {
          formData.append(key.toString(), item)
        })
      } else if (typeof data[key] === 'number' ) {      
        formData.append(key, String(data[key]))
      } else {
        formData.append(key, data[key] as string)
      }
    })
  }
  return formData
}
