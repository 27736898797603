import { FC, memo } from 'react'
import { IIPCSelectAttributeItemProps } from 'src/Components/IPC/IPC.model'

const IPCSelectAttributeItem: FC<IIPCSelectAttributeItemProps> = memo(
  ({ attributeOptionId, attributeLabel, optionLabel, optionOrder }): JSX.Element => {
    return (
      <option
        data-attributeoptionid={attributeOptionId}
        value={attributeOptionId}
        data-attributelabel={attributeLabel}
        data-optionorder={optionOrder}
        data-optionlabel={optionLabel}
      >
        {optionLabel}
      </option>
    )
  },
)
export default IPCSelectAttributeItem
