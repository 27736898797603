import { api, formDataApi } from '../api'
import { IAttributeOptions, ICustomOrdersUploadFile, ICustomerAddressesData, IGetCustomOrderCreate, IPaymentMethods, IProductGroupData, IReprint, ISendAddCustomOrder, IShipmentMethodsData } from './model'

export const getCustomOrderCreate = (): Promise<IGetCustomOrderCreate> =>
  api.get('/dashboard/customOrders/addCustomOrderPage').then(res => res.data)

export const getAttributeOptions = (attributeId: string): Promise<IAttributeOptions[]> =>
  api.get(`/dashboard/customOrders/getAttributeOptions/${attributeId}`).then(res => res.data)

export const getProductAttributes = (productGroupId: string): Promise<IProductGroupData[]> =>
  api.get(`/dashboard/customOrders/getProductAttributes/${productGroupId}`).then(res => res.data)

export const getCustomerAddresses = (customerId: number): Promise<ICustomerAddressesData[]> =>
  api.get(`/dashboard/customOrders/getCustomerAddresses/${customerId}`).then(res => res.data)

export const getShipmentMethods = (): Promise<IShipmentMethodsData[]> =>
  api.get('/dashboard/customOrders/getShipmentMethods').then(res => res.data)

export const getPaymentMethods = (): Promise<IPaymentMethods[]> =>
  api.get('/dashboard/customOrders/getPaymentMethods').then(res => res.data)

export const postCustomOrder = (data: ISendAddCustomOrder): Promise<{orderId: number}> =>
  api.post('/dashboard/customOrders/saveOrder',data).then(res => res.data)

export const postCustomOrdersUploadFile = (file: FormData): Promise<ICustomOrdersUploadFile> =>
  formDataApi.post('/dashboard/customOrders/uploadFile', file).then(res => res.data)

export const getRepprint = (jobId: number): Promise<IReprint> =>
  api.get(`/dashboard/customOrders/getJobDetails/${jobId}`).then(res => res.data)