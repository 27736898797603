import { FC, memo } from 'react'

import { IIPCPriceCalcProps } from '../IPC.model'
import IPCSelectAttributes from './Attributes/Atributes'
import classes from './IPCPriceCalc.module.scss'
import IPCSelectQuantity from './Quantity/Quantity'
import IPCSelectSize from './Size/IPCSelectSize'
import IPCSelectTurnaround from './Turnaround/Turnaround'

const IPCPriceCalc: FC<IIPCPriceCalcProps> = memo(
  ({
    IPCRes,
    changeIPCPayloadUpdate,
    payloadUpdates,
    isMajesticPostcards
  }): JSX.Element => {

    // ??  const currentlyLookingUserCount = useMemo(() => Math.round(8 + Math.random() * 27), [])

    // ??  useEffect(() => {
    //   dispatch(setCurrentlyLookingUserCount(currentlyLookingUserCount))
    // }, [])

    return (
      <div className={classes.IPCPriceCalc_body_selectOptions}>
        <IPCSelectSize
          IPCRes={IPCRes}
          changeIPCPayloadUpdate={changeIPCPayloadUpdate}
          payloadUpdates={payloadUpdates}
        />
        <IPCSelectQuantity IPCRes={IPCRes} changeIPCPayloadUpdate={changeIPCPayloadUpdate} />
        <IPCSelectAttributes
          IPCRes={IPCRes}
          changeIPCPayloadUpdate={changeIPCPayloadUpdate}
          payloadUpdates={payloadUpdates}
          isMajesticPostcards={isMajesticPostcards}
        />
        <IPCSelectTurnaround
          IPCRes={IPCRes}
          changeIPCPayloadUpdate={changeIPCPayloadUpdate}
          payloadUpdates={payloadUpdates}
        />
      </div>
    )
  },
)

export default IPCPriceCalc
