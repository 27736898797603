import { makeAutoObservable, toJS } from 'mobx'

import { ICurrentProductSizes, IPrintingProductStateDiecutTrimData } from 'src/Components/IPC/IPC.model'
import { IIPCDataResponse } from 'src/api/ipc/model'
import { IInitialState, IPrintingProductStateSelectedTrim } from './model'

class IpcStore {
  currentProductSizes: ICurrentProductSizes = {
    width: 0,
    height: 0,
  }

  ipcRes: IIPCDataResponse = {} as IIPCDataResponse
  isLoading = false
  isClickedOnCustomSize = false
  isShowSizesPopup = false
  data: IInitialState = {
    diecutTrimData: [],
    selectedTrimSizes: null,
    isAvailableTrimOrientation: false
  }

  constructor () {
    makeAutoObservable(this)
  }

  setCurrentProductSizes = (payload: {width: string; height: string, printProductId?: number}): void => {
    if (!payload?.printProductId) {
      this.currentProductSizes.width = Number(payload.width)
      this.currentProductSizes.height = Number(payload.height)
    } else {
      this.currentProductSizes.printProductId = Number(payload?.printProductId)
    }
  }

  getCurrentProductSizes = (): ICurrentProductSizes | undefined => {
    return toJS(this.currentProductSizes)
  }

  get isClickedCustomSize (): boolean {
    return toJS(this.isClickedOnCustomSize)
  }

  set isClickedCustomSize (payload: boolean) {
    this.isClickedOnCustomSize = payload
  }

  get isShowSizesPopupWarning (): boolean {
    return toJS(this.isShowSizesPopup)
  }

  set isShowSizesPopupWarning (payload: boolean) {
    this.isShowSizesPopup = payload
  }

  get loadingStatus (): boolean {
    return toJS(this.isLoading)
  }

  set loadingStatus (payload: boolean) {
    this.isLoading = payload
  }

  setDicuteTrimData = (payload: IPrintingProductStateDiecutTrimData[]): void => {
    this.data.diecutTrimData = payload
  }

  setSelectedTrim = ( payload: IPrintingProductStateSelectedTrim): void => {
    this.data.selectedTrim = payload
  }

  changeTrimOrientationAvailabilityStatus = (payload: {width: number, height: number}): void => {
    this.data.isAvailableTrimOrientation = payload.width !== payload.height
    this.data.selectedTrimSizes = payload
  }
}

export const ipcStore = new IpcStore()