import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import classes from './Cutter.module.scss'
import { MetaData } from 'src/ui/MetaData'
import Loading from 'src/Components/Loading/Loading'
import { FC, useEffect, useState } from 'react'
import { IGetCutterData } from 'src/api/postApproval/cutter/model'
import { getAllCutterByType } from 'src/api/postApproval/cutter/cutter'
import CutterAction from './CutterAction/CutterAction'
import { useLocation, useNavigate } from 'react-router-dom'
const Cutter: FC<{requestType: number}> = ({ requestType }): JSX.Element => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [cutterInfo, setCutterInfo] = useState<IGetCutterData[]>([])
  const cutterData = async (type: number): Promise<void> => {
    try {
      setIsLoading(true)
      setCutterInfo(await getAllCutterByType(type))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    cutterData(requestType)
  },[requestType])

  const pageName = (requestType: number): string =>{
    if (requestType) {
      return 'Die Cutting'
    } 
    return 'Cutter'
  }

  const updateCurrentItemStatus = (gangId: number): void => {
    setCutterInfo(prev => {
      return [...prev.map(e => {
        if (e.gang_id === gangId) {
          e.status = 'Cutting'
        }
        return e
      })]
    })
  }

  const renderSinglePage = (gangId: number): void => {
    navigate(`${pathname}/${gangId}`)
  }

  return (
    <> 
      {
        isLoading ? <Loading/> :
          <div className={classes.cutter}>
            <MetaData title={pageName(requestType)}/>
            <div className={classes.cutter_container}>
              <PageHeader pageName={pageName(requestType)} pageWidth='100'/>
              <div className={classes.cutter_container_tableBlock}>
                <table>
                  <thead>
                    <tr>
                      <th>Gang</th>
                      <th>Run</th>
                      <th>Paper</th>
                      <th>Due</th>
                      <th>Custom Size</th>
                      <th>Bindery</th>
                      <th>Lots</th>
                      <th>MES</th>
                      <th>Notes</th>
                      <th>FSC Certified</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      cutterInfo.length ? cutterInfo.map(e => (

                        <tr key={e.gang_id}
                          onClick={() => renderSinglePage(e.gang_id)}
                        >
                          <td>{e.gang_id}</td>
                          <td>{e.quantity}</td>
                          <td>{e.internal_name}</td>
                          <td>{e.due_on}</td>
                          <td>{e.isCustomSize}</td>
                          <td>{e.bindery}</td>
                          <td>{e.hasLots}</td>
                          <td>{e.messengered}</td>
                          <td><span>{e.hasJobNotes}</span></td>
                          <td>{e.fsc}</td>
                          <td> 
                            <CutterAction 
                              status={e.status}
                              gangId={e.gang_id}
                              updateCurrentItemStatus={updateCurrentItemStatus}
                            />
                          </td>
                        </tr>
                      )) : null
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      }
    </>
  )
}
export default Cutter